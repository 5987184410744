export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
export type MakeEmpty<T extends { [key: string]: unknown }, K extends keyof T> = { [_ in K]?: never };
export type Incremental<T> = T | { [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string; }
  String: { input: string; output: string; }
  Boolean: { input: boolean; output: boolean; }
  Int: { input: number; output: number; }
  Float: { input: number; output: number; }
  Color: { input: any; output: any; }
  Date: { input: any; output: any; }
  DateTime: { input: any; output: any; }
  Domain: { input: any; output: any; }
  Email: { input: any; output: any; }
  HTML: { input: any; output: any; }
  JSON: { input: any; output: any; }
  JSONObject: { input: any; output: any; }
  JWT: { input: any; output: any; }
  Password: { input: any; output: any; }
  Phone: { input: any; output: any; }
  Point: { input: any; output: any; }
  Time: { input: any; output: any; }
  URI: { input: any; output: any; }
  Upload: { input: any; output: any; }
};

export type AbCampaign = {
  __typename: 'AbCampaign';
  /** Campaign id. */
  campaignId: Maybe<Scalars['String']['output']>;
  /** Scenario id. */
  scenarioId: Maybe<Scalars['String']['output']>;
};

export type ActionItem = {
  __typename: 'ActionItem';
  /** The total available quantity in the corresponding warehouse */
  availableQty: Maybe<Scalars['Int']['output']>;
  /** The quantity that is requested as part of this action */
  requestQty: Maybe<Scalars['Int']['output']>;
  /** The SKU */
  sku: Maybe<Scalars['String']['output']>;
};

export type ActionItems = {
  __typename: 'ActionItems';
  /** An array of items that are part of the action */
  items: Maybe<Array<Maybe<ActionItem>>>;
  /** The source details */
  source: Maybe<Source>;
};

export type ActiveWidgets = {
  __typename: 'ActiveWidgets';
  contactUs: Maybe<WidgetContactUs>;
  dropDownCart: Maybe<WidgetDropDownCart>;
  productDetailsAddToCart: Maybe<WidgetProductDetailsAddToCart>;
  sitewideBanner: Maybe<WidgetSiteWideBanner>;
};

export type AddAmGiftCardProductsToCartInput = {
  /** The unique ID that identifies the customer's cart */
  cartId: Scalars['String']['input'];
  cartItems: Array<InputMaybe<AmGiftCardProductCartItemInput>>;
};

export type AddAmGiftCardProductsToCartOutput = {
  __typename: 'AddAmGiftCardProductsToCartOutput';
  /** Describes the content of the specified shopping cart */
  cart: Cart;
};

/** Defines the bundle products to add to the cart. */
export type AddBundleProductsToCartInput = {
  /** The ID of the cart. */
  cartId: Scalars['String']['input'];
  /** An array of bundle products to add. */
  cartItems: Array<InputMaybe<BundleProductCartItemInput>>;
};

/** Contains details about the cart after adding bundle products. */
export type AddBundleProductsToCartOutput = {
  __typename: 'AddBundleProductsToCartOutput';
  /** The cart after adding products. */
  cart: Cart;
};

/** Defines the configurable products to add to the cart. */
export type AddConfigurableProductsToCartInput = {
  /** The ID of the cart. */
  cartId: Scalars['String']['input'];
  /** An array of configurable products to add. */
  cartItems: Array<InputMaybe<ConfigurableProductCartItemInput>>;
};

/** Contains details about the cart after adding configurable products. */
export type AddConfigurableProductsToCartOutput = {
  __typename: 'AddConfigurableProductsToCartOutput';
  /** The cart after adding products. */
  cart: Cart;
};

export type AddDownloadableProductsToCartInput = {
  /** The ID of the cart. */
  cartId: Scalars['String']['input'];
  /** An array of downloadable products to add. */
  cartItems: Array<InputMaybe<DownloadableProductCartItemInput>>;
};

/** Contains details about the cart after adding downloadable products. */
export type AddDownloadableProductsToCartOutput = {
  __typename: 'AddDownloadableProductsToCartOutput';
  /** The cart after adding products. */
  cart: Cart;
};

/** Contains details about the cart after adding products to it. */
export type AddProductsToCartOutput = {
  __typename: 'AddProductsToCartOutput';
  /** The cart after products have been added. */
  cart: Cart;
  /** Contains errors encountered while adding an item to the cart. */
  userErrors: Array<Maybe<CartUserInputError>>;
};

/** Contains products to add to an existing compare list. */
export type AddProductsToCompareListInput = {
  /** An array of product IDs to add to the compare list. */
  products: Array<InputMaybe<Scalars['ID']['input']>>;
  /** The unique identifier of the compare list to modify. */
  uid: Scalars['ID']['input'];
};

/** Contains the customer's wish list and any errors encountered. */
export type AddProductsToWishlistOutput = {
  __typename: 'AddProductsToWishlistOutput';
  /** An array of errors encountered while adding products to a wish list. */
  userErrors: Array<Maybe<WishListUserInputError>>;
  /** Contains the wish list with all items that were successfully added. */
  wishlist: Wishlist;
};

/** Defines the simple and group products to add to the cart. */
export type AddSimpleProductsToCartInput = {
  /** The unique ID of a `Cart` object. */
  cartId: Scalars['String']['input'];
  /** An array of simple and group items to add. */
  cartItems: Array<InputMaybe<SimpleProductCartItemInput>>;
};

/** Contains details about the cart after adding simple or group products. */
export type AddSimpleProductsToCartOutput = {
  __typename: 'AddSimpleProductsToCartOutput';
  /** The cart after adding products. */
  cart: Cart;
};

/** Defines the virtual products to add to the cart. */
export type AddVirtualProductsToCartInput = {
  /** The unique ID of a `Cart` object. */
  cartId: Scalars['String']['input'];
  /** An array of virtual products to add. */
  cartItems: Array<InputMaybe<VirtualProductCartItemInput>>;
};

/** Contains details about the cart after adding virtual products. */
export type AddVirtualProductsToCartOutput = {
  __typename: 'AddVirtualProductsToCartOutput';
  /** The cart after adding products. */
  cart: Cart;
};

/** Contains the resultant wish list and any error information. */
export type AddWishlistItemsToCartOutput = {
  __typename: 'AddWishlistItemsToCartOutput';
  /** An array of errors encountered while adding products to the customer's cart. */
  addWishlistItemsToCartUserErrors: Array<Maybe<WishlistCartUserInputError>>;
  /** Indicates whether the attempt to add items to the customer's cart was successful. */
  status: Scalars['Boolean']['output'];
  /** Contains the wish list with all items that were successfully added. */
  wishlist: Wishlist;
};

/** This enumeration defines the address group types */
export enum AddressGroupEnum {
  /**
   * Business
   * @deprecated
   */
  BUSINESS = 'BUSINESS',
  /**
   * Residential
   * @deprecated
   */
  RESIDENTIAL = 'RESIDENTIAL'
}

export type AdminEnum = {
  __typename: 'AdminEnum';
  type: Scalars['String']['output'];
  values: Maybe<Array<Maybe<AdminEnumValue>>>;
};

export type AdminEnumValue = {
  __typename: 'AdminEnumValue';
  title: Scalars['String']['output'];
  value: Scalars['String']['output'];
};

export type AdminLayout = {
  __typename: 'AdminLayout';
  areas: Maybe<Scalars['String']['output']>;
  cells: Array<SimpleRowItem>;
  columnGap: Maybe<Scalars['String']['output']>;
  columns: Maybe<Scalars['String']['output']>;
  rowGap: Maybe<Scalars['String']['output']>;
  rows: Maybe<Scalars['String']['output']>;
  template: Maybe<Scalars['String']['output']>;
};

export type AdminPathInput = {
  icon?: InputMaybe<Scalars['String']['input']>;
  path: Scalars['String']['input'];
  title: Scalars['String']['input'];
};

export type AdminPathItem = {
  __typename: 'AdminPathItem';
  icon: Maybe<Scalars['String']['output']>;
  path: Scalars['String']['output'];
  title: Maybe<Scalars['String']['output']>;
  type: Maybe<Scalars['String']['output']>;
};

/** Contains information for each filterable option (such as price, category `UID`, and custom attributes). */
export type Aggregation = {
  __typename: 'Aggregation';
  /** Attribute code of the aggregation group. */
  attributeCode: Scalars['String']['output'];
  /** The number of options in the aggregation group. */
  count: Maybe<Scalars['Int']['output']>;
  /** The attribute frontend input type model. */
  frontendInput: Maybe<Scalars['String']['output']>;
  granularity: Maybe<Scalars['Int']['output']>;
  /** Whether the aggregation has more values to be displayed. */
  hasMore: Maybe<Scalars['Boolean']['output']>;
  isDefault: Scalars['Boolean']['output'];
  /** The aggregation display name. */
  label: Maybe<Scalars['String']['output']>;
  /** Array of options for the aggregation. */
  options: Maybe<Array<Maybe<AggregationOption>>>;
  /** The relative position of the attribute in a layered navigation block. */
  position: Maybe<Scalars['Int']['output']>;
  /** Whether the aggregation has an html attribute rel="nofollow" to be displayed at filter links of current attribute in Layered Navigation. */
  relNofollow: Maybe<Scalars['Boolean']['output']>;
  type: AggregationTypeEnum;
};

/** An implementation of `AggregationOptionInterface`. */
export type AggregationOption = AggregationOptionInterface & {
  __typename: 'AggregationOption';
  /** The number of items that match the aggregation option. */
  count: Maybe<Scalars['Int']['output']>;
  /** The display label for an aggregation option. */
  label: Maybe<Scalars['String']['output']>;
  /** The internal ID that represents the value of the option. */
  value: Scalars['String']['output'];
};

/** Defines aggregation option fields. */
export type AggregationOptionInterface = {
  /** The number of items that match the aggregation option. */
  count: Maybe<Scalars['Int']['output']>;
  /** The display label for an aggregation option. */
  label: Maybe<Scalars['String']['output']>;
  /** The internal ID that represents the value of the option. */
  value: Scalars['String']['output'];
};

/** This enumeration defines the aggregation types */
export enum AggregationTypeEnum {
  /**
   * Category
   * @deprecated
   */
  CATEGORY = 'CATEGORY',
  /**
   * Ignore
   * @deprecated
   */
  IGNORE = 'IGNORE',
  /**
   * Multi Select
   * @deprecated
   */
  MULTI_SELECT = 'MULTI_SELECT',
  /**
   * Single Select
   * @deprecated
   */
  SINGLE_SELECT = 'SINGLE_SELECT',
  /**
   * Slider
   * @deprecated
   */
  SLIDER = 'SLIDER'
}

export type AggregationTypeOverride = {
  __typename: 'AggregationTypeOverride';
  /** The type of aggregation to use for the attribute. */
  aggregationType: Maybe<AggregationTypeEnum>;
  /** The attribute code. */
  attributeCode: Scalars['String']['output'];
};

/** Filter category aggregations in layered navigation. */
export type AggregationsCategoryFilterInput = {
  /** Indicates whether to include only direct subcategories or all children categories at all levels. */
  includeDirectChildrenOnly?: InputMaybe<Scalars['Boolean']['input']>;
};

/** An input object that specifies the filters used in product aggregations. */
export type AggregationsFilterInput = {
  /** Filter category aggregations in layered navigation. */
  category?: InputMaybe<AggregationsCategoryFilterInput>;
};

/** Contains details about the gift card account */
export type AmGiftCardAccount = {
  __typename: 'AmGiftCardAccount';
  /** Gift card account code */
  code: Maybe<Scalars['String']['output']>;
  /** Current balance of gift card */
  currentBalance: Maybe<Money>;
  /** Gift card expiration date */
  expirationDate: Maybe<Scalars['String']['output']>;
  /** Gift card status */
  status: Maybe<Scalars['String']['output']>;
  /** Usage information of gift card */
  usage: Maybe<Scalars['String']['output']>;
};

export type AmGiftCardAccountInput = {
  /** Defines the input required to identify the gift card account */
  amGiftCardCode: Scalars['String']['input'];
};

export type AmGiftCardCartItem = CartItemInterface & {
  __typename: 'AmGiftCardCartItem';
  /** Image of the gift card product */
  amGiftcardImage: Maybe<Scalars['String']['output']>;
  /** Options of the gift card product */
  amGiftcardOptions: Array<Maybe<AmGiftCardOption>>;
  /** An array of errors encountered while loading the cart item */
  errors: Maybe<Array<Maybe<CartItemError>>>;
  /** @deprecated Use `uid` instead. */
  id: Scalars['String']['output'];
  /** Contains details about the price of the item, including taxes and discounts. */
  prices: Maybe<CartItemPrices>;
  /** Details about an item in the cart. */
  product: ProductInterface;
  /** The quantity of this item in the cart. */
  quantity: Scalars['Float']['output'];
  /** The unique ID for a `CartItemInterface` object. */
  uid: Scalars['ID']['output'];
};

export type AmGiftCardCodeInAccountInput = {
  /** Gift card code. */
  amGiftcardCode: Scalars['String']['input'];
};

/** This enumeration defines the type of fee that can be applied to gift card */
export enum AmGiftCardFeeTypeEnum {
  /** @deprecated  */
  FIXED = 'FIXED',
  /** @deprecated  */
  PERCENT = 'PERCENT'
}

/** AmGiftCardImage contains image data of gift card */
export type AmGiftCardImage = {
  __typename: 'AmGiftCardImage';
  /** Unique ID of gift card image */
  imageId: Maybe<Scalars['Int']['output']>;
  /** The path of Gift Card Image */
  imagePath: Maybe<Scalars['String']['output']>;
  /** Either Enabled or Disabled */
  status: Maybe<Scalars['Int']['output']>;
  /** Gift Card image title */
  title: Maybe<Scalars['String']['output']>;
  /** The unique ID for a Image object */
  uid: Maybe<Scalars['ID']['output']>;
  /** Is user uploaded flag */
  userUpload: Maybe<Scalars['Boolean']['output']>;
};

export type AmGiftCardMutationCommonOutput = {
  __typename: 'AmGiftCardMutationCommonOutput';
  /** Is mutation was failed. */
  error: Maybe<Scalars['Boolean']['output']>;
  /** Result message. */
  message: Maybe<Scalars['String']['output']>;
};

export type AmGiftCardOption = {
  __typename: 'AmGiftCardOption';
  /** Code of gift card option */
  code: Maybe<Scalars['String']['output']>;
  /** Label of gift card option */
  label: Maybe<Scalars['String']['output']>;
  /** Value of gift card option */
  value: Maybe<Scalars['String']['output']>;
};

export type AmGiftCardOptionsInput = {
  /** Predefined price of gift card */
  amGiftcardAmount?: InputMaybe<Scalars['Float']['input']>;
  /** Custom price of gift card */
  amGiftcardAmountCustom?: InputMaybe<Scalars['Float']['input']>;
  /** Custom image of gift card */
  amGiftcardCustomImage?: InputMaybe<Scalars['String']['input']>;
  /** Delivery date of gift card */
  amGiftcardDateDelivery?: InputMaybe<Scalars['String']['input']>;
  /** Timezone of gift card delivery date */
  amGiftcardDateDeliveryTimezone?: InputMaybe<Scalars['String']['input']>;
  /** Image of gift card */
  amGiftcardImage?: InputMaybe<Scalars['Int']['input']>;
  /** Message from person who bought gift card that will come with product */
  amGiftcardMessage?: InputMaybe<Scalars['String']['input']>;
  /** Email of person who will recieve virtual gift card */
  amGiftcardRecipientEmail?: InputMaybe<Scalars['String']['input']>;
  /** Name of person who will recieve gift card */
  amGiftcardRecipientName?: InputMaybe<Scalars['String']['input']>;
  /** Name of person who bought the gift card */
  amGiftcardSenderName?: InputMaybe<Scalars['String']['input']>;
  /** Card type for combined gift cards. Can be either printed virtual or combined */
  amGiftcardType?: InputMaybe<Scalars['Int']['input']>;
  /** Flag to mark if gift card should be delivired to specified date */
  isDateDelivery?: InputMaybe<Scalars['Boolean']['input']>;
};

export type AmGiftCardPreview = {
  __typename: 'AmGiftCardPreview';
  /** Rendered preview of Gift Card. */
  content: Maybe<Scalars['String']['output']>;
};

export type AmGiftCardPreviewInput = {
  /** Predefined price of gift card */
  amGiftcardAmount?: InputMaybe<Scalars['Float']['input']>;
  /** Custom price of gift card */
  amGiftcardAmountCustom?: InputMaybe<Scalars['Float']['input']>;
  /** Custom Image name with extension received from image upload API endpoint. */
  amGiftcardCustomImage?: InputMaybe<Scalars['String']['input']>;
  /** Image of gift card */
  amGiftcardImage?: InputMaybe<Scalars['Int']['input']>;
  /** Message from person who bought gift card that will come with product */
  amGiftcardMessage?: InputMaybe<Scalars['String']['input']>;
  /** Name of person who will recieve gift card */
  amGiftcardRecipientName?: InputMaybe<Scalars['String']['input']>;
  /** Name of person who bought the gift card */
  amGiftcardSenderName?: InputMaybe<Scalars['String']['input']>;
};

/** AmGiftCardPrice contains data of defined value of gift card */
export type AmGiftCardPrice = {
  __typename: 'AmGiftCardPrice';
  /** An internal attribute ID */
  attributeId: Maybe<Scalars['Int']['output']>;
  /** Unique ID of gift card price */
  priceId: Maybe<Scalars['Int']['output']>;
  /** The value of the price */
  value: Maybe<GiftCardPriceValue>;
  /** ID of the website price is assigned to */
  websiteId: Maybe<Scalars['Int']['output']>;
};

/** AmGiftCardProduct defines properties of a gift card, including arrays of availiable values and images of the specific gift card */
export type AmGiftCardProduct = CustomizableProductInterface & PhysicalProductInterface & ProductInterface & RoutableInterface & {
  __typename: 'AmGiftCardProduct';
  /** @deprecated Use the `custom_attributes` field instead. */
  age: Maybe<Scalars['String']['output']>;
  /** Indicates whether customers have the ability to set the value of the gift card */
  amAllowOpenAmount: Maybe<Scalars['Boolean']['output']>;
  /** Email template unique ID which will be send to the virtual or combined cart receiver */
  amEmailTemplate: Maybe<Scalars['String']['output']>;
  /** Indicates whether gift card have additional fee for purchase */
  amGiftcardFeeEnable: Maybe<Scalars['Boolean']['output']>;
  /** Either PERCENT or FIXED */
  amGiftcardFeeType: Maybe<AmGiftCardFeeTypeEnum>;
  /** Value of fee */
  amGiftcardFeeValue: Maybe<Scalars['Float']['output']>;
  /** The number of days after purchase until the gift card expires */
  amGiftcardLifetime: Maybe<Scalars['Int']['output']>;
  /** An array that contains information about the values of gift card */
  amGiftcardPrices: Maybe<Array<Maybe<AmGiftCardPrice>>>;
  /** Either VIRTUAL PRINTED OR COMBINED */
  amGiftcardType: Maybe<AmGiftCardTypeEnum>;
  /** An array that contains information about associated images to Gift Card */
  amImages: Maybe<Array<Maybe<AmGiftCardImage>>>;
  /** The maximum acceptable value of an open amount gift card */
  amOpenAmountMax: Maybe<GiftCardPriceValue>;
  /** The minimum acceptable value of an open amount gift card */
  amOpenAmountMin: Maybe<GiftCardPriceValue>;
  /** Information about guest highlight points available by product. */
  amastyRewardsGuestHighlights: Maybe<HighlightOutput>;
  /** Information about highlight points available by product. */
  amastyRewardsHighlights: Maybe<HighlightOutput>;
  /**
   * The attribute set assigned to the product.
   * @deprecated The field should not be used on the storefront.
   */
  attributeSetId: Maybe<Scalars['Int']['output']>;
  /** Returns all assigned product attributes and their values */
  attributes: Array<Maybe<ProductAttributes>>;
  /** Returns the brand of the product */
  brand: Maybe<Brand>;
  cacheBuster: Maybe<Scalars['String']['output']>;
  /** The relative canonical URL. This value is returned only if the system setting 'Use Canonical Link Meta Tag For Products' is enabled. */
  canonicalUrl: Maybe<Scalars['String']['output']>;
  cartItemUid: Maybe<Scalars['String']['output']>;
  /** The categories assigned to a product. */
  categories: Maybe<Array<Maybe<CategoryInterface>>>;
  /** @deprecated Use the `custom_attributes` field instead. */
  colour: Maybe<Scalars['Int']['output']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  concentration: Maybe<Scalars['Int']['output']>;
  /** The product's country of origin. */
  countryOfManufacture: Maybe<Scalars['String']['output']>;
  coverImage: Image;
  /**
   * Timestamp indicating when the product was created.
   * @deprecated The field should not be used on the storefront.
   */
  createdAt: Maybe<Scalars['String']['output']>;
  /** Crosssell Products */
  crosssellProducts: Maybe<Array<Maybe<ProductInterface>>>;
  /** @deprecated Use the `custom_attributes` field instead. */
  department: Maybe<Scalars['String']['output']>;
  /** Detailed information about the product. The value can include simple HTML tags. */
  description: Maybe<ComplexTextValue>;
  /** @deprecated Use the `custom_attributes` field instead. */
  flavour: Maybe<Scalars['Int']['output']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  fragrance: Maybe<Scalars['Int']['output']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  gender: Maybe<Scalars['String']['output']>;
  /** Indicates whether a gift message is available. */
  giftMessageAvailable: Maybe<Scalars['String']['output']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  hairType: Maybe<Scalars['String']['output']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  height: Maybe<Scalars['Float']['output']>;
  /**
   * The ID number assigned to the product.
   * @deprecated Use the `uid` field instead.
   */
  id: Maybe<Scalars['Int']['output']>;
  /** The relative path to the main image on the product page. */
  image: Maybe<ProductImage>;
  imageGallery: Array<Image>;
  ingredientList: Maybe<Array<Maybe<ProductIngredient>>>;
  /** @deprecated Use the `custom_attributes` field instead. */
  ingredients: Maybe<Scalars['String']['output']>;
  isInWishlist: Scalars['Boolean']['output'];
  /** List of product labels associated to the product */
  labels: Array<Maybe<LabelItem>>;
  /** @deprecated Use the `custom_attributes` field instead. */
  length: Maybe<Scalars['Float']['output']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  licensingStatus: Maybe<Scalars['String']['output']>;
  /**
   * A number representing the product's manufacturer.
   * @deprecated Use the `custom_attributes` field instead.
   */
  manufacturer: Maybe<Scalars['Int']['output']>;
  /** An array of media gallery objects. */
  mediaGallery: Maybe<Array<Maybe<MediaGalleryInterface>>>;
  /**
   * An array of MediaGalleryEntry objects.
   * @deprecated Use `media_gallery` instead.
   */
  mediaGalleryEntries: Maybe<Array<Maybe<MediaGalleryEntry>>>;
  /** An array of media gallery objects. */
  mediaGalleryV2: Maybe<Array<Maybe<MediaGalleryV2Interface>>>;
  /** A brief overview of the product for search results listings, maximum 255 characters. */
  metaDescription: Maybe<Scalars['String']['output']>;
  /** A comma-separated list of keywords that are visible only to search engines. */
  metaKeyword: Maybe<Scalars['String']['output']>;
  /** A string that is displayed in the title bar and tab of the browser and in search results lists. */
  metaTitle: Maybe<Scalars['String']['output']>;
  /** The product name. Customers use this name to identify the product. */
  name: Maybe<Scalars['String']['output']>;
  /** The beginning date for new product listings, and determines if the product is featured as a new product. */
  newFromDate: Maybe<Scalars['String']['output']>;
  /** The end date for new product listings. */
  newToDate: Maybe<Scalars['String']['output']>;
  /** Product stock only x left count */
  onlyXLeftInStock: Maybe<Scalars['Float']['output']>;
  /** An array of options for a customizable product. */
  options: Maybe<Array<Maybe<CustomizableOptionInterface>>>;
  /** If the product has multiple options, determines where they appear on the product page. */
  optionsContainer: Maybe<Scalars['String']['output']>;
  /**
   * Indicates the price of an item.
   * @deprecated Use `price_range` for product price information.
   */
  price: Maybe<ProductPrices>;
  /** The range of prices for the product */
  priceRange: PriceRange;
  /** An array of `TierPrice` objects. */
  priceTiers: Maybe<Array<Maybe<TierPrice>>>;
  /** An array of `ProductLinks` objects. */
  productLinks: Maybe<Array<Maybe<ProductLinksInterface>>>;
  productType: ProductTypeEnum;
  /** @deprecated Use the `custom_attributes` field instead. */
  quantity: Maybe<Scalars['Int']['output']>;
  quantityInCart: Scalars['Int']['output'];
  /** The rating summary for the product */
  ratingSummary: RatingSummary;
  /** Contains 0 when there is no redirect error. A value of 301 indicates the URL of the requested resource has been changed permanently, while a value of 302 indicates a temporary redirect. */
  redirectCode: Scalars['Int']['output'];
  /** Related Products */
  relatedProducts: Maybe<Array<Maybe<ProductInterface>>>;
  /** The internal relative URL. If the specified URL is a redirect, the query returns the redirected URL, not the original. */
  relativeUrl: Maybe<Scalars['String']['output']>;
  /** The total count of all the reviews given to the product. */
  reviewCount: Scalars['Int']['output'];
  /** The list of products reviews */
  reviews: ProductReviews;
  /** A short description of the product. Its use depends on the theme. */
  shortDescription: Maybe<ComplexTextValue>;
  /** @deprecated Use the `custom_attributes` field instead. */
  size: Maybe<Scalars['Int']['output']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  skinConcern: Maybe<Scalars['String']['output']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  skinType: Maybe<Scalars['String']['output']>;
  /** A number or code assigned to a product to identify the product, options, price, and manufacturer. */
  sku: Maybe<Scalars['String']['output']>;
  /** The relative path to the small image, which is used on catalog pages. */
  smallImage: Maybe<ProductImage>;
  /**
   * The beginning date that a product has a special price.
   * @deprecated The field should not be used on the storefront.
   */
  specialFromDate: Maybe<Scalars['String']['output']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  specialIcons: Maybe<Scalars['String']['output']>;
  /** The discounted price of the product. */
  specialPrice: Maybe<Scalars['Float']['output']>;
  /** The end date for a product with a special price. */
  specialToDate: Maybe<Scalars['String']['output']>;
  stockInfo: Maybe<StockInfo>;
  /** Stock status of the product */
  stockStatus: Maybe<ProductStockStatus>;
  /** The file name of a swatch image. */
  swatchImage: Maybe<Scalars['String']['output']>;
  /** Returns all assigned product tags and their values */
  tags: Array<Maybe<ProductTags>>;
  /** The relative path to the product's thumbnail image. */
  thumbnail: Maybe<ProductImage>;
  /**
   * The price when tier pricing is in effect and the items purchased threshold has been reached.
   * @deprecated Use `price_tiers` for product tier price information.
   */
  tierPrice: Maybe<Scalars['Float']['output']>;
  /**
   * An array of ProductTierPrices objects.
   * @deprecated Use `price_tiers` for product tier price information.
   */
  tierPrices: Maybe<Array<Maybe<ProductTierPrices>>>;
  /** One of PRODUCT, CATEGORY, or CMS_PAGE. */
  type: Maybe<UrlRewriteEntityTypeEnum>;
  /**
   * One of simple, virtual, bundle, downloadable, grouped, or configurable.
   * @deprecated Use `__typename` instead.
   */
  typeId: Maybe<Scalars['String']['output']>;
  /** The unique ID for a `ProductInterface` object. */
  uid: Scalars['ID']['output'];
  /**
   * Timestamp indicating when the product was updated.
   * @deprecated The field should not be used on the storefront.
   */
  updatedAt: Maybe<Scalars['String']['output']>;
  /** Upsell Products */
  upsellProducts: Maybe<Array<Maybe<ProductInterface>>>;
  /** The part of the URL that identifies the product */
  urlKey: Maybe<Scalars['String']['output']>;
  /** @deprecated Use product's `canonical_url` or url rewrites instead */
  urlPath: Maybe<Scalars['String']['output']>;
  /** URL rewrites list */
  urlRewrites: Maybe<Array<Maybe<UrlRewrite>>>;
  /** The part of the product URL that is appended after the url key */
  urlSuffix: Maybe<Scalars['String']['output']>;
  valueAttributes: Maybe<Array<Maybe<ProductValue>>>;
  /** @deprecated Use the `custom_attributes` field instead. */
  valueTraits: Maybe<Scalars['String']['output']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  valuesDiet: Maybe<Scalars['String']['output']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  valuesEnvironmental: Maybe<Scalars['String']['output']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  valuesIngredient: Maybe<Scalars['String']['output']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  valuesSocial: Maybe<Scalars['String']['output']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  volumeWeight: Maybe<Scalars['Float']['output']>;
  /**
   * An array of websites in which the product is available.
   * @deprecated The field should not be used on the storefront.
   */
  websites: Maybe<Array<Maybe<Website>>>;
  /** The weight of the item, in units defined by the store. */
  weight: Maybe<Scalars['Float']['output']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  width: Maybe<Scalars['Float']['output']>;
  wishlistItemId: Maybe<Scalars['Int']['output']>;
};


/** AmGiftCardProduct defines properties of a gift card, including arrays of availiable values and images of the specific gift card */
export type AmGiftCardProductamastyRewardsHighlightsArgs = {
  attributes?: InputMaybe<Scalars['String']['input']>;
};


/** AmGiftCardProduct defines properties of a gift card, including arrays of availiable values and images of the specific gift card */
export type AmGiftCardProductlabelsArgs = {
  order?: InputMaybe<LabelOrderInput>;
};


/** AmGiftCardProduct defines properties of a gift card, including arrays of availiable values and images of the specific gift card */
export type AmGiftCardProductreviewsArgs = {
  currentPage?: InputMaybe<Scalars['Int']['input']>;
  filters?: InputMaybe<ReviewFilterInput>;
  pageSize?: InputMaybe<Scalars['Int']['input']>;
  sort?: InputMaybe<ReviewSortInput>;
};

export type AmGiftCardProductCartItemInput = {
  data: CartItemInput;
  /** Defines input with gift card product options */
  giftCardOptions: AmGiftCardOptionsInput;
};

export type AmGiftCardSettings = {
  __typename: 'AmGiftCardSettings';
  /** Allowed product types array. */
  allowedProductTypes: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  /** Gift Card fields array. */
  giftCardFields: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  /** Gift Card Lifetime. */
  giftCardLifetime: Maybe<Scalars['Int']['output']>;
  /** Gift Card timezones array. */
  giftCardTimezones: Maybe<Array<Maybe<AmGiftCardTimezone>>>;
  /** Image uploading tooltip. */
  imageUploadTooltip: Maybe<Scalars['String']['output']>;
  /** Is allowed to use Gift Card by user who bought it. */
  isAllowUseThemselves: Maybe<Scalars['Boolean']['output']>;
  /** Is allowed to upload images by customers. */
  isAllowUserImages: Maybe<Scalars['Boolean']['output']>;
  /** Is module enabled flag. */
  isEnabled: Maybe<Scalars['Boolean']['output']>;
  /** Is allowed to pay extra fee via Gift Card. */
  isExtraFeePaidAllowed: Maybe<Scalars['Boolean']['output']>;
  /** Is show on checkout and cart pages flag. */
  isShowOnCartAndCheckout: Maybe<Scalars['Boolean']['output']>;
  /** Is allowed to pay shipping via Gift Card. */
  isSippingPaidAllowed: Maybe<Scalars['Boolean']['output']>;
  /** Is allowed to pay tax via Gift Card. */
  isTaxPaidAllowed: Maybe<Scalars['Boolean']['output']>;
};

export type AmGiftCardTimezone = {
  __typename: 'AmGiftCardTimezone';
  /** Timezone label */
  label: Maybe<Scalars['String']['output']>;
  /** Timezone unique value */
  value: Maybe<Scalars['String']['output']>;
};

/** This enumeration defines the types of gift cards */
export enum AmGiftCardTypeEnum {
  /** @deprecated  */
  COMBINED = 'COMBINED',
  /** @deprecated  */
  PRINTED = 'PRINTED',
  /** @deprecated  */
  VIRTUAL = 'VIRTUAL'
}

export enum ApiScalarDataTypeEnum {
  /** Boolean */
  BOOLEAN = 'BOOLEAN',
  /** Color */
  COLOR = 'COLOR',
  /** Date */
  DATE = 'DATE',
  /** Date Time */
  DATE_TIME = 'DATE_TIME',
  /** Double */
  DOUBLE = 'DOUBLE',
  /** Email */
  EMAIL = 'EMAIL',
  /** Float */
  FLOAT = 'FLOAT',
  /** Hyper Text */
  HYPERTEXT = 'HYPERTEXT',
  /** Integer */
  INT = 'INT',
  /** Month */
  MONTH = 'MONTH',
  /** Phone */
  PHONE = 'PHONE',
  /** String */
  STRING = 'STRING',
  /** Text */
  TEXT = 'TEXT',
  /** Time */
  TIME = 'TIME',
  /** Link */
  URI = 'URI',
  /** Week */
  WEEK = 'WEEK',
  /** Year */
  YEAR = 'YEAR'
}

export type App = {
  __typename: 'App';
  baseCategoryId: Maybe<Scalars['String']['output']>;
  deviceType: DeviceTypeEnum;
  id: Scalars['ID']['output'];
  isNativeApp: Scalars['Boolean']['output'];
  magentoIsInMaintenace: Scalars['Boolean']['output'];
  mobileOSType: MobileOSTypeEnum;
  showBottomNav: Scalars['Boolean']['output'];
  showFooter: Scalars['Boolean']['output'];
  showHeaderContent: Scalars['Boolean']['output'];
  showSearch: Scalars['Boolean']['output'];
  storeId: Maybe<Scalars['Int']['output']>;
};

export type ApplePayConfig = PaymentConfigItem & {
  __typename: 'ApplePayConfig';
  /** The styles for the ApplePay Smart Button configuration */
  buttonStyles: Maybe<ButtonStyles>;
  /** The payment method code as defined in the payment gateway */
  code: Maybe<Scalars['String']['output']>;
  /** Indicates whether the payment method is displayed */
  isVisible: Maybe<Scalars['Boolean']['output']>;
  /** Defines the payment intent (Authorize or Capture */
  paymentIntent: Maybe<Scalars['String']['output']>;
  /** The payment source for the payment method */
  paymentSource: Maybe<Scalars['String']['output']>;
  /** The PayPal parameters required to load the JS SDK */
  sdkParams: Maybe<Array<Maybe<SdkParams>>>;
  /** The relative order the payment method is displayed on the checkout page */
  sortOrder: Maybe<Scalars['String']['output']>;
  /** The name displayed for the payment method */
  title: Maybe<Scalars['String']['output']>;
};

/** Apple Pay inputs */
export type ApplePayMethodInput = {
  /** The payment source for the payment method */
  paymentSource?: InputMaybe<Scalars['String']['input']>;
  /** The payment services order ID */
  paymentsOrderId?: InputMaybe<Scalars['String']['input']>;
  /** PayPal order ID */
  paypalOrderId?: InputMaybe<Scalars['String']['input']>;
};

/** Contains the applied gift card with applied and remaining balance */
export type AppliedAmGiftCard = {
  __typename: 'AppliedAmGiftCard';
  /** Applied balance to the current cart */
  appliedBalance: Maybe<Money>;
  /** Gift card account code */
  code: Maybe<Scalars['String']['output']>;
  /** Current balance remaining on gift card */
  currentBalance: Maybe<Money>;
  /** Gift card expiration date */
  expirationDate: Maybe<Scalars['String']['output']>;
};

/** Contains the applied coupon code. */
export type AppliedCoupon = {
  __typename: 'AppliedCoupon';
  /** The coupon code the shopper applied to the card. */
  code: Scalars['String']['output'];
};

/** Defines the input required to run the applyAmGiftCardToCart mutation */
export type ApplyAmGiftCardToCartInput = {
  /** The gift card code to be applied to the cart */
  amGiftCardCode: Scalars['String']['input'];
  /** The unique ID that identifies the customer's cart */
  cartId: Scalars['String']['input'];
};

/** Defines the possible output for the applyAmGiftCardToCart mutation */
export type ApplyAmGiftCardToCartOutput = {
  __typename: 'ApplyAmGiftCardToCartOutput';
  /** Describes the contents of the specified shopping cart */
  cart: Cart;
};

/** Specifies the coupon code to apply to the cart. */
export type ApplyCouponToCartInput = {
  /** The unique ID of a `Cart` object. */
  cartId: Scalars['String']['input'];
  /** A valid coupon code. */
  couponCode: Scalars['String']['input'];
};

/** Contains details about the cart after applying a coupon. */
export type ApplyCouponToCartOutput = {
  __typename: 'ApplyCouponToCartOutput';
  /** The cart after applying a coupon. */
  cart: Cart;
};

/** Contains the results of the request to assign a compare list. */
export type AssignCompareListToCustomerOutput = {
  __typename: 'AssignCompareListToCustomerOutput';
  /** The contents of the customer's compare list. */
  compareList: Maybe<CompareList>;
  /** Indicates whether the compare list was successfully assigned to the customer. */
  result: Scalars['Boolean']['output'];
};

/** Contains details about the attribute, including the code and type. */
export type Attribute = {
  __typename: 'Attribute';
  /** The unique identifier for an attribute code. This value should be in lowercase letters without spaces. */
  attributeCode: Maybe<Scalars['String']['output']>;
  /** Attribute options list. */
  attributeOptions: Maybe<Array<Maybe<AttributeOption>>>;
  /** The data type of the attribute. */
  attributeType: Maybe<Scalars['String']['output']>;
  /** The type of entity that defines the attribute. */
  entityType: Maybe<Scalars['String']['output']>;
  /** The frontend input type of the attribute. */
  inputType: Maybe<Scalars['String']['output']>;
  /** Details about the storefront properties configured for the attribute. */
  storefrontProperties: Maybe<StorefrontProperties>;
};

/** List of all entity types. Populated by the modules introducing EAV entities. */
export enum AttributeEntityTypeEnum {
  /** @deprecated  */
  CATALOG_PRODUCT = 'CATALOG_PRODUCT',
  /** @deprecated  */
  CUSTOMER = 'CUSTOMER',
  /** @deprecated  */
  CUSTOMER_ADDRESS = 'CUSTOMER_ADDRESS'
}

/** EAV attribute frontend input types. */
export enum AttributeFrontendInputEnum {
  /** @deprecated  */
  BOOLEAN = 'BOOLEAN',
  /** @deprecated  */
  DATE = 'DATE',
  /** @deprecated  */
  DATETIME = 'DATETIME',
  /** @deprecated  */
  FILE = 'FILE',
  /** @deprecated  */
  GALLERY = 'GALLERY',
  /** @deprecated  */
  HIDDEN = 'HIDDEN',
  /** @deprecated  */
  IMAGE = 'IMAGE',
  /** @deprecated  */
  MEDIA_IMAGE = 'MEDIA_IMAGE',
  /** @deprecated  */
  MULTILINE = 'MULTILINE',
  /** @deprecated  */
  MULTISELECT = 'MULTISELECT',
  /** @deprecated  */
  PRICE = 'PRICE',
  /** @deprecated  */
  SELECT = 'SELECT',
  /** @deprecated  */
  TEXT = 'TEXT',
  /** @deprecated  */
  TEXTAREA = 'TEXTAREA',
  /** @deprecated  */
  UNDEFINED = 'UNDEFINED',
  /** @deprecated  */
  WEIGHT = 'WEIGHT'
}

/** Defines the attribute characteristics to search for the `attribute_code` and `entity_type` to search. */
export type AttributeInput = {
  /** The unique identifier for an attribute code. This value should be in lowercase letters without spaces. */
  attributeCode?: InputMaybe<Scalars['String']['input']>;
  /** The type of entity that defines the attribute. */
  entityType?: InputMaybe<Scalars['String']['input']>;
};

/** Specifies selected option for dropdown or multiselect attribute value . */
export type AttributeInputSelectedOption = {
  /** Selected option UID. */
  uid: Scalars['ID']['input'];
  /** The attribute option value. */
  value: Scalars['String']['input'];
};

/** Base EAV implementation of CustomAttributeMetadataInterface. */
export type AttributeMetadata = CustomAttributeMetadataInterface & {
  __typename: 'AttributeMetadata';
  /** The unique identifier for an attribute code. This value should be in lowercase letters without spaces. */
  code: Scalars['String']['output'];
  /** Default attribute value. */
  defaultValue: Maybe<Scalars['String']['output']>;
  /** The type of entity that defines the attribute. */
  entityType: AttributeEntityTypeEnum;
  /** The frontend input type of the attribute. */
  frontendInput: Maybe<AttributeFrontendInputEnum>;
  /** Whether the attribute value is required. */
  isRequired: Scalars['Boolean']['output'];
  /** Whether the attribute value must be unique. */
  isUnique: Scalars['Boolean']['output'];
  /** The label assigned to the attribute. */
  label: Maybe<Scalars['String']['output']>;
  /** Attribute options. */
  options: Array<Maybe<CustomAttributeOptionInterface>>;
  /** The unique ID of an attribute. Based on entity type and attribute code */
  uid: Scalars['ID']['output'];
};

/** Attribute metadata retrieval error. */
export type AttributeMetadataError = {
  __typename: 'AttributeMetadataError';
  /** Attribute metadata retrieval error message. */
  message: Scalars['String']['output'];
  /** Attribute metadata retrieval error type. */
  type: AttributeMetadataErrorType;
};

/** Attribute metadata retrieval error types. */
export enum AttributeMetadataErrorType {
  /**
   * The requested attribute was not found.
   * @deprecated
   */
  ATTRIBUTE_NOT_FOUND = 'ATTRIBUTE_NOT_FOUND',
  /**
   * The requested entity was not found.
   * @deprecated
   */
  ENTITY_NOT_FOUND = 'ENTITY_NOT_FOUND',
  /**
   * Not categorized error, see the error message.
   * @deprecated
   */
  UNDEFINED = 'UNDEFINED'
}

/** Defines an attribute option. */
export type AttributeOption = {
  __typename: 'AttributeOption';
  /** The label assigned to the attribute option. */
  label: Maybe<Scalars['String']['output']>;
  /** The attribute option value. */
  value: Maybe<Scalars['String']['output']>;
};

/** Base EAV implementation of CustomAttributeOptionInterface. */
export type AttributeOptionMetadata = CustomAttributeOptionInterface & {
  __typename: 'AttributeOptionMetadata';
  /** Is the option value default. */
  isDefault: Maybe<Scalars['Boolean']['output']>;
  /** The label assigned to the attribute option. */
  label: Scalars['String']['output'];
  /** The unique ID of an attribute option. */
  uid: Scalars['ID']['output'];
  /** The attribute option value. */
  value: Scalars['String']['output'];
};

export type AttributeSelectedOption = AttributeSelectedOptionInterface & {
  __typename: 'AttributeSelectedOption';
  /** The attribute selected option label. */
  label: Scalars['String']['output'];
  /** The unique ID of an attribute selected option. */
  uid: Scalars['ID']['output'];
  /** The attribute selected option value. */
  value: Scalars['String']['output'];
};

export type AttributeSelectedOptionInterface = {
  /** The attribute selected option label. */
  label: Scalars['String']['output'];
  /** The unique ID of an attribute selected option. */
  uid: Scalars['ID']['output'];
  /** The attribute selected option value. */
  value: Scalars['String']['output'];
};

export type AttributeSelectedOptions = AttributeValueInterface & {
  __typename: 'AttributeSelectedOptions';
  /** The attribute code. */
  code: Scalars['String']['output'];
  selectedOptions: Array<Maybe<AttributeSelectedOptionInterface>>;
  /** The unique ID of an attribute value. */
  uid: Scalars['ID']['output'];
};

export type AttributeValue = AttributeValueInterface & {
  __typename: 'AttributeValue';
  /** The attribute code. */
  code: Scalars['String']['output'];
  /** The unique ID of an attribute value. */
  uid: Scalars['ID']['output'];
  /** The attribute value. */
  value: Scalars['String']['output'];
};

export type AttributeValueInput = {
  /** The attribute code. */
  attributeCode: Scalars['String']['input'];
  /** An array with selected option(s) for select or multiselect attribute */
  selectedOptions?: InputMaybe<Array<InputMaybe<AttributeInputSelectedOption>>>;
  /** The attribute value. */
  value?: InputMaybe<Scalars['String']['input']>;
};

export type AttributeValueInterface = {
  /** The attribute code. */
  code: Scalars['String']['output'];
  /** The unique ID of an attribute value. */
  uid: Scalars['ID']['output'];
};

/** Metadata of EAV attributes associated to form */
export type AttributesFormOutput = {
  __typename: 'AttributesFormOutput';
  /** Errors of retrieving certain attributes metadata. */
  errors: Array<Maybe<AttributeMetadataError>>;
  /** Requested attributes metadata. */
  items: Array<Maybe<CustomAttributeMetadataInterface>>;
};

/** Metadata of EAV attributes. */
export type AttributesMetadataOutput = {
  __typename: 'AttributesMetadataOutput';
  /** Errors of retrieving certain attributes metadata. */
  errors: Array<Maybe<AttributeMetadataError>>;
  /** Requested attributes metadata. */
  items: Array<Maybe<CustomAttributeMetadataInterface>>;
};

export type Auth = {
  __typename: 'Auth';
  cartId: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  token: Maybe<Scalars['String']['output']>;
};

/** Describes a payment method that the shopper can use to pay for the order. */
export type AvailablePaymentMethod = {
  __typename: 'AvailablePaymentMethod';
  /** The payment method code. */
  code: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  /** If the payment method is an online integration */
  isDeferred: Scalars['Boolean']['output'];
  /** The payment method title. */
  title: Scalars['String']['output'];
};

/** Contains details about the possible shipping methods and carriers. */
export type AvailableShippingMethod = {
  __typename: 'AvailableShippingMethod';
  /** An array of shipping actions that are allowed to be used for the shipping method */
  allowedShippingActions: Maybe<Array<Maybe<ShippingActionEnum>>>;
  /** The cost of shipping using this shipping method. */
  amount: Money;
  /** Indicates whether this shipping method can be applied to the cart. */
  available: Scalars['Boolean']['output'];
  /** @deprecated The field should not be used on the storefront. */
  baseAmount: Maybe<Money>;
  /** A string that identifies a commercial carrier or an offline shipping method. */
  carrierCode: Scalars['String']['output'];
  /** The label for the carrier code. */
  carrierTitle: Scalars['String']['output'];
  /** The carrier type */
  carrierType: Maybe<CarrierTypeEnum>;
  /** Describes an error condition. */
  errorMessage: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  /** A shipping method code associated with a carrier. The value could be null if no method is available. */
  methodCode: Maybe<Scalars['String']['output']>;
  /** The label for the shipping method code. The value could be null if no method is available. */
  methodTitle: Maybe<Scalars['String']['output']>;
  /** The cost of shipping using this shipping method, excluding tax. */
  priceExclTax: Money;
  /** The cost of shipping using this shipping method, including tax. */
  priceInclTax: Money;
  /** The shipping rate UID */
  rateUid: Maybe<Scalars['String']['output']>;
  /** The sort order of the shipping rate */
  sortOrder: Maybe<Scalars['Int']['output']>;
};

export enum BatchMutationStatus {
  /** @deprecated  */
  FAILURE = 'FAILURE',
  /** @deprecated  */
  MIXED_RESULTS = 'MIXED_RESULTS',
  /** @deprecated  */
  SUCCESS = 'SUCCESS'
}

/** Defines the billing address. */
export type BillingAddressInput = {
  /** Defines a billing address. */
  address?: InputMaybe<CartAddressInput>;
  /** An ID from the customer's address book that uniquely identifies the address to be used for billing. */
  customerAddressId?: InputMaybe<Scalars['Int']['input']>;
  /** Indicates whether to set the billing address to be the same as the existing shipping address on the cart. */
  sameAsShipping?: InputMaybe<Scalars['Boolean']['input']>;
  /** Indicates whether to set the shipping address to be the same as this billing address. */
  useForShipping?: InputMaybe<Scalars['Boolean']['input']>;
};

/** Contains details about the billing address. */
export type BillingCartAddress = CartAddressInterface & {
  __typename: 'BillingCartAddress';
  /** The customer's prefered address group */
  addressGroup: Maybe<AddressGroupEnum>;
  /** The city specified for the billing or shipping address. */
  city: Scalars['String']['output'];
  /** The company specified for the billing or shipping address. */
  company: Maybe<Scalars['String']['output']>;
  /** An object containing the country label and code. */
  country: CartAddressCountry;
  /** @deprecated The field is used only in shipping address. */
  customerNotes: Maybe<Scalars['String']['output']>;
  /** The customer's delivery note for the address */
  deliveryNote: Maybe<Scalars['String']['output']>;
  /** The first name of the customer or guest. */
  firstname: Scalars['String']['output'];
  /** The last name of the customer or guest. */
  lastname: Scalars['String']['output'];
  /** The ZIP or postal code of the billing or shipping address. */
  postcode: Maybe<Scalars['String']['output']>;
  /** An object containing the region label and code. */
  region: Maybe<CartAddressRegion>;
  /** An array containing the street for the billing or shipping address. */
  street: Array<Maybe<Scalars['String']['output']>>;
  /** The customer's suburb */
  suburb: Maybe<Scalars['String']['output']>;
  /** The telephone number for the billing or shipping address. */
  telephone: Maybe<Scalars['String']['output']>;
  /** The alternative telephone number */
  telephoneAlt: Maybe<Scalars['String']['output']>;
  /** The unique id of the customer address. */
  uid: Scalars['String']['output'];
  /** The VAT company number for billing or shipping address. */
  vatId: Maybe<Scalars['String']['output']>;
};

export type BooleanEqualsInRangeFilterInput = {
  doesNotEqual?: InputMaybe<Scalars['Boolean']['input']>;
  equals?: InputMaybe<Scalars['Boolean']['input']>;
  greaterThan?: InputMaybe<Scalars['Boolean']['input']>;
  in?: InputMaybe<Array<InputMaybe<Scalars['Boolean']['input']>>>;
  lessThan?: InputMaybe<Scalars['Boolean']['input']>;
  notIn?: InputMaybe<Array<InputMaybe<Scalars['Boolean']['input']>>>;
};

export type Brand = {
  __typename: 'Brand';
  /** The name of the brand. */
  name: Scalars['String']['output'];
  /** The URL path of the brand's listing page. */
  path: Scalars['String']['output'];
};

/** Contains details about an individual category that comprises a breadcrumb. */
export type Breadcrumb = {
  __typename: 'Breadcrumb';
  /**
   * The ID of the category.
   * @deprecated Use `category_uid` instead.
   */
  categoryId: Maybe<Scalars['Int']['output']>;
  /** The category level. */
  categoryLevel: Maybe<Scalars['Int']['output']>;
  /** The display name of the category. */
  categoryName: Maybe<Scalars['String']['output']>;
  /** The unique ID for a `Breadcrumb` object. */
  categoryUid: Scalars['ID']['output'];
  /** The URL key of the category. */
  categoryUrlKey: Maybe<Scalars['String']['output']>;
  /** The URL path of the category. */
  categoryUrlPath: Maybe<Scalars['String']['output']>;
};

/** An implementation for bundle product cart items. */
export type BundleCartItem = CartItemInterface & {
  __typename: 'BundleCartItem';
  /** An array containing the bundle options the shopper selected. */
  bundleOptions: Array<Maybe<SelectedBundleOption>>;
  /** An array containing the customizable options the shopper selected. */
  customizableOptions: Array<Maybe<SelectedCustomizableOption>>;
  /** An array of errors encountered while loading the cart item */
  errors: Maybe<Array<Maybe<CartItemError>>>;
  /** The entered gift message for the cart item */
  giftMessage: Maybe<GiftMessage>;
  /** @deprecated Use `uid` instead. */
  id: Scalars['String']['output'];
  /** Contains details about the price of the item, including taxes and discounts. */
  prices: Maybe<CartItemPrices>;
  /** Details about an item in the cart. */
  product: ProductInterface;
  /** The quantity of this item in the cart. */
  quantity: Scalars['Float']['output'];
  /** The unique ID for a `CartItemInterface` object. */
  uid: Scalars['ID']['output'];
};

/** Defines bundle product options for `CreditMemoItemInterface`. */
export type BundleCreditMemoItem = CreditMemoItemInterface & {
  __typename: 'BundleCreditMemoItem';
  /** A list of bundle options that are assigned to a bundle product that is part of a credit memo. */
  bundleOptions: Maybe<Array<Maybe<ItemSelectedBundleOption>>>;
  /** Details about the final discount amount for the base product, including discounts on options. */
  discounts: Maybe<Array<Maybe<Discount>>>;
  /** The unique ID for a `CreditMemoItemInterface` object. */
  id: Scalars['ID']['output'];
  /** The order item the credit memo is applied to. */
  orderItem: Maybe<OrderItemInterface>;
  /** The name of the base product. */
  productName: Maybe<Scalars['String']['output']>;
  /** The sale price for the base product, including selected options. */
  productSalePrice: Money;
  /** The SKU of the base product. */
  productSku: Scalars['String']['output'];
  /** The number of refunded items. */
  quantityRefunded: Maybe<Scalars['Float']['output']>;
};

/** Defines bundle product options for `InvoiceItemInterface`. */
export type BundleInvoiceItem = InvoiceItemInterface & {
  __typename: 'BundleInvoiceItem';
  /** A list of bundle options that are assigned to an invoiced bundle product. */
  bundleOptions: Maybe<Array<Maybe<ItemSelectedBundleOption>>>;
  /** Information about the final discount amount for the base product, including discounts on options. */
  discounts: Maybe<Array<Maybe<Discount>>>;
  /** The unique ID for an `InvoiceItemInterface` object. */
  id: Scalars['ID']['output'];
  /** Details about an individual order item. */
  orderItem: Maybe<OrderItemInterface>;
  /** The name of the base product. */
  productName: Maybe<Scalars['String']['output']>;
  /** The sale price for the base product including selected options. */
  productSalePrice: Money;
  /** The SKU of the base product. */
  productSku: Scalars['String']['output'];
  /** The number of invoiced items. */
  quantityInvoiced: Maybe<Scalars['Float']['output']>;
};

/** Defines an individual item within a bundle product. */
export type BundleItem = {
  __typename: 'BundleItem';
  /**
   * An ID assigned to each type of item in a bundle product.
   * @deprecated Use `uid` instead
   */
  optionId: Maybe<Scalars['Int']['output']>;
  /** An array of additional options for this bundle item. */
  options: Maybe<Array<Maybe<BundleItemOption>>>;
  /** A number indicating the sequence order of this item compared to the other bundle items. */
  position: Maybe<Scalars['Int']['output']>;
  /** The range of prices for the product */
  priceRange: PriceRange;
  /** Indicates whether the item must be included in the bundle. */
  required: Maybe<Scalars['Boolean']['output']>;
  /** The SKU of the bundle product. */
  sku: Maybe<Scalars['String']['output']>;
  /** The display name of the item. */
  title: Maybe<Scalars['String']['output']>;
  /** The input type that the customer uses to select the item. Examples include radio button and checkbox. */
  type: Maybe<Scalars['String']['output']>;
  /** The unique ID for a `BundleItem` object. */
  uid: Maybe<Scalars['ID']['output']>;
};

/** Defines the characteristics that comprise a specific bundle item and its options. */
export type BundleItemOption = {
  __typename: 'BundleItemOption';
  /** Indicates whether the customer can change the number of items for this option. */
  canChangeQuantity: Maybe<Scalars['Boolean']['output']>;
  /**
   * The ID assigned to the bundled item option.
   * @deprecated Use `uid` instead
   */
  id: Maybe<Scalars['Int']['output']>;
  /** Indicates whether this option is the default option. */
  isDefault: Maybe<Scalars['Boolean']['output']>;
  /** The text that identifies the bundled item option. */
  label: Maybe<Scalars['String']['output']>;
  /** When a bundle item contains multiple options, the relative position of this option compared to the other options. */
  position: Maybe<Scalars['Int']['output']>;
  /** The price of the selected option. */
  price: Maybe<Scalars['Float']['output']>;
  /** One of FIXED, PERCENT, or DYNAMIC. */
  priceType: Maybe<PriceTypeEnum>;
  /** Contains details about this product option. */
  product: Maybe<ProductInterface>;
  /**
   * Indicates the quantity of this specific bundle item.
   * @deprecated Use `quantity` instead.
   */
  qty: Maybe<Scalars['Float']['output']>;
  /** The quantity of this specific bundle item. */
  quantity: Maybe<Scalars['Float']['output']>;
  /** The unique ID for a `BundleItemOption` object. */
  uid: Scalars['ID']['output'];
};

/** Defines the input for a bundle option. */
export type BundleOptionInput = {
  /** The ID of the option. */
  id: Scalars['Int']['input'];
  /** The number of the selected item to add to the cart. */
  quantity: Scalars['Float']['input'];
  /** An array with the chosen value of the option. */
  value: Array<InputMaybe<Scalars['String']['input']>>;
};

/** Defines bundle product options for `OrderItemInterface`. */
export type BundleOrderItem = OrderItemInterface & {
  __typename: 'BundleOrderItem';
  /** A list of bundle options that are assigned to the bundle product. */
  bundleOptions: Maybe<Array<Maybe<ItemSelectedBundleOption>>>;
  coverImage: Image;
  /** The final discount information for the product. */
  discounts: Maybe<Array<Maybe<Discount>>>;
  /** The entered option for the base product, such as a logo or image. */
  enteredOptions: Maybe<Array<Maybe<OrderItemOption>>>;
  /** The selected gift message for the order item */
  giftMessage: Maybe<GiftMessage>;
  /** The unique ID for an `OrderItemInterface` object. */
  id: Scalars['ID']['output'];
  /** The product image */
  productImage: Maybe<ProductImageV2>;
  /** The name of the base product. */
  productName: Maybe<Scalars['String']['output']>;
  /** The sale price of the base product, including selected options. */
  productSalePrice: Money;
  /** The SKU of the base product. */
  productSku: Scalars['String']['output'];
  /** The type of product, such as simple, configurable, etc. */
  productType: Maybe<Scalars['String']['output']>;
  /** URL key of the base product. */
  productUrlKey: Maybe<Scalars['String']['output']>;
  /** The number of canceled items. */
  quantityCanceled: Maybe<Scalars['Float']['output']>;
  /** The number of invoiced items. */
  quantityInvoiced: Maybe<Scalars['Float']['output']>;
  /** The number of units ordered for this item. */
  quantityOrdered: Maybe<Scalars['Float']['output']>;
  /** The number of refunded items. */
  quantityRefunded: Maybe<Scalars['Float']['output']>;
  /** The number of returned items. */
  quantityReturned: Maybe<Scalars['Float']['output']>;
  /** The number of shipped items. */
  quantityShipped: Maybe<Scalars['Float']['output']>;
  /** The selected options for the base product, such as color or size. */
  selectedOptions: Maybe<Array<Maybe<OrderItemOption>>>;
  /** The status of the order item. */
  status: Maybe<Scalars['String']['output']>;
};

/** Defines basic features of a bundle product and contains multiple BundleItems. */
export type BundleProduct = CustomizableProductInterface & PhysicalProductInterface & ProductInterface & RoutableInterface & {
  __typename: 'BundleProduct';
  /** @deprecated Use the `custom_attributes` field instead. */
  age: Maybe<Scalars['String']['output']>;
  /** Information about guest highlight points available by product. */
  amastyRewardsGuestHighlights: Maybe<HighlightOutput>;
  /** Information about highlight points available by product. */
  amastyRewardsHighlights: Maybe<HighlightOutput>;
  /**
   * The attribute set assigned to the product.
   * @deprecated The field should not be used on the storefront.
   */
  attributeSetId: Maybe<Scalars['Int']['output']>;
  /** Returns all assigned product attributes and their values */
  attributes: Array<Maybe<ProductAttributes>>;
  /** Returns the brand of the product */
  brand: Maybe<Brand>;
  cacheBuster: Maybe<Scalars['String']['output']>;
  /** The relative canonical URL. This value is returned only if the system setting 'Use Canonical Link Meta Tag For Products' is enabled. */
  canonicalUrl: Maybe<Scalars['String']['output']>;
  cartItemUid: Maybe<Scalars['String']['output']>;
  /** The categories assigned to a product. */
  categories: Maybe<Array<Maybe<CategoryInterface>>>;
  /** @deprecated Use the `custom_attributes` field instead. */
  colour: Maybe<Scalars['Int']['output']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  concentration: Maybe<Scalars['Int']['output']>;
  /** The product's country of origin. */
  countryOfManufacture: Maybe<Scalars['String']['output']>;
  coverImage: Image;
  /**
   * Timestamp indicating when the product was created.
   * @deprecated The field should not be used on the storefront.
   */
  createdAt: Maybe<Scalars['String']['output']>;
  /** Crosssell Products */
  crosssellProducts: Maybe<Array<Maybe<ProductInterface>>>;
  /** @deprecated Use the `custom_attributes` field instead. */
  department: Maybe<Scalars['String']['output']>;
  /** Detailed information about the product. The value can include simple HTML tags. */
  description: Maybe<ComplexTextValue>;
  /** Indicates whether the bundle product has a dynamic price. */
  dynamicPrice: Maybe<Scalars['Boolean']['output']>;
  /** Indicates whether the bundle product has a dynamic SKU. */
  dynamicSku: Maybe<Scalars['Boolean']['output']>;
  /** Indicates whether the bundle product has a dynamically calculated weight. */
  dynamicWeight: Maybe<Scalars['Boolean']['output']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  flavour: Maybe<Scalars['Int']['output']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  fragrance: Maybe<Scalars['Int']['output']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  gender: Maybe<Scalars['String']['output']>;
  /** Indicates whether a gift message is available. */
  giftMessageAvailable: Maybe<Scalars['String']['output']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  hairType: Maybe<Scalars['String']['output']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  height: Maybe<Scalars['Float']['output']>;
  /**
   * The ID number assigned to the product.
   * @deprecated Use the `uid` field instead.
   */
  id: Maybe<Scalars['Int']['output']>;
  /** The relative path to the main image on the product page. */
  image: Maybe<ProductImage>;
  imageGallery: Array<Image>;
  ingredientList: Maybe<Array<Maybe<ProductIngredient>>>;
  /** @deprecated Use the `custom_attributes` field instead. */
  ingredients: Maybe<Scalars['String']['output']>;
  isInWishlist: Scalars['Boolean']['output'];
  /** An array containing information about individual bundle items. */
  items: Maybe<Array<Maybe<BundleItem>>>;
  /** List of product labels associated to the product */
  labels: Array<Maybe<LabelItem>>;
  /** @deprecated Use the `custom_attributes` field instead. */
  length: Maybe<Scalars['Float']['output']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  licensingStatus: Maybe<Scalars['String']['output']>;
  /**
   * A number representing the product's manufacturer.
   * @deprecated Use the `custom_attributes` field instead.
   */
  manufacturer: Maybe<Scalars['Int']['output']>;
  /** An array of media gallery objects. */
  mediaGallery: Maybe<Array<Maybe<MediaGalleryInterface>>>;
  /**
   * An array of MediaGalleryEntry objects.
   * @deprecated Use `media_gallery` instead.
   */
  mediaGalleryEntries: Maybe<Array<Maybe<MediaGalleryEntry>>>;
  /** An array of media gallery objects. */
  mediaGalleryV2: Maybe<Array<Maybe<MediaGalleryV2Interface>>>;
  /** A brief overview of the product for search results listings, maximum 255 characters. */
  metaDescription: Maybe<Scalars['String']['output']>;
  /** A comma-separated list of keywords that are visible only to search engines. */
  metaKeyword: Maybe<Scalars['String']['output']>;
  /** A string that is displayed in the title bar and tab of the browser and in search results lists. */
  metaTitle: Maybe<Scalars['String']['output']>;
  /** The product name. Customers use this name to identify the product. */
  name: Maybe<Scalars['String']['output']>;
  /** The beginning date for new product listings, and determines if the product is featured as a new product. */
  newFromDate: Maybe<Scalars['String']['output']>;
  /** The end date for new product listings. */
  newToDate: Maybe<Scalars['String']['output']>;
  /** Product stock only x left count */
  onlyXLeftInStock: Maybe<Scalars['Float']['output']>;
  /** An array of options for a customizable product. */
  options: Maybe<Array<Maybe<CustomizableOptionInterface>>>;
  /** If the product has multiple options, determines where they appear on the product page. */
  optionsContainer: Maybe<Scalars['String']['output']>;
  /**
   * Indicates the price of an item.
   * @deprecated Use `price_range` for product price information.
   */
  price: Maybe<ProductPrices>;
  /** The range of prices for the product */
  priceRange: PriceRange;
  /** An array of `TierPrice` objects. */
  priceTiers: Maybe<Array<Maybe<TierPrice>>>;
  /** One of PRICE_RANGE or AS_LOW_AS. */
  priceView: Maybe<PriceViewEnum>;
  /** An array of `ProductLinks` objects. */
  productLinks: Maybe<Array<Maybe<ProductLinksInterface>>>;
  productType: ProductTypeEnum;
  /** @deprecated Use the `custom_attributes` field instead. */
  quantity: Maybe<Scalars['Int']['output']>;
  quantityInCart: Scalars['Int']['output'];
  /** The rating summary for the product */
  ratingSummary: RatingSummary;
  /** Contains 0 when there is no redirect error. A value of 301 indicates the URL of the requested resource has been changed permanently, while a value of 302 indicates a temporary redirect. */
  redirectCode: Scalars['Int']['output'];
  /** Related Products */
  relatedProducts: Maybe<Array<Maybe<ProductInterface>>>;
  /** The internal relative URL. If the specified URL is a redirect, the query returns the redirected URL, not the original. */
  relativeUrl: Maybe<Scalars['String']['output']>;
  /** The total count of all the reviews given to the product. */
  reviewCount: Scalars['Int']['output'];
  /** The list of products reviews */
  reviews: ProductReviews;
  /** Indicates whether to ship bundle items together or individually. */
  shipBundleItems: Maybe<ShipBundleItemsEnum>;
  /** A short description of the product. Its use depends on the theme. */
  shortDescription: Maybe<ComplexTextValue>;
  /** @deprecated Use the `custom_attributes` field instead. */
  size: Maybe<Scalars['Int']['output']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  skinConcern: Maybe<Scalars['String']['output']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  skinType: Maybe<Scalars['String']['output']>;
  /** A number or code assigned to a product to identify the product, options, price, and manufacturer. */
  sku: Maybe<Scalars['String']['output']>;
  /** The relative path to the small image, which is used on catalog pages. */
  smallImage: Maybe<ProductImage>;
  /**
   * The beginning date that a product has a special price.
   * @deprecated The field should not be used on the storefront.
   */
  specialFromDate: Maybe<Scalars['String']['output']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  specialIcons: Maybe<Scalars['String']['output']>;
  /** The discounted price of the product. */
  specialPrice: Maybe<Scalars['Float']['output']>;
  /** The end date for a product with a special price. */
  specialToDate: Maybe<Scalars['String']['output']>;
  stockInfo: Maybe<StockInfo>;
  /** Stock status of the product */
  stockStatus: Maybe<ProductStockStatus>;
  /** The file name of a swatch image. */
  swatchImage: Maybe<Scalars['String']['output']>;
  /** Returns all assigned product tags and their values */
  tags: Array<Maybe<ProductTags>>;
  /** The relative path to the product's thumbnail image. */
  thumbnail: Maybe<ProductImage>;
  /**
   * The price when tier pricing is in effect and the items purchased threshold has been reached.
   * @deprecated Use `price_tiers` for product tier price information.
   */
  tierPrice: Maybe<Scalars['Float']['output']>;
  /**
   * An array of ProductTierPrices objects.
   * @deprecated Use `price_tiers` for product tier price information.
   */
  tierPrices: Maybe<Array<Maybe<ProductTierPrices>>>;
  /** One of PRODUCT, CATEGORY, or CMS_PAGE. */
  type: Maybe<UrlRewriteEntityTypeEnum>;
  /**
   * One of simple, virtual, bundle, downloadable, grouped, or configurable.
   * @deprecated Use `__typename` instead.
   */
  typeId: Maybe<Scalars['String']['output']>;
  /** The unique ID for a `ProductInterface` object. */
  uid: Scalars['ID']['output'];
  /**
   * Timestamp indicating when the product was updated.
   * @deprecated The field should not be used on the storefront.
   */
  updatedAt: Maybe<Scalars['String']['output']>;
  /** Upsell Products */
  upsellProducts: Maybe<Array<Maybe<ProductInterface>>>;
  /** The part of the URL that identifies the product */
  urlKey: Maybe<Scalars['String']['output']>;
  /** @deprecated Use product's `canonical_url` or url rewrites instead */
  urlPath: Maybe<Scalars['String']['output']>;
  /** URL rewrites list */
  urlRewrites: Maybe<Array<Maybe<UrlRewrite>>>;
  /** The part of the product URL that is appended after the url key */
  urlSuffix: Maybe<Scalars['String']['output']>;
  valueAttributes: Maybe<Array<Maybe<ProductValue>>>;
  /** @deprecated Use the `custom_attributes` field instead. */
  valueTraits: Maybe<Scalars['String']['output']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  valuesDiet: Maybe<Scalars['String']['output']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  valuesEnvironmental: Maybe<Scalars['String']['output']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  valuesIngredient: Maybe<Scalars['String']['output']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  valuesSocial: Maybe<Scalars['String']['output']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  volumeWeight: Maybe<Scalars['Float']['output']>;
  /**
   * An array of websites in which the product is available.
   * @deprecated The field should not be used on the storefront.
   */
  websites: Maybe<Array<Maybe<Website>>>;
  /** The weight of the item, in units defined by the store. */
  weight: Maybe<Scalars['Float']['output']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  width: Maybe<Scalars['Float']['output']>;
  wishlistItemId: Maybe<Scalars['Int']['output']>;
};


/** Defines basic features of a bundle product and contains multiple BundleItems. */
export type BundleProductamastyRewardsHighlightsArgs = {
  attributes?: InputMaybe<Scalars['String']['input']>;
};


/** Defines basic features of a bundle product and contains multiple BundleItems. */
export type BundleProductlabelsArgs = {
  order?: InputMaybe<LabelOrderInput>;
};


/** Defines basic features of a bundle product and contains multiple BundleItems. */
export type BundleProductreviewsArgs = {
  currentPage?: InputMaybe<Scalars['Int']['input']>;
  filters?: InputMaybe<ReviewFilterInput>;
  pageSize?: InputMaybe<Scalars['Int']['input']>;
  sort?: InputMaybe<ReviewSortInput>;
};

/** Defines a single bundle product. */
export type BundleProductCartItemInput = {
  /** A mandatory array of options for the bundle product, including each chosen option and specified quantity. */
  bundleOptions: Array<InputMaybe<BundleOptionInput>>;
  /** The ID and value of the option. */
  customizableOptions?: InputMaybe<Array<InputMaybe<CustomizableOptionInput>>>;
  /** The quantity and SKU of the bundle product. */
  data: CartItemInput;
};

/** Defines bundle product options for `ShipmentItemInterface`. */
export type BundleShipmentItem = ShipmentItemInterface & {
  __typename: 'BundleShipmentItem';
  /** A list of bundle options that are assigned to a shipped product. */
  bundleOptions: Maybe<Array<Maybe<ItemSelectedBundleOption>>>;
  /** The unique ID for a `ShipmentItemInterface` object. */
  id: Scalars['ID']['output'];
  /** The order item associated with the shipment item. */
  orderItem: Maybe<OrderItemInterface>;
  /** The name of the base product. */
  productName: Maybe<Scalars['String']['output']>;
  /** The sale price for the base product. */
  productSalePrice: Money;
  /** The SKU of the base product. */
  productSku: Scalars['String']['output'];
  /** The number of shipped items. */
  quantityShipped: Scalars['Float']['output'];
};

/** Defines bundle product options for `WishlistItemInterface`. */
export type BundleWishlistItem = WishlistItemInterface & {
  __typename: 'BundleWishlistItem';
  /** The date and time the item was added to the wish list. */
  addedAt: Scalars['String']['output'];
  /** An array containing information about the selected bundle items. */
  bundleOptions: Maybe<Array<Maybe<SelectedBundleOption>>>;
  /** Custom options selected for the wish list item. */
  customizableOptions: Array<Maybe<SelectedCustomizableOption>>;
  /** The description of the item. */
  description: Maybe<Scalars['String']['output']>;
  /** The unique ID for a `WishlistItemInterface` object. */
  id: Scalars['ID']['output'];
  /** Product details of the wish list item. */
  product: Maybe<ProductInterface>;
  /** The quantity of this wish list item. */
  quantity: Scalars['Float']['output'];
};

export type ButtonStyles = {
  __typename: 'ButtonStyles';
  /** The button color */
  color: Maybe<Scalars['String']['output']>;
  /** The button height in pixels */
  height: Maybe<Scalars['Int']['output']>;
  /** The button label */
  label: Maybe<Scalars['String']['output']>;
  /** The button layout */
  layout: Maybe<Scalars['String']['output']>;
  /** The button shape */
  shape: Maybe<Scalars['String']['output']>;
  /** Indicates whether the tagline is displayed */
  tagline: Maybe<Scalars['Boolean']['output']>;
  /** Defines if the button uses default height. If the value is false, the value of height is used */
  useDefaultHeight: Maybe<Scalars['Boolean']['output']>;
};

export enum CacheControlScope {
  PRIVATE = 'PRIVATE',
  PUBLIC = 'PUBLIC'
}

export type CacheHit = {
  __typename: 'CacheHit';
  cacheKey: Scalars['String']['output'];
  cachedUntil: Scalars['DateTime']['output'];
  createdAt: Scalars['DateTime']['output'];
  id: Scalars['ID']['output'];
  lastRequestTime: Maybe<Scalars['DateTime']['output']>;
  query: CachedQuery;
  updatedAt: Scalars['DateTime']['output'];
  variables: Scalars['String']['output'];
};

export type CacheKey = {
  __typename: 'CacheKey';
  id: Scalars['ID']['output'];
  type: Scalars['String']['output'];
};

export type CachedQuery = {
  __typename: 'CachedQuery';
  createdAt: Scalars['DateTime']['output'];
  hash: Scalars['String']['output'];
  hits: Maybe<Array<CacheHit>>;
  id: Scalars['ID']['output'];
  lastRequestTime: Maybe<Scalars['DateTime']['output']>;
  query: Scalars['String']['output'];
  updatedAt: Scalars['DateTime']['output'];
};

export type Card = {
  __typename: 'Card';
  /** Card bin details */
  binDetails: Maybe<CardBin>;
  /** Expiration month of the card */
  cardExpiryMonth: Maybe<Scalars['String']['output']>;
  /** Expiration year of the card */
  cardExpiryYear: Maybe<Scalars['String']['output']>;
  /** Last four digits of the card */
  lastDigits: Maybe<Scalars['String']['output']>;
  /** Name on the card */
  name: Maybe<Scalars['String']['output']>;
};

export type CardBin = {
  __typename: 'CardBin';
  /** Card bin number */
  bin: Maybe<Scalars['String']['output']>;
};

/** This enumeration defines the carrier types */
export enum CarrierTypeEnum {
  /**
   * Collect
   * @deprecated
   */
  COLLECT = 'COLLECT',
  /**
   * Delivery
   * @deprecated
   */
  DELIVERY = 'DELIVERY',
  /**
   * Unassigned
   * @deprecated
   */
  UNASSIGNED = 'UNASSIGNED'
}

/** Contains the contents and other details about a guest or customer cart. */
export type Cart = {
  __typename: 'Cart';
  /** Information about guest highlight points available by cart content. */
  amastyRewardsGuestCartHighlights: Maybe<HighlightOutput>;
  /** Information about guest highlight points available by checkout content. */
  amastyRewardsGuestCheckoutHighlights: Maybe<HighlightOutput>;
  /** Information about highlight points available by cart content. */
  amastyRewardsHighlights: Maybe<HighlightOutput>;
  /** Information about used points in cart. */
  amastyRewardsUsedPoints: Maybe<UsedPointsOutput>;
  /** Contains the code attribute, which specifies the applied gift card codes */
  appliedAmGiftCards: Maybe<Array<Maybe<AppliedAmGiftCard>>>;
  /** @deprecated Use `applied_coupons` instead. */
  appliedCoupon: Maybe<AppliedCoupon>;
  /** An array of `AppliedCoupon` objects. Each object contains the `code` text attribute, which specifies the coupon code. */
  appliedCoupons: Maybe<Array<Maybe<AppliedCoupon>>>;
  /** An array of available payment methods. */
  availablePaymentMethods: Maybe<Array<Maybe<AvailablePaymentMethod>>>;
  /** The billing address assigned to the cart. */
  billingAddress: Maybe<BillingCartAddress>;
  /** The email address of the guest or customer. */
  email: Maybe<Scalars['String']['output']>;
  /** The entered gift message for the cart */
  giftMessage: Maybe<GiftMessage>;
  /** The unique ID for a `Cart` object. */
  id: Scalars['ID']['output'];
  /** Indicates whether the cart contains only virtual products. */
  isVirtual: Scalars['Boolean']['output'];
  /** An array of products that have been added to the cart. */
  items: Maybe<Array<Maybe<CartItemInterface>>>;
  nexusNoCacheDummyPropert: Maybe<Scalars['String']['output']>;
  open: Scalars['Boolean']['output'];
  /** Pricing details for the quote. */
  prices: Maybe<CartPrices>;
  /** Indicates which payment method was applied to the cart. */
  selectedPaymentMethod: Maybe<SelectedPaymentMethod>;
  /** An array of shipping addresses assigned to the cart. */
  shippingAddresses: Array<Maybe<ShippingCartAddress>>;
  /** The total number of items in the cart. */
  totalQuantity: Scalars['Float']['output'];
};

/** Contains details the country in a billing or shipping address. */
export type CartAddressCountry = {
  __typename: 'CartAddressCountry';
  /** The country code. */
  code: Scalars['String']['output'];
  /** The display label for the country. */
  label: Scalars['String']['output'];
};

/** Defines the billing or shipping address to be applied to the cart. */
export type CartAddressInput = {
  /** The customer's prefered address group */
  addressGroup?: InputMaybe<AddressGroupEnum>;
  /** The city specified for the billing or shipping address. */
  city: Scalars['String']['input'];
  /** The company specified for the billing or shipping address. */
  company?: InputMaybe<Scalars['String']['input']>;
  /** The country code and label for the billing or shipping address. */
  countryCode: Scalars['String']['input'];
  /** The customer's delivery note for the address */
  deliveryNote?: InputMaybe<Scalars['String']['input']>;
  /** The first name of the customer or guest. */
  firstname: Scalars['String']['input'];
  /** The last name of the customer or guest. */
  lastname: Scalars['String']['input'];
  /** The ZIP or postal code of the billing or shipping address. */
  postcode?: InputMaybe<Scalars['String']['input']>;
  /** A string that defines the state or province of the billing or shipping address. */
  region?: InputMaybe<Scalars['String']['input']>;
  /** An integer that defines the state or province of the billing or shipping address. */
  regionId?: InputMaybe<Scalars['Int']['input']>;
  /** Determines whether to save the address in the customer's address book. The default value is true. */
  saveInAddressBook?: InputMaybe<Scalars['Boolean']['input']>;
  /** An array containing the street for the billing or shipping address. */
  street: Array<InputMaybe<Scalars['String']['input']>>;
  /** The customer's suburb */
  suburb?: InputMaybe<Scalars['String']['input']>;
  /** The telephone number for the billing or shipping address. */
  telephone?: InputMaybe<Scalars['String']['input']>;
  /** The alternative telephone number */
  telephoneAlt?: InputMaybe<Scalars['String']['input']>;
  /** The VAT company number for billing or shipping address. */
  vatId?: InputMaybe<Scalars['String']['input']>;
};

export type CartAddressInterface = {
  /** The customer's prefered address group */
  addressGroup: Maybe<AddressGroupEnum>;
  /** The city specified for the billing or shipping address. */
  city: Scalars['String']['output'];
  /** The company specified for the billing or shipping address. */
  company: Maybe<Scalars['String']['output']>;
  /** An object containing the country label and code. */
  country: CartAddressCountry;
  /** The customer's delivery note for the address */
  deliveryNote: Maybe<Scalars['String']['output']>;
  /** The first name of the customer or guest. */
  firstname: Scalars['String']['output'];
  /** The last name of the customer or guest. */
  lastname: Scalars['String']['output'];
  /** The ZIP or postal code of the billing or shipping address. */
  postcode: Maybe<Scalars['String']['output']>;
  /** An object containing the region label and code. */
  region: Maybe<CartAddressRegion>;
  /** An array containing the street for the billing or shipping address. */
  street: Array<Maybe<Scalars['String']['output']>>;
  /** The customer's suburb */
  suburb: Maybe<Scalars['String']['output']>;
  /** The telephone number for the billing or shipping address. */
  telephone: Maybe<Scalars['String']['output']>;
  /** The alternative telephone number */
  telephoneAlt: Maybe<Scalars['String']['output']>;
  /** The unique id of the customer address. */
  uid: Scalars['String']['output'];
  /** The VAT company number for billing or shipping address. */
  vatId: Maybe<Scalars['String']['output']>;
};

/** Contains details about the region in a billing or shipping address. */
export type CartAddressRegion = {
  __typename: 'CartAddressRegion';
  /** The state or province code. */
  code: Maybe<Scalars['String']['output']>;
  /** The display label for the region. */
  label: Maybe<Scalars['String']['output']>;
  /** The unique ID for a pre-defined region. */
  regionId: Maybe<Scalars['Int']['output']>;
};

/** Contains information about discounts applied to the cart. */
export type CartDiscount = {
  __typename: 'CartDiscount';
  /** The amount of the discount applied to the item. */
  amount: Money;
  /** The description of the discount. */
  label: Array<Maybe<Scalars['String']['output']>>;
};

export type CartItemError = {
  __typename: 'CartItemError';
  /** An error code that describes the error encountered */
  code: CartItemErrorType;
  /** A localized error message */
  message: Scalars['String']['output'];
};

export enum CartItemErrorType {
  /** @deprecated  */
  ITEM_INCREMENTS = 'ITEM_INCREMENTS',
  /** @deprecated  */
  ITEM_QTY = 'ITEM_QTY',
  /** @deprecated  */
  UNDEFINED = 'UNDEFINED'
}

/** Defines an item to be added to the cart. */
export type CartItemInput = {
  /** An array of entered options for the base product, such as personalization text. */
  enteredOptions?: InputMaybe<Array<InputMaybe<EnteredOptionInput>>>;
  /** For a child product, the SKU of its parent product. */
  parentSku?: InputMaybe<Scalars['String']['input']>;
  /** The amount or number of an item to add. */
  quantity: Scalars['Float']['input'];
  /** The selected options for the base product, such as color or size, using the unique ID for an object such as `CustomizableRadioOption`, `CustomizableDropDownOption`, or `ConfigurableProductOptionsValues`. */
  selectedOptions?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** The SKU of the product. */
  sku: Scalars['String']['input'];
};

/** An interface for products in a cart. */
export type CartItemInterface = {
  /** An array of errors encountered while loading the cart item */
  errors: Maybe<Array<Maybe<CartItemError>>>;
  /** @deprecated Use `uid` instead. */
  id: Scalars['String']['output'];
  /** Contains details about the price of the item, including taxes and discounts. */
  prices: Maybe<CartItemPrices>;
  /** Details about an item in the cart. */
  product: ProductInterface;
  /** The quantity of this item in the cart. */
  quantity: Scalars['Float']['output'];
  /** The unique ID for a `CartItemInterface` object. */
  uid: Scalars['ID']['output'];
};

/** Contains details about the price of the item, including taxes and discounts. */
export type CartItemPrices = {
  __typename: 'CartItemPrices';
  /** An array of discounts to be applied to the cart item. */
  discounts: Maybe<Array<Maybe<Discount>>>;
  /** The price of the item before any discounts were applied. The price that might include tax, depending on the configured display settings for cart. */
  price: Money;
  /** The price of the item before any discounts were applied. The price that might include tax, depending on the configured display settings for cart. */
  priceIncludingTax: Money;
  /** The value of the price multiplied by the quantity of the item. */
  rowTotal: Money;
  /** The value of `row_total` plus the tax applied to the item. */
  rowTotalIncludingTax: Money;
  /** The total of all discounts applied to the item. */
  totalItemDiscount: Maybe<Money>;
};

/** Deprecated: The `ShippingCartAddress.cart_items` field now returns `CartItemInterface`. */
export type CartItemQuantity = {
  __typename: 'CartItemQuantity';
  /** @deprecated The `ShippingCartAddress.cart_items` field now returns `CartItemInterface`. */
  cartItemId: Scalars['Int']['output'];
  /** @deprecated The `ShippingCartAddress.cart_items` field now returns `CartItemInterface`. */
  quantity: Scalars['Float']['output'];
};

/** Contains details about the price of a selected customizable value. */
export type CartItemSelectedOptionValuePrice = {
  __typename: 'CartItemSelectedOptionValuePrice';
  /** Indicates whether the price type is fixed, percent, or dynamic. */
  type: PriceTypeEnum;
  /** A string that describes the unit of the value. */
  units: Scalars['String']['output'];
  /** A price value. */
  value: Scalars['Float']['output'];
};

/** A single item to be updated. */
export type CartItemUpdateInput = {
  /** Deprecated. Use `cart_item_uid` instead. */
  cartItemId?: InputMaybe<Scalars['Int']['input']>;
  /** The unique ID for a `CartItemInterface` object. */
  cartItemUid?: InputMaybe<Scalars['ID']['input']>;
  /** An array that defines customizable options for the product. */
  customizableOptions?: InputMaybe<Array<InputMaybe<CustomizableOptionInput>>>;
  /** Gift message details for the cart item */
  giftMessage?: InputMaybe<GiftMessageInput>;
  /** The new quantity of the item. */
  quantity?: InputMaybe<Scalars['Float']['input']>;
};

/** Contains details about the final price of items in the cart, including discount and tax information. */
export type CartPrices = {
  __typename: 'CartPrices';
  /** An array containing the names and amounts of taxes applied to each item in the cart. */
  appliedTaxes: Maybe<Array<Maybe<CartTaxItem>>>;
  /** @deprecated Use discounts instead. */
  discount: Maybe<CartDiscount>;
  /** An array containing cart rule discounts, store credit and gift cards applied to the cart. */
  discounts: Maybe<Array<Maybe<Discount>>>;
  /** The total, including discounts, taxes, shipping, and other fees. */
  grandTotal: Maybe<Money>;
  /** The subtotal without any applied taxes. */
  subtotalExcludingTax: Maybe<Money>;
  /** The subtotal including any applied taxes. */
  subtotalIncludingTax: Maybe<Money>;
  /** The subtotal with any discounts applied, but not taxes. */
  subtotalWithDiscountExcludingTax: Maybe<Money>;
};

/** Contains tax information about an item in the cart. */
export type CartTaxItem = {
  __typename: 'CartTaxItem';
  /** The amount of tax applied to the item. */
  amount: Money;
  /** The description of the tax. */
  label: Scalars['String']['output'];
};

/** An error encountered while adding an item to the the cart. */
export type CartUserInputError = {
  __typename: 'CartUserInputError';
  /** A cart-specific error code. */
  code: CartUserInputErrorType;
  /** A localized error message. */
  message: Scalars['String']['output'];
};

export enum CartUserInputErrorType {
  /** @deprecated  */
  INSUFFICIENT_STOCK = 'INSUFFICIENT_STOCK',
  /** @deprecated  */
  NOT_SALABLE = 'NOT_SALABLE',
  /** @deprecated  */
  PRODUCT_NOT_FOUND = 'PRODUCT_NOT_FOUND',
  /** @deprecated  */
  UNDEFINED = 'UNDEFINED'
}

/** Specifies the attribute to use for sorting search results and indicates whether the results are sorted in ascending or descending order. */
export type CategoryAttributeSortInput = {
  /** Sort by name */
  name?: InputMaybe<SortEnum>;
  /** Sort by position */
  position?: InputMaybe<SortEnum>;
};

/** Defines the filters to be used in the search. A filter contains at least one attribute, a comparison operator, and the value that is being searched for. */
export type CategoryFilterInput = {
  /** Filter by the category type of the category using the `CategoryTypeEnum`. */
  categoryType?: InputMaybe<FilterEqualTypeInput>;
  /** Filter by the unique category ID for a `CategoryInterface` object. */
  categoryUid?: InputMaybe<FilterEqualTypeInput>;
  /** Deprecated: use 'category_uid' to filter uniquely identifiers of categories. */
  ids?: InputMaybe<FilterEqualTypeInput>;
  /** Filter by the display name of the category. */
  name?: InputMaybe<FilterMatchTypeInput>;
  /** Filter by the unique parent category ID for a `CategoryInterface` object. */
  parentCategoryUid?: InputMaybe<FilterEqualTypeInput>;
  /** Filter by the unique parent category ID for a `CategoryInterface` object. */
  parentId?: InputMaybe<FilterEqualTypeInput>;
  /** Filter by the part of the URL that identifies the category. */
  urlKey?: InputMaybe<FilterEqualTypeInput>;
  /** Filter by the URL path for the category. */
  urlPath?: InputMaybe<FilterEqualTypeInput>;
};

/** Contains the full set of attributes that can be returned in a category search. */
export type CategoryInterface = {
  availableSortBy: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  /** An array of breadcrumb items. */
  breadcrumbs: Maybe<Array<Maybe<Breadcrumb>>>;
  /** The relative canonical URL. This value is returned only if the system setting 'Use Canonical Link Meta Tag For Categories' is enabled. */
  canonicalUrl: Maybe<Scalars['String']['output']>;
  categoryType: Maybe<Scalars['String']['output']>;
  childrenCount: Maybe<Scalars['String']['output']>;
  /** Contains a category CMS block. */
  cmsBlock: Maybe<CmsBlock>;
  contentSections: Maybe<Scalars['String']['output']>;
  /**
   * The timestamp indicating when the category was created.
   * @deprecated The field should not be used on the storefront.
   */
  createdAt: Maybe<Scalars['String']['output']>;
  customLayoutUpdateFile: Maybe<Scalars['String']['output']>;
  /** The attribute to use for sorting. */
  defaultSortBy: Maybe<Scalars['String']['output']>;
  /** An optional description of the category. */
  description: Maybe<Scalars['String']['output']>;
  displayMode: Maybe<Scalars['String']['output']>;
  filterPriceRange: Maybe<Scalars['Float']['output']>;
  generateRootCategorySubtree: Maybe<Scalars['Int']['output']>;
  /**
   * An ID that uniquely identifies the category.
   * @deprecated Use `uid` instead.
   */
  id: Maybe<Scalars['Int']['output']>;
  image: Maybe<Scalars['String']['output']>;
  imageSlider: Maybe<Scalars['String']['output']>;
  includeInMenu: Maybe<Scalars['Int']['output']>;
  isAnchor: Maybe<Scalars['Int']['output']>;
  isDisplayedInAutocomplete: Maybe<Scalars['Int']['output']>;
  isVirtualCategory: Maybe<Scalars['Int']['output']>;
  landingPage: Maybe<Scalars['Int']['output']>;
  /** The depth of the category within the tree. */
  level: Maybe<Scalars['Int']['output']>;
  metaDescription: Maybe<Scalars['String']['output']>;
  metaKeywords: Maybe<Scalars['String']['output']>;
  metaTitle: Maybe<Scalars['String']['output']>;
  /** The display name of the category. */
  name: Maybe<Scalars['String']['output']>;
  /** The full category path. */
  path: Maybe<Scalars['String']['output']>;
  /** The category path within the store. */
  pathInStore: Maybe<Scalars['String']['output']>;
  /** The position of the category relative to other categories at the same level in tree. */
  position: Maybe<Scalars['Int']['output']>;
  /** The number of products in the category that are marked as visible. By default, in complex products, parent products are visible, but their child products are not. */
  productCount: Maybe<Scalars['Int']['output']>;
  /** The list of products assigned to the category. */
  products: Maybe<CategoryProducts>;
  promotionsUi: Maybe<Scalars['Int']['output']>;
  reverseSort: Maybe<Scalars['Int']['output']>;
  searchindexWeight: Maybe<Scalars['String']['output']>;
  sortDirection: Maybe<Scalars['String']['output']>;
  titleColour: Maybe<Scalars['String']['output']>;
  /** The unique ID for a `CategoryInterface` object. */
  uid: Scalars['ID']['output'];
  /**
   * The timestamp indicating when the category was updated.
   * @deprecated The field should not be used on the storefront.
   */
  updatedAt: Maybe<Scalars['String']['output']>;
  /** The URL key assigned to the category. */
  urlKey: Maybe<Scalars['String']['output']>;
  /** The URL path assigned to the category. */
  urlPath: Maybe<Scalars['String']['output']>;
  /** The part of the category URL that is appended after the url key */
  urlSuffix: Maybe<Scalars['String']['output']>;
  useNameInProductSearch: Maybe<Scalars['Int']['output']>;
  useStorePositions: Maybe<Scalars['Int']['output']>;
  usedInProductSearch: Maybe<Scalars['Int']['output']>;
  virtualCategory: Maybe<Scalars['String']['output']>;
  virtualCategoryRoot: Maybe<Scalars['Int']['output']>;
};


/** Contains the full set of attributes that can be returned in a category search. */
export type CategoryInterfaceproductsArgs = {
  currentPage?: InputMaybe<Scalars['Int']['input']>;
  pageSize?: InputMaybe<Scalars['Int']['input']>;
  sort?: InputMaybe<ProductAttributeSortInput>;
};

/** Contains details about the products assigned to a category. */
export type CategoryProducts = {
  __typename: 'CategoryProducts';
  /** An array of products that are assigned to the category. */
  items: Maybe<Array<Maybe<ProductInterface>>>;
  /** Pagination metadata. */
  pageInfo: Maybe<SearchResultPageInfo>;
  /** The number of products in the category that are marked as visible. By default, in complex products, parent products are visible, but their child products are not. */
  totalCount: Maybe<Scalars['Int']['output']>;
};

/** Contains a collection of `CategoryTree` objects and pagination information. */
export type CategoryResult = {
  __typename: 'CategoryResult';
  /** A list of categories that match the filter criteria. */
  items: Maybe<Array<Maybe<CategoryTree>>>;
  /** An object that includes the `page_info` and `currentPage` values specified in the query. */
  pageInfo: Maybe<SearchResultPageInfo>;
  /** The total number of categories that match the criteria. */
  totalCount: Maybe<Scalars['Int']['output']>;
};

export type CategorySuggestion = {
  __typename: 'CategorySuggestion';
  breadcrumb: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  /** Category type */
  categoryType: Maybe<CategoryTypeEnum>;
  title: Maybe<Scalars['String']['output']>;
  /** The unique ID for a `CategorySuggestion` object. */
  uid: Scalars['ID']['output'];
  url: Maybe<Scalars['String']['output']>;
  urlKey: Maybe<Scalars['String']['output']>;
};

/** Contains the hierarchy of categories. */
export type CategoryTree = CategoryInterface & RoutableInterface & {
  __typename: 'CategoryTree';
  availableSortBy: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  /** An array of breadcrumb items. */
  breadcrumbs: Maybe<Array<Maybe<Breadcrumb>>>;
  /** The relative canonical URL. This value is returned only if the system setting 'Use Canonical Link Meta Tag For Categories' is enabled. */
  canonicalUrl: Maybe<Scalars['String']['output']>;
  /** Category type */
  categoryType: Maybe<Scalars['String']['output']>;
  /** A tree of child categories. */
  children: Maybe<Array<Maybe<CategoryTree>>>;
  childrenCount: Maybe<Scalars['String']['output']>;
  /** Contains a category CMS block. */
  cmsBlock: Maybe<CmsBlock>;
  contentSections: Maybe<Scalars['String']['output']>;
  coverImage: Image;
  /**
   * The timestamp indicating when the category was created.
   * @deprecated The field should not be used on the storefront.
   */
  createdAt: Maybe<Scalars['String']['output']>;
  customLayoutUpdateFile: Maybe<Scalars['String']['output']>;
  /** The attribute to use for sorting. */
  defaultSortBy: Maybe<Scalars['String']['output']>;
  /** An optional description of the category. */
  description: Maybe<Scalars['String']['output']>;
  displayMode: Maybe<Scalars['String']['output']>;
  filterPriceRange: Maybe<Scalars['Float']['output']>;
  generateRootCategorySubtree: Maybe<Scalars['Int']['output']>;
  /**
   * An ID that uniquely identifies the category.
   * @deprecated Use `uid` instead.
   */
  id: Maybe<Scalars['Int']['output']>;
  image: Maybe<Scalars['String']['output']>;
  /** Category slider image */
  imageSlider: Maybe<Scalars['String']['output']>;
  includeInMenu: Maybe<Scalars['Int']['output']>;
  isAnchor: Maybe<Scalars['Int']['output']>;
  isDisplayedInAutocomplete: Maybe<Scalars['Int']['output']>;
  isVirtualCategory: Maybe<Scalars['Int']['output']>;
  landingPage: Maybe<Scalars['Int']['output']>;
  /** The depth of the category within the tree. */
  level: Maybe<Scalars['Int']['output']>;
  metaDescription: Maybe<Scalars['String']['output']>;
  metaKeywords: Maybe<Scalars['String']['output']>;
  metaTitle: Maybe<Scalars['String']['output']>;
  /** The display name of the category. */
  name: Maybe<Scalars['String']['output']>;
  pageContent: Maybe<Page>;
  /** The full category path. */
  path: Maybe<Scalars['String']['output']>;
  /** The category path within the store. */
  pathInStore: Maybe<Scalars['String']['output']>;
  /** The position of the category relative to other categories at the same level in tree. */
  position: Maybe<Scalars['Int']['output']>;
  /** The number of products in the category that are marked as visible. By default, in complex products, parent products are visible, but their child products are not. */
  productCount: Maybe<Scalars['Int']['output']>;
  /** The list of products assigned to the category. */
  products: Maybe<CategoryProducts>;
  promotionsUi: Maybe<Scalars['Int']['output']>;
  /** Contains 0 when there is no redirect error. A value of 301 indicates the URL of the requested resource has been changed permanently, while a value of 302 indicates a temporary redirect. */
  redirectCode: Scalars['Int']['output'];
  /** The internal relative URL. If the specified URL is a redirect, the query returns the redirected URL, not the original. */
  relativeUrl: Maybe<Scalars['String']['output']>;
  reverseSort: Maybe<Scalars['Int']['output']>;
  searchindexWeight: Maybe<Scalars['String']['output']>;
  sortDirection: Maybe<Scalars['String']['output']>;
  /** Category title colour */
  titleColour: Maybe<Scalars['String']['output']>;
  /** One of PRODUCT, CATEGORY, or CMS_PAGE. */
  type: Maybe<UrlRewriteEntityTypeEnum>;
  /** The unique ID for a `CategoryInterface` object. */
  uid: Scalars['ID']['output'];
  /**
   * The timestamp indicating when the category was updated.
   * @deprecated The field should not be used on the storefront.
   */
  updatedAt: Maybe<Scalars['String']['output']>;
  /** The URL key assigned to the category. */
  urlKey: Maybe<Scalars['String']['output']>;
  /** The URL path assigned to the category. */
  urlPath: Maybe<Scalars['String']['output']>;
  /** The part of the category URL that is appended after the url key */
  urlSuffix: Maybe<Scalars['String']['output']>;
  useNameInProductSearch: Maybe<Scalars['Int']['output']>;
  useStorePositions: Maybe<Scalars['Int']['output']>;
  usedInProductSearch: Maybe<Scalars['Int']['output']>;
  virtualCategory: Maybe<Scalars['String']['output']>;
  virtualCategoryRoot: Maybe<Scalars['Int']['output']>;
};


/** Contains the hierarchy of categories. */
export type CategoryTreeproductsArgs = {
  currentPage?: InputMaybe<Scalars['Int']['input']>;
  pageSize?: InputMaybe<Scalars['Int']['input']>;
  sort?: InputMaybe<ProductAttributeSortInput>;
};

/** This enumeration defines the category types */
export enum CategoryTypeEnum {
  /**
   * Brand
   * @deprecated
   */
  BRAND = 'BRAND',
  /**
   * Category
   * @deprecated
   */
  CATEGORY = 'CATEGORY',
  /**
   * Ingredient
   * @deprecated
   */
  INGREDIENT = 'INGREDIENT'
}

export enum CellAlignEnum {
  /** Center */
  CENTER = 'CENTER',
  /** Left */
  LEFT = 'LEFT',
  /** Right */
  RIGHT = 'RIGHT'
}

export type Checkout = {
  __typename: 'Checkout';
  canAdvance: Scalars['Boolean']['output'];
  confirmInfo: ConfirmInfo;
  deliveryInfo: DeliveryInfo;
  id: Scalars['ID']['output'];
  paymentInfo: PaymentInfo;
  showSteps: Scalars['Boolean']['output'];
  step: CheckoutStepEnum;
};

/** Defines details about an individual checkout agreement. */
export type CheckoutAgreement = {
  __typename: 'CheckoutAgreement';
  /** The ID for a checkout agreement. */
  agreementId: Scalars['Int']['output'];
  /** The checkbox text for the checkout agreement. */
  checkboxText: Scalars['String']['output'];
  /** Required. The text of the agreement. */
  content: Scalars['String']['output'];
  /** The height of the text box where the Terms and Conditions statement appears during checkout. */
  contentHeight: Maybe<Scalars['String']['output']>;
  /** Indicates whether the `content` text is in HTML format. */
  isHtml: Scalars['Boolean']['output'];
  /** Indicates whether agreements are accepted automatically or manually. */
  mode: CheckoutAgreementMode;
  /** The name given to the condition. */
  name: Scalars['String']['output'];
};

/** Indicates how agreements are accepted. */
export enum CheckoutAgreementMode {
  /**
   * Conditions are automatically accepted upon checkout.
   * @deprecated
   */
  AUTO = 'AUTO',
  /**
   * Shoppers must manually accept the conditions to place an order.
   * @deprecated
   */
  MANUAL = 'MANUAL'
}

export enum CheckoutStepEnum {
  /** Delivery */
  DELIVERY = 'DELIVERY',
  /** Payment */
  PAYMENT = 'PAYMENT',
  /** Place */
  PLACE = 'PLACE'
}

/** An error encountered while adding an item to the cart. */
export type CheckoutUserInputError = {
  __typename: 'CheckoutUserInputError';
  /** An error code that is specific to Checkout. */
  code: CheckoutUserInputErrorCodes;
  /** A localized error message. */
  message: Scalars['String']['output'];
  /** The path to the input field that caused an error. See the GraphQL specification about path errors for details: http://spec.graphql.org/draft/#sec-Errors */
  path: Array<Maybe<Scalars['String']['output']>>;
};

export enum CheckoutUserInputErrorCodes {
  /** @deprecated  */
  INSUFFICIENT_STOCK = 'INSUFFICIENT_STOCK',
  /** @deprecated  */
  NOT_SALABLE = 'NOT_SALABLE',
  /** @deprecated  */
  PRODUCT_NOT_FOUND = 'PRODUCT_NOT_FOUND',
  /** @deprecated  */
  REORDER_NOT_AVAILABLE = 'REORDER_NOT_AVAILABLE',
  /** @deprecated  */
  UNDEFINED = 'UNDEFINED'
}

/** Chronological Period Type */
export enum ChronologicalPeriodTypeEnum {
  /** Daily */
  DAILY = 'DAILY',
  /** Monthly */
  MONTHLY = 'MONTHLY',
  /** Weekly */
  WEEKLY = 'WEEKLY',
  /** Yearly */
  YEARLY = 'YEARLY'
}

/** Chronological Unit Type */
export enum ChronologicalUnitTypeEnum {
  /** Day(s) */
  DAY = 'DAY',
  /** Hour(s) */
  HOUR = 'HOUR',
  /** Minute(s) */
  MINUTE = 'MINUTE',
  /** Month(s) */
  MONTH = 'MONTH',
  /** Week(s) */
  WEEK = 'WEEK',
  /** Year(s) */
  YEAR = 'YEAR'
}

export type ClientInterface = {
  __typename: 'ClientInterface';
  kind: Scalars['String']['output'];
  name: Scalars['String']['output'];
  possibleTypes: Maybe<Array<Maybe<ClientInterfaceType>>>;
};

export type ClientInterfaceType = {
  __typename: 'ClientInterfaceType';
  name: Scalars['String']['output'];
};

export enum ClientReloadTypeEnum {
  /** Data Only */
  DATA_ONLY = 'DATA_ONLY',
  /** Full Refresh */
  FULL_REFRESH = 'FULL_REFRESH'
}

/** Contains details about a specific CMS block. */
export type CmsBlock = {
  __typename: 'CmsBlock';
  /** The content of the CMS block in raw HTML. */
  content: Maybe<Scalars['String']['output']>;
  /** Silvertree Page Builder sectional */
  contentSection: Maybe<Scalars['String']['output']>;
  /** The CMS block identifier. */
  identifier: Maybe<Scalars['String']['output']>;
  /** The title assigned to the CMS block. */
  title: Maybe<Scalars['String']['output']>;
};

/** Contains an array CMS block items. */
export type CmsBlocks = {
  __typename: 'CmsBlocks';
  /** An array of CMS blocks. */
  items: Maybe<Array<Maybe<CmsBlock>>>;
};

/** Contains details about a CMS page. */
export type CmsPage = RoutableInterface & {
  __typename: 'CmsPage';
  /** The content of the CMS page in raw HTML. */
  content: Maybe<Scalars['String']['output']>;
  /** The heading that displays at the top of the CMS page. */
  contentHeading: Maybe<Scalars['String']['output']>;
  /** Silvertree Page Builder sectionals */
  contentSections: Maybe<Scalars['String']['output']>;
  /** The ID of a CMS page. */
  identifier: Maybe<Scalars['String']['output']>;
  /** A brief description of the page for search results listings. */
  metaDescription: Maybe<Scalars['String']['output']>;
  /** A brief description of the page for search results listings. */
  metaKeywords: Maybe<Scalars['String']['output']>;
  /** A page title that is indexed by search engines and appears in search results listings. */
  metaTitle: Maybe<Scalars['String']['output']>;
  pageContent: Maybe<Page>;
  /** The design layout of the page, indicating the number of columns and navigation features used on the page. */
  pageLayout: Maybe<Scalars['String']['output']>;
  /** Contains 0 when there is no redirect error. A value of 301 indicates the URL of the requested resource has been changed permanently, while a value of 302 indicates a temporary redirect. */
  redirectCode: Scalars['Int']['output'];
  /** The internal relative URL. If the specified URL is a redirect, the query returns the redirected URL, not the original. */
  relativeUrl: Maybe<Scalars['String']['output']>;
  /** The name that appears in the breadcrumb trail navigation and in the browser title bar and tab. */
  title: Maybe<Scalars['String']['output']>;
  /** One of PRODUCT, CATEGORY, or CMS_PAGE. */
  type: Maybe<UrlRewriteEntityTypeEnum>;
  /** The URL key of the CMS page, which is often based on the `content_heading`. */
  urlKey: Maybe<Scalars['String']['output']>;
};

export type ColorSwatchData = SwatchDataInterface & {
  __typename: 'ColorSwatchData';
  /** The value can be represented as color (HEX code), image link, or text. */
  value: Maybe<Scalars['String']['output']>;
};

/** Contains an attribute code that is used for product comparisons. */
export type ComparableAttribute = {
  __typename: 'ComparableAttribute';
  /** An attribute code that is enabled for product comparisons. */
  code: Scalars['String']['output'];
  /** The label of the attribute code. */
  label: Scalars['String']['output'];
};

/** Defines an object used to iterate through items for product comparisons. */
export type ComparableItem = {
  __typename: 'ComparableItem';
  /** An array of product attributes that can be used to compare products. */
  attributes: Array<Maybe<ProductAttribute>>;
  /** Details about a product in a compare list. */
  product: ProductInterface;
  /** The unique ID of an item in a compare list. */
  uid: Scalars['ID']['output'];
};

/** Contains iterable information such as the array of items, the count, and attributes that represent the compare list. */
export type CompareList = {
  __typename: 'CompareList';
  /** An array of attributes that can be used for comparing products. */
  attributes: Maybe<Array<Maybe<ComparableAttribute>>>;
  /** The number of items in the compare list. */
  itemCount: Scalars['Int']['output'];
  /** An array of products to compare. */
  items: Maybe<Array<Maybe<ComparableItem>>>;
  /** The unique ID assigned to the compare list. */
  uid: Scalars['ID']['output'];
};

export type ComplexTextValue = {
  __typename: 'ComplexTextValue';
  /** Text that can contain HTML tags. */
  html: Scalars['String']['output'];
};

/** Contains details about a configurable product attribute option. */
export type ConfigurableAttributeOption = {
  __typename: 'ConfigurableAttributeOption';
  /** The ID assigned to the attribute. */
  code: Maybe<Scalars['String']['output']>;
  /** A string that describes the configurable attribute option. */
  label: Maybe<Scalars['String']['output']>;
  /** The unique ID for a `ConfigurableAttributeOption` object. */
  uid: Scalars['ID']['output'];
  /** A unique index number assigned to the configurable product option. */
  valueIndex: Maybe<Scalars['Int']['output']>;
};

/** An implementation for configurable product cart items. */
export type ConfigurableCartItem = CartItemInterface & {
  __typename: 'ConfigurableCartItem';
  /** An array containing the configuranle options the shopper selected. */
  configurableOptions: Array<Maybe<SelectedConfigurableOption>>;
  /** Product details of the cart item. */
  configuredVariant: ProductInterface;
  /** An array containing the customizable options the shopper selected. */
  customizableOptions: Array<Maybe<SelectedCustomizableOption>>;
  /** An array of errors encountered while loading the cart item */
  errors: Maybe<Array<Maybe<CartItemError>>>;
  /** The entered gift message for the cart item */
  giftMessage: Maybe<GiftMessage>;
  /** @deprecated Use `uid` instead. */
  id: Scalars['String']['output'];
  /** Contains details about the price of the item, including taxes and discounts. */
  prices: Maybe<CartItemPrices>;
  /** Details about an item in the cart. */
  product: ProductInterface;
  /** The quantity of this item in the cart. */
  quantity: Scalars['Float']['output'];
  /** The unique ID for a `CartItemInterface` object. */
  uid: Scalars['ID']['output'];
};

/** Describes configurable options that have been selected and can be selected as a result of the previous selections. */
export type ConfigurableOptionAvailableForSelection = {
  __typename: 'ConfigurableOptionAvailableForSelection';
  /** An attribute code that uniquely identifies a configurable option. */
  attributeCode: Scalars['String']['output'];
  /** An array of selectable option value IDs. */
  optionValueUids: Array<Maybe<Scalars['ID']['output']>>;
};

/** Defines basic features of a configurable product and its simple product variants. */
export type ConfigurableProduct = CustomizableProductInterface & PhysicalProductInterface & ProductInterface & RoutableInterface & {
  __typename: 'ConfigurableProduct';
  /** @deprecated Use the `custom_attributes` field instead. */
  age: Maybe<Scalars['String']['output']>;
  /** Information about guest highlight points available by product. */
  amastyRewardsGuestHighlights: Maybe<HighlightOutput>;
  /** Information about highlight points available by product. */
  amastyRewardsHighlights: Maybe<HighlightOutput>;
  /**
   * The attribute set assigned to the product.
   * @deprecated The field should not be used on the storefront.
   */
  attributeSetId: Maybe<Scalars['Int']['output']>;
  /** Returns all assigned product attributes and their values */
  attributes: Array<Maybe<ProductAttributes>>;
  /** Returns the brand of the product */
  brand: Maybe<Brand>;
  cacheBuster: Maybe<Scalars['String']['output']>;
  /** The relative canonical URL. This value is returned only if the system setting 'Use Canonical Link Meta Tag For Products' is enabled. */
  canonicalUrl: Maybe<Scalars['String']['output']>;
  cartItemUid: Maybe<Scalars['String']['output']>;
  /** The categories assigned to a product. */
  categories: Maybe<Array<Maybe<CategoryInterface>>>;
  /** @deprecated Use the `custom_attributes` field instead. */
  colour: Maybe<Scalars['Int']['output']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  concentration: Maybe<Scalars['Int']['output']>;
  /** An array of options for the configurable product. */
  configurableOptions: Maybe<Array<Maybe<ConfigurableProductOptions>>>;
  /** An array of media gallery items and other details about selected configurable product options as well as details about remaining selectable options. */
  configurableProductOptionsSelection: Maybe<ConfigurableProductOptionsSelection>;
  /** The product's country of origin. */
  countryOfManufacture: Maybe<Scalars['String']['output']>;
  coverImage: Image;
  /**
   * Timestamp indicating when the product was created.
   * @deprecated The field should not be used on the storefront.
   */
  createdAt: Maybe<Scalars['String']['output']>;
  /** Crosssell Products */
  crosssellProducts: Maybe<Array<Maybe<ProductInterface>>>;
  /** @deprecated Use the `custom_attributes` field instead. */
  department: Maybe<Scalars['String']['output']>;
  /** Detailed information about the product. The value can include simple HTML tags. */
  description: Maybe<ComplexTextValue>;
  /** @deprecated Use the `custom_attributes` field instead. */
  flavour: Maybe<Scalars['Int']['output']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  fragrance: Maybe<Scalars['Int']['output']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  gender: Maybe<Scalars['String']['output']>;
  /** Indicates whether a gift message is available. */
  giftMessageAvailable: Maybe<Scalars['String']['output']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  hairType: Maybe<Scalars['String']['output']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  height: Maybe<Scalars['Float']['output']>;
  /**
   * The ID number assigned to the product.
   * @deprecated Use the `uid` field instead.
   */
  id: Maybe<Scalars['Int']['output']>;
  /** The relative path to the main image on the product page. */
  image: Maybe<ProductImage>;
  imageGallery: Array<Image>;
  ingredientList: Maybe<Array<Maybe<ProductIngredient>>>;
  /** @deprecated Use the `custom_attributes` field instead. */
  ingredients: Maybe<Scalars['String']['output']>;
  isInWishlist: Scalars['Boolean']['output'];
  /** List of product labels associated to the product */
  labels: Array<Maybe<LabelItem>>;
  /** @deprecated Use the `custom_attributes` field instead. */
  length: Maybe<Scalars['Float']['output']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  licensingStatus: Maybe<Scalars['String']['output']>;
  /**
   * A number representing the product's manufacturer.
   * @deprecated Use the `custom_attributes` field instead.
   */
  manufacturer: Maybe<Scalars['Int']['output']>;
  /** An array of media gallery objects. */
  mediaGallery: Maybe<Array<Maybe<MediaGalleryInterface>>>;
  /**
   * An array of MediaGalleryEntry objects.
   * @deprecated Use `media_gallery` instead.
   */
  mediaGalleryEntries: Maybe<Array<Maybe<MediaGalleryEntry>>>;
  /** An array of media gallery objects. */
  mediaGalleryV2: Maybe<Array<Maybe<MediaGalleryV2Interface>>>;
  /** A brief overview of the product for search results listings, maximum 255 characters. */
  metaDescription: Maybe<Scalars['String']['output']>;
  /** A comma-separated list of keywords that are visible only to search engines. */
  metaKeyword: Maybe<Scalars['String']['output']>;
  /** A string that is displayed in the title bar and tab of the browser and in search results lists. */
  metaTitle: Maybe<Scalars['String']['output']>;
  /** The product name. Customers use this name to identify the product. */
  name: Maybe<Scalars['String']['output']>;
  /** The beginning date for new product listings, and determines if the product is featured as a new product. */
  newFromDate: Maybe<Scalars['String']['output']>;
  /** The end date for new product listings. */
  newToDate: Maybe<Scalars['String']['output']>;
  /** Product stock only x left count */
  onlyXLeftInStock: Maybe<Scalars['Float']['output']>;
  /** An array of options for a customizable product. */
  options: Maybe<Array<Maybe<CustomizableOptionInterface>>>;
  /** If the product has multiple options, determines where they appear on the product page. */
  optionsContainer: Maybe<Scalars['String']['output']>;
  /**
   * Indicates the price of an item.
   * @deprecated Use `price_range` for product price information.
   */
  price: Maybe<ProductPrices>;
  /** The range of prices for the product */
  priceRange: PriceRange;
  /** An array of `TierPrice` objects. */
  priceTiers: Maybe<Array<Maybe<TierPrice>>>;
  /** An array of `ProductLinks` objects. */
  productLinks: Maybe<Array<Maybe<ProductLinksInterface>>>;
  productType: ProductTypeEnum;
  /** @deprecated Use the `custom_attributes` field instead. */
  quantity: Maybe<Scalars['Int']['output']>;
  quantityInCart: Scalars['Int']['output'];
  /** The rating summary for the product */
  ratingSummary: RatingSummary;
  /** Contains 0 when there is no redirect error. A value of 301 indicates the URL of the requested resource has been changed permanently, while a value of 302 indicates a temporary redirect. */
  redirectCode: Scalars['Int']['output'];
  /** Related Products */
  relatedProducts: Maybe<Array<Maybe<ProductInterface>>>;
  /** The internal relative URL. If the specified URL is a redirect, the query returns the redirected URL, not the original. */
  relativeUrl: Maybe<Scalars['String']['output']>;
  /** The total count of all the reviews given to the product. */
  reviewCount: Scalars['Int']['output'];
  /** The list of products reviews */
  reviews: ProductReviews;
  /** A short description of the product. Its use depends on the theme. */
  shortDescription: Maybe<ComplexTextValue>;
  /** @deprecated Use the `custom_attributes` field instead. */
  size: Maybe<Scalars['Int']['output']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  skinConcern: Maybe<Scalars['String']['output']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  skinType: Maybe<Scalars['String']['output']>;
  /** A number or code assigned to a product to identify the product, options, price, and manufacturer. */
  sku: Maybe<Scalars['String']['output']>;
  /** The relative path to the small image, which is used on catalog pages. */
  smallImage: Maybe<ProductImage>;
  /**
   * The beginning date that a product has a special price.
   * @deprecated The field should not be used on the storefront.
   */
  specialFromDate: Maybe<Scalars['String']['output']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  specialIcons: Maybe<Scalars['String']['output']>;
  /** The discounted price of the product. */
  specialPrice: Maybe<Scalars['Float']['output']>;
  /** The end date for a product with a special price. */
  specialToDate: Maybe<Scalars['String']['output']>;
  stockInfo: Maybe<StockInfo>;
  /** Stock status of the product */
  stockStatus: Maybe<ProductStockStatus>;
  /** The file name of a swatch image. */
  swatchImage: Maybe<Scalars['String']['output']>;
  /** Returns all assigned product tags and their values */
  tags: Array<Maybe<ProductTags>>;
  /** The relative path to the product's thumbnail image. */
  thumbnail: Maybe<ProductImage>;
  /**
   * The price when tier pricing is in effect and the items purchased threshold has been reached.
   * @deprecated Use `price_tiers` for product tier price information.
   */
  tierPrice: Maybe<Scalars['Float']['output']>;
  /**
   * An array of ProductTierPrices objects.
   * @deprecated Use `price_tiers` for product tier price information.
   */
  tierPrices: Maybe<Array<Maybe<ProductTierPrices>>>;
  /** One of PRODUCT, CATEGORY, or CMS_PAGE. */
  type: Maybe<UrlRewriteEntityTypeEnum>;
  /**
   * One of simple, virtual, bundle, downloadable, grouped, or configurable.
   * @deprecated Use `__typename` instead.
   */
  typeId: Maybe<Scalars['String']['output']>;
  /** The unique ID for a `ProductInterface` object. */
  uid: Scalars['ID']['output'];
  /**
   * Timestamp indicating when the product was updated.
   * @deprecated The field should not be used on the storefront.
   */
  updatedAt: Maybe<Scalars['String']['output']>;
  /** Upsell Products */
  upsellProducts: Maybe<Array<Maybe<ProductInterface>>>;
  /** The part of the URL that identifies the product */
  urlKey: Maybe<Scalars['String']['output']>;
  /** @deprecated Use product's `canonical_url` or url rewrites instead */
  urlPath: Maybe<Scalars['String']['output']>;
  /** URL rewrites list */
  urlRewrites: Maybe<Array<Maybe<UrlRewrite>>>;
  /** The part of the product URL that is appended after the url key */
  urlSuffix: Maybe<Scalars['String']['output']>;
  valueAttributes: Maybe<Array<Maybe<ProductValue>>>;
  /** @deprecated Use the `custom_attributes` field instead. */
  valueTraits: Maybe<Scalars['String']['output']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  valuesDiet: Maybe<Scalars['String']['output']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  valuesEnvironmental: Maybe<Scalars['String']['output']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  valuesIngredient: Maybe<Scalars['String']['output']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  valuesSocial: Maybe<Scalars['String']['output']>;
  /** An array of simple product variants. */
  variants: Maybe<Array<Maybe<ConfigurableVariant>>>;
  /** @deprecated Use the `custom_attributes` field instead. */
  volumeWeight: Maybe<Scalars['Float']['output']>;
  /**
   * An array of websites in which the product is available.
   * @deprecated The field should not be used on the storefront.
   */
  websites: Maybe<Array<Maybe<Website>>>;
  /** The weight of the item, in units defined by the store. */
  weight: Maybe<Scalars['Float']['output']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  width: Maybe<Scalars['Float']['output']>;
  wishlistItemId: Maybe<Scalars['Int']['output']>;
};


/** Defines basic features of a configurable product and its simple product variants. */
export type ConfigurableProductamastyRewardsHighlightsArgs = {
  attributes?: InputMaybe<Scalars['String']['input']>;
};


/** Defines basic features of a configurable product and its simple product variants. */
export type ConfigurableProductconfigurableProductOptionsSelectionArgs = {
  configurableOptionValueUids?: InputMaybe<Array<Scalars['ID']['input']>>;
};


/** Defines basic features of a configurable product and its simple product variants. */
export type ConfigurableProductlabelsArgs = {
  order?: InputMaybe<LabelOrderInput>;
};


/** Defines basic features of a configurable product and its simple product variants. */
export type ConfigurableProductreviewsArgs = {
  currentPage?: InputMaybe<Scalars['Int']['input']>;
  filters?: InputMaybe<ReviewFilterInput>;
  pageSize?: InputMaybe<Scalars['Int']['input']>;
  sort?: InputMaybe<ReviewSortInput>;
};

export type ConfigurableProductCartItemInput = {
  /** The ID and value of the option. */
  customizableOptions?: InputMaybe<Array<InputMaybe<CustomizableOptionInput>>>;
  /** The quantity and SKU of the configurable product. */
  data: CartItemInput;
  /** The SKU of the parent configurable product. */
  parentSku?: InputMaybe<Scalars['String']['input']>;
  /** Deprecated. Use `CartItemInput.sku` instead. */
  variantSku?: InputMaybe<Scalars['String']['input']>;
};

/** Contains details about configurable product options. */
export type ConfigurableProductOption = {
  __typename: 'ConfigurableProductOption';
  /** An attribute code that uniquely identifies a configurable option. */
  attributeCode: Scalars['String']['output'];
  /** The display name of the option. */
  label: Scalars['String']['output'];
  /** The unique ID of the configurable option. */
  uid: Scalars['ID']['output'];
  /** An array of values that are applicable for this option. */
  values: Maybe<Array<Maybe<ConfigurableProductOptionValue>>>;
};

/** Defines a value for a configurable product option. */
export type ConfigurableProductOptionValue = {
  __typename: 'ConfigurableProductOptionValue';
  /** Indicates whether the product is available with this selected option. */
  isAvailable: Scalars['Boolean']['output'];
  /** Indicates whether the value is the default. */
  isUseDefault: Scalars['Boolean']['output'];
  /** The display name of the value. */
  label: Scalars['String']['output'];
  /** The URL assigned to the thumbnail of the swatch image. */
  swatch: Maybe<SwatchDataInterface>;
  /** The unique ID of the value. */
  uid: Scalars['ID']['output'];
};

/** Defines configurable attributes for the specified product. */
export type ConfigurableProductOptions = {
  __typename: 'ConfigurableProductOptions';
  /** A string that identifies the attribute. */
  attributeCode: Maybe<Scalars['String']['output']>;
  /**
   * The ID assigned to the attribute.
   * @deprecated Use `attribute_uid` instead.
   */
  attributeId: Maybe<Scalars['String']['output']>;
  /**
   * The ID assigned to the attribute.
   * @deprecated Use `attribute_uid` instead.
   */
  attributeIdV2: Maybe<Scalars['Int']['output']>;
  /** The unique ID for an `Attribute` object. */
  attributeUid: Scalars['ID']['output'];
  /**
   * The configurable option ID number assigned by the system.
   * @deprecated Use `uid` instead.
   */
  id: Maybe<Scalars['Int']['output']>;
  /** A displayed string that describes the configurable product option. */
  label: Maybe<Scalars['String']['output']>;
  /** A number that indicates the order in which the attribute is displayed. */
  position: Maybe<Scalars['Int']['output']>;
  /**
   * This is the same as a product's `id` field.
   * @deprecated `product_id` is not needed and can be obtained from its parent.
   */
  productId: Maybe<Scalars['Int']['output']>;
  /** The unique ID for a `ConfigurableProductOptions` object. */
  uid: Scalars['ID']['output'];
  /** Indicates whether the option is the default. */
  useDefault: Maybe<Scalars['Boolean']['output']>;
  /** An array that defines the `value_index` codes assigned to the configurable product. */
  values: Maybe<Array<Maybe<ConfigurableProductOptionsValues>>>;
};

/** Contains metadata corresponding to the selected configurable options. */
export type ConfigurableProductOptionsSelection = {
  __typename: 'ConfigurableProductOptionsSelection';
  /** An array of all possible configurable options. */
  configurableOptions: Maybe<Array<Maybe<ConfigurableProductOption>>>;
  /** Product images and videos corresponding to the specified configurable options selection. */
  mediaGallery: Maybe<Array<Maybe<MediaGalleryInterface>>>;
  /** The configurable options available for further selection based on the current selection. */
  optionsAvailableForSelection: Maybe<Array<Maybe<ConfigurableOptionAvailableForSelection>>>;
  /** A variant represented by the specified configurable options selection. The value is expected to be null until selections are made for each configurable option. */
  variant: Maybe<SimpleProduct>;
};

/** Contains the index number assigned to a configurable product option. */
export type ConfigurableProductOptionsValues = {
  __typename: 'ConfigurableProductOptionsValues';
  /** The label of the product on the default store. */
  defaultLabel: Maybe<Scalars['String']['output']>;
  /** The label of the product. */
  label: Maybe<Scalars['String']['output']>;
  /** The label of the product on the current store. */
  storeLabel: Maybe<Scalars['String']['output']>;
  /** Swatch data for a configurable product option. */
  swatchData: Maybe<SwatchDataInterface>;
  /** The unique ID for a `ConfigurableProductOptionsValues` object. */
  uid: Maybe<Scalars['ID']['output']>;
  /** Indicates whether to use the default_label. */
  useDefaultValue: Maybe<Scalars['Boolean']['output']>;
  /**
   * A unique index number assigned to the configurable product option.
   * @deprecated Use `uid` instead.
   */
  valueIndex: Maybe<Scalars['Int']['output']>;
};

/** Contains all the simple product variants of a configurable product. */
export type ConfigurableVariant = {
  __typename: 'ConfigurableVariant';
  /** An array of configurable attribute options. */
  attributes: Maybe<Array<Maybe<ConfigurableAttributeOption>>>;
  /** An array of linked simple products. */
  product: Maybe<SimpleProduct>;
};

/** A configurable product wish list item. */
export type ConfigurableWishlistItem = WishlistItemInterface & {
  __typename: 'ConfigurableWishlistItem';
  /** The date and time the item was added to the wish list. */
  addedAt: Scalars['String']['output'];
  /**
   * The SKU of the simple product corresponding to a set of selected configurable options.
   * @deprecated Use `ConfigurableWishlistItem.configured_variant.sku` instead.
   */
  childSku: Scalars['String']['output'];
  /** An array of selected configurable options. */
  configurableOptions: Maybe<Array<Maybe<SelectedConfigurableOption>>>;
  /** Product details of the selected variant. The value is null if some options are not configured. */
  configuredVariant: Maybe<ProductInterface>;
  /** Custom options selected for the wish list item. */
  customizableOptions: Array<Maybe<SelectedCustomizableOption>>;
  /** The description of the item. */
  description: Maybe<Scalars['String']['output']>;
  /** The unique ID for a `WishlistItemInterface` object. */
  id: Scalars['ID']['output'];
  /** Product details of the wish list item. */
  product: Maybe<ProductInterface>;
  /** The quantity of this wish list item. */
  quantity: Scalars['Float']['output'];
};

export type ConfirmInfo = {
  __typename: 'ConfirmInfo';
  id: Scalars['ID']['output'];
  step: PlaceStepEnum;
};

export enum ContactReasonEnum {
  /** @deprecated  */
  ACCOUNT_RELATED = 'ACCOUNT_RELATED',
  /** @deprecated  */
  FEEDBACK_COMMENTS = 'FEEDBACK_COMMENTS',
  /** @deprecated  */
  PAYMENT_PURCHASES = 'PAYMENT_PURCHASES',
  /** @deprecated  */
  REFUNDS_RETURNS = 'REFUNDS_RETURNS',
  /** @deprecated  */
  TRACKING_DELIVERY = 'TRACKING_DELIVERY',
  /** @deprecated  */
  WEBSITE_RELATED = 'WEBSITE_RELATED'
}

export type ContactUsFormSubmitInput = {
  contactReason?: InputMaybe<ContactReasonEnum>;
  currentMood?: InputMaybe<CurrentMoodEnum>;
  emailAddress: Scalars['String']['input'];
  isOrderRelated?: InputMaybe<Scalars['String']['input']>;
  message?: InputMaybe<Scalars['String']['input']>;
  mobile: Scalars['String']['input'];
  preferredContactMethod?: InputMaybe<PreferredContactMethodEnum>;
  subject?: InputMaybe<Scalars['String']['input']>;
};

export type ContactUsFormSubmitPayload = {
  __typename: 'ContactUsFormSubmitPayload';
  message: Maybe<Scalars['String']['output']>;
  success: Maybe<Scalars['Boolean']['output']>;
};

export type ContactUsInput = {
  /** The shopper's comment to the merchant. */
  comment: Scalars['String']['input'];
  /** The email address of the shopper. */
  email: Scalars['String']['input'];
  /** The full name of the shopper. */
  name: Scalars['String']['input'];
  /** The shopper's telephone number. */
  telephone?: InputMaybe<Scalars['String']['input']>;
};

/** Contains the status of the request. */
export type ContactUsOutput = {
  __typename: 'ContactUsOutput';
  /** Indicates whether the request was successful. */
  status: Scalars['Boolean']['output'];
};

export type ContactUsStore = {
  __typename: 'ContactUsStore';
  address: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  times: Scalars['String']['output'];
};

export type ContactUsStoreInput = {
  address: Scalars['String']['input'];
  name: Scalars['String']['input'];
  times: Scalars['String']['input'];
};

export type Content = {
  __typename: 'Content';
  createdAt: Scalars['DateTime']['output'];
  deepCloned: Scalars['Boolean']['output'];
  derivedFrom: Maybe<Scalars['Int']['output']>;
  id: Scalars['ID']['output'];
  isActive: Scalars['Boolean']['output'];
  name: Scalars['String']['output'];
  page: Page;
  scheduledEnd: Maybe<Scalars['DateTime']['output']>;
  scheduledStart: Maybe<Scalars['DateTime']['output']>;
  sectionGroups: Maybe<Array<PageSectionGroup>>;
  updatedAt: Scalars['DateTime']['output'];
  version: Scalars['Int']['output'];
};

export type ContentAddInput = {
  deepCloned: Scalars['Boolean']['input'];
  derivedFrom?: InputMaybe<Scalars['Int']['input']>;
  name: Scalars['String']['input'];
  page: Scalars['ID']['input'];
  scheduledEnd?: InputMaybe<Scalars['DateTime']['input']>;
  scheduledStart?: InputMaybe<Scalars['DateTime']['input']>;
  sectionGroups?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  version: Scalars['Int']['input'];
};

export type ContentFilterInput = {
  createdAt?: InputMaybe<DateTimeEqualsInRangeFilterInput>;
  id?: InputMaybe<IDEqualsInFilterInput>;
  isActive?: InputMaybe<BooleanEqualsInRangeFilterInput>;
  page?: InputMaybe<IDEqualsInRangeFilterInput>;
  scheduledEnd?: InputMaybe<DateTimeEqualsInRangeFilterInput>;
  scheduledStart?: InputMaybe<DateTimeEqualsInRangeFilterInput>;
  search?: InputMaybe<Scalars['String']['input']>;
  updatedAt?: InputMaybe<DateTimeEqualsInRangeFilterInput>;
};

export type ContentList = {
  __typename: 'ContentList';
  limit: Scalars['Int']['output'];
  list: Array<Content>;
  skip: Scalars['Int']['output'];
  total: Scalars['Int']['output'];
};

export type ContentOrderInput = {
  createdAt?: InputMaybe<OrderDirectionEnum>;
  id?: InputMaybe<OrderDirectionEnum>;
  scheduledEnd?: InputMaybe<OrderDirectionEnum>;
  scheduledStart?: InputMaybe<OrderDirectionEnum>;
  updatedAt?: InputMaybe<OrderDirectionEnum>;
};

export type ContentUpdateInput = {
  deepCloned?: InputMaybe<Scalars['Boolean']['input']>;
  derivedFrom?: InputMaybe<Scalars['Int']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  page?: InputMaybe<Scalars['ID']['input']>;
  scheduledEnd?: InputMaybe<Scalars['DateTime']['input']>;
  scheduledStart?: InputMaybe<Scalars['DateTime']['input']>;
  sectionGroups?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  version?: InputMaybe<Scalars['Int']['input']>;
};

export type ContentUpdates = {
  __typename: 'ContentUpdates';
  id: Scalars['ID']['output'];
  item: Maybe<Content>;
  type: SimpleUpdateTypeEnum;
};

export type Country = {
  __typename: 'Country';
  /** An array of regions within a particular country. */
  availableRegions: Maybe<Array<Maybe<Region>>>;
  /** The name of the country in English. */
  fullNameEnglish: Maybe<Scalars['String']['output']>;
  /** The name of the country in the current locale. */
  fullNameLocale: Maybe<Scalars['String']['output']>;
  /** The unique ID for a `Country` object. */
  id: Maybe<Scalars['String']['output']>;
  /** The three-letter abbreviation of the country, such as USA. */
  threeLetterAbbreviation: Maybe<Scalars['String']['output']>;
  /** The two-letter abbreviation of the country, such as US. */
  twoLetterAbbreviation: Maybe<Scalars['String']['output']>;
};

/** The list of country codes. */
export enum CountryCodeEnum {
  /**
   * Andorra
   * @deprecated
   */
  AD = 'AD',
  /**
   * United Arab Emirates
   * @deprecated
   */
  AE = 'AE',
  /**
   * Afghanistan
   * @deprecated
   */
  AF = 'AF',
  /**
   * Antigua & Barbuda
   * @deprecated
   */
  AG = 'AG',
  /**
   * Anguilla
   * @deprecated
   */
  AI = 'AI',
  /**
   * Albania
   * @deprecated
   */
  AL = 'AL',
  /**
   * Armenia
   * @deprecated
   */
  AM = 'AM',
  /**
   * Netherlands Antilles
   * @deprecated
   */
  AN = 'AN',
  /**
   * Angola
   * @deprecated
   */
  AO = 'AO',
  /**
   * Antarctica
   * @deprecated
   */
  AQ = 'AQ',
  /**
   * Argentina
   * @deprecated
   */
  AR = 'AR',
  /**
   * American Samoa
   * @deprecated
   */
  AS = 'AS',
  /**
   * Austria
   * @deprecated
   */
  AT = 'AT',
  /**
   * Australia
   * @deprecated
   */
  AU = 'AU',
  /**
   * Aruba
   * @deprecated
   */
  AW = 'AW',
  /**
   * Åland Islands
   * @deprecated
   */
  AX = 'AX',
  /**
   * Azerbaijan
   * @deprecated
   */
  AZ = 'AZ',
  /**
   * Bosnia & Herzegovina
   * @deprecated
   */
  BA = 'BA',
  /**
   * Barbados
   * @deprecated
   */
  BB = 'BB',
  /**
   * Bangladesh
   * @deprecated
   */
  BD = 'BD',
  /**
   * Belgium
   * @deprecated
   */
  BE = 'BE',
  /**
   * Burkina Faso
   * @deprecated
   */
  BF = 'BF',
  /**
   * Bulgaria
   * @deprecated
   */
  BG = 'BG',
  /**
   * Bahrain
   * @deprecated
   */
  BH = 'BH',
  /**
   * Burundi
   * @deprecated
   */
  BI = 'BI',
  /**
   * Benin
   * @deprecated
   */
  BJ = 'BJ',
  /**
   * St. Barthélemy
   * @deprecated
   */
  BL = 'BL',
  /**
   * Bermuda
   * @deprecated
   */
  BM = 'BM',
  /**
   * Brunei
   * @deprecated
   */
  BN = 'BN',
  /**
   * Bolivia
   * @deprecated
   */
  BO = 'BO',
  /**
   * Brazil
   * @deprecated
   */
  BR = 'BR',
  /**
   * Bahamas
   * @deprecated
   */
  BS = 'BS',
  /**
   * Bhutan
   * @deprecated
   */
  BT = 'BT',
  /**
   * Bouvet Island
   * @deprecated
   */
  BV = 'BV',
  /**
   * Botswana
   * @deprecated
   */
  BW = 'BW',
  /**
   * Belarus
   * @deprecated
   */
  BY = 'BY',
  /**
   * Belize
   * @deprecated
   */
  BZ = 'BZ',
  /**
   * Canada
   * @deprecated
   */
  CA = 'CA',
  /**
   * Cocos (Keeling) Islands
   * @deprecated
   */
  CC = 'CC',
  /**
   * Congo-Kinshasa
   * @deprecated
   */
  CD = 'CD',
  /**
   * Central African Republic
   * @deprecated
   */
  CF = 'CF',
  /**
   * Congo-Brazzaville
   * @deprecated
   */
  CG = 'CG',
  /**
   * Switzerland
   * @deprecated
   */
  CH = 'CH',
  /**
   * Côte d’Ivoire
   * @deprecated
   */
  CI = 'CI',
  /**
   * Cook Islands
   * @deprecated
   */
  CK = 'CK',
  /**
   * Chile
   * @deprecated
   */
  CL = 'CL',
  /**
   * Cameroon
   * @deprecated
   */
  CM = 'CM',
  /**
   * China
   * @deprecated
   */
  CN = 'CN',
  /**
   * Colombia
   * @deprecated
   */
  CO = 'CO',
  /**
   * Costa Rica
   * @deprecated
   */
  CR = 'CR',
  /**
   * Cuba
   * @deprecated
   */
  CU = 'CU',
  /**
   * Cape Verde
   * @deprecated
   */
  CV = 'CV',
  /**
   * Christmas Island
   * @deprecated
   */
  CX = 'CX',
  /**
   * Cyprus
   * @deprecated
   */
  CY = 'CY',
  /**
   * Czech Republic
   * @deprecated
   */
  CZ = 'CZ',
  /**
   * Germany
   * @deprecated
   */
  DE = 'DE',
  /**
   * Djibouti
   * @deprecated
   */
  DJ = 'DJ',
  /**
   * Denmark
   * @deprecated
   */
  DK = 'DK',
  /**
   * Dominica
   * @deprecated
   */
  DM = 'DM',
  /**
   * Dominican Republic
   * @deprecated
   */
  DO = 'DO',
  /**
   * Algeria
   * @deprecated
   */
  DZ = 'DZ',
  /**
   * Ecuador
   * @deprecated
   */
  EC = 'EC',
  /**
   * Estonia
   * @deprecated
   */
  EE = 'EE',
  /**
   * Egypt
   * @deprecated
   */
  EG = 'EG',
  /**
   * Western Sahara
   * @deprecated
   */
  EH = 'EH',
  /**
   * Eritrea
   * @deprecated
   */
  ER = 'ER',
  /**
   * Spain
   * @deprecated
   */
  ES = 'ES',
  /**
   * Ethiopia
   * @deprecated
   */
  ET = 'ET',
  /**
   * Finland
   * @deprecated
   */
  FI = 'FI',
  /**
   * Fiji
   * @deprecated
   */
  FJ = 'FJ',
  /**
   * Falkland Islands
   * @deprecated
   */
  FK = 'FK',
  /**
   * Micronesia
   * @deprecated
   */
  FM = 'FM',
  /**
   * Faroe Islands
   * @deprecated
   */
  FO = 'FO',
  /**
   * France
   * @deprecated
   */
  FR = 'FR',
  /**
   * Gabon
   * @deprecated
   */
  GA = 'GA',
  /**
   * United Kingdom
   * @deprecated
   */
  GB = 'GB',
  /**
   * Grenada
   * @deprecated
   */
  GD = 'GD',
  /**
   * Georgia
   * @deprecated
   */
  GE = 'GE',
  /**
   * French Guiana
   * @deprecated
   */
  GF = 'GF',
  /**
   * Guernsey
   * @deprecated
   */
  GG = 'GG',
  /**
   * Ghana
   * @deprecated
   */
  GH = 'GH',
  /**
   * Gibraltar
   * @deprecated
   */
  GI = 'GI',
  /**
   * Greenland
   * @deprecated
   */
  GL = 'GL',
  /**
   * Gambia
   * @deprecated
   */
  GM = 'GM',
  /**
   * Guinea
   * @deprecated
   */
  GN = 'GN',
  /**
   * Guadeloupe
   * @deprecated
   */
  GP = 'GP',
  /**
   * Equatorial Guinea
   * @deprecated
   */
  GQ = 'GQ',
  /**
   * Greece
   * @deprecated
   */
  GR = 'GR',
  /**
   * South Georgia & South Sandwich Islands
   * @deprecated
   */
  GS = 'GS',
  /**
   * Guatemala
   * @deprecated
   */
  GT = 'GT',
  /**
   * Guam
   * @deprecated
   */
  GU = 'GU',
  /**
   * Guinea-Bissau
   * @deprecated
   */
  GW = 'GW',
  /**
   * Guyana
   * @deprecated
   */
  GY = 'GY',
  /**
   * Hong Kong SAR China
   * @deprecated
   */
  HK = 'HK',
  /**
   * Heard &amp; McDonald Islands
   * @deprecated
   */
  HM = 'HM',
  /**
   * Honduras
   * @deprecated
   */
  HN = 'HN',
  /**
   * Croatia
   * @deprecated
   */
  HR = 'HR',
  /**
   * Haiti
   * @deprecated
   */
  HT = 'HT',
  /**
   * Hungary
   * @deprecated
   */
  HU = 'HU',
  /**
   * Indonesia
   * @deprecated
   */
  ID = 'ID',
  /**
   * Ireland
   * @deprecated
   */
  IE = 'IE',
  /**
   * Israel
   * @deprecated
   */
  IL = 'IL',
  /**
   * Isle of Man
   * @deprecated
   */
  IM = 'IM',
  /**
   * India
   * @deprecated
   */
  IN = 'IN',
  /**
   * British Indian Ocean Territory
   * @deprecated
   */
  IO = 'IO',
  /**
   * Iraq
   * @deprecated
   */
  IQ = 'IQ',
  /**
   * Iran
   * @deprecated
   */
  IR = 'IR',
  /**
   * Iceland
   * @deprecated
   */
  IS = 'IS',
  /**
   * Italy
   * @deprecated
   */
  IT = 'IT',
  /**
   * Jersey
   * @deprecated
   */
  JE = 'JE',
  /**
   * Jamaica
   * @deprecated
   */
  JM = 'JM',
  /**
   * Jordan
   * @deprecated
   */
  JO = 'JO',
  /**
   * Japan
   * @deprecated
   */
  JP = 'JP',
  /**
   * Kenya
   * @deprecated
   */
  KE = 'KE',
  /**
   * Kyrgyzstan
   * @deprecated
   */
  KG = 'KG',
  /**
   * Cambodia
   * @deprecated
   */
  KH = 'KH',
  /**
   * Kiribati
   * @deprecated
   */
  KI = 'KI',
  /**
   * Comoros
   * @deprecated
   */
  KM = 'KM',
  /**
   * St. Kitts & Nevis
   * @deprecated
   */
  KN = 'KN',
  /**
   * North Korea
   * @deprecated
   */
  KP = 'KP',
  /**
   * South Korea
   * @deprecated
   */
  KR = 'KR',
  /**
   * Kuwait
   * @deprecated
   */
  KW = 'KW',
  /**
   * Cayman Islands
   * @deprecated
   */
  KY = 'KY',
  /**
   * Kazakhstan
   * @deprecated
   */
  KZ = 'KZ',
  /**
   * Laos
   * @deprecated
   */
  LA = 'LA',
  /**
   * Lebanon
   * @deprecated
   */
  LB = 'LB',
  /**
   * St. Lucia
   * @deprecated
   */
  LC = 'LC',
  /**
   * Liechtenstein
   * @deprecated
   */
  LI = 'LI',
  /**
   * Sri Lanka
   * @deprecated
   */
  LK = 'LK',
  /**
   * Liberia
   * @deprecated
   */
  LR = 'LR',
  /**
   * Lesotho
   * @deprecated
   */
  LS = 'LS',
  /**
   * Lithuania
   * @deprecated
   */
  LT = 'LT',
  /**
   * Luxembourg
   * @deprecated
   */
  LU = 'LU',
  /**
   * Latvia
   * @deprecated
   */
  LV = 'LV',
  /**
   * Libya
   * @deprecated
   */
  LY = 'LY',
  /**
   * Morocco
   * @deprecated
   */
  MA = 'MA',
  /**
   * Monaco
   * @deprecated
   */
  MC = 'MC',
  /**
   * Moldova
   * @deprecated
   */
  MD = 'MD',
  /**
   * Montenegro
   * @deprecated
   */
  ME = 'ME',
  /**
   * St. Martin
   * @deprecated
   */
  MF = 'MF',
  /**
   * Madagascar
   * @deprecated
   */
  MG = 'MG',
  /**
   * Marshall Islands
   * @deprecated
   */
  MH = 'MH',
  /**
   * Macedonia
   * @deprecated
   */
  MK = 'MK',
  /**
   * Mali
   * @deprecated
   */
  ML = 'ML',
  /**
   * Myanmar (Burma)
   * @deprecated
   */
  MM = 'MM',
  /**
   * Mongolia
   * @deprecated
   */
  MN = 'MN',
  /**
   * Macau SAR China
   * @deprecated
   */
  MO = 'MO',
  /**
   * Northern Mariana Islands
   * @deprecated
   */
  MP = 'MP',
  /**
   * Martinique
   * @deprecated
   */
  MQ = 'MQ',
  /**
   * Mauritania
   * @deprecated
   */
  MR = 'MR',
  /**
   * Montserrat
   * @deprecated
   */
  MS = 'MS',
  /**
   * Malta
   * @deprecated
   */
  MT = 'MT',
  /**
   * Mauritius
   * @deprecated
   */
  MU = 'MU',
  /**
   * Maldives
   * @deprecated
   */
  MV = 'MV',
  /**
   * Malawi
   * @deprecated
   */
  MW = 'MW',
  /**
   * Mexico
   * @deprecated
   */
  MX = 'MX',
  /**
   * Malaysia
   * @deprecated
   */
  MY = 'MY',
  /**
   * Mozambique
   * @deprecated
   */
  MZ = 'MZ',
  /**
   * Namibia
   * @deprecated
   */
  NA = 'NA',
  /**
   * New Caledonia
   * @deprecated
   */
  NC = 'NC',
  /**
   * Niger
   * @deprecated
   */
  NE = 'NE',
  /**
   * Norfolk Island
   * @deprecated
   */
  NF = 'NF',
  /**
   * Nigeria
   * @deprecated
   */
  NG = 'NG',
  /**
   * Nicaragua
   * @deprecated
   */
  NI = 'NI',
  /**
   * Netherlands
   * @deprecated
   */
  NL = 'NL',
  /**
   * Norway
   * @deprecated
   */
  NO = 'NO',
  /**
   * Nepal
   * @deprecated
   */
  NP = 'NP',
  /**
   * Nauru
   * @deprecated
   */
  NR = 'NR',
  /**
   * Niue
   * @deprecated
   */
  NU = 'NU',
  /**
   * New Zealand
   * @deprecated
   */
  NZ = 'NZ',
  /**
   * Oman
   * @deprecated
   */
  OM = 'OM',
  /**
   * Panama
   * @deprecated
   */
  PA = 'PA',
  /**
   * Peru
   * @deprecated
   */
  PE = 'PE',
  /**
   * French Polynesia
   * @deprecated
   */
  PF = 'PF',
  /**
   * Papua New Guinea
   * @deprecated
   */
  PG = 'PG',
  /**
   * Philippines
   * @deprecated
   */
  PH = 'PH',
  /**
   * Pakistan
   * @deprecated
   */
  PK = 'PK',
  /**
   * Poland
   * @deprecated
   */
  PL = 'PL',
  /**
   * St. Pierre & Miquelon
   * @deprecated
   */
  PM = 'PM',
  /**
   * Pitcairn Islands
   * @deprecated
   */
  PN = 'PN',
  /**
   * Palestinian Territories
   * @deprecated
   */
  PS = 'PS',
  /**
   * Portugal
   * @deprecated
   */
  PT = 'PT',
  /**
   * Palau
   * @deprecated
   */
  PW = 'PW',
  /**
   * Paraguay
   * @deprecated
   */
  PY = 'PY',
  /**
   * Qatar
   * @deprecated
   */
  QA = 'QA',
  /**
   * Réunion
   * @deprecated
   */
  RE = 'RE',
  /**
   * Romania
   * @deprecated
   */
  RO = 'RO',
  /**
   * Serbia
   * @deprecated
   */
  RS = 'RS',
  /**
   * Russia
   * @deprecated
   */
  RU = 'RU',
  /**
   * Rwanda
   * @deprecated
   */
  RW = 'RW',
  /**
   * Saudi Arabia
   * @deprecated
   */
  SA = 'SA',
  /**
   * Solomon Islands
   * @deprecated
   */
  SB = 'SB',
  /**
   * Seychelles
   * @deprecated
   */
  SC = 'SC',
  /**
   * Sudan
   * @deprecated
   */
  SD = 'SD',
  /**
   * Sweden
   * @deprecated
   */
  SE = 'SE',
  /**
   * Singapore
   * @deprecated
   */
  SG = 'SG',
  /**
   * St. Helena
   * @deprecated
   */
  SH = 'SH',
  /**
   * Slovenia
   * @deprecated
   */
  SI = 'SI',
  /**
   * Svalbard & Jan Mayen
   * @deprecated
   */
  SJ = 'SJ',
  /**
   * Slovakia
   * @deprecated
   */
  SK = 'SK',
  /**
   * Sierra Leone
   * @deprecated
   */
  SL = 'SL',
  /**
   * San Marino
   * @deprecated
   */
  SM = 'SM',
  /**
   * Senegal
   * @deprecated
   */
  SN = 'SN',
  /**
   * Somalia
   * @deprecated
   */
  SO = 'SO',
  /**
   * Suriname
   * @deprecated
   */
  SR = 'SR',
  /**
   * São Tomé & Príncipe
   * @deprecated
   */
  ST = 'ST',
  /**
   * El Salvador
   * @deprecated
   */
  SV = 'SV',
  /**
   * Syria
   * @deprecated
   */
  SY = 'SY',
  /**
   * Swaziland
   * @deprecated
   */
  SZ = 'SZ',
  /**
   * Turks & Caicos Islands
   * @deprecated
   */
  TC = 'TC',
  /**
   * Chad
   * @deprecated
   */
  TD = 'TD',
  /**
   * French Southern Territories
   * @deprecated
   */
  TF = 'TF',
  /**
   * Togo
   * @deprecated
   */
  TG = 'TG',
  /**
   * Thailand
   * @deprecated
   */
  TH = 'TH',
  /**
   * Tajikistan
   * @deprecated
   */
  TJ = 'TJ',
  /**
   * Tokelau
   * @deprecated
   */
  TK = 'TK',
  /**
   * Timor-Leste
   * @deprecated
   */
  TL = 'TL',
  /**
   * Turkmenistan
   * @deprecated
   */
  TM = 'TM',
  /**
   * Tunisia
   * @deprecated
   */
  TN = 'TN',
  /**
   * Tonga
   * @deprecated
   */
  TO = 'TO',
  /**
   * Turkey
   * @deprecated
   */
  TR = 'TR',
  /**
   * Trinidad & Tobago
   * @deprecated
   */
  TT = 'TT',
  /**
   * Tuvalu
   * @deprecated
   */
  TV = 'TV',
  /**
   * Taiwan
   * @deprecated
   */
  TW = 'TW',
  /**
   * Tanzania
   * @deprecated
   */
  TZ = 'TZ',
  /**
   * Ukraine
   * @deprecated
   */
  UA = 'UA',
  /**
   * Uganda
   * @deprecated
   */
  UG = 'UG',
  /**
   * U.S. Outlying Islands
   * @deprecated
   */
  UM = 'UM',
  /**
   * United States
   * @deprecated
   */
  US = 'US',
  /**
   * Uruguay
   * @deprecated
   */
  UY = 'UY',
  /**
   * Uzbekistan
   * @deprecated
   */
  UZ = 'UZ',
  /**
   * Vatican City
   * @deprecated
   */
  VA = 'VA',
  /**
   * St. Vincent & Grenadines
   * @deprecated
   */
  VC = 'VC',
  /**
   * Venezuela
   * @deprecated
   */
  VE = 'VE',
  /**
   * British Virgin Islands
   * @deprecated
   */
  VG = 'VG',
  /**
   * U.S. Virgin Islands
   * @deprecated
   */
  VI = 'VI',
  /**
   * Vietnam
   * @deprecated
   */
  VN = 'VN',
  /**
   * Vanuatu
   * @deprecated
   */
  VU = 'VU',
  /**
   * Wallis & Futuna
   * @deprecated
   */
  WF = 'WF',
  /**
   * Samoa
   * @deprecated
   */
  WS = 'WS',
  /**
   * Yemen
   * @deprecated
   */
  YE = 'YE',
  /**
   * Mayotte
   * @deprecated
   */
  YT = 'YT',
  /**
   * South Africa
   * @deprecated
   */
  ZA = 'ZA',
  /**
   * Zambia
   * @deprecated
   */
  ZM = 'ZM',
  /**
   * Zimbabwe
   * @deprecated
   */
  ZW = 'ZW'
}

/** Contains an array of product IDs to use for creating a compare list. */
export type CreateCompareListInput = {
  /** An array of product IDs to add to the compare list. */
  products?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
};

/** Assigns a specific `cart_id` to the empty cart. */
export type CreateEmptyCartInput = {
  /** The ID to assign to the cart. */
  cartId?: InputMaybe<Scalars['String']['input']>;
};

/** Contains the secure information used to authorize transaction. Applies to Payflow Pro and Payments Pro payment methods. */
export type CreatePayflowProTokenOutput = {
  __typename: 'CreatePayflowProTokenOutput';
  /** The RESPMSG returned by PayPal. If the `result` is `0`, then `response_message` is `Approved`. */
  responseMessage: Scalars['String']['output'];
  /** A non-zero value if any errors occurred. */
  result: Scalars['Int']['output'];
  /** The RESULT returned by PayPal. A value of `0` indicates the transaction was approved. */
  resultCode: Scalars['Int']['output'];
  /** A secure token generated by PayPal. */
  secureToken: Scalars['String']['output'];
  /** A secure token ID generated by PayPal. */
  secureTokenId: Scalars['String']['output'];
};

/** Contains payment order details that are used while processing the payment order */
export type CreatePaymentOrderInput = {
  /** The customer cart ID */
  cartId: Scalars['String']['input'];
  /** Defines the origin location for that payment request */
  location: PaymentLocation;
  /** The code for the payment method used in the order */
  methodCode: Scalars['String']['input'];
  /** The identifiable payment source for the payment method */
  paymentSource: Scalars['String']['input'];
  /** Indicates whether the payment information should be vaulted */
  vaultIntent?: InputMaybe<Scalars['Boolean']['input']>;
};

/** Contains payment order details that are used while processing the payment order */
export type CreatePaymentOrderOutput = {
  __typename: 'CreatePaymentOrderOutput';
  /** The amount of the payment order */
  amount: Maybe<Scalars['Float']['output']>;
  /** The currency of the payment order */
  currencyCode: Maybe<Scalars['String']['output']>;
  /** PayPal order ID */
  id: Maybe<Scalars['String']['output']>;
  /** The order ID generated by Payment Services */
  mpOrderId: Maybe<Scalars['String']['output']>;
  /** The status of the payment order */
  status: Maybe<Scalars['String']['output']>;
};

/** Defines a new product review. */
export type CreateProductReviewInput = {
  /** The customer's nickname. Defaults to the customer name, if logged in. */
  nickname: Scalars['String']['input'];
  /** The ratings details by category. For example, Price: 5 stars, Quality: 4 stars, etc. */
  ratings: Array<InputMaybe<ProductReviewRatingInput>>;
  /** The SKU of the reviewed product. */
  sku: Scalars['String']['input'];
  /** The summary (title) of the review. */
  summary: Scalars['String']['input'];
  /** The review text. */
  text: Scalars['String']['input'];
};

/** Contains the completed product review. */
export type CreateProductReviewOutput = {
  __typename: 'CreateProductReviewOutput';
  /** Product review details. */
  review: ProductReview;
};

/** Required fields for Payflow Pro and Payments Pro credit card payments. */
export type CreditCardDetailsInput = {
  /** The credit card expiration month. */
  ccExpMonth: Scalars['Int']['input'];
  /** The credit card expiration year. */
  ccExpYear: Scalars['Int']['input'];
  /** The last 4 digits of the credit card. */
  ccLast_4: Scalars['Int']['input'];
  /** The credit card type. */
  ccType: Scalars['String']['input'];
};

export type CreditCardInfo = {
  __typename: 'CreditCardInfo';
  brand: Maybe<Scalars['String']['output']>;
  cvv: Maybe<Scalars['String']['output']>;
  expiryMonth: Maybe<Scalars['String']['output']>;
  expiryYear: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  name: Maybe<Scalars['String']['output']>;
  nickname: Maybe<Scalars['String']['output']>;
  number: Maybe<Scalars['String']['output']>;
  saveCard: Maybe<Scalars['Boolean']['output']>;
};

/** Contains credit memo details. */
export type CreditMemo = {
  __typename: 'CreditMemo';
  /** Comments on the credit memo. */
  comments: Maybe<Array<Maybe<SalesCommentItem>>>;
  /** The unique ID for a `CreditMemo` object. */
  id: Scalars['ID']['output'];
  /** An array containing details about refunded items. */
  items: Maybe<Array<Maybe<CreditMemoItemInterface>>>;
  /** The sequential credit memo number. */
  number: Scalars['String']['output'];
  /** Details about the total refunded amount. */
  total: Maybe<CreditMemoTotal>;
};

export type CreditMemoItem = CreditMemoItemInterface & {
  __typename: 'CreditMemoItem';
  /** Details about the final discount amount for the base product, including discounts on options. */
  discounts: Maybe<Array<Maybe<Discount>>>;
  /** The unique ID for a `CreditMemoItemInterface` object. */
  id: Scalars['ID']['output'];
  /** The order item the credit memo is applied to. */
  orderItem: Maybe<OrderItemInterface>;
  /** The name of the base product. */
  productName: Maybe<Scalars['String']['output']>;
  /** The sale price for the base product, including selected options. */
  productSalePrice: Money;
  /** The SKU of the base product. */
  productSku: Scalars['String']['output'];
  /** The number of refunded items. */
  quantityRefunded: Maybe<Scalars['Float']['output']>;
};

/** Credit memo item details. */
export type CreditMemoItemInterface = {
  /** Details about the final discount amount for the base product, including discounts on options. */
  discounts: Maybe<Array<Maybe<Discount>>>;
  /** The unique ID for a `CreditMemoItemInterface` object. */
  id: Scalars['ID']['output'];
  /** The order item the credit memo is applied to. */
  orderItem: Maybe<OrderItemInterface>;
  /** The name of the base product. */
  productName: Maybe<Scalars['String']['output']>;
  /** The sale price for the base product, including selected options. */
  productSalePrice: Money;
  /** The SKU of the base product. */
  productSku: Scalars['String']['output'];
  /** The number of refunded items. */
  quantityRefunded: Maybe<Scalars['Float']['output']>;
};

/** Contains credit memo price details. */
export type CreditMemoTotal = {
  __typename: 'CreditMemoTotal';
  /** An adjustment manually applied to the order. */
  adjustment: Money;
  /** The final base grand total amount in the base currency. */
  baseGrandTotal: Money;
  /** The applied discounts to the credit memo. */
  discounts: Maybe<Array<Maybe<Discount>>>;
  /** The final total amount, including shipping, discounts, and taxes. */
  grandTotal: Money;
  /** Details about the shipping and handling costs for the credit memo. */
  shippingHandling: Maybe<ShippingHandling>;
  /** The subtotal of the invoice, excluding shipping, discounts, and taxes. */
  subtotal: Money;
  /** The credit memo tax details. */
  taxes: Maybe<Array<Maybe<TaxItem>>>;
  /** The shipping amount for the credit memo. */
  totalShipping: Money;
  /** The amount of tax applied to the credit memo. */
  totalTax: Money;
};

export type Currency = {
  __typename: 'Currency';
  /** An array of three-letter currency codes accepted by the store, such as USD and EUR. */
  availableCurrencyCodes: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  /** The base currency set for the store, such as USD. */
  baseCurrencyCode: Maybe<Scalars['String']['output']>;
  /** The symbol for the specified base currency, such as $. */
  baseCurrencySymbol: Maybe<Scalars['String']['output']>;
  /** @deprecated Symbol was missed. Use `default_display_currency_code`. */
  defaultDisplayCurrecyCode: Maybe<Scalars['String']['output']>;
  /** @deprecated Symbol was missed. Use `default_display_currency_code`. */
  defaultDisplayCurrecySymbol: Maybe<Scalars['String']['output']>;
  /** The currency that is displayed by default, such as USD. */
  defaultDisplayCurrencyCode: Maybe<Scalars['String']['output']>;
  /** The currency symbol that is displayed by default, such as $. */
  defaultDisplayCurrencySymbol: Maybe<Scalars['String']['output']>;
  /** An array of exchange rates for currencies defined in the store. */
  exchangeRates: Maybe<Array<Maybe<ExchangeRate>>>;
};

/** The list of available currency codes. */
export enum CurrencyEnum {
  /** @deprecated  */
  AED = 'AED',
  /** @deprecated  */
  AFN = 'AFN',
  /** @deprecated  */
  ALL = 'ALL',
  /** @deprecated  */
  AMD = 'AMD',
  /** @deprecated  */
  ANG = 'ANG',
  /** @deprecated  */
  AOA = 'AOA',
  /** @deprecated  */
  ARS = 'ARS',
  /** @deprecated  */
  AUD = 'AUD',
  /** @deprecated  */
  AWG = 'AWG',
  /** @deprecated  */
  AZM = 'AZM',
  /** @deprecated  */
  AZN = 'AZN',
  /** @deprecated  */
  BAM = 'BAM',
  /** @deprecated  */
  BBD = 'BBD',
  /** @deprecated  */
  BDT = 'BDT',
  /** @deprecated  */
  BGN = 'BGN',
  /** @deprecated  */
  BHD = 'BHD',
  /** @deprecated  */
  BIF = 'BIF',
  /** @deprecated  */
  BMD = 'BMD',
  /** @deprecated  */
  BND = 'BND',
  /** @deprecated  */
  BOB = 'BOB',
  /** @deprecated  */
  BRL = 'BRL',
  /** @deprecated  */
  BSD = 'BSD',
  /** @deprecated  */
  BTN = 'BTN',
  /** @deprecated  */
  BUK = 'BUK',
  /** @deprecated  */
  BWP = 'BWP',
  /** @deprecated  */
  BYN = 'BYN',
  /** @deprecated  */
  BZD = 'BZD',
  /** @deprecated  */
  CAD = 'CAD',
  /** @deprecated  */
  CDF = 'CDF',
  /** @deprecated  */
  CHE = 'CHE',
  /** @deprecated  */
  CHF = 'CHF',
  /** @deprecated  */
  CHW = 'CHW',
  /** @deprecated  */
  CLP = 'CLP',
  /** @deprecated  */
  CNY = 'CNY',
  /** @deprecated  */
  COP = 'COP',
  /** @deprecated  */
  CRC = 'CRC',
  /** @deprecated  */
  CUP = 'CUP',
  /** @deprecated  */
  CVE = 'CVE',
  /** @deprecated  */
  CZK = 'CZK',
  /** @deprecated  */
  DJF = 'DJF',
  /** @deprecated  */
  DKK = 'DKK',
  /** @deprecated  */
  DOP = 'DOP',
  /** @deprecated  */
  DZD = 'DZD',
  /** @deprecated  */
  EEK = 'EEK',
  /** @deprecated  */
  EGP = 'EGP',
  /** @deprecated  */
  ERN = 'ERN',
  /** @deprecated  */
  ETB = 'ETB',
  /** @deprecated  */
  EUR = 'EUR',
  /** @deprecated  */
  FJD = 'FJD',
  /** @deprecated  */
  FKP = 'FKP',
  /** @deprecated  */
  GBP = 'GBP',
  /** @deprecated  */
  GEK = 'GEK',
  /** @deprecated  */
  GEL = 'GEL',
  /** @deprecated  */
  GHS = 'GHS',
  /** @deprecated  */
  GIP = 'GIP',
  /** @deprecated  */
  GMD = 'GMD',
  /** @deprecated  */
  GNF = 'GNF',
  /** @deprecated  */
  GQE = 'GQE',
  /** @deprecated  */
  GTQ = 'GTQ',
  /** @deprecated  */
  GYD = 'GYD',
  /** @deprecated  */
  HKD = 'HKD',
  /** @deprecated  */
  HNL = 'HNL',
  /** @deprecated  */
  HRK = 'HRK',
  /** @deprecated  */
  HTG = 'HTG',
  /** @deprecated  */
  HUF = 'HUF',
  /** @deprecated  */
  IDR = 'IDR',
  /** @deprecated  */
  ILS = 'ILS',
  /** @deprecated  */
  INR = 'INR',
  /** @deprecated  */
  IQD = 'IQD',
  /** @deprecated  */
  IRR = 'IRR',
  /** @deprecated  */
  ISK = 'ISK',
  /** @deprecated  */
  JMD = 'JMD',
  /** @deprecated  */
  JOD = 'JOD',
  /** @deprecated  */
  JPY = 'JPY',
  /** @deprecated  */
  KES = 'KES',
  /** @deprecated  */
  KGS = 'KGS',
  /** @deprecated  */
  KHR = 'KHR',
  /** @deprecated  */
  KMF = 'KMF',
  /** @deprecated  */
  KPW = 'KPW',
  /** @deprecated  */
  KRW = 'KRW',
  /** @deprecated  */
  KWD = 'KWD',
  /** @deprecated  */
  KYD = 'KYD',
  /** @deprecated  */
  KZT = 'KZT',
  /** @deprecated  */
  LAK = 'LAK',
  /** @deprecated  */
  LBP = 'LBP',
  /** @deprecated  */
  LKR = 'LKR',
  /** @deprecated  */
  LRD = 'LRD',
  /** @deprecated  */
  LSL = 'LSL',
  /** @deprecated  */
  LSM = 'LSM',
  /** @deprecated  */
  LTL = 'LTL',
  /** @deprecated  */
  LVL = 'LVL',
  /** @deprecated  */
  LYD = 'LYD',
  /** @deprecated  */
  MAD = 'MAD',
  /** @deprecated  */
  MDL = 'MDL',
  /** @deprecated  */
  MGA = 'MGA',
  /** @deprecated  */
  MKD = 'MKD',
  /** @deprecated  */
  MMK = 'MMK',
  /** @deprecated  */
  MNT = 'MNT',
  /** @deprecated  */
  MOP = 'MOP',
  /** @deprecated  */
  MRO = 'MRO',
  /** @deprecated  */
  MUR = 'MUR',
  /** @deprecated  */
  MVR = 'MVR',
  /** @deprecated  */
  MWK = 'MWK',
  /** @deprecated  */
  MXN = 'MXN',
  /** @deprecated  */
  MYR = 'MYR',
  /** @deprecated  */
  MZN = 'MZN',
  /** @deprecated  */
  NAD = 'NAD',
  /** @deprecated  */
  NGN = 'NGN',
  /** @deprecated  */
  NIC = 'NIC',
  /** @deprecated  */
  NOK = 'NOK',
  /** @deprecated  */
  NPR = 'NPR',
  /** @deprecated  */
  NZD = 'NZD',
  /** @deprecated  */
  OMR = 'OMR',
  /** @deprecated  */
  PAB = 'PAB',
  /** @deprecated  */
  PEN = 'PEN',
  /** @deprecated  */
  PGK = 'PGK',
  /** @deprecated  */
  PHP = 'PHP',
  /** @deprecated  */
  PKR = 'PKR',
  /** @deprecated  */
  PLN = 'PLN',
  /** @deprecated  */
  PYG = 'PYG',
  /** @deprecated  */
  QAR = 'QAR',
  /** @deprecated  */
  RHD = 'RHD',
  /** @deprecated  */
  ROL = 'ROL',
  /** @deprecated  */
  RON = 'RON',
  /** @deprecated  */
  RSD = 'RSD',
  /** @deprecated  */
  RUB = 'RUB',
  /** @deprecated  */
  RWF = 'RWF',
  /** @deprecated  */
  SAR = 'SAR',
  /** @deprecated  */
  SBD = 'SBD',
  /** @deprecated  */
  SCR = 'SCR',
  /** @deprecated  */
  SDG = 'SDG',
  /** @deprecated  */
  SEK = 'SEK',
  /** @deprecated  */
  SGD = 'SGD',
  /** @deprecated  */
  SHP = 'SHP',
  /** @deprecated  */
  SKK = 'SKK',
  /** @deprecated  */
  SLL = 'SLL',
  /** @deprecated  */
  SOS = 'SOS',
  /** @deprecated  */
  SRD = 'SRD',
  /** @deprecated  */
  STD = 'STD',
  /** @deprecated  */
  SVC = 'SVC',
  /** @deprecated  */
  SYP = 'SYP',
  /** @deprecated  */
  SZL = 'SZL',
  /** @deprecated  */
  THB = 'THB',
  /** @deprecated  */
  TJS = 'TJS',
  /** @deprecated  */
  TMM = 'TMM',
  /** @deprecated  */
  TND = 'TND',
  /** @deprecated  */
  TOP = 'TOP',
  /** @deprecated  */
  TRL = 'TRL',
  /** @deprecated  */
  TRY = 'TRY',
  /** @deprecated  */
  TTD = 'TTD',
  /** @deprecated  */
  TWD = 'TWD',
  /** @deprecated  */
  TZS = 'TZS',
  /** @deprecated  */
  UAH = 'UAH',
  /** @deprecated  */
  UGX = 'UGX',
  /** @deprecated  */
  USD = 'USD',
  /** @deprecated  */
  UYU = 'UYU',
  /** @deprecated  */
  UZS = 'UZS',
  /** @deprecated  */
  VEB = 'VEB',
  /** @deprecated  */
  VEF = 'VEF',
  /** @deprecated  */
  VND = 'VND',
  /** @deprecated  */
  VUV = 'VUV',
  /** @deprecated  */
  WST = 'WST',
  /** @deprecated  */
  XCD = 'XCD',
  /** @deprecated  */
  XOF = 'XOF',
  /** @deprecated  */
  XPF = 'XPF',
  /** @deprecated  */
  YER = 'YER',
  /** @deprecated  */
  YTL = 'YTL',
  /** @deprecated  */
  ZAR = 'ZAR',
  /** @deprecated  */
  ZMK = 'ZMK',
  /** @deprecated  */
  ZWD = 'ZWD'
}

export enum CurrentMoodEnum {
  /** @deprecated  */
  ANGERED = 'ANGERED',
  /** @deprecated  */
  CONFUSED = 'CONFUSED',
  /** @deprecated  */
  EXCITED = 'EXCITED',
  /** @deprecated  */
  HAPPY = 'HAPPY',
  /** @deprecated  */
  SAD = 'SAD',
  /** @deprecated  */
  WORRIED = 'WORRIED'
}

/** Defines an array of custom attributes. */
export type CustomAttributeMetadata = {
  __typename: 'CustomAttributeMetadata';
  /** An array of attributes. */
  items: Maybe<Array<Maybe<Attribute>>>;
};

/** An interface containing fields that define the EAV attribute. */
export type CustomAttributeMetadataInterface = {
  /** The unique identifier for an attribute code. This value should be in lowercase letters without spaces. */
  code: Scalars['String']['output'];
  /** Default attribute value. */
  defaultValue: Maybe<Scalars['String']['output']>;
  /** The type of entity that defines the attribute. */
  entityType: AttributeEntityTypeEnum;
  /** The frontend input type of the attribute. */
  frontendInput: Maybe<AttributeFrontendInputEnum>;
  /** Whether the attribute value is required. */
  isRequired: Scalars['Boolean']['output'];
  /** Whether the attribute value must be unique. */
  isUnique: Scalars['Boolean']['output'];
  /** The label assigned to the attribute. */
  label: Maybe<Scalars['String']['output']>;
  /** Attribute options. */
  options: Array<Maybe<CustomAttributeOptionInterface>>;
  /** The unique ID of an attribute. Based on entity type and attribute code */
  uid: Scalars['ID']['output'];
};

export type CustomAttributeOptionInterface = {
  /** Is the option value default. */
  isDefault: Maybe<Scalars['Boolean']['output']>;
  /** The label assigned to the attribute option. */
  label: Scalars['String']['output'];
  /** The unique ID of an attribute option. */
  uid: Scalars['ID']['output'];
  /** The attribute option value. */
  value: Scalars['String']['output'];
};

/** Defines the customer name, addresses, and other details. */
export type Customer = {
  __typename: 'Customer';
  /** An array containing the customer's shipping and billing addresses. */
  addresses: Maybe<Array<Maybe<CustomerAddress>>>;
  /** Indicates whether the customer has enabled remote shopping assistance. */
  allowRemoteShoppingAssistance: Scalars['Boolean']['output'];
  cacheId: Scalars['String']['output'];
  /** The contents of the customer's compare list. */
  compareList: Maybe<CompareList>;
  /** Timestamp indicating when the account was created. */
  createdAt: Maybe<Scalars['String']['output']>;
  /** Customer's custom attributes. */
  customAttributes: Maybe<Array<Maybe<AttributeValueInterface>>>;
  customerType: CustomerTypeEnum;
  /** The customer's date of birth. */
  dateOfBirth: Maybe<Scalars['String']['output']>;
  /** The ID assigned to the billing address. */
  defaultBilling: Maybe<Scalars['String']['output']>;
  /** The ID assigned to the shipping address. */
  defaultShipping: Maybe<Scalars['String']['output']>;
  /**
   * The customer's date of birth.
   * @deprecated Use `date_of_birth` instead.
   */
  dob: Maybe<Scalars['String']['output']>;
  /** The customer's email address. Required. */
  email: Maybe<Scalars['String']['output']>;
  /** The customer's prefered express paymemnt method */
  expressPaymentMethod: Maybe<Scalars['String']['output']>;
  /** The customer's prefered express shipping method */
  expressShippingMethod: Maybe<Scalars['String']['output']>;
  /** The customer's first name. */
  firstname: Maybe<Scalars['String']['output']>;
  /** The customer's gender (Male - 1, Female - 2). */
  gender: Maybe<Scalars['Int']['output']>;
  /** @deprecated Customer group should not be exposed in the storefront scenarios. */
  groupId: Maybe<Scalars['Int']['output']>;
  /**
   * The ID assigned to the customer.
   * @deprecated `id` is not needed as part of `Customer`, because on the server side, it can be identified based on the customer token used for authentication. There is no need to know customer ID on the client side.
   */
  id: Maybe<Scalars['Int']['output']>;
  /** Indicates if the customer account is active */
  isActive: Maybe<Scalars['Boolean']['output']>;
  /** Indicates whether the customer is subscribed to the company's newsletter. */
  isSubscribed: Maybe<Scalars['Boolean']['output']>;
  /** The customer's family name. */
  lastname: Maybe<Scalars['String']['output']>;
  /** The customer's middle name. */
  middlename: Maybe<Scalars['String']['output']>;
  /** The customer's mobile number */
  mobile: Maybe<Scalars['String']['output']>;
  /** Indicates if the customer mobile has been verified */
  mobileVerified: Maybe<Scalars['Boolean']['output']>;
  /** Indicates if the customer newsletter subscription has been confirmed */
  newsletterConfirmed: Maybe<Scalars['Boolean']['output']>;
  orders: Maybe<CustomerOrders>;
  /** An honorific, such as Dr., Mr., or Mrs. */
  prefix: Maybe<Scalars['String']['output']>;
  /** Contains the customer's product reviews. */
  reviews: ProductReviews;
  /** A value such as Sr., Jr., or III. */
  suffix: Maybe<Scalars['String']['output']>;
  /** The customer's Value-added tax (VAT) number (for corporate customers). */
  taxvat: Maybe<Scalars['String']['output']>;
  /**
   * Return a customer's wish lists.
   * @deprecated Use `Customer.wishlists` or `Customer.wishlist_v2` instead.
   */
  wishlist: Wishlist;
  wishlistId: Maybe<Scalars['Int']['output']>;
  /** Retrieve the wish list identified by the unique ID for a `Wishlist` object. */
  wishlistV2: Maybe<Wishlist>;
  /** An array of wishlists. In Magento Open Source, customers are limited to one wish list. The number of wish lists is configurable for Adobe Commerce. */
  wishlists: Array<Maybe<Wishlist>>;
};


/** Defines the customer name, addresses, and other details. */
export type CustomerordersArgs = {
  currentPage?: InputMaybe<Scalars['Int']['input']>;
  filter?: InputMaybe<CustomerOrdersFilterInput>;
  pageSize?: InputMaybe<Scalars['Int']['input']>;
  scope?: InputMaybe<ScopeTypeEnum>;
  sort?: InputMaybe<CustomerOrderSortInput>;
};


/** Defines the customer name, addresses, and other details. */
export type CustomerreviewsArgs = {
  currentPage?: InputMaybe<Scalars['Int']['input']>;
  pageSize?: InputMaybe<Scalars['Int']['input']>;
};


/** Defines the customer name, addresses, and other details. */
export type CustomerwishlistV2Args = {
  id: Scalars['ID']['input'];
};


/** Defines the customer name, addresses, and other details. */
export type CustomerwishlistsArgs = {
  currentPage?: InputMaybe<Scalars['Int']['input']>;
  pageSize?: InputMaybe<Scalars['Int']['input']>;
};

/** Contains detailed information about a customer's billing or shipping address. */
export type CustomerAddress = {
  __typename: 'CustomerAddress';
  /** The customer's prefered address group */
  addressGroup: Maybe<AddressGroupEnum>;
  /** The customer's city or town. */
  city: Maybe<Scalars['String']['output']>;
  /** The customer's company. */
  company: Maybe<Scalars['String']['output']>;
  /** The customer's country. */
  countryCode: Maybe<CountryCodeEnum>;
  /**
   * The customer's country.
   * @deprecated Use `country_code` instead.
   */
  countryId: Maybe<Scalars['String']['output']>;
  /** @deprecated Use custom_attributesV2 instead. */
  customAttributes: Maybe<Array<Maybe<CustomerAddressAttribute>>>;
  /** Customer address' custom attributes. */
  customAttributesV2: Array<Maybe<AttributeValueInterface>>;
  /**
   * The customer ID
   * @deprecated `customer_id` is not needed as part of `CustomerAddress`. The `id` is a unique identifier for the addresses.
   */
  customerId: Maybe<Scalars['Int']['output']>;
  /** Indicates whether the address is the customer's default billing address. */
  defaultBilling: Maybe<Scalars['Boolean']['output']>;
  /** Indicates whether the address is the customer's default shipping address. */
  defaultShipping: Maybe<Scalars['Boolean']['output']>;
  /** The customer's delivery note for the address */
  deliveryNote: Maybe<Scalars['String']['output']>;
  /** Contains any extension attributes for the address. */
  extensionAttributes: Maybe<Array<Maybe<CustomerAddressAttribute>>>;
  /** The customer's fax number. */
  fax: Maybe<Scalars['String']['output']>;
  /** The first name of the person associated with the shipping/billing address. */
  firstname: Maybe<Scalars['String']['output']>;
  /** The ID of a `CustomerAddress` object. */
  id: Maybe<Scalars['Int']['output']>;
  /** The family name of the person associated with the shipping/billing address. */
  lastname: Maybe<Scalars['String']['output']>;
  /** The middle name of the person associated with the shipping/billing address. */
  middlename: Maybe<Scalars['String']['output']>;
  /** The customer's ZIP or postal code. */
  postcode: Maybe<Scalars['String']['output']>;
  /** An honorific, such as Dr., Mr., or Mrs. */
  prefix: Maybe<Scalars['String']['output']>;
  /** An object containing the region name, region code, and region ID. */
  region: Maybe<CustomerAddressRegion>;
  /** The unique ID for a pre-defined region. */
  regionId: Maybe<Scalars['Int']['output']>;
  /** An array of strings that define the street number and name. */
  street: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  /** The customer's suburb */
  suburb: Maybe<Scalars['String']['output']>;
  /** A value such as Sr., Jr., or III. */
  suffix: Maybe<Scalars['String']['output']>;
  /** The customer's telephone number. */
  telephone: Maybe<Scalars['String']['output']>;
  /** The alternative telephone number */
  telephoneAlt: Maybe<Scalars['String']['output']>;
  /** The customer's Value-added tax (VAT) number (for corporate customers). */
  vatId: Maybe<Scalars['String']['output']>;
};

/** Specifies the attribute code and value of a customer address attribute. */
export type CustomerAddressAttribute = {
  __typename: 'CustomerAddressAttribute';
  /** The name assigned to the customer address attribute. */
  attributeCode: Maybe<Scalars['String']['output']>;
  /** The valuue assigned to the customer address attribute. */
  value: Maybe<Scalars['String']['output']>;
};

/** Specifies the attribute code and value of a customer attribute. */
export type CustomerAddressAttributeInput = {
  /** The name assigned to the attribute. */
  attributeCode: Scalars['String']['input'];
  /** The value assigned to the attribute. */
  value: Scalars['String']['input'];
};

/** Contains details about a billing or shipping address. */
export type CustomerAddressInput = {
  /** The customer's prefered address group */
  addressGroup?: InputMaybe<AddressGroupEnum>;
  /** The customer's city or town. */
  city?: InputMaybe<Scalars['String']['input']>;
  /** The customer's company. */
  company?: InputMaybe<Scalars['String']['input']>;
  /** The two-letter code representing the customer's country. */
  countryCode?: InputMaybe<CountryCodeEnum>;
  /** Deprecated: use `country_code` instead. */
  countryId?: InputMaybe<CountryCodeEnum>;
  /** Deprecated: Custom attributes should not be put into container. */
  customAttributes?: InputMaybe<Array<InputMaybe<AttributeValueInput>>>;
  /** Indicates whether the address is the default billing address. */
  defaultBilling?: InputMaybe<Scalars['Boolean']['input']>;
  /** Indicates whether the address is the default shipping address. */
  defaultShipping?: InputMaybe<Scalars['Boolean']['input']>;
  /** The customer's delivery note for the address */
  deliveryNote?: InputMaybe<Scalars['String']['input']>;
  /** The customer's fax number. */
  fax?: InputMaybe<Scalars['String']['input']>;
  /** The first name of the person associated with the billing/shipping address. */
  firstname?: InputMaybe<Scalars['String']['input']>;
  /** The family name of the person associated with the billing/shipping address. */
  lastname?: InputMaybe<Scalars['String']['input']>;
  /** The middle name of the person associated with the billing/shipping address. */
  middlename?: InputMaybe<Scalars['String']['input']>;
  /** The customer's ZIP or postal code. */
  postcode?: InputMaybe<Scalars['String']['input']>;
  /** An honorific, such as Dr., Mr., or Mrs. */
  prefix?: InputMaybe<Scalars['String']['input']>;
  /** An object containing the region name, region code, and region ID. */
  region?: InputMaybe<CustomerAddressRegionInput>;
  /** An array of strings that define the street number and name. */
  street?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  /** The customer's suburb */
  suburb?: InputMaybe<Scalars['String']['input']>;
  /** A value such as Sr., Jr., or III. */
  suffix?: InputMaybe<Scalars['String']['input']>;
  /** The customer's telephone number. */
  telephone?: InputMaybe<Scalars['String']['input']>;
  /** The alternative telephone number */
  telephoneAlt?: InputMaybe<Scalars['String']['input']>;
  /** The customer's Tax/VAT number (for corporate customers). */
  vatId?: InputMaybe<Scalars['String']['input']>;
};

/** Defines the customer's state or province. */
export type CustomerAddressRegion = {
  __typename: 'CustomerAddressRegion';
  /** The state or province name. */
  region: Maybe<Scalars['String']['output']>;
  /** The address region code. */
  regionCode: Maybe<Scalars['String']['output']>;
  /** The unique ID for a pre-defined region. */
  regionId: Maybe<Scalars['Int']['output']>;
};

/** Defines the customer's state or province. */
export type CustomerAddressRegionInput = {
  /** The state or province name. */
  region?: InputMaybe<Scalars['String']['input']>;
  /** The address region code. */
  regionCode?: InputMaybe<Scalars['String']['input']>;
  /** The unique ID for a pre-defined region. */
  regionId?: InputMaybe<Scalars['Int']['input']>;
};

/** Customer attribute metadata. */
export type CustomerAttributeMetadata = CustomAttributeMetadataInterface & {
  __typename: 'CustomerAttributeMetadata';
  /** The unique identifier for an attribute code. This value should be in lowercase letters without spaces. */
  code: Scalars['String']['output'];
  /** Default attribute value. */
  defaultValue: Maybe<Scalars['String']['output']>;
  /** The type of entity that defines the attribute. */
  entityType: AttributeEntityTypeEnum;
  /** The frontend input type of the attribute. */
  frontendInput: Maybe<AttributeFrontendInputEnum>;
  /** The template used for the input of the attribute (e.g., 'date'). */
  inputFilter: Maybe<InputFilterEnum>;
  /** Whether the attribute value is required. */
  isRequired: Scalars['Boolean']['output'];
  /** Whether the attribute value must be unique. */
  isUnique: Scalars['Boolean']['output'];
  /** The label assigned to the attribute. */
  label: Maybe<Scalars['String']['output']>;
  /** The number of lines of the attribute value. */
  multilineCount: Maybe<Scalars['Int']['output']>;
  /** Attribute options. */
  options: Array<Maybe<CustomAttributeOptionInterface>>;
  /** The position of the attribute in the form. */
  sortOrder: Maybe<Scalars['Int']['output']>;
  /** The unique ID of an attribute. Based on entity type and attribute code */
  uid: Scalars['ID']['output'];
  /** The validation rules of the attribute value. */
  validateRules: Maybe<Array<Maybe<ValidationRule>>>;
};

/** An input object for creating a customer. */
export type CustomerCreateInput = {
  /** Indicates whether the customer has enabled remote shopping assistance. */
  allowRemoteShoppingAssistance?: InputMaybe<Scalars['Boolean']['input']>;
  /** The customer's custom attributes. */
  customAttributes?: InputMaybe<Array<InputMaybe<AttributeValueInput>>>;
  /** The customer's date of birth. */
  dateOfBirth?: InputMaybe<Scalars['String']['input']>;
  /** Deprecated: Use `date_of_birth` instead. */
  dob?: InputMaybe<Scalars['String']['input']>;
  /** The customer's email address. */
  email: Scalars['String']['input'];
  /** The customer's first name. */
  firstname: Scalars['String']['input'];
  /** The customer's gender (Male - 1, Female - 2). */
  gender?: InputMaybe<Scalars['Int']['input']>;
  /** Indicates whether the customer is subscribed to the company's newsletter. */
  isSubscribed?: InputMaybe<Scalars['Boolean']['input']>;
  /** The customer's family name. */
  lastname: Scalars['String']['input'];
  /** The customer's middle name. */
  middlename?: InputMaybe<Scalars['String']['input']>;
  /** The customer's mobile number */
  mobile: Scalars['String']['input'];
  /** The customer's password. */
  password?: InputMaybe<Scalars['String']['input']>;
  /** An honorific, such as Dr., Mr., or Mrs. */
  prefix?: InputMaybe<Scalars['String']['input']>;
  /** A value such as Sr., Jr., or III. */
  suffix?: InputMaybe<Scalars['String']['input']>;
  /** The customer's Tax/VAT number (for corporate customers). */
  taxvat?: InputMaybe<Scalars['String']['input']>;
};

/** Contains details about a single downloadable product. */
export type CustomerDownloadableProduct = {
  __typename: 'CustomerDownloadableProduct';
  /** The date and time the purchase was made. */
  date: Maybe<Scalars['String']['output']>;
  /** The fully qualified URL to the download file. */
  downloadUrl: Maybe<Scalars['String']['output']>;
  /** The unique ID assigned to the item. */
  orderIncrementId: Maybe<Scalars['String']['output']>;
  /** The remaining number of times the customer can download the product. */
  remainingDownloads: Maybe<Scalars['String']['output']>;
  /** Indicates when the product becomes available for download. Options are `Pending` and `Invoiced`. */
  status: Maybe<Scalars['String']['output']>;
};

/** Contains a list of downloadable products. */
export type CustomerDownloadableProducts = {
  __typename: 'CustomerDownloadableProducts';
  /** An array of purchased downloadable items. */
  items: Maybe<Array<Maybe<CustomerDownloadableProduct>>>;
};

/** An input object that assigns or updates customer attributes. */
export type CustomerInput = {
  /** The customer's date of birth. */
  dateOfBirth?: InputMaybe<Scalars['String']['input']>;
  /** Deprecated: Use `date_of_birth` instead. */
  dob?: InputMaybe<Scalars['String']['input']>;
  /** The customer's email address. Required when creating a customer. */
  email?: InputMaybe<Scalars['String']['input']>;
  /** The customer's first name. */
  firstname?: InputMaybe<Scalars['String']['input']>;
  /** The customer's gender (Male - 1, Female - 2). */
  gender?: InputMaybe<Scalars['Int']['input']>;
  /** Indicates whether the customer is subscribed to the company's newsletter. */
  isSubscribed?: InputMaybe<Scalars['Boolean']['input']>;
  /** The customer's family name. */
  lastname?: InputMaybe<Scalars['String']['input']>;
  /** The customer's middle name. */
  middlename?: InputMaybe<Scalars['String']['input']>;
  /** The customer's mobile number */
  mobile: Scalars['String']['input'];
  /** The customer's password. */
  password?: InputMaybe<Scalars['String']['input']>;
  /** An honorific, such as Dr., Mr., or Mrs. */
  prefix?: InputMaybe<Scalars['String']['input']>;
  /** A value such as Sr., Jr., or III. */
  suffix?: InputMaybe<Scalars['String']['input']>;
  /** The customer's Tax/VAT number (for corporate customers). */
  taxvat?: InputMaybe<Scalars['String']['input']>;
};

/** Contains details about each of the customer's orders. */
export type CustomerOrder = {
  __typename: 'CustomerOrder';
  /** The billing address for the order. */
  billingAddress: Maybe<OrderAddress>;
  /** The shipping carrier for the order delivery. */
  carrier: Maybe<Scalars['String']['output']>;
  /** The carrier type */
  carrierType: Maybe<CarrierTypeEnum>;
  /** Comments about the order. */
  comments: Maybe<Array<Maybe<SalesCommentItem>>>;
  /** @deprecated Use the `order_date` field instead. */
  createdAt: Maybe<Scalars['String']['output']>;
  /** A list of credit memos. */
  creditMemos: Maybe<Array<Maybe<CreditMemo>>>;
  /** The entered gift message for the order */
  giftMessage: Maybe<GiftMessage>;
  /** @deprecated Use the `totals.grand_total` field instead. */
  grandTotal: Maybe<Scalars['Float']['output']>;
  /** The unique ID for a `CustomerOrder` object. */
  id: Scalars['ID']['output'];
  /** @deprecated Use the `id` field instead. */
  incrementId: Maybe<Scalars['String']['output']>;
  /** A list of invoices for the order. */
  invoices: Array<Maybe<Invoice>>;
  /** An array containing the items purchased in this order. */
  items: Maybe<Array<Maybe<OrderItemInterface>>>;
  /** The order number. */
  number: Scalars['String']['output'];
  /** The date the order was placed. */
  orderDate: Scalars['String']['output'];
  /** @deprecated Use the `number` field instead. */
  orderNumber: Scalars['String']['output'];
  /** Payment details for the order. */
  paymentMethods: Maybe<Array<Maybe<OrderPaymentMethod>>>;
  /** An array of shipment sources */
  shipmentSources: Array<Maybe<ShipmentSource>>;
  /** A list of shipments for the order. */
  shipments: Maybe<Array<Maybe<OrderShipment>>>;
  /** The shipping address for the order. */
  shippingAddress: Maybe<OrderAddress>;
  /** The delivery method for the order. */
  shippingMethod: Maybe<Scalars['String']['output']>;
  /** The current status of the order. */
  status: Scalars['String']['output'];
  /** Details about the calculated totals for this order. */
  total: Maybe<OrderTotal>;
};

/** CustomerOrderSortInput specifies the field to use for sorting search results and indicates whether the results are sorted in ascending or descending order. */
export type CustomerOrderSortInput = {
  /** This enumeration indicates whether to return results in ascending or descending order */
  sortDirection: SortEnum;
  /** Specifies the field to use for sorting */
  sortField: CustomerOrderSortableField;
};

/** Specifies the field to use for sorting */
export enum CustomerOrderSortableField {
  /**
   * Sorts customer orders by created_at field
   * @deprecated
   */
  CREATED_AT = 'CREATED_AT',
  /**
   * Sorts customer orders by number
   * @deprecated
   */
  NUMBER = 'NUMBER'
}

/** The collection of orders that match the conditions defined in the filter. */
export type CustomerOrders = {
  __typename: 'CustomerOrders';
  /** An array of customer orders. */
  items: Array<Maybe<CustomerOrder>>;
  /** Contains pagination metadata. */
  pageInfo: Maybe<SearchResultPageInfo>;
  /** The total count of customer orders. */
  totalCount: Maybe<Scalars['Int']['output']>;
};

/** Identifies the filter to use for filtering orders. */
export type CustomerOrdersFilterInput = {
  /** Filters by order number. */
  number?: InputMaybe<FilterStringTypeInput>;
};

/** Contains details about a newly-created or updated customer. */
export type CustomerOutput = {
  __typename: 'CustomerOutput';
  /** Customer details after creating or updating a customer. */
  customer: Customer;
};

/** Contains payment tokens stored in the customer's vault. */
export type CustomerPaymentTokens = {
  __typename: 'CustomerPaymentTokens';
  /** An array of payment tokens. */
  items: Array<Maybe<PaymentToken>>;
};

/** Contains a customer authorization token. */
export type CustomerToken = {
  __typename: 'CustomerToken';
  /** The customer authorization token. */
  token: Maybe<Scalars['String']['output']>;
};

export enum CustomerTypeEnum {
  /** Guest */
  GUEST = 'GUEST',
  /** Registered */
  REGISTERED = 'REGISTERED'
}

/** An input object for updating a customer. */
export type CustomerUpdateInput = {
  /** Indicates whether the customer has enabled remote shopping assistance. */
  allowRemoteShoppingAssistance?: InputMaybe<Scalars['Boolean']['input']>;
  /** The customer's custom attributes. */
  customAttributes?: InputMaybe<Array<InputMaybe<AttributeValueInput>>>;
  /** The customer's date of birth. */
  dateOfBirth?: InputMaybe<Scalars['String']['input']>;
  /** Deprecated: Use `date_of_birth` instead. */
  dob?: InputMaybe<Scalars['String']['input']>;
  /** The customer's first name. */
  firstname?: InputMaybe<Scalars['String']['input']>;
  /** The customer's gender (Male - 1, Female - 2). */
  gender?: InputMaybe<Scalars['Int']['input']>;
  /** Indicates whether the customer is subscribed to the company's newsletter. */
  isSubscribed?: InputMaybe<Scalars['Boolean']['input']>;
  /** The customer's family name. */
  lastname?: InputMaybe<Scalars['String']['input']>;
  /** The customer's middle name. */
  middlename?: InputMaybe<Scalars['String']['input']>;
  /** An honorific, such as Dr., Mr., or Mrs. */
  prefix?: InputMaybe<Scalars['String']['input']>;
  /** A value such as Sr., Jr., or III. */
  suffix?: InputMaybe<Scalars['String']['input']>;
  /** The customer's Tax/VAT number (for corporate customers). */
  taxvat?: InputMaybe<Scalars['String']['input']>;
};

/** Contains information about a text area that is defined as part of a customizable option. */
export type CustomizableAreaOption = CustomizableOptionInterface & {
  __typename: 'CustomizableAreaOption';
  /**
   * Option ID.
   * @deprecated Use `uid` instead
   */
  optionId: Maybe<Scalars['Int']['output']>;
  /** The Stock Keeping Unit of the base product. */
  productSku: Maybe<Scalars['String']['output']>;
  /** Indicates whether the option is required. */
  required: Maybe<Scalars['Boolean']['output']>;
  /** The order in which the option is displayed. */
  sortOrder: Maybe<Scalars['Int']['output']>;
  /** The display name for this option. */
  title: Maybe<Scalars['String']['output']>;
  /** The unique ID for a `CustomizableOptionInterface` object. */
  uid: Scalars['ID']['output'];
  /** An object that defines a text area. */
  value: Maybe<CustomizableAreaValue>;
};

/** Defines the price and sku of a product whose page contains a customized text area. */
export type CustomizableAreaValue = {
  __typename: 'CustomizableAreaValue';
  /** The maximum number of characters that can be entered for this customizable option. */
  maxCharacters: Maybe<Scalars['Int']['output']>;
  /** The price assigned to this option. */
  price: Maybe<Scalars['Float']['output']>;
  /** FIXED, PERCENT, or DYNAMIC. */
  priceType: Maybe<PriceTypeEnum>;
  /** The Stock Keeping Unit for this option. */
  sku: Maybe<Scalars['String']['output']>;
  /** The unique ID for a `CustomizableAreaValue` object. */
  uid: Scalars['ID']['output'];
};

/** Contains information about a set of checkbox values that are defined as part of a customizable option. */
export type CustomizableCheckboxOption = CustomizableOptionInterface & {
  __typename: 'CustomizableCheckboxOption';
  /**
   * Option ID.
   * @deprecated Use `uid` instead
   */
  optionId: Maybe<Scalars['Int']['output']>;
  /** Indicates whether the option is required. */
  required: Maybe<Scalars['Boolean']['output']>;
  /** The order in which the option is displayed. */
  sortOrder: Maybe<Scalars['Int']['output']>;
  /** The display name for this option. */
  title: Maybe<Scalars['String']['output']>;
  /** The unique ID for a `CustomizableOptionInterface` object. */
  uid: Scalars['ID']['output'];
  /** An array that defines a set of checkbox values. */
  value: Maybe<Array<Maybe<CustomizableCheckboxValue>>>;
};

/** Defines the price and sku of a product whose page contains a customized set of checkbox values. */
export type CustomizableCheckboxValue = {
  __typename: 'CustomizableCheckboxValue';
  /** The ID assigned to the value. */
  optionTypeId: Maybe<Scalars['Int']['output']>;
  /** The price assigned to this option. */
  price: Maybe<Scalars['Float']['output']>;
  /** FIXED, PERCENT, or DYNAMIC. */
  priceType: Maybe<PriceTypeEnum>;
  /** The Stock Keeping Unit for this option. */
  sku: Maybe<Scalars['String']['output']>;
  /** The order in which the checkbox value is displayed. */
  sortOrder: Maybe<Scalars['Int']['output']>;
  /** The display name for this option. */
  title: Maybe<Scalars['String']['output']>;
  /** The unique ID for a `CustomizableCheckboxValue` object. */
  uid: Scalars['ID']['output'];
};

/** Contains information about a date picker that is defined as part of a customizable option. */
export type CustomizableDateOption = CustomizableOptionInterface & {
  __typename: 'CustomizableDateOption';
  /**
   * Option ID.
   * @deprecated Use `uid` instead
   */
  optionId: Maybe<Scalars['Int']['output']>;
  /** The Stock Keeping Unit of the base product. */
  productSku: Maybe<Scalars['String']['output']>;
  /** Indicates whether the option is required. */
  required: Maybe<Scalars['Boolean']['output']>;
  /** The order in which the option is displayed. */
  sortOrder: Maybe<Scalars['Int']['output']>;
  /** The display name for this option. */
  title: Maybe<Scalars['String']['output']>;
  /** The unique ID for a `CustomizableOptionInterface` object. */
  uid: Scalars['ID']['output'];
  /** An object that defines a date field in a customizable option. */
  value: Maybe<CustomizableDateValue>;
};

/** Defines the customizable date type. */
export enum CustomizableDateTypeEnum {
  /** @deprecated  */
  DATE = 'DATE',
  /** @deprecated  */
  DATE_TIME = 'DATE_TIME',
  /** @deprecated  */
  TIME = 'TIME'
}

/** Defines the price and sku of a product whose page contains a customized date picker. */
export type CustomizableDateValue = {
  __typename: 'CustomizableDateValue';
  /** The price assigned to this option. */
  price: Maybe<Scalars['Float']['output']>;
  /** FIXED, PERCENT, or DYNAMIC. */
  priceType: Maybe<PriceTypeEnum>;
  /** The Stock Keeping Unit for this option. */
  sku: Maybe<Scalars['String']['output']>;
  /** DATE, DATE_TIME or TIME */
  type: Maybe<CustomizableDateTypeEnum>;
  /** The unique ID for a `CustomizableDateValue` object. */
  uid: Scalars['ID']['output'];
};

/** Contains information about a drop down menu that is defined as part of a customizable option. */
export type CustomizableDropDownOption = CustomizableOptionInterface & {
  __typename: 'CustomizableDropDownOption';
  /**
   * Option ID.
   * @deprecated Use `uid` instead
   */
  optionId: Maybe<Scalars['Int']['output']>;
  /** Indicates whether the option is required. */
  required: Maybe<Scalars['Boolean']['output']>;
  /** The order in which the option is displayed. */
  sortOrder: Maybe<Scalars['Int']['output']>;
  /** The display name for this option. */
  title: Maybe<Scalars['String']['output']>;
  /** The unique ID for a `CustomizableOptionInterface` object. */
  uid: Scalars['ID']['output'];
  /** An array that defines the set of options for a drop down menu. */
  value: Maybe<Array<Maybe<CustomizableDropDownValue>>>;
};

/** Defines the price and sku of a product whose page contains a customized drop down menu. */
export type CustomizableDropDownValue = {
  __typename: 'CustomizableDropDownValue';
  /** The ID assigned to the value. */
  optionTypeId: Maybe<Scalars['Int']['output']>;
  /** The price assigned to this option. */
  price: Maybe<Scalars['Float']['output']>;
  /** FIXED, PERCENT, or DYNAMIC. */
  priceType: Maybe<PriceTypeEnum>;
  /** The Stock Keeping Unit for this option. */
  sku: Maybe<Scalars['String']['output']>;
  /** The order in which the option is displayed. */
  sortOrder: Maybe<Scalars['Int']['output']>;
  /** The display name for this option. */
  title: Maybe<Scalars['String']['output']>;
  /** The unique ID for a `CustomizableDropDownValue` object. */
  uid: Scalars['ID']['output'];
};

/** Contains information about a text field that is defined as part of a customizable option. */
export type CustomizableFieldOption = CustomizableOptionInterface & {
  __typename: 'CustomizableFieldOption';
  /**
   * Option ID.
   * @deprecated Use `uid` instead
   */
  optionId: Maybe<Scalars['Int']['output']>;
  /** The Stock Keeping Unit of the base product. */
  productSku: Maybe<Scalars['String']['output']>;
  /** Indicates whether the option is required. */
  required: Maybe<Scalars['Boolean']['output']>;
  /** The order in which the option is displayed. */
  sortOrder: Maybe<Scalars['Int']['output']>;
  /** The display name for this option. */
  title: Maybe<Scalars['String']['output']>;
  /** The unique ID for a `CustomizableOptionInterface` object. */
  uid: Scalars['ID']['output'];
  /** An object that defines a text field. */
  value: Maybe<CustomizableFieldValue>;
};

/** Defines the price and sku of a product whose page contains a customized text field. */
export type CustomizableFieldValue = {
  __typename: 'CustomizableFieldValue';
  /** The maximum number of characters that can be entered for this customizable option. */
  maxCharacters: Maybe<Scalars['Int']['output']>;
  /** The price of the custom value. */
  price: Maybe<Scalars['Float']['output']>;
  /** FIXED, PERCENT, or DYNAMIC. */
  priceType: Maybe<PriceTypeEnum>;
  /** The Stock Keeping Unit for this option. */
  sku: Maybe<Scalars['String']['output']>;
  /** The unique ID for a `CustomizableFieldValue` object. */
  uid: Scalars['ID']['output'];
};

/** Contains information about a file picker that is defined as part of a customizable option. */
export type CustomizableFileOption = CustomizableOptionInterface & {
  __typename: 'CustomizableFileOption';
  /**
   * Option ID.
   * @deprecated Use `uid` instead
   */
  optionId: Maybe<Scalars['Int']['output']>;
  /** The Stock Keeping Unit of the base product. */
  productSku: Maybe<Scalars['String']['output']>;
  /** Indicates whether the option is required. */
  required: Maybe<Scalars['Boolean']['output']>;
  /** The order in which the option is displayed. */
  sortOrder: Maybe<Scalars['Int']['output']>;
  /** The display name for this option. */
  title: Maybe<Scalars['String']['output']>;
  /** The unique ID for a `CustomizableOptionInterface` object. */
  uid: Scalars['ID']['output'];
  /** An object that defines a file value. */
  value: Maybe<CustomizableFileValue>;
};

/** Defines the price and sku of a product whose page contains a customized file picker. */
export type CustomizableFileValue = {
  __typename: 'CustomizableFileValue';
  /** The file extension to accept. */
  fileExtension: Maybe<Scalars['String']['output']>;
  /** The maximum width of an image. */
  imageSizeX: Maybe<Scalars['Int']['output']>;
  /** The maximum height of an image. */
  imageSizeY: Maybe<Scalars['Int']['output']>;
  /** The price assigned to this option. */
  price: Maybe<Scalars['Float']['output']>;
  /** FIXED, PERCENT, or DYNAMIC. */
  priceType: Maybe<PriceTypeEnum>;
  /** The Stock Keeping Unit for this option. */
  sku: Maybe<Scalars['String']['output']>;
  /** The unique ID for a `CustomizableFileValue` object. */
  uid: Scalars['ID']['output'];
};

/** Contains information about a multiselect that is defined as part of a customizable option. */
export type CustomizableMultipleOption = CustomizableOptionInterface & {
  __typename: 'CustomizableMultipleOption';
  /**
   * Option ID.
   * @deprecated Use `uid` instead
   */
  optionId: Maybe<Scalars['Int']['output']>;
  /** Indicates whether the option is required. */
  required: Maybe<Scalars['Boolean']['output']>;
  /** The order in which the option is displayed. */
  sortOrder: Maybe<Scalars['Int']['output']>;
  /** The display name for this option. */
  title: Maybe<Scalars['String']['output']>;
  /** The unique ID for a `CustomizableOptionInterface` object. */
  uid: Scalars['ID']['output'];
  /** An array that defines the set of options for a multiselect. */
  value: Maybe<Array<Maybe<CustomizableMultipleValue>>>;
};

/** Defines the price and sku of a product whose page contains a customized multiselect. */
export type CustomizableMultipleValue = {
  __typename: 'CustomizableMultipleValue';
  /** The ID assigned to the value. */
  optionTypeId: Maybe<Scalars['Int']['output']>;
  /** The price assigned to this option. */
  price: Maybe<Scalars['Float']['output']>;
  /** FIXED, PERCENT, or DYNAMIC. */
  priceType: Maybe<PriceTypeEnum>;
  /** The Stock Keeping Unit for this option. */
  sku: Maybe<Scalars['String']['output']>;
  /** The order in which the option is displayed. */
  sortOrder: Maybe<Scalars['Int']['output']>;
  /** The display name for this option. */
  title: Maybe<Scalars['String']['output']>;
  /** The unique ID for a `CustomizableMultipleValue` object. */
  uid: Scalars['ID']['output'];
};

/** Defines a customizable option. */
export type CustomizableOptionInput = {
  /** The customizable option ID of the product. */
  id?: InputMaybe<Scalars['Int']['input']>;
  /** The unique ID for a `CartItemInterface` object. */
  uid?: InputMaybe<Scalars['ID']['input']>;
  /** The string value of the option. */
  valueString: Scalars['String']['input'];
};

/** Contains basic information about a customizable option. It can be implemented by several types of configurable options. */
export type CustomizableOptionInterface = {
  /**
   * Option ID.
   * @deprecated Use `uid` instead
   */
  optionId: Maybe<Scalars['Int']['output']>;
  /** Indicates whether the option is required. */
  required: Maybe<Scalars['Boolean']['output']>;
  /** The order in which the option is displayed. */
  sortOrder: Maybe<Scalars['Int']['output']>;
  /** The display name for this option. */
  title: Maybe<Scalars['String']['output']>;
  /** The unique ID for a `CustomizableOptionInterface` object. */
  uid: Scalars['ID']['output'];
};

/** Contains information about customizable product options. */
export type CustomizableProductInterface = {
  /** An array of options for a customizable product. */
  options: Maybe<Array<Maybe<CustomizableOptionInterface>>>;
};

/** Contains information about a set of radio buttons that are defined as part of a customizable option. */
export type CustomizableRadioOption = CustomizableOptionInterface & {
  __typename: 'CustomizableRadioOption';
  /**
   * Option ID.
   * @deprecated Use `uid` instead
   */
  optionId: Maybe<Scalars['Int']['output']>;
  /** Indicates whether the option is required. */
  required: Maybe<Scalars['Boolean']['output']>;
  /** The order in which the option is displayed. */
  sortOrder: Maybe<Scalars['Int']['output']>;
  /** The display name for this option. */
  title: Maybe<Scalars['String']['output']>;
  /** The unique ID for a `CustomizableOptionInterface` object. */
  uid: Scalars['ID']['output'];
  /** An array that defines a set of radio buttons. */
  value: Maybe<Array<Maybe<CustomizableRadioValue>>>;
};

/** Defines the price and sku of a product whose page contains a customized set of radio buttons. */
export type CustomizableRadioValue = {
  __typename: 'CustomizableRadioValue';
  /** The ID assigned to the value. */
  optionTypeId: Maybe<Scalars['Int']['output']>;
  /** The price assigned to this option. */
  price: Maybe<Scalars['Float']['output']>;
  /** FIXED, PERCENT, or DYNAMIC. */
  priceType: Maybe<PriceTypeEnum>;
  /** The Stock Keeping Unit for this option. */
  sku: Maybe<Scalars['String']['output']>;
  /** The order in which the radio button is displayed. */
  sortOrder: Maybe<Scalars['Int']['output']>;
  /** The display name for this option. */
  title: Maybe<Scalars['String']['output']>;
  /** The unique ID for a `CustomizableRadioValue` object. */
  uid: Scalars['ID']['output'];
};

export type DateTimeEqualsInRangeFilterInput = {
  /** DateTime does not equal */
  doesNotEqual?: InputMaybe<Scalars['DateTime']['input']>;
  /** DateTime equals */
  equals?: InputMaybe<Scalars['DateTime']['input']>;
  /** DateTime is greater than */
  greaterThan?: InputMaybe<Scalars['DateTime']['input']>;
  /** DateTime is in */
  in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
  /** DateTime is less than */
  lessThan?: InputMaybe<Scalars['DateTime']['input']>;
  /** DateTime is not in */
  notIn?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
};

/** Contains the results of the request to delete a compare list. */
export type DeleteCompareListOutput = {
  __typename: 'DeleteCompareListOutput';
  /** Indicates whether the compare list was successfully deleted. */
  result: Scalars['Boolean']['output'];
};

/** Indicates whether the request succeeded and returns the remaining customer payment tokens. */
export type DeletePaymentTokenOutput = {
  __typename: 'DeletePaymentTokenOutput';
  /** A container for the customer's remaining payment tokens. */
  customerPaymentTokens: Maybe<CustomerPaymentTokens>;
  /** Indicates whether the request succeeded. */
  result: Scalars['Boolean']['output'];
};

export enum DeleteReactionTypeEnum {
  /** Cascade */
  CASCADE = 'CASCADE',
  /** Never */
  NEVER = 'NEVER',
  /** Remove */
  REMOVE = 'REMOVE'
}

export type DeliveryInfo = {
  __typename: 'DeliveryInfo';
  carrierCode: Maybe<Scalars['String']['output']>;
  deliveryAction: Maybe<ShippingActionEnum>;
  deliveryAddressId: Maybe<Scalars['Int']['output']>;
  deliveryMethod: Maybe<Scalars['String']['output']>;
  hasSetInfo: Scalars['Boolean']['output'];
  id: Scalars['ID']['output'];
  pargoLocation: Maybe<PargoLocation>;
  rateUid: Maybe<Scalars['String']['output']>;
  requiresDeliveryAction: Maybe<Scalars['Boolean']['output']>;
  requiresPargoLocation: Maybe<Scalars['Boolean']['output']>;
  step: DeliveryStepEnum;
};

export enum DeliveryStepEnum {
  /** Delivery Address */
  DELIVERY_ADDRESS = 'DELIVERY_ADDRESS',
  /** Delivery Method */
  DELIVERY_METHOD = 'DELIVERY_METHOD'
}

export enum DeviceTypeEnum {
  /** Desktop */
  DESKTOP = 'DESKTOP',
  /** Mobile */
  MOBILE = 'MOBILE',
  /** Tablet */
  TABLET = 'TABLET',
  /** Ultra */
  ULTRA = 'ULTRA'
}

/** Defines an individual discount. A discount can be applied to the cart as a whole or to an item. */
export type Discount = {
  __typename: 'Discount';
  /** The amount of the discount. */
  amount: Money;
  /** A description of the discount. */
  label: Scalars['String']['output'];
};

/** An implementation for downloadable product cart items. */
export type DownloadableCartItem = CartItemInterface & {
  __typename: 'DownloadableCartItem';
  /** An array containing the customizable options the shopper selected. */
  customizableOptions: Array<Maybe<SelectedCustomizableOption>>;
  /** An array of errors encountered while loading the cart item */
  errors: Maybe<Array<Maybe<CartItemError>>>;
  /** @deprecated Use `uid` instead. */
  id: Scalars['String']['output'];
  /** An array containing information about the links for the downloadable product added to the cart. */
  links: Maybe<Array<Maybe<DownloadableProductLinks>>>;
  /** Contains details about the price of the item, including taxes and discounts. */
  prices: Maybe<CartItemPrices>;
  /** Details about an item in the cart. */
  product: ProductInterface;
  /** The quantity of this item in the cart. */
  quantity: Scalars['Float']['output'];
  /** An array containing information about samples of the selected downloadable product. */
  samples: Maybe<Array<Maybe<DownloadableProductSamples>>>;
  /** The unique ID for a `CartItemInterface` object. */
  uid: Scalars['ID']['output'];
};

/** Defines downloadable product options for `CreditMemoItemInterface`. */
export type DownloadableCreditMemoItem = CreditMemoItemInterface & {
  __typename: 'DownloadableCreditMemoItem';
  /** Details about the final discount amount for the base product, including discounts on options. */
  discounts: Maybe<Array<Maybe<Discount>>>;
  /** A list of downloadable links that are refunded from the downloadable product. */
  downloadableLinks: Maybe<Array<Maybe<DownloadableItemsLinks>>>;
  /** The unique ID for a `CreditMemoItemInterface` object. */
  id: Scalars['ID']['output'];
  /** The order item the credit memo is applied to. */
  orderItem: Maybe<OrderItemInterface>;
  /** The name of the base product. */
  productName: Maybe<Scalars['String']['output']>;
  /** The sale price for the base product, including selected options. */
  productSalePrice: Money;
  /** The SKU of the base product. */
  productSku: Scalars['String']['output'];
  /** The number of refunded items. */
  quantityRefunded: Maybe<Scalars['Float']['output']>;
};

export enum DownloadableFileTypeEnum {
  /** @deprecated `sample_url` serves to get the downloadable sample */
  FILE = 'FILE',
  /** @deprecated `sample_url` serves to get the downloadable sample */
  URL = 'URL'
}

/** Defines downloadable product options for `InvoiceItemInterface`. */
export type DownloadableInvoiceItem = InvoiceItemInterface & {
  __typename: 'DownloadableInvoiceItem';
  /** Information about the final discount amount for the base product, including discounts on options. */
  discounts: Maybe<Array<Maybe<Discount>>>;
  /** A list of downloadable links that are invoiced from the downloadable product. */
  downloadableLinks: Maybe<Array<Maybe<DownloadableItemsLinks>>>;
  /** The unique ID for an `InvoiceItemInterface` object. */
  id: Scalars['ID']['output'];
  /** Details about an individual order item. */
  orderItem: Maybe<OrderItemInterface>;
  /** The name of the base product. */
  productName: Maybe<Scalars['String']['output']>;
  /** The sale price for the base product including selected options. */
  productSalePrice: Money;
  /** The SKU of the base product. */
  productSku: Scalars['String']['output'];
  /** The number of invoiced items. */
  quantityInvoiced: Maybe<Scalars['Float']['output']>;
};

/** Defines characteristics of the links for downloadable product. */
export type DownloadableItemsLinks = {
  __typename: 'DownloadableItemsLinks';
  /** A number indicating the sort order. */
  sortOrder: Maybe<Scalars['Int']['output']>;
  /** The display name of the link. */
  title: Maybe<Scalars['String']['output']>;
  /** The unique ID for a `DownloadableItemsLinks` object. */
  uid: Scalars['ID']['output'];
};

/** Defines downloadable product options for `OrderItemInterface`. */
export type DownloadableOrderItem = OrderItemInterface & {
  __typename: 'DownloadableOrderItem';
  coverImage: Image;
  /** The final discount information for the product. */
  discounts: Maybe<Array<Maybe<Discount>>>;
  /** A list of downloadable links that are ordered from the downloadable product. */
  downloadableLinks: Maybe<Array<Maybe<DownloadableItemsLinks>>>;
  /** The entered option for the base product, such as a logo or image. */
  enteredOptions: Maybe<Array<Maybe<OrderItemOption>>>;
  /** The selected gift message for the order item */
  giftMessage: Maybe<GiftMessage>;
  /** The unique ID for an `OrderItemInterface` object. */
  id: Scalars['ID']['output'];
  /** The product image */
  productImage: Maybe<ProductImageV2>;
  /** The name of the base product. */
  productName: Maybe<Scalars['String']['output']>;
  /** The sale price of the base product, including selected options. */
  productSalePrice: Money;
  /** The SKU of the base product. */
  productSku: Scalars['String']['output'];
  /** The type of product, such as simple, configurable, etc. */
  productType: Maybe<Scalars['String']['output']>;
  /** URL key of the base product. */
  productUrlKey: Maybe<Scalars['String']['output']>;
  /** The number of canceled items. */
  quantityCanceled: Maybe<Scalars['Float']['output']>;
  /** The number of invoiced items. */
  quantityInvoiced: Maybe<Scalars['Float']['output']>;
  /** The number of units ordered for this item. */
  quantityOrdered: Maybe<Scalars['Float']['output']>;
  /** The number of refunded items. */
  quantityRefunded: Maybe<Scalars['Float']['output']>;
  /** The number of returned items. */
  quantityReturned: Maybe<Scalars['Float']['output']>;
  /** The number of shipped items. */
  quantityShipped: Maybe<Scalars['Float']['output']>;
  /** The selected options for the base product, such as color or size. */
  selectedOptions: Maybe<Array<Maybe<OrderItemOption>>>;
  /** The status of the order item. */
  status: Maybe<Scalars['String']['output']>;
};

/** Defines a product that the shopper downloads. */
export type DownloadableProduct = CustomizableProductInterface & ProductInterface & RoutableInterface & {
  __typename: 'DownloadableProduct';
  /** @deprecated Use the `custom_attributes` field instead. */
  age: Maybe<Scalars['String']['output']>;
  /** Information about guest highlight points available by product. */
  amastyRewardsGuestHighlights: Maybe<HighlightOutput>;
  /** Information about highlight points available by product. */
  amastyRewardsHighlights: Maybe<HighlightOutput>;
  /**
   * The attribute set assigned to the product.
   * @deprecated The field should not be used on the storefront.
   */
  attributeSetId: Maybe<Scalars['Int']['output']>;
  /** Returns all assigned product attributes and their values */
  attributes: Array<Maybe<ProductAttributes>>;
  /** Returns the brand of the product */
  brand: Maybe<Brand>;
  cacheBuster: Maybe<Scalars['String']['output']>;
  /** The relative canonical URL. This value is returned only if the system setting 'Use Canonical Link Meta Tag For Products' is enabled. */
  canonicalUrl: Maybe<Scalars['String']['output']>;
  cartItemUid: Maybe<Scalars['String']['output']>;
  /** The categories assigned to a product. */
  categories: Maybe<Array<Maybe<CategoryInterface>>>;
  /** @deprecated Use the `custom_attributes` field instead. */
  colour: Maybe<Scalars['Int']['output']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  concentration: Maybe<Scalars['Int']['output']>;
  /** The product's country of origin. */
  countryOfManufacture: Maybe<Scalars['String']['output']>;
  coverImage: Image;
  /**
   * Timestamp indicating when the product was created.
   * @deprecated The field should not be used on the storefront.
   */
  createdAt: Maybe<Scalars['String']['output']>;
  /** Crosssell Products */
  crosssellProducts: Maybe<Array<Maybe<ProductInterface>>>;
  /** @deprecated Use the `custom_attributes` field instead. */
  department: Maybe<Scalars['String']['output']>;
  /** Detailed information about the product. The value can include simple HTML tags. */
  description: Maybe<ComplexTextValue>;
  /** An array containing information about the links for this downloadable product. */
  downloadableProductLinks: Maybe<Array<Maybe<DownloadableProductLinks>>>;
  /** An array containing information about samples of this downloadable product. */
  downloadableProductSamples: Maybe<Array<Maybe<DownloadableProductSamples>>>;
  /** @deprecated Use the `custom_attributes` field instead. */
  flavour: Maybe<Scalars['Int']['output']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  fragrance: Maybe<Scalars['Int']['output']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  gender: Maybe<Scalars['String']['output']>;
  /** Indicates whether a gift message is available. */
  giftMessageAvailable: Maybe<Scalars['String']['output']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  hairType: Maybe<Scalars['String']['output']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  height: Maybe<Scalars['Float']['output']>;
  /**
   * The ID number assigned to the product.
   * @deprecated Use the `uid` field instead.
   */
  id: Maybe<Scalars['Int']['output']>;
  /** The relative path to the main image on the product page. */
  image: Maybe<ProductImage>;
  imageGallery: Array<Image>;
  ingredientList: Maybe<Array<Maybe<ProductIngredient>>>;
  /** @deprecated Use the `custom_attributes` field instead. */
  ingredients: Maybe<Scalars['String']['output']>;
  isInWishlist: Scalars['Boolean']['output'];
  /** List of product labels associated to the product */
  labels: Array<Maybe<LabelItem>>;
  /** @deprecated Use the `custom_attributes` field instead. */
  length: Maybe<Scalars['Float']['output']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  licensingStatus: Maybe<Scalars['String']['output']>;
  /** A value of 1 indicates that each link in the array must be purchased separately. */
  linksPurchasedSeparately: Maybe<Scalars['Int']['output']>;
  /** The heading above the list of downloadable products. */
  linksTitle: Maybe<Scalars['String']['output']>;
  /**
   * A number representing the product's manufacturer.
   * @deprecated Use the `custom_attributes` field instead.
   */
  manufacturer: Maybe<Scalars['Int']['output']>;
  /** An array of media gallery objects. */
  mediaGallery: Maybe<Array<Maybe<MediaGalleryInterface>>>;
  /**
   * An array of MediaGalleryEntry objects.
   * @deprecated Use `media_gallery` instead.
   */
  mediaGalleryEntries: Maybe<Array<Maybe<MediaGalleryEntry>>>;
  /** An array of media gallery objects. */
  mediaGalleryV2: Maybe<Array<Maybe<MediaGalleryV2Interface>>>;
  /** A brief overview of the product for search results listings, maximum 255 characters. */
  metaDescription: Maybe<Scalars['String']['output']>;
  /** A comma-separated list of keywords that are visible only to search engines. */
  metaKeyword: Maybe<Scalars['String']['output']>;
  /** A string that is displayed in the title bar and tab of the browser and in search results lists. */
  metaTitle: Maybe<Scalars['String']['output']>;
  /** The product name. Customers use this name to identify the product. */
  name: Maybe<Scalars['String']['output']>;
  /** The beginning date for new product listings, and determines if the product is featured as a new product. */
  newFromDate: Maybe<Scalars['String']['output']>;
  /** The end date for new product listings. */
  newToDate: Maybe<Scalars['String']['output']>;
  /** Product stock only x left count */
  onlyXLeftInStock: Maybe<Scalars['Float']['output']>;
  /** An array of options for a customizable product. */
  options: Maybe<Array<Maybe<CustomizableOptionInterface>>>;
  /** If the product has multiple options, determines where they appear on the product page. */
  optionsContainer: Maybe<Scalars['String']['output']>;
  /**
   * Indicates the price of an item.
   * @deprecated Use `price_range` for product price information.
   */
  price: Maybe<ProductPrices>;
  /** The range of prices for the product */
  priceRange: PriceRange;
  /** An array of `TierPrice` objects. */
  priceTiers: Maybe<Array<Maybe<TierPrice>>>;
  /** An array of `ProductLinks` objects. */
  productLinks: Maybe<Array<Maybe<ProductLinksInterface>>>;
  productType: ProductTypeEnum;
  /** @deprecated Use the `custom_attributes` field instead. */
  quantity: Maybe<Scalars['Int']['output']>;
  quantityInCart: Scalars['Int']['output'];
  /** The rating summary for the product */
  ratingSummary: RatingSummary;
  /** Contains 0 when there is no redirect error. A value of 301 indicates the URL of the requested resource has been changed permanently, while a value of 302 indicates a temporary redirect. */
  redirectCode: Scalars['Int']['output'];
  /** Related Products */
  relatedProducts: Maybe<Array<Maybe<ProductInterface>>>;
  /** The internal relative URL. If the specified URL is a redirect, the query returns the redirected URL, not the original. */
  relativeUrl: Maybe<Scalars['String']['output']>;
  /** The total count of all the reviews given to the product. */
  reviewCount: Scalars['Int']['output'];
  /** The list of products reviews */
  reviews: ProductReviews;
  /** A short description of the product. Its use depends on the theme. */
  shortDescription: Maybe<ComplexTextValue>;
  /** @deprecated Use the `custom_attributes` field instead. */
  size: Maybe<Scalars['Int']['output']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  skinConcern: Maybe<Scalars['String']['output']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  skinType: Maybe<Scalars['String']['output']>;
  /** A number or code assigned to a product to identify the product, options, price, and manufacturer. */
  sku: Maybe<Scalars['String']['output']>;
  /** The relative path to the small image, which is used on catalog pages. */
  smallImage: Maybe<ProductImage>;
  /**
   * The beginning date that a product has a special price.
   * @deprecated The field should not be used on the storefront.
   */
  specialFromDate: Maybe<Scalars['String']['output']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  specialIcons: Maybe<Scalars['String']['output']>;
  /** The discounted price of the product. */
  specialPrice: Maybe<Scalars['Float']['output']>;
  /** The end date for a product with a special price. */
  specialToDate: Maybe<Scalars['String']['output']>;
  stockInfo: Maybe<StockInfo>;
  /** Stock status of the product */
  stockStatus: Maybe<ProductStockStatus>;
  /** The file name of a swatch image. */
  swatchImage: Maybe<Scalars['String']['output']>;
  /** Returns all assigned product tags and their values */
  tags: Array<Maybe<ProductTags>>;
  /** The relative path to the product's thumbnail image. */
  thumbnail: Maybe<ProductImage>;
  /**
   * The price when tier pricing is in effect and the items purchased threshold has been reached.
   * @deprecated Use `price_tiers` for product tier price information.
   */
  tierPrice: Maybe<Scalars['Float']['output']>;
  /**
   * An array of ProductTierPrices objects.
   * @deprecated Use `price_tiers` for product tier price information.
   */
  tierPrices: Maybe<Array<Maybe<ProductTierPrices>>>;
  /** One of PRODUCT, CATEGORY, or CMS_PAGE. */
  type: Maybe<UrlRewriteEntityTypeEnum>;
  /**
   * One of simple, virtual, bundle, downloadable, grouped, or configurable.
   * @deprecated Use `__typename` instead.
   */
  typeId: Maybe<Scalars['String']['output']>;
  /** The unique ID for a `ProductInterface` object. */
  uid: Scalars['ID']['output'];
  /**
   * Timestamp indicating when the product was updated.
   * @deprecated The field should not be used on the storefront.
   */
  updatedAt: Maybe<Scalars['String']['output']>;
  /** Upsell Products */
  upsellProducts: Maybe<Array<Maybe<ProductInterface>>>;
  /** The part of the URL that identifies the product */
  urlKey: Maybe<Scalars['String']['output']>;
  /** @deprecated Use product's `canonical_url` or url rewrites instead */
  urlPath: Maybe<Scalars['String']['output']>;
  /** URL rewrites list */
  urlRewrites: Maybe<Array<Maybe<UrlRewrite>>>;
  /** The part of the product URL that is appended after the url key */
  urlSuffix: Maybe<Scalars['String']['output']>;
  valueAttributes: Maybe<Array<Maybe<ProductValue>>>;
  /** @deprecated Use the `custom_attributes` field instead. */
  valueTraits: Maybe<Scalars['String']['output']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  valuesDiet: Maybe<Scalars['String']['output']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  valuesEnvironmental: Maybe<Scalars['String']['output']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  valuesIngredient: Maybe<Scalars['String']['output']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  valuesSocial: Maybe<Scalars['String']['output']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  volumeWeight: Maybe<Scalars['Float']['output']>;
  /**
   * An array of websites in which the product is available.
   * @deprecated The field should not be used on the storefront.
   */
  websites: Maybe<Array<Maybe<Website>>>;
  /** @deprecated Use the `custom_attributes` field instead. */
  width: Maybe<Scalars['Float']['output']>;
  wishlistItemId: Maybe<Scalars['Int']['output']>;
};


/** Defines a product that the shopper downloads. */
export type DownloadableProductamastyRewardsHighlightsArgs = {
  attributes?: InputMaybe<Scalars['String']['input']>;
};


/** Defines a product that the shopper downloads. */
export type DownloadableProductlabelsArgs = {
  order?: InputMaybe<LabelOrderInput>;
};


/** Defines a product that the shopper downloads. */
export type DownloadableProductreviewsArgs = {
  currentPage?: InputMaybe<Scalars['Int']['input']>;
  filters?: InputMaybe<ReviewFilterInput>;
  pageSize?: InputMaybe<Scalars['Int']['input']>;
  sort?: InputMaybe<ReviewSortInput>;
};

/** Defines a single downloadable product. */
export type DownloadableProductCartItemInput = {
  /** The ID and value of the option. */
  customizableOptions?: InputMaybe<Array<InputMaybe<CustomizableOptionInput>>>;
  /** The quantity and SKU of the downloadable product. */
  data: CartItemInput;
  /** An array of objects containing the link_id of the downloadable product link. */
  downloadableProductLinks?: InputMaybe<Array<InputMaybe<DownloadableProductLinksInput>>>;
};

/** Defines characteristics of a downloadable product. */
export type DownloadableProductLinks = {
  __typename: 'DownloadableProductLinks';
  /** @deprecated This information should not be exposed on frontend. */
  id: Maybe<Scalars['Int']['output']>;
  /** @deprecated This information should not be exposed on frontend. */
  isShareable: Maybe<Scalars['Boolean']['output']>;
  /** @deprecated `sample_url` serves to get the downloadable sample */
  linkType: Maybe<DownloadableFileTypeEnum>;
  /** @deprecated This information should not be exposed on frontend. */
  numberOfDownloads: Maybe<Scalars['Int']['output']>;
  /** The price of the downloadable product. */
  price: Maybe<Scalars['Float']['output']>;
  /** @deprecated `sample_url` serves to get the downloadable sample */
  sampleFile: Maybe<Scalars['String']['output']>;
  /** @deprecated `sample_url` serves to get the downloadable sample */
  sampleType: Maybe<DownloadableFileTypeEnum>;
  /** The full URL to the downloadable sample. */
  sampleUrl: Maybe<Scalars['String']['output']>;
  /** A number indicating the sort order. */
  sortOrder: Maybe<Scalars['Int']['output']>;
  /** The display name of the link. */
  title: Maybe<Scalars['String']['output']>;
  /** The unique ID for a `DownloadableProductLinks` object. */
  uid: Scalars['ID']['output'];
};

/** Contains the link ID for the downloadable product. */
export type DownloadableProductLinksInput = {
  /** The unique ID of the downloadable product link. */
  linkId: Scalars['Int']['input'];
};

/** Defines characteristics of a downloadable product. */
export type DownloadableProductSamples = {
  __typename: 'DownloadableProductSamples';
  /** @deprecated This information should not be exposed on frontend. */
  id: Maybe<Scalars['Int']['output']>;
  /** @deprecated `sample_url` serves to get the downloadable sample */
  sampleFile: Maybe<Scalars['String']['output']>;
  /** @deprecated `sample_url` serves to get the downloadable sample */
  sampleType: Maybe<DownloadableFileTypeEnum>;
  /** The full URL to the downloadable sample. */
  sampleUrl: Maybe<Scalars['String']['output']>;
  /** A number indicating the sort order. */
  sortOrder: Maybe<Scalars['Int']['output']>;
  /** The display name of the sample. */
  title: Maybe<Scalars['String']['output']>;
};

/** A downloadable product wish list item. */
export type DownloadableWishlistItem = WishlistItemInterface & {
  __typename: 'DownloadableWishlistItem';
  /** The date and time the item was added to the wish list. */
  addedAt: Scalars['String']['output'];
  /** Custom options selected for the wish list item. */
  customizableOptions: Array<Maybe<SelectedCustomizableOption>>;
  /** The description of the item. */
  description: Maybe<Scalars['String']['output']>;
  /** The unique ID for a `WishlistItemInterface` object. */
  id: Scalars['ID']['output'];
  /** An array containing information about the selected links. */
  linksV2: Maybe<Array<Maybe<DownloadableProductLinks>>>;
  /** Product details of the wish list item. */
  product: Maybe<ProductInterface>;
  /** The quantity of this wish list item. */
  quantity: Scalars['Float']['output'];
  /** An array containing information about the selected samples. */
  samples: Maybe<Array<Maybe<DownloadableProductSamples>>>;
};

export enum DropDownCartDisplayType {
  /** Add to Cart */
  ADD_TO_CART = 'ADD_TO_CART',
  /** Cart */
  CART = 'CART',
  /** None */
  NONE = 'NONE'
}

export type ElasticsuiteFilterRecommendation = FilterRecommendationInterface & {
  __typename: 'ElasticsuiteFilterRecommendation';
  /** Filter attribute code. */
  attributeCode: Maybe<Scalars['String']['output']>;
  /** Filter label. */
  label: Maybe<Scalars['String']['output']>;
  /** Filter value. */
  value: Scalars['String']['output'];
};

export type ElasticsuiteTermRecommendation = TermRecommendationInterface & {
  __typename: 'ElasticsuiteTermRecommendation';
  /** Filter value. */
  count: Scalars['String']['output'];
  /** Recommended search term. */
  name: Maybe<Scalars['String']['output']>;
};

export type EmbeddedEntityConfig = EntityConfig & {
  __typename: 'EmbeddedEntityConfig';
  description: Scalars['String']['output'];
  doesImplement: Array<Scalars['String']['output']>;
  enums: Array<AdminEnum>;
  externals: Maybe<Array<ExternalEntity>>;
  fields: Array<EntityFieldConfig>;
  id: Scalars['ID']['output'];
  inputFields: Maybe<Array<EntityInputFieldConfig>>;
  isImplementedBy: Array<Scalars['String']['output']>;
  isInterface: Maybe<Scalars['Boolean']['output']>;
  keys: EntityKeys;
  names: EntityNames;
  relationships: Maybe<Array<EntityRelationshipConfig>>;
  rowLayout: AdminLayout;
  type: Scalars['String']['output'];
};

export type EmbeddedEntityKeys = {
  __typename: 'EmbeddedEntityKeys';
  idKey: Scalars['String']['output'];
  nameKeys: Array<Scalars['String']['output']>;
  nameSeperator: Scalars['String']['output'];
  nameStyle: Maybe<Scalars['String']['output']>;
  nameTitleStyle: Maybe<Scalars['String']['output']>;
  showNameTitle: Scalars['Boolean']['output'];
};

/** Defines a customer-entered option. */
export type EnteredOptionInput = {
  /** The unique ID for a `CustomizableOptionInterface` object, such as a `CustomizableFieldOption`, `CustomizableFileOption`, or `CustomizableAreaOption` object. */
  uid: Scalars['ID']['input'];
  /** Text the customer entered. */
  value: Scalars['String']['input'];
};

export type EntityConfig = {
  description: Scalars['String']['output'];
  doesImplement: Array<Scalars['String']['output']>;
  enums: Array<AdminEnum>;
  externals: Maybe<Array<ExternalEntity>>;
  fields: Array<EntityFieldConfig>;
  id: Scalars['ID']['output'];
  isImplementedBy: Array<Scalars['String']['output']>;
  isInterface: Maybe<Scalars['Boolean']['output']>;
  keys: EntityKeys;
  names: EntityNames;
  relationships: Maybe<Array<EntityRelationshipConfig>>;
  rowLayout: AdminLayout;
  type: Scalars['String']['output'];
};

export type EntityFieldConfig = {
  __typename: 'EntityFieldConfig';
  description: Scalars['String']['output'];
  isArray: Scalars['Boolean']['output'];
  isDerived: Scalars['Boolean']['output'];
  isEmbedded: Scalars['Boolean']['output'];
  isEnum: Scalars['Boolean']['output'];
  isExternal: Scalars['Boolean']['output'];
  isGenerated: Scalars['Boolean']['output'];
  isImmutable: Scalars['Boolean']['output'];
  isNullable: Scalars['Boolean']['output'];
  isRelationship: Scalars['Boolean']['output'];
  isResolved: Scalars['Boolean']['output'];
  key: Scalars['String']['output'];
  type: Scalars['String']['output'];
};

export type EntityFilterConfig = {
  __typename: 'EntityFilterConfig';
  filterOptions: Array<SimpleFilterTypeEnum>;
  isRelationship: Scalars['Boolean']['output'];
  key: Scalars['String']['output'];
  relatedType: Maybe<Scalars['String']['output']>;
  type: Scalars['String']['output'];
};

export type EntityFilterOption = {
  __typename: 'EntityFilterOption';
  key: Scalars['String']['output'];
  options: Array<EntityFilterValue>;
};

export type EntityFilterValue = {
  title: Scalars['String']['output'];
};

export type EntityFloatFilterValue = EntityFilterValue & {
  __typename: 'EntityFloatFilterValue';
  floatValue: Scalars['Float']['output'];
  title: Scalars['String']['output'];
};

export type EntityInputFieldConfig = {
  __typename: 'EntityInputFieldConfig';
  description: Scalars['String']['output'];
  embeddedType: Maybe<Scalars['String']['output']>;
  isArray: Scalars['Boolean']['output'];
  isEmbedded: Scalars['Boolean']['output'];
  isEnum: Scalars['Boolean']['output'];
  isExternal: Scalars['Boolean']['output'];
  isNullable: Scalars['Boolean']['output'];
  isRelationship: Scalars['Boolean']['output'];
  key: Scalars['String']['output'];
  type: Scalars['String']['output'];
};

export type EntityIntFilterValue = EntityFilterValue & {
  __typename: 'EntityIntFilterValue';
  intValue: Scalars['Int']['output'];
  title: Scalars['String']['output'];
};

export type EntityKeys = {
  __typename: 'EntityKeys';
  createdAtKey: Scalars['String']['output'];
  idKey: Scalars['String']['output'];
  nameKeys: Array<Scalars['String']['output']>;
  nameSeperator: Scalars['String']['output'];
  nameStyle: Maybe<Scalars['String']['output']>;
  nameTitleStyle: Maybe<Scalars['String']['output']>;
  showNameTitle: Scalars['Boolean']['output'];
  updatedAtKey: Scalars['String']['output'];
};

export type EntityNames = {
  __typename: 'EntityNames';
  plural: Scalars['String']['output'];
  singular: Scalars['String']['output'];
};

export type EntityOrderConfig = {
  __typename: 'EntityOrderConfig';
  isDefault: Maybe<Scalars['Boolean']['output']>;
  key: Maybe<Scalars['String']['output']>;
};

export type EntityRelationshipConfig = {
  __typename: 'EntityRelationshipConfig';
  idKey: Scalars['String']['output'];
  key: Scalars['String']['output'];
  relatedType: Scalars['String']['output'];
  relationshipType: EntityRelationshipTypeEnum;
};

export enum EntityRelationshipTypeEnum {
  /** Many to Many */
  MANY_TO_MANY = 'MANY_TO_MANY',
  /** Many to One */
  MANY_TO_ONE = 'MANY_TO_ONE',
  /** One to Many */
  ONE_TO_MANY = 'ONE_TO_MANY',
  /** One to one */
  ONE_TO_ONE = 'ONE_TO_ONE',
  /** To Many (no inverse) */
  TO_MANY = 'TO_MANY',
  /** To One (no invers) */
  TO_ONE = 'TO_ONE'
}

export type EntityStringFilterValue = EntityFilterValue & {
  __typename: 'EntityStringFilterValue';
  stringValue: Scalars['String']['output'];
  title: Scalars['String']['output'];
};

export enum EntityTypeEnum {
  /** Base Category */
  BASE_CATEGORY = 'BASE_CATEGORY',
  /** Brand */
  BRAND = 'BRAND',
  /** Category */
  CATEGORY = 'CATEGORY',
  /** Content */
  CONTENT = 'CONTENT',
  /** Deals */
  DEALS = 'DEALS',
  /** Ingredient */
  INGREDIENT = 'INGREDIENT',
  /** Product */
  PRODUCT = 'PRODUCT'
}

/** Contains the `uid`, `relative_url`, and `type` attributes. */
export type EntityUrl = {
  __typename: 'EntityUrl';
  /** @deprecated Use `relative_url` instead. */
  canonicalUrl: Maybe<Scalars['String']['output']>;
  /** The unique ID for a `ProductInterface`, `CategoryInterface`, `CmsPage`, or similar object associated with the specified URL. This could be a product, category, or CMS page UID. */
  entityUid: Maybe<Scalars['ID']['output']>;
  /**
   * The ID assigned to the object associated with the specified url. This could be a product ID, category ID, or page ID.
   * @deprecated Use `entity_uid` instead.
   */
  id: Maybe<Scalars['Int']['output']>;
  /** Contains 0 when there is no redirect error. A value of 301 indicates the URL of the requested resource has been changed permanently, while a value of 302 indicates a temporary redirect. */
  redirectCode: Maybe<Scalars['Int']['output']>;
  /** The internal relative URL. If the specified URL is a redirect, the query returns the redirected URL, not the original. */
  relativeUrl: Maybe<Scalars['String']['output']>;
  /** One of PRODUCT, CATEGORY, or CMS_PAGE. */
  type: Maybe<UrlRewriteEntityTypeEnum>;
};

export type EnumDescription = {
  __typename: 'EnumDescription';
  description: Scalars['String']['output'];
  name: Scalars['String']['output'];
  values: Maybe<Array<Maybe<EnumValue>>>;
};

export type EnumValue = {
  __typename: 'EnumValue';
  title: Scalars['String']['output'];
  value: Scalars['String']['output'];
};

export type ErrorInterface = {
  /** The returned error message. */
  message: Scalars['String']['output'];
};

/** Lists the exchange rate. */
export type ExchangeRate = {
  __typename: 'ExchangeRate';
  /** Specifies the store’s default currency to exchange to. */
  currencyTo: Maybe<Scalars['String']['output']>;
  /** The exchange rate for the store’s default currency. */
  rate: Maybe<Scalars['Float']['output']>;
};

export type ExternalEntity = {
  __typename: 'ExternalEntity';
  description: Scalars['String']['output'];
  field: Scalars['String']['output'];
  isArray: Maybe<Scalars['Boolean']['output']>;
  isNullable: Maybe<Scalars['Boolean']['output']>;
  keys: EntityKeys;
  localId: Scalars['String']['output'];
  type: Scalars['String']['output'];
};

export type ExternalEntityConfig = EntityConfig & {
  __typename: 'ExternalEntityConfig';
  description: Scalars['String']['output'];
  doesImplement: Array<Scalars['String']['output']>;
  enums: Array<AdminEnum>;
  externals: Maybe<Array<ExternalEntity>>;
  fields: Array<EntityFieldConfig>;
  id: Scalars['ID']['output'];
  isImplementedBy: Array<Scalars['String']['output']>;
  isInterface: Maybe<Scalars['Boolean']['output']>;
  keys: EntityKeys;
  names: EntityNames;
  relationships: Maybe<Array<EntityRelationshipConfig>>;
  rowLayout: AdminLayout;
  type: Scalars['String']['output'];
};

export enum FacetTypeEnum {
  /** Select */
  SELECT = 'SELECT',
  /** Slider */
  SLIDER = 'SLIDER'
}

export type File = {
  __typename: 'File';
  destination: Scalars['String']['output'];
  encoding: Scalars['String']['output'];
  fieldname: Scalars['String']['output'];
  filename: Scalars['String']['output'];
  mimetype: Scalars['String']['output'];
  originalname: Scalars['String']['output'];
  path: Scalars['String']['output'];
  size: Scalars['Int']['output'];
};

export type FileInput = {
  destination: Scalars['String']['input'];
  encoding: Scalars['String']['input'];
  fieldname: Scalars['String']['input'];
  filename: Scalars['String']['input'];
  mimetype: Scalars['String']['input'];
  originalname: Scalars['String']['input'];
  path: Scalars['String']['input'];
  size: Scalars['Int']['input'];
};

/** Defines a filter that matches the input exactly. */
export type FilterEqualTypeInput = {
  /** Use this attribute to exactly match the specified string. For example, to filter on a specific category ID, specify a value such as `5`. */
  eq?: InputMaybe<Scalars['String']['input']>;
  /** Use this attribute to filter on an array of values. For example, to filter on category IDs 4, 5, and 6, specify a value of `["4", "5", "6"]`. */
  in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

/** Defines a filter that performs a fuzzy search. */
export type FilterMatchTypeInput = {
  /** Use this attribute to fuzzy match the specified string. For example, to filter on a specific SKU, specify a value such as `24-MB01`. */
  match?: InputMaybe<Scalars['String']['input']>;
};

/** Defines a filter that matches a range of values, such as prices or dates. */
export type FilterRangeTypeInput = {
  /** Use this attribute to specify the lowest possible value in the range. */
  from?: InputMaybe<Scalars['String']['input']>;
  /** Use this attribute to specify the highest possible value in the range. */
  to?: InputMaybe<Scalars['String']['input']>;
};

export type FilterRecommendationInterface = {
  /** Filter attribute code. */
  attributeCode: Maybe<Scalars['String']['output']>;
  /** Filter label. */
  label: Maybe<Scalars['String']['output']>;
  /** Filter value. */
  value: Scalars['String']['output'];
};

/** Recommendations of filters for a given category and a given user. */
export type FilterRecommendations = {
  __typename: 'FilterRecommendations';
  /** An array of filters recommended for the user. */
  items: Maybe<Array<Maybe<FilterRecommendationInterface>>>;
};

/** Defines a filter for an input string. */
export type FilterStringTypeInput = {
  /** Filters items that are exactly the same as the specified string. */
  eq?: InputMaybe<Scalars['String']['input']>;
  /** Filters items that are exactly the same as entries specified in an array of strings. */
  in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  /** Defines a filter that performs a fuzzy search using the specified string. */
  match?: InputMaybe<Scalars['String']['input']>;
};

/** Defines the comparison operators that can be used in a filter. */
export type FilterTypeInput = {
  /** Equals. */
  eq?: InputMaybe<Scalars['String']['input']>;
  finset?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  /** From. Must be used with the `to` field. */
  from?: InputMaybe<Scalars['String']['input']>;
  /** Greater than. */
  gt?: InputMaybe<Scalars['String']['input']>;
  /** Greater than or equal to. */
  gteq?: InputMaybe<Scalars['String']['input']>;
  /** In. The value can contain a set of comma-separated values. */
  in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  /** Like. The specified value can contain % (percent signs) to allow matching of 0 or more characters. */
  like?: InputMaybe<Scalars['String']['input']>;
  /** Less than. */
  lt?: InputMaybe<Scalars['String']['input']>;
  /** Less than or equal to. */
  lteq?: InputMaybe<Scalars['String']['input']>;
  /** More than or equal to. */
  moreq?: InputMaybe<Scalars['String']['input']>;
  /** Not equal to. */
  neq?: InputMaybe<Scalars['String']['input']>;
  /** Not in. The value can contain a set of comma-separated values. */
  nin?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  /** Not null. */
  notnull?: InputMaybe<Scalars['String']['input']>;
  /** Is null. */
  null?: InputMaybe<Scalars['String']['input']>;
  /** To. Must be used with the `from` field. */
  to?: InputMaybe<Scalars['String']['input']>;
};

export type FrequentlyBoughtProduct = {
  __typename: 'FrequentlyBoughtProduct';
  /** The order frequency in the period provided */
  frequency: Scalars['Float']['output'];
  /** The product ID */
  id: Scalars['String']['output'];
  /** The order date */
  lastOrderDate: Scalars['String']['output'];
  nexusNoCacheDummyPropert: Maybe<Scalars['String']['output']>;
  /** The product */
  product: ProductInterface;
};

export type FrequentlyBoughtResult = {
  __typename: 'FrequentlyBoughtResult';
  /** The frequently bought products */
  items: Maybe<Array<Maybe<FrequentlyBoughtProduct>>>;
  /** The pagination information */
  pageInfo: Maybe<SearchResultPageInfo>;
  /** The total number of frequently bought products */
  totalCount: Maybe<Scalars['Int']['output']>;
};

/** Identifies which customer requires remote shopping assistance. */
export type GenerateCustomerTokenAsAdminInput = {
  /** The email address of the customer requesting remote shopping assistance. */
  customerEmail: Scalars['String']['input'];
};

/** Contains the generated customer token. */
export type GenerateCustomerTokenAsAdminOutput = {
  __typename: 'GenerateCustomerTokenAsAdminOutput';
  /** The generated customer token. */
  customerToken: Scalars['String']['output'];
};

export type GetCustomerNotificationOptionsOutput = {
  __typename: 'GetCustomerNotificationOptionsOutput';
  /** Send email when customer earns reward points */
  amrewardsEarningNotification: Maybe<Scalars['Boolean']['output']>;
  /** Send email when customer reward points are about to expire */
  amrewardsExpireNotification: Maybe<Scalars['Boolean']['output']>;
};

export type GetExpiringDataArrayOutput = {
  __typename: 'GetExpiringDataArrayOutput';
  /** Expiring data array */
  expiringData: Maybe<Array<Maybe<GetExpiringDataOutput>>>;
};

export type GetExpiringDataOutput = {
  __typename: 'GetExpiringDataOutput';
  /** Expiring amount */
  amount: Maybe<Scalars['Float']['output']>;
  /** Expiring date */
  expirationDate: Maybe<Scalars['String']['output']>;
};

/** Gets the payment SDK URLs and values */
export type GetPaymentSdkOutput = {
  __typename: 'GetPaymentSdkOutput';
  /** The payment SDK parameters */
  sdkParams: Maybe<Array<Maybe<PaymentSdkParamsItem>>>;
};

export type GetRewardsPropertyOutput = {
  __typename: 'GetRewardsPropertyOutput';
  /** Currency currency code */
  currentCurrencyCode: Maybe<Scalars['String']['output']>;
  /** Currency points rate */
  pointsRate: Maybe<Scalars['Float']['output']>;
};

export type GetRewardsStatisticOutput = {
  __typename: 'GetRewardsStatisticOutput';
  /** Current customer balance */
  balance: Maybe<Scalars['String']['output']>;
  /** Expired */
  expired: Maybe<Scalars['String']['output']>;
  /** Total spent */
  redeemed: Maybe<Scalars['String']['output']>;
  /** Total earned */
  rewarded: Maybe<Scalars['String']['output']>;
};

export type GiftCardPriceValue = {
  __typename: 'GiftCardPriceValue';
  currency: Maybe<CurrencyEnum>;
  default: Maybe<Scalars['Float']['output']>;
  value: Maybe<Scalars['Float']['output']>;
};

/** Contains the text of a gift message, its sender, and recipient */
export type GiftMessage = {
  __typename: 'GiftMessage';
  /** Sender name */
  from: Scalars['String']['output'];
  /** Gift message text */
  message: Scalars['String']['output'];
  /** Recipient name */
  to: Scalars['String']['output'];
};

/** Contains the text of a gift message, its sender, and recipient */
export type GiftMessageInput = {
  /** Sender name */
  from: Scalars['String']['input'];
  /** Gift message text */
  message: Scalars['String']['input'];
  /** Recipient name */
  to: Scalars['String']['input'];
};

export type GooglePayButtonStyles = {
  __typename: 'GooglePayButtonStyles';
  /** The button color */
  color: Maybe<Scalars['String']['output']>;
  /** The button height in pixels */
  height: Maybe<Scalars['Int']['output']>;
  /** The button type */
  type: Maybe<Scalars['String']['output']>;
};

export type GooglePayConfig = PaymentConfigItem & {
  __typename: 'GooglePayConfig';
  /** The styles for the GooglePay Button configuration */
  buttonStyles: Maybe<GooglePayButtonStyles>;
  /** The payment method code as defined in the payment gateway */
  code: Maybe<Scalars['String']['output']>;
  /** Indicates whether the payment method is displayed */
  isVisible: Maybe<Scalars['Boolean']['output']>;
  /** Defines the payment intent (Authorize or Capture */
  paymentIntent: Maybe<Scalars['String']['output']>;
  /** The payment source for the payment method */
  paymentSource: Maybe<Scalars['String']['output']>;
  /** The PayPal parameters required to load the JS SDK */
  sdkParams: Maybe<Array<Maybe<SdkParams>>>;
  /** The relative order the payment method is displayed on the checkout page */
  sortOrder: Maybe<Scalars['String']['output']>;
  /** The name displayed for the payment method */
  title: Maybe<Scalars['String']['output']>;
};

/** Google Pay inputs */
export type GooglePayMethodInput = {
  /** The payment source for the payment method */
  paymentSource?: InputMaybe<Scalars['String']['input']>;
  /** The payment services order ID */
  paymentsOrderId?: InputMaybe<Scalars['String']['input']>;
  /** PayPal order ID */
  paypalOrderId?: InputMaybe<Scalars['String']['input']>;
};

/** Defines a grouped product, which consists of simple standalone products that are presented as a group. */
export type GroupedProduct = PhysicalProductInterface & ProductInterface & RoutableInterface & {
  __typename: 'GroupedProduct';
  /** @deprecated Use the `custom_attributes` field instead. */
  age: Maybe<Scalars['String']['output']>;
  /** Information about guest highlight points available by product. */
  amastyRewardsGuestHighlights: Maybe<HighlightOutput>;
  /** Information about highlight points available by product. */
  amastyRewardsHighlights: Maybe<HighlightOutput>;
  /**
   * The attribute set assigned to the product.
   * @deprecated The field should not be used on the storefront.
   */
  attributeSetId: Maybe<Scalars['Int']['output']>;
  /** Returns all assigned product attributes and their values */
  attributes: Array<Maybe<ProductAttributes>>;
  /** Returns the brand of the product */
  brand: Maybe<Brand>;
  cacheBuster: Maybe<Scalars['String']['output']>;
  /** The relative canonical URL. This value is returned only if the system setting 'Use Canonical Link Meta Tag For Products' is enabled. */
  canonicalUrl: Maybe<Scalars['String']['output']>;
  cartItemUid: Maybe<Scalars['String']['output']>;
  /** The categories assigned to a product. */
  categories: Maybe<Array<Maybe<CategoryInterface>>>;
  /** @deprecated Use the `custom_attributes` field instead. */
  colour: Maybe<Scalars['Int']['output']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  concentration: Maybe<Scalars['Int']['output']>;
  /** The product's country of origin. */
  countryOfManufacture: Maybe<Scalars['String']['output']>;
  coverImage: Image;
  /**
   * Timestamp indicating when the product was created.
   * @deprecated The field should not be used on the storefront.
   */
  createdAt: Maybe<Scalars['String']['output']>;
  /** Crosssell Products */
  crosssellProducts: Maybe<Array<Maybe<ProductInterface>>>;
  /** @deprecated Use the `custom_attributes` field instead. */
  department: Maybe<Scalars['String']['output']>;
  /** Detailed information about the product. The value can include simple HTML tags. */
  description: Maybe<ComplexTextValue>;
  /** @deprecated Use the `custom_attributes` field instead. */
  flavour: Maybe<Scalars['Int']['output']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  fragrance: Maybe<Scalars['Int']['output']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  gender: Maybe<Scalars['String']['output']>;
  /** Indicates whether a gift message is available. */
  giftMessageAvailable: Maybe<Scalars['String']['output']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  hairType: Maybe<Scalars['String']['output']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  height: Maybe<Scalars['Float']['output']>;
  /**
   * The ID number assigned to the product.
   * @deprecated Use the `uid` field instead.
   */
  id: Maybe<Scalars['Int']['output']>;
  /** The relative path to the main image on the product page. */
  image: Maybe<ProductImage>;
  imageGallery: Array<Image>;
  ingredientList: Maybe<Array<Maybe<ProductIngredient>>>;
  /** @deprecated Use the `custom_attributes` field instead. */
  ingredients: Maybe<Scalars['String']['output']>;
  isInWishlist: Scalars['Boolean']['output'];
  /** An array containing grouped product items. */
  items: Maybe<Array<Maybe<GroupedProductItem>>>;
  /** List of product labels associated to the product */
  labels: Array<Maybe<LabelItem>>;
  /** @deprecated Use the `custom_attributes` field instead. */
  length: Maybe<Scalars['Float']['output']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  licensingStatus: Maybe<Scalars['String']['output']>;
  /**
   * A number representing the product's manufacturer.
   * @deprecated Use the `custom_attributes` field instead.
   */
  manufacturer: Maybe<Scalars['Int']['output']>;
  /** An array of media gallery objects. */
  mediaGallery: Maybe<Array<Maybe<MediaGalleryInterface>>>;
  /**
   * An array of MediaGalleryEntry objects.
   * @deprecated Use `media_gallery` instead.
   */
  mediaGalleryEntries: Maybe<Array<Maybe<MediaGalleryEntry>>>;
  /** An array of media gallery objects. */
  mediaGalleryV2: Maybe<Array<Maybe<MediaGalleryV2Interface>>>;
  /** A brief overview of the product for search results listings, maximum 255 characters. */
  metaDescription: Maybe<Scalars['String']['output']>;
  /** A comma-separated list of keywords that are visible only to search engines. */
  metaKeyword: Maybe<Scalars['String']['output']>;
  /** A string that is displayed in the title bar and tab of the browser and in search results lists. */
  metaTitle: Maybe<Scalars['String']['output']>;
  /** The product name. Customers use this name to identify the product. */
  name: Maybe<Scalars['String']['output']>;
  /** The beginning date for new product listings, and determines if the product is featured as a new product. */
  newFromDate: Maybe<Scalars['String']['output']>;
  /** The end date for new product listings. */
  newToDate: Maybe<Scalars['String']['output']>;
  /** Product stock only x left count */
  onlyXLeftInStock: Maybe<Scalars['Float']['output']>;
  /** If the product has multiple options, determines where they appear on the product page. */
  optionsContainer: Maybe<Scalars['String']['output']>;
  /**
   * Indicates the price of an item.
   * @deprecated Use `price_range` for product price information.
   */
  price: Maybe<ProductPrices>;
  /** The range of prices for the product */
  priceRange: PriceRange;
  /** An array of `TierPrice` objects. */
  priceTiers: Maybe<Array<Maybe<TierPrice>>>;
  /** An array of `ProductLinks` objects. */
  productLinks: Maybe<Array<Maybe<ProductLinksInterface>>>;
  productType: ProductTypeEnum;
  /** @deprecated Use the `custom_attributes` field instead. */
  quantity: Maybe<Scalars['Int']['output']>;
  quantityInCart: Scalars['Int']['output'];
  /** The rating summary for the product */
  ratingSummary: RatingSummary;
  /** Contains 0 when there is no redirect error. A value of 301 indicates the URL of the requested resource has been changed permanently, while a value of 302 indicates a temporary redirect. */
  redirectCode: Scalars['Int']['output'];
  /** Related Products */
  relatedProducts: Maybe<Array<Maybe<ProductInterface>>>;
  /** The internal relative URL. If the specified URL is a redirect, the query returns the redirected URL, not the original. */
  relativeUrl: Maybe<Scalars['String']['output']>;
  /** The total count of all the reviews given to the product. */
  reviewCount: Scalars['Int']['output'];
  /** The list of products reviews */
  reviews: ProductReviews;
  /** A short description of the product. Its use depends on the theme. */
  shortDescription: Maybe<ComplexTextValue>;
  /** @deprecated Use the `custom_attributes` field instead. */
  size: Maybe<Scalars['Int']['output']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  skinConcern: Maybe<Scalars['String']['output']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  skinType: Maybe<Scalars['String']['output']>;
  /** A number or code assigned to a product to identify the product, options, price, and manufacturer. */
  sku: Maybe<Scalars['String']['output']>;
  /** The relative path to the small image, which is used on catalog pages. */
  smallImage: Maybe<ProductImage>;
  /**
   * The beginning date that a product has a special price.
   * @deprecated The field should not be used on the storefront.
   */
  specialFromDate: Maybe<Scalars['String']['output']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  specialIcons: Maybe<Scalars['String']['output']>;
  /** The discounted price of the product. */
  specialPrice: Maybe<Scalars['Float']['output']>;
  /** The end date for a product with a special price. */
  specialToDate: Maybe<Scalars['String']['output']>;
  stockInfo: Maybe<StockInfo>;
  /** Stock status of the product */
  stockStatus: Maybe<ProductStockStatus>;
  /** The file name of a swatch image. */
  swatchImage: Maybe<Scalars['String']['output']>;
  /** Returns all assigned product tags and their values */
  tags: Array<Maybe<ProductTags>>;
  /** The relative path to the product's thumbnail image. */
  thumbnail: Maybe<ProductImage>;
  /**
   * The price when tier pricing is in effect and the items purchased threshold has been reached.
   * @deprecated Use `price_tiers` for product tier price information.
   */
  tierPrice: Maybe<Scalars['Float']['output']>;
  /**
   * An array of ProductTierPrices objects.
   * @deprecated Use `price_tiers` for product tier price information.
   */
  tierPrices: Maybe<Array<Maybe<ProductTierPrices>>>;
  /** One of PRODUCT, CATEGORY, or CMS_PAGE. */
  type: Maybe<UrlRewriteEntityTypeEnum>;
  /**
   * One of simple, virtual, bundle, downloadable, grouped, or configurable.
   * @deprecated Use `__typename` instead.
   */
  typeId: Maybe<Scalars['String']['output']>;
  /** The unique ID for a `ProductInterface` object. */
  uid: Scalars['ID']['output'];
  /**
   * Timestamp indicating when the product was updated.
   * @deprecated The field should not be used on the storefront.
   */
  updatedAt: Maybe<Scalars['String']['output']>;
  /** Upsell Products */
  upsellProducts: Maybe<Array<Maybe<ProductInterface>>>;
  /** The part of the URL that identifies the product */
  urlKey: Maybe<Scalars['String']['output']>;
  /** @deprecated Use product's `canonical_url` or url rewrites instead */
  urlPath: Maybe<Scalars['String']['output']>;
  /** URL rewrites list */
  urlRewrites: Maybe<Array<Maybe<UrlRewrite>>>;
  /** The part of the product URL that is appended after the url key */
  urlSuffix: Maybe<Scalars['String']['output']>;
  valueAttributes: Maybe<Array<Maybe<ProductValue>>>;
  /** @deprecated Use the `custom_attributes` field instead. */
  valueTraits: Maybe<Scalars['String']['output']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  valuesDiet: Maybe<Scalars['String']['output']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  valuesEnvironmental: Maybe<Scalars['String']['output']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  valuesIngredient: Maybe<Scalars['String']['output']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  valuesSocial: Maybe<Scalars['String']['output']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  volumeWeight: Maybe<Scalars['Float']['output']>;
  /**
   * An array of websites in which the product is available.
   * @deprecated The field should not be used on the storefront.
   */
  websites: Maybe<Array<Maybe<Website>>>;
  /** The weight of the item, in units defined by the store. */
  weight: Maybe<Scalars['Float']['output']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  width: Maybe<Scalars['Float']['output']>;
  wishlistItemId: Maybe<Scalars['Int']['output']>;
};


/** Defines a grouped product, which consists of simple standalone products that are presented as a group. */
export type GroupedProductamastyRewardsHighlightsArgs = {
  attributes?: InputMaybe<Scalars['String']['input']>;
};


/** Defines a grouped product, which consists of simple standalone products that are presented as a group. */
export type GroupedProductlabelsArgs = {
  order?: InputMaybe<LabelOrderInput>;
};


/** Defines a grouped product, which consists of simple standalone products that are presented as a group. */
export type GroupedProductreviewsArgs = {
  currentPage?: InputMaybe<Scalars['Int']['input']>;
  filters?: InputMaybe<ReviewFilterInput>;
  pageSize?: InputMaybe<Scalars['Int']['input']>;
  sort?: InputMaybe<ReviewSortInput>;
};

/** Contains information about an individual grouped product item. */
export type GroupedProductItem = {
  __typename: 'GroupedProductItem';
  /** The relative position of this item compared to the other group items. */
  position: Maybe<Scalars['Int']['output']>;
  /** Details about this product option. */
  product: Maybe<ProductInterface>;
  /** The quantity of this grouped product item. */
  qty: Maybe<Scalars['Float']['output']>;
};

/** A grouped product wish list item. */
export type GroupedProductWishlistItem = WishlistItemInterface & {
  __typename: 'GroupedProductWishlistItem';
  /** The date and time the item was added to the wish list. */
  addedAt: Scalars['String']['output'];
  /** Custom options selected for the wish list item. */
  customizableOptions: Array<Maybe<SelectedCustomizableOption>>;
  /** The description of the item. */
  description: Maybe<Scalars['String']['output']>;
  /** The unique ID for a `WishlistItemInterface` object. */
  id: Scalars['ID']['output'];
  /** Product details of the wish list item. */
  product: Maybe<ProductInterface>;
  /** The quantity of this wish list item. */
  quantity: Scalars['Float']['output'];
};

export type HighlightOutput = {
  __typename: 'HighlightOutput';
  /** Capture custom color. */
  captionColor: Maybe<Scalars['String']['output']>;
  /** Amout of points with description. */
  captionText: Maybe<Scalars['String']['output']>;
  /** Is capture visible on storefront. */
  visible: Maybe<Scalars['Boolean']['output']>;
};

export type HighlightQuery = {
  __typename: 'HighlightQuery';
  /** Information about highlight points available by cart content. */
  cart: Maybe<HighlightOutput>;
  /** Information about highlight points available by category. */
  category: Maybe<HighlightOutput>;
};


export type HighlightQuerycategoryArgs = {
  attributes?: InputMaybe<Scalars['String']['input']>;
  productId: Scalars['Int']['input'];
};

export type HistoryItem = {
  __typename: 'HistoryItem';
  /** Type of transaction or accrual source. */
  action: Maybe<Scalars['String']['output']>;
  /** The date of transaction. */
  actionDate: Maybe<Scalars['String']['output']>;
  /** Name of admin who made transaction. */
  adminName: Maybe<Scalars['String']['output']>;
  /** Transaction amount of reward points. */
  amount: Maybe<Scalars['Float']['output']>;
  /** Comment of reward points. */
  comment: Maybe<Scalars['String']['output']>;
  /** Expiration days left. */
  daysLeft: Maybe<Scalars['String']['output']>;
  /** Reward points expiration. Null if spending action or points are lifelong. */
  expirationDate: Maybe<Scalars['String']['output']>;
  /** Expiring amount. */
  expiringAmount: Maybe<Scalars['Float']['output']>;
  /** Customer reward point balance after transaction done. */
  pointsLeft: Maybe<Scalars['Float']['output']>;
  /** Visible comment for customer. */
  visibleForCustomer: Maybe<Scalars['Boolean']['output']>;
};

export type HistoryOutput = {
  __typename: 'HistoryOutput';
  /** History records. Count must be limited. */
  items: Maybe<Array<Maybe<HistoryItem>>>;
  /** Total amount of history records. */
  totalCount: Maybe<Scalars['Int']['output']>;
};


export type HistoryOutputitemsArgs = {
  currentPage?: InputMaybe<Scalars['Int']['input']>;
  pageSize?: InputMaybe<Scalars['Int']['input']>;
};

export type HostedFieldsConfig = PaymentConfigItem & {
  __typename: 'HostedFieldsConfig';
  /** Vault payment method code */
  ccVaultCode: Maybe<Scalars['String']['output']>;
  /** The payment method code as defined in the payment gateway */
  code: Maybe<Scalars['String']['output']>;
  /** Card vault enabled */
  isVaultEnabled: Maybe<Scalars['Boolean']['output']>;
  /** Indicates whether the payment method is displayed */
  isVisible: Maybe<Scalars['Boolean']['output']>;
  /** Defines the payment intent (Authorize or Capture */
  paymentIntent: Maybe<Scalars['String']['output']>;
  /** The payment source for the payment method */
  paymentSource: Maybe<Scalars['String']['output']>;
  /** Card and bin details required */
  requiresCardDetails: Maybe<Scalars['Boolean']['output']>;
  /** The PayPal parameters required to load the JS SDK */
  sdkParams: Maybe<Array<Maybe<SdkParams>>>;
  /** The relative order the payment method is displayed on the checkout page */
  sortOrder: Maybe<Scalars['String']['output']>;
  /** 3DS mode */
  threeDs: Maybe<Scalars['Boolean']['output']>;
  /** The name displayed for the payment method */
  title: Maybe<Scalars['String']['output']>;
};

/** Hosted Fields payment inputs */
export type HostedFieldsInput = {
  /** Card bin number */
  cardBin?: InputMaybe<Scalars['String']['input']>;
  /** Expiration month of the card */
  cardExpiryMonth?: InputMaybe<Scalars['String']['input']>;
  /** Expiration year of the card */
  cardExpiryYear?: InputMaybe<Scalars['String']['input']>;
  /** Last four digits of the card */
  cardLast4?: InputMaybe<Scalars['String']['input']>;
  /** Name on the card */
  holderName?: InputMaybe<Scalars['String']['input']>;
  /** Indicates whether details about the shopper's credit/debit card should be tokenized for later usage. Required only if Vault is enabled for the Payment Services payment integration. */
  isActivePaymentTokenEnabler?: InputMaybe<Scalars['Boolean']['input']>;
  /** The payment source for the payment method */
  paymentSource?: InputMaybe<Scalars['String']['input']>;
  /** The payment services order ID */
  paymentsOrderId?: InputMaybe<Scalars['String']['input']>;
  /** PayPal order ID */
  paypalOrderId?: InputMaybe<Scalars['String']['input']>;
};

/** Contains a set of relative URLs that PayPal uses in response to various actions during the authorization process. Magento prepends the base URL to this value to create a full URL. For example, if the full URL is https://www.example.com/path/to/page.html, the relative URL is path/to/page.html. Use this input for Payments Pro Hosted Solution payment method. */
export type HostedProInput = {
  /** The relative URL of the page that PayPal redirects to when the buyer cancels the transaction in order to choose a different payment method. For example, if the full URL to this page is https://www.example.com/paypal/action/cancel.html, the relative URL is paypal/action/cancel.html. */
  cancelUrl: Scalars['String']['input'];
  /** The relative URL of the final confirmation page that PayPal redirects to upon payment success. For example, if the full URL to this page is https://www.example.com/paypal/action/return.html, the relative URL is paypal/action/return.html. */
  returnUrl: Scalars['String']['input'];
};

/** Contains the secure URL used for the Payments Pro Hosted Solution payment method. */
export type HostedProUrl = {
  __typename: 'HostedProUrl';
  /** The secure URL generated by PayPal. */
  secureFormUrl: Maybe<Scalars['String']['output']>;
};

/** Contains the required input to request the secure URL for Payments Pro Hosted Solution payment. */
export type HostedProUrlInput = {
  /** The unique ID that identifies the shopper's cart. */
  cartId: Scalars['String']['input'];
};

/** Contains target path parameters. */
export type HttpQueryParameter = {
  __typename: 'HttpQueryParameter';
  /** A parameter name. */
  name: Maybe<Scalars['String']['output']>;
  /** A parameter value. */
  value: Maybe<Scalars['String']['output']>;
};

export type IDContainsFilterInput = {
  contains?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  containsGreaterThan?: InputMaybe<Scalars['ID']['input']>;
  containsLessThan?: InputMaybe<Scalars['ID']['input']>;
  doesNotContain?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
};

export type IDEqualsInFilterInput = {
  /** ID does not equal */
  doesNotEqual?: InputMaybe<Scalars['ID']['input']>;
  /** ID equals */
  equals?: InputMaybe<Scalars['ID']['input']>;
  /** ID is in */
  in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** ID is not in */
  notIn?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
};

export type IDEqualsInRangeFilterInput = {
  in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  notIn?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
};

export type Image = {
  __typename: 'Image';
  /** The alt description of the Image */
  alt: Maybe<Scalars['String']['output']>;
  /** The cache keys for this object */
  cacheKeys: Maybe<Array<CacheKey>>;
  createdAt: Scalars['DateTime']['output'];
  displayIndex: Maybe<Scalars['Int']['output']>;
  /** The file extension */
  extension: Scalars['String']['output'];
  extra: Maybe<Scalars['JSONObject']['output']>;
  /** The filename */
  filename: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  identifier: Maybe<Scalars['String']['output']>;
  imageInfo: ImageInfo;
  /** The media items location */
  location: Scalars['String']['output'];
  /** The file mimetype */
  mimetype: Scalars['String']['output'];
  /** The media items name */
  name: Scalars['String']['output'];
  /** The storage type local or S3 */
  storageType: Scalars['String']['output'];
  updatedAt: Scalars['DateTime']['output'];
  url: Maybe<Scalars['URI']['output']>;
};


export type ImageurlArgs = {
  encoding?: InputMaybe<ImageEncodingEnum>;
  height?: InputMaybe<Scalars['Int']['input']>;
  scale?: InputMaybe<ImageScaleEnum>;
  transforms?: InputMaybe<Array<InputMaybe<ImageTransform>>>;
  width?: InputMaybe<Scalars['Int']['input']>;
};

export type ImageAddInput = {
  /** The alt description of the Image */
  alt?: InputMaybe<Scalars['String']['input']>;
  /** The file input for upload */
  file: FileInput;
  identifier?: InputMaybe<Scalars['String']['input']>;
  /** The media items name */
  name: Scalars['String']['input'];
};

export enum ImageEncodingEnum {
  /** JPEG */
  JPEG = 'JPEG',
  /** PNG */
  PNG = 'PNG',
  /** SVG */
  SVG = 'SVG',
  /** WebP */
  WEBP = 'WEBP'
}

export type ImageFilterInput = {
  createdAt?: InputMaybe<DateTimeEqualsInRangeFilterInput>;
  id?: InputMaybe<IDEqualsInFilterInput>;
  identifier?: InputMaybe<StringEqualsInRangeFilterInput>;
  search?: InputMaybe<Scalars['String']['input']>;
  updatedAt?: InputMaybe<DateTimeEqualsInRangeFilterInput>;
};

export type ImageInfo = {
  __typename: 'ImageInfo';
  /** Image height */
  height: Scalars['Int']['output'];
  id: Scalars['ID']['output'];
  /** Image type */
  type: Scalars['String']['output'];
  /** Image width */
  width: Scalars['Int']['output'];
};

export type ImageList = {
  __typename: 'ImageList';
  limit: Scalars['Int']['output'];
  list: Array<Image>;
  skip: Scalars['Int']['output'];
  total: Scalars['Int']['output'];
};

export type ImageOrderInput = {
  createdAt?: InputMaybe<OrderDirectionEnum>;
  displayIndex?: InputMaybe<OrderDirectionEnum>;
  id?: InputMaybe<OrderDirectionEnum>;
  name?: InputMaybe<OrderDirectionEnum>;
  updatedAt?: InputMaybe<OrderDirectionEnum>;
};

export enum ImageScaleEnum {
  /** Scale to Contain */
  CONTAIN = 'CONTAIN',
  /** Scale to Cover */
  COVER = 'COVER',
  /** Scale to Fit */
  FIT = 'FIT'
}

export type ImageSwatchData = SwatchDataInterface & {
  __typename: 'ImageSwatchData';
  /** The URL assigned to the thumbnail of the swatch image. */
  thumbnail: Maybe<Scalars['String']['output']>;
  /** The value can be represented as color (HEX code), image link, or text. */
  value: Maybe<Scalars['String']['output']>;
};

export type ImageTransform = {
  /** Image transform type */
  type: ImageTransformTypeEnum;
  /** Image transform value */
  value?: InputMaybe<Scalars['Float']['input']>;
};

export enum ImageTransformTypeEnum {
  /** Blur */
  BLUR = 'BLUR',
  /** CMYK Color Space */
  CMYK = 'CMYK',
  /** Gaussian Blur */
  GAUSSIAN_BLUR = 'GAUSSIAN_BLUR',
  /** Grey Scale */
  GREY_SCALE = 'GREY_SCALE',
  /** Opacity */
  OPACITY = 'OPACITY',
  /** Pixelate */
  PIXELATE = 'PIXELATE',
  /** Posterize */
  POSTERIZE = 'POSTERIZE',
  /** Sepia */
  SEPIA = 'SEPIA'
}

export type ImageUpdateInput = {
  /** The alt description of the Image */
  alt?: InputMaybe<Scalars['String']['input']>;
  identifier?: InputMaybe<Scalars['String']['input']>;
  /** The media items name */
  name: Scalars['String']['input'];
};

export type ImageUpdates = {
  __typename: 'ImageUpdates';
  id: Scalars['ID']['output'];
  item: Maybe<Image>;
  type: SimpleUpdateTypeEnum;
};

/** List of templates/filters applied to customer attribute input. */
export enum InputFilterEnum {
  /**
   * Forces attribute input to follow the date format.
   * @deprecated
   */
  DATE = 'DATE',
  /**
   * Escape HTML Entities.
   * @deprecated
   */
  ESCAPEHTML = 'ESCAPEHTML',
  /**
   * There are no templates or filters to be applied.
   * @deprecated
   */
  NONE = 'NONE',
  /**
   * Strip HTML Tags.
   * @deprecated
   */
  STRIPTAGS = 'STRIPTAGS',
  /**
   * Strip whitespace (or other characters) from the beginning and end of the input.
   * @deprecated
   */
  TRIM = 'TRIM'
}

/** Contains an error message when an internal error occurred. */
export type InternalError = ErrorInterface & {
  __typename: 'InternalError';
  /** The returned error message. */
  message: Scalars['String']['output'];
};

/** Contains invoice details. */
export type Invoice = {
  __typename: 'Invoice';
  /** Comments on the invoice. */
  comments: Maybe<Array<Maybe<SalesCommentItem>>>;
  /** The unique ID for a `Invoice` object. */
  id: Scalars['ID']['output'];
  /** Invoiced product details. */
  items: Maybe<Array<Maybe<InvoiceItemInterface>>>;
  /** Sequential invoice number. */
  number: Scalars['String']['output'];
  /** Invoice total amount details. */
  total: Maybe<InvoiceTotal>;
};

export type InvoiceItem = InvoiceItemInterface & {
  __typename: 'InvoiceItem';
  /** Information about the final discount amount for the base product, including discounts on options. */
  discounts: Maybe<Array<Maybe<Discount>>>;
  /** The unique ID for an `InvoiceItemInterface` object. */
  id: Scalars['ID']['output'];
  /** Details about an individual order item. */
  orderItem: Maybe<OrderItemInterface>;
  /** The name of the base product. */
  productName: Maybe<Scalars['String']['output']>;
  /** The sale price for the base product including selected options. */
  productSalePrice: Money;
  /** The SKU of the base product. */
  productSku: Scalars['String']['output'];
  /** The number of invoiced items. */
  quantityInvoiced: Maybe<Scalars['Float']['output']>;
};

/** Contains detailes about invoiced items. */
export type InvoiceItemInterface = {
  /** Information about the final discount amount for the base product, including discounts on options. */
  discounts: Maybe<Array<Maybe<Discount>>>;
  /** The unique ID for an `InvoiceItemInterface` object. */
  id: Scalars['ID']['output'];
  /** Details about an individual order item. */
  orderItem: Maybe<OrderItemInterface>;
  /** The name of the base product. */
  productName: Maybe<Scalars['String']['output']>;
  /** The sale price for the base product including selected options. */
  productSalePrice: Money;
  /** The SKU of the base product. */
  productSku: Scalars['String']['output'];
  /** The number of invoiced items. */
  quantityInvoiced: Maybe<Scalars['Float']['output']>;
};

/** Contains price details from an invoice. */
export type InvoiceTotal = {
  __typename: 'InvoiceTotal';
  /** The final base grand total amount in the base currency. */
  baseGrandTotal: Money;
  /** The applied discounts to the invoice. */
  discounts: Maybe<Array<Maybe<Discount>>>;
  /** The final total amount, including shipping, discounts, and taxes. */
  grandTotal: Money;
  /** Details about the shipping and handling costs for the invoice. */
  shippingHandling: Maybe<ShippingHandling>;
  /** The subtotal of the invoice, excluding shipping, discounts, and taxes. */
  subtotal: Money;
  /** The invoice tax details. */
  taxes: Maybe<Array<Maybe<TaxItem>>>;
  /** The shipping amount for the invoice. */
  totalShipping: Money;
  /** The amount of tax applied to the invoice. */
  totalTax: Money;
};

/** Contains the result of the `isEmailAvailable` query. */
export type IsEmailAvailableOutput = {
  __typename: 'IsEmailAvailableOutput';
  /** Indicates whether the specified email address can be used to create a customer. */
  isEmailAvailable: Maybe<Scalars['Boolean']['output']>;
};

/** A list of options of the selected bundle product. */
export type ItemSelectedBundleOption = {
  __typename: 'ItemSelectedBundleOption';
  /**
   * The unique ID for a `ItemSelectedBundleOption` object.
   * @deprecated Use `uid` instead.
   */
  id: Scalars['ID']['output'];
  /** The label of the option. */
  label: Scalars['String']['output'];
  /** The unique ID for a `ItemSelectedBundleOption` object. */
  uid: Scalars['ID']['output'];
  /** A list of products that represent the values of the parent option. */
  values: Maybe<Array<Maybe<ItemSelectedBundleOptionValue>>>;
};

/** A list of values for the selected bundle product. */
export type ItemSelectedBundleOptionValue = {
  __typename: 'ItemSelectedBundleOptionValue';
  /**
   * The unique ID for a `ItemSelectedBundleOptionValue` object.
   * @deprecated Use `uid` instead.
   */
  id: Scalars['ID']['output'];
  /** The price of the child bundle product. */
  price: Money;
  /** The name of the child bundle product. */
  productName: Scalars['String']['output'];
  /** The SKU of the child bundle product. */
  productSku: Scalars['String']['output'];
  /** The number of this bundle product that were ordered. */
  quantity: Scalars['Float']['output'];
  /** The unique ID for a `ItemSelectedBundleOptionValue` object. */
  uid: Scalars['ID']['output'];
};

/** Contains a key-value pair. */
export type KeyValue = {
  __typename: 'KeyValue';
  /** The name part of the key/value pair. */
  name: Maybe<Scalars['String']['output']>;
  /** The value part of the key/value pair. */
  value: Maybe<Scalars['String']['output']>;
};

export type LabelItem = {
  __typename: 'LabelItem';
  /** Label text */
  label: Maybe<Scalars['String']['output']>;
  /** Text color */
  labelColor: Maybe<Scalars['String']['output']>;
  /** Foreground color */
  labelForegroundColor: Maybe<Scalars['String']['output']>;
};

/** Label ordering input */
export enum LabelOrderEnum {
  /**
   * Label text
   * @deprecated
   */
  LABEL = 'LABEL',
  /**
   * Label priority
   * @deprecated
   */
  PRIORITY = 'PRIORITY'
}

/** Label ordering input */
export type LabelOrderInput = {
  /** Sorting direction */
  direction: SortEnum;
  /** The enumerations for the label order */
  field: LabelOrderEnum;
};

/** Contains information for rendering layered navigation. */
export type LayerFilter = {
  __typename: 'LayerFilter';
  /**
   * An array of filter items.
   * @deprecated Use `Aggregation.options` instead.
   */
  filterItems: Maybe<Array<Maybe<LayerFilterItemInterface>>>;
  /**
   * The count of filter items in filter group.
   * @deprecated Use `Aggregation.count` instead.
   */
  filterItemsCount: Maybe<Scalars['Int']['output']>;
  /**
   * The name of a layered navigation filter.
   * @deprecated Use `Aggregation.label` instead.
   */
  name: Maybe<Scalars['String']['output']>;
  /**
   * The request variable name for a filter query.
   * @deprecated Use `Aggregation.attribute_code` instead.
   */
  requestVar: Maybe<Scalars['String']['output']>;
};

export type LayerFilterItem = LayerFilterItemInterface & {
  __typename: 'LayerFilterItem';
  /**
   * The count of items per filter.
   * @deprecated Use `AggregationOption.count` instead.
   */
  itemsCount: Maybe<Scalars['Int']['output']>;
  /**
   * The label for a filter.
   * @deprecated Use `AggregationOption.label` instead.
   */
  label: Maybe<Scalars['String']['output']>;
  /**
   * The value of a filter request variable to be used in query.
   * @deprecated Use `AggregationOption.value` instead.
   */
  valueString: Maybe<Scalars['String']['output']>;
};

export type LayerFilterItemInterface = {
  /**
   * The count of items per filter.
   * @deprecated Use `AggregationOption.count` instead.
   */
  itemsCount: Maybe<Scalars['Int']['output']>;
  /**
   * The label for a filter.
   * @deprecated Use `AggregationOption.label` instead.
   */
  label: Maybe<Scalars['String']['output']>;
  /**
   * The value of a filter request variable to be used in query.
   * @deprecated Use `AggregationOption.value` instead.
   */
  valueString: Maybe<Scalars['String']['output']>;
};

/** Defines a M1 Gift Voucher, which is a non-tangible product that does not require shipping and is not kept in inventory. */
export type M1GiftVoucher = CustomizableProductInterface & ProductInterface & RoutableInterface & {
  __typename: 'M1GiftVoucher';
  /** @deprecated Use the `custom_attributes` field instead. */
  age: Maybe<Scalars['String']['output']>;
  /** Information about guest highlight points available by product. */
  amastyRewardsGuestHighlights: Maybe<HighlightOutput>;
  /** Information about highlight points available by product. */
  amastyRewardsHighlights: Maybe<HighlightOutput>;
  /**
   * The attribute set assigned to the product.
   * @deprecated The field should not be used on the storefront.
   */
  attributeSetId: Maybe<Scalars['Int']['output']>;
  /** Returns all assigned product attributes and their values */
  attributes: Array<Maybe<ProductAttributes>>;
  /** Returns the brand of the product */
  brand: Maybe<Brand>;
  cacheBuster: Maybe<Scalars['String']['output']>;
  /** The relative canonical URL. This value is returned only if the system setting 'Use Canonical Link Meta Tag For Products' is enabled. */
  canonicalUrl: Maybe<Scalars['String']['output']>;
  cartItemUid: Maybe<Scalars['String']['output']>;
  /** The categories assigned to a product. */
  categories: Maybe<Array<Maybe<CategoryInterface>>>;
  /** @deprecated Use the `custom_attributes` field instead. */
  colour: Maybe<Scalars['Int']['output']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  concentration: Maybe<Scalars['Int']['output']>;
  /** The product's country of origin. */
  countryOfManufacture: Maybe<Scalars['String']['output']>;
  coverImage: Image;
  /**
   * Timestamp indicating when the product was created.
   * @deprecated The field should not be used on the storefront.
   */
  createdAt: Maybe<Scalars['String']['output']>;
  /** Crosssell Products */
  crosssellProducts: Maybe<Array<Maybe<ProductInterface>>>;
  /** @deprecated Use the `custom_attributes` field instead. */
  department: Maybe<Scalars['String']['output']>;
  /** Detailed information about the product. The value can include simple HTML tags. */
  description: Maybe<ComplexTextValue>;
  /** @deprecated Use the `custom_attributes` field instead. */
  flavour: Maybe<Scalars['Int']['output']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  fragrance: Maybe<Scalars['Int']['output']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  gender: Maybe<Scalars['String']['output']>;
  /** Indicates whether a gift message is available. */
  giftMessageAvailable: Maybe<Scalars['String']['output']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  hairType: Maybe<Scalars['String']['output']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  height: Maybe<Scalars['Float']['output']>;
  /**
   * The ID number assigned to the product.
   * @deprecated Use the `uid` field instead.
   */
  id: Maybe<Scalars['Int']['output']>;
  /** The relative path to the main image on the product page. */
  image: Maybe<ProductImage>;
  imageGallery: Array<Image>;
  ingredientList: Maybe<Array<Maybe<ProductIngredient>>>;
  /** @deprecated Use the `custom_attributes` field instead. */
  ingredients: Maybe<Scalars['String']['output']>;
  isInWishlist: Scalars['Boolean']['output'];
  /** List of product labels associated to the product */
  labels: Array<Maybe<LabelItem>>;
  /** @deprecated Use the `custom_attributes` field instead. */
  length: Maybe<Scalars['Float']['output']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  licensingStatus: Maybe<Scalars['String']['output']>;
  /**
   * A number representing the product's manufacturer.
   * @deprecated Use the `custom_attributes` field instead.
   */
  manufacturer: Maybe<Scalars['Int']['output']>;
  /** An array of media gallery objects. */
  mediaGallery: Maybe<Array<Maybe<MediaGalleryInterface>>>;
  /**
   * An array of MediaGalleryEntry objects.
   * @deprecated Use `media_gallery` instead.
   */
  mediaGalleryEntries: Maybe<Array<Maybe<MediaGalleryEntry>>>;
  /** An array of media gallery objects. */
  mediaGalleryV2: Maybe<Array<Maybe<MediaGalleryV2Interface>>>;
  /** A brief overview of the product for search results listings, maximum 255 characters. */
  metaDescription: Maybe<Scalars['String']['output']>;
  /** A comma-separated list of keywords that are visible only to search engines. */
  metaKeyword: Maybe<Scalars['String']['output']>;
  /** A string that is displayed in the title bar and tab of the browser and in search results lists. */
  metaTitle: Maybe<Scalars['String']['output']>;
  /** The product name. Customers use this name to identify the product. */
  name: Maybe<Scalars['String']['output']>;
  /** The beginning date for new product listings, and determines if the product is featured as a new product. */
  newFromDate: Maybe<Scalars['String']['output']>;
  /** The end date for new product listings. */
  newToDate: Maybe<Scalars['String']['output']>;
  /** Product stock only x left count */
  onlyXLeftInStock: Maybe<Scalars['Float']['output']>;
  /** An array of options for a customizable product. */
  options: Maybe<Array<Maybe<CustomizableOptionInterface>>>;
  /** If the product has multiple options, determines where they appear on the product page. */
  optionsContainer: Maybe<Scalars['String']['output']>;
  /**
   * Indicates the price of an item.
   * @deprecated Use `price_range` for product price information.
   */
  price: Maybe<ProductPrices>;
  /** The range of prices for the product */
  priceRange: PriceRange;
  /** An array of `TierPrice` objects. */
  priceTiers: Maybe<Array<Maybe<TierPrice>>>;
  /** An array of `ProductLinks` objects. */
  productLinks: Maybe<Array<Maybe<ProductLinksInterface>>>;
  productType: ProductTypeEnum;
  /** @deprecated Use the `custom_attributes` field instead. */
  quantity: Maybe<Scalars['Int']['output']>;
  quantityInCart: Scalars['Int']['output'];
  /** The rating summary for the product */
  ratingSummary: RatingSummary;
  /** Contains 0 when there is no redirect error. A value of 301 indicates the URL of the requested resource has been changed permanently, while a value of 302 indicates a temporary redirect. */
  redirectCode: Scalars['Int']['output'];
  /** Related Products */
  relatedProducts: Maybe<Array<Maybe<ProductInterface>>>;
  /** The internal relative URL. If the specified URL is a redirect, the query returns the redirected URL, not the original. */
  relativeUrl: Maybe<Scalars['String']['output']>;
  /** The total count of all the reviews given to the product. */
  reviewCount: Scalars['Int']['output'];
  /** The list of products reviews */
  reviews: ProductReviews;
  /** A short description of the product. Its use depends on the theme. */
  shortDescription: Maybe<ComplexTextValue>;
  /** @deprecated Use the `custom_attributes` field instead. */
  size: Maybe<Scalars['Int']['output']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  skinConcern: Maybe<Scalars['String']['output']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  skinType: Maybe<Scalars['String']['output']>;
  /** A number or code assigned to a product to identify the product, options, price, and manufacturer. */
  sku: Maybe<Scalars['String']['output']>;
  /** The relative path to the small image, which is used on catalog pages. */
  smallImage: Maybe<ProductImage>;
  /**
   * The beginning date that a product has a special price.
   * @deprecated The field should not be used on the storefront.
   */
  specialFromDate: Maybe<Scalars['String']['output']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  specialIcons: Maybe<Scalars['String']['output']>;
  /** The discounted price of the product. */
  specialPrice: Maybe<Scalars['Float']['output']>;
  /** The end date for a product with a special price. */
  specialToDate: Maybe<Scalars['String']['output']>;
  stockInfo: Maybe<StockInfo>;
  /** Stock status of the product */
  stockStatus: Maybe<ProductStockStatus>;
  /** The file name of a swatch image. */
  swatchImage: Maybe<Scalars['String']['output']>;
  /** Returns all assigned product tags and their values */
  tags: Array<Maybe<ProductTags>>;
  /** The relative path to the product's thumbnail image. */
  thumbnail: Maybe<ProductImage>;
  /**
   * The price when tier pricing is in effect and the items purchased threshold has been reached.
   * @deprecated Use `price_tiers` for product tier price information.
   */
  tierPrice: Maybe<Scalars['Float']['output']>;
  /**
   * An array of ProductTierPrices objects.
   * @deprecated Use `price_tiers` for product tier price information.
   */
  tierPrices: Maybe<Array<Maybe<ProductTierPrices>>>;
  /** One of PRODUCT, CATEGORY, or CMS_PAGE. */
  type: Maybe<UrlRewriteEntityTypeEnum>;
  /**
   * One of simple, virtual, bundle, downloadable, grouped, or configurable.
   * @deprecated Use `__typename` instead.
   */
  typeId: Maybe<Scalars['String']['output']>;
  /** The unique ID for a `ProductInterface` object. */
  uid: Scalars['ID']['output'];
  /**
   * Timestamp indicating when the product was updated.
   * @deprecated The field should not be used on the storefront.
   */
  updatedAt: Maybe<Scalars['String']['output']>;
  /** Upsell Products */
  upsellProducts: Maybe<Array<Maybe<ProductInterface>>>;
  /** The part of the URL that identifies the product */
  urlKey: Maybe<Scalars['String']['output']>;
  /** @deprecated Use product's `canonical_url` or url rewrites instead */
  urlPath: Maybe<Scalars['String']['output']>;
  /** URL rewrites list */
  urlRewrites: Maybe<Array<Maybe<UrlRewrite>>>;
  /** The part of the product URL that is appended after the url key */
  urlSuffix: Maybe<Scalars['String']['output']>;
  valueAttributes: Maybe<Array<Maybe<ProductValue>>>;
  /** @deprecated Use the `custom_attributes` field instead. */
  valueTraits: Maybe<Scalars['String']['output']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  valuesDiet: Maybe<Scalars['String']['output']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  valuesEnvironmental: Maybe<Scalars['String']['output']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  valuesIngredient: Maybe<Scalars['String']['output']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  valuesSocial: Maybe<Scalars['String']['output']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  volumeWeight: Maybe<Scalars['Float']['output']>;
  /**
   * An array of websites in which the product is available.
   * @deprecated The field should not be used on the storefront.
   */
  websites: Maybe<Array<Maybe<Website>>>;
  /** @deprecated Use the `custom_attributes` field instead. */
  width: Maybe<Scalars['Float']['output']>;
  wishlistItemId: Maybe<Scalars['Int']['output']>;
};


/** Defines a M1 Gift Voucher, which is a non-tangible product that does not require shipping and is not kept in inventory. */
export type M1GiftVoucheramastyRewardsHighlightsArgs = {
  attributes?: InputMaybe<Scalars['String']['input']>;
};


/** Defines a M1 Gift Voucher, which is a non-tangible product that does not require shipping and is not kept in inventory. */
export type M1GiftVoucherlabelsArgs = {
  order?: InputMaybe<LabelOrderInput>;
};


/** Defines a M1 Gift Voucher, which is a non-tangible product that does not require shipping and is not kept in inventory. */
export type M1GiftVoucherreviewsArgs = {
  currentPage?: InputMaybe<Scalars['Int']['input']>;
  filters?: InputMaybe<ReviewFilterInput>;
  pageSize?: InputMaybe<Scalars['Int']['input']>;
  sort?: InputMaybe<ReviewSortInput>;
};

export enum MarkupTypeEnum {
  /** Formatted Text */
  FORMATTED_TEXT = 'FORMATTED_TEXT',
  /** HTML */
  HTML = 'HTML',
  /** Markdown */
  MARKDOWN = 'MARKDOWN'
}

/** Defines characteristics about images and videos associated with a specific product. */
export type MediaGalleryEntry = {
  __typename: 'MediaGalleryEntry';
  /** Details about the content of the media gallery item. */
  content: Maybe<ProductMediaGalleryEntriesContent>;
  /** Indicates whether the image is hidden from view. */
  disabled: Maybe<Scalars['Boolean']['output']>;
  /** The path of the image on the server. */
  file: Maybe<Scalars['String']['output']>;
  /**
   * The identifier assigned to the object.
   * @deprecated Use `uid` instead.
   */
  id: Maybe<Scalars['Int']['output']>;
  /** The alt text displayed on the storefront when the user points to the image. */
  label: Maybe<Scalars['String']['output']>;
  /** Either `image` or `video`. */
  mediaType: Maybe<Scalars['String']['output']>;
  /** The media item's position after it has been sorted. */
  position: Maybe<Scalars['Int']['output']>;
  /** Array of image types. It can have the following values: image, small_image, thumbnail. */
  types: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  /** The unique ID for a `MediaGalleryEntry` object. */
  uid: Scalars['ID']['output'];
  /** Details about the content of a video item. */
  videoContent: Maybe<ProductMediaGalleryEntriesVideoContent>;
};

/** Contains basic information about a product image or video. */
export type MediaGalleryInterface = {
  /** Indicates whether the image is hidden from view. */
  disabled: Maybe<Scalars['Boolean']['output']>;
  /** The label of the product image or video. */
  label: Maybe<Scalars['String']['output']>;
  /** The media item's position after it has been sorted. */
  position: Maybe<Scalars['Int']['output']>;
  /** The URL of the product image or video. */
  url: Maybe<Scalars['String']['output']>;
};

/** Contains basic information about a product image. */
export type MediaGalleryV2Interface = {
  /** The label of the product image. */
  label: Maybe<Scalars['String']['output']>;
  /** The path of the product image. */
  path: Maybe<Scalars['String']['output']>;
};

export type MenuCategories = {
  __typename: 'MenuCategories';
  currentBottom: Maybe<Scalars['String']['output']>;
  currentMiddle: Maybe<Scalars['String']['output']>;
  currentTop: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  open: Scalars['Boolean']['output'];
};

export type MessageStyleLogo = {
  __typename: 'MessageStyleLogo';
  /** The type of logo for the PayPal Pay Later messaging */
  type: Maybe<Scalars['String']['output']>;
};

export type MessageStyles = {
  __typename: 'MessageStyles';
  /** The message layout */
  layout: Maybe<Scalars['String']['output']>;
  /** The message logo */
  logo: Maybe<MessageStyleLogo>;
};

export enum MobileMenuDisplayEnum {
  /** Hide */
  HIDE = 'HIDE',
  /** Show */
  SHOW = 'SHOW'
}

export enum MobileOSTypeEnum {
  /** Android */
  ANDROID = 'ANDROID',
  /** Apple */
  APPLE = 'APPLE',
  /** Not Mobile */
  NOT_NATIVE = 'NOT_NATIVE'
}

export type Modals = {
  __typename: 'Modals';
  forgotPassword: Scalars['Boolean']['output'];
  id: Scalars['ID']['output'];
  logIn: Scalars['Boolean']['output'];
  search: Scalars['Boolean']['output'];
  signUp: Scalars['Boolean']['output'];
  subscribe: Scalars['Boolean']['output'];
  validateMobile: Scalars['Boolean']['output'];
};

/** Defines a monetary value, including a numeric value and a currency code. */
export type Money = {
  __typename: 'Money';
  /** A three-letter currency code, such as USD or EUR. */
  currency: Maybe<CurrencyEnum>;
  /** A number expressing a monetary value. */
  value: Maybe<Scalars['Float']['output']>;
};

/** Month Weeks */
export enum MonthWeekEnum {
  /** 1st */
  FIRST = 'FIRST',
  /** 4th */
  FOURTH = 'FOURTH',
  /** 2nd */
  SECOND = 'SECOND',
  /** 3rd */
  THIRD = 'THIRD'
}

export type Mutation = {
  __typename: 'Mutation';
  /** Add gift card account to customer's account */
  addAmGiftCardCodeToAccount: Maybe<AmGiftCardMutationCommonOutput>;
  /** Add gift card product to specified cart */
  addAmGiftCardProductsToCart: Maybe<AddAmGiftCardProductsToCartOutput>;
  /** Add one or more bundle products to the specified cart. We recommend using `addProductsToCart` instead. */
  addBundleProductsToCart: Maybe<AddBundleProductsToCartOutput>;
  /** Add one or more configurable products to the specified cart. We recommend using `addProductsToCart` instead. */
  addConfigurableProductsToCart: Maybe<AddConfigurableProductsToCartOutput>;
  addContent: Content;
  /** Add one or more downloadable products to the specified cart. We recommend using `addProductsToCart` instead. */
  addDownloadableProductsToCart: Maybe<AddDownloadableProductsToCartOutput>;
  addImage: Image;
  addPage: Page;
  addPageAccordionSection: PageAccordionSection;
  addPageBanner: PageBanner;
  addPageBannerCategory: PageBannerCategory;
  addPageBannerSection: PageBannerSection;
  addPageBlockSection: PageBlockSection;
  addPageCarouselSection: PageCarouselSection;
  addPageChipSection: PageChipSection;
  addPageExcerptSection: PageExcerptSection;
  addPageHeadingSection: PageHeadingSection;
  addPageHeroSection: PageHeroSection;
  addPageIconSection: PageIconSection;
  addPageLinkSection: PageLinkSection;
  addPageMarkupSection: PageMarkupSection;
  addPageMediaSection: PageMediaSection;
  addPageMyDiscountsSection: PageMyDiscountsSection;
  addPageMyShopSection: PageMyShopSection;
  addPageNotificationSection: PageNotificationSection;
  addPagePlainTextSection: PagePlainTextSection;
  addPageProductSliderSection: PageProductSliderSection;
  addPageQuestionGroup: PageQuestionGroup;
  addPageQuestionSection: PageQuestionSection;
  addPageSectionGroup: PageSectionGroup;
  addPageSiteNoticeSection: PageSiteNoticeSection;
  addPageSplitTextSection: PageSplitTextSection;
  addPageTimetableSection: PageTimetableSection;
  /** Add any type of product to the cart. */
  addProductsToCart: Maybe<AddProductsToCartOutput>;
  /** Add products to the specified compare list. */
  addProductsToCompareList: Maybe<CompareList>;
  /** Add one or more products to the specified wish list. This mutation supports all product types. */
  addProductsToWishlist: Maybe<AddProductsToWishlistOutput>;
  addReviewVote: Maybe<ReviewVoteItem>;
  /** Add one or more simple products to the specified cart. We recommend using `addProductsToCart` instead. */
  addSimpleProductsToCart: Maybe<AddSimpleProductsToCartOutput>;
  /** Add one or more virtual products to the specified cart. We recommend using `addProductsToCart` instead. */
  addVirtualProductsToCart: Maybe<AddVirtualProductsToCartOutput>;
  addWidgetContactUs: WidgetContactUs;
  addWidgetDropDownCart: WidgetDropDownCart;
  addWidgetProductDetailsAddToCart: WidgetProductDetailsAddToCart;
  addWidgetSiteWideBanner: WidgetSiteWideBanner;
  /** Add items in the specified wishlist to the customer's cart. */
  addWishlistItemsToCart: Maybe<AddWishlistItemsToCartOutput>;
  /** Apply a gift card code to the specified cart */
  applyAmGiftCardToCart: Maybe<ApplyAmGiftCardToCartOutput>;
  /** Apply a pre-defined coupon code to the specified cart. */
  applyCouponToCart: Maybe<ApplyCouponToCartOutput>;
  /** Assign the specified compare list to the logged in customer. */
  assignCompareListToCustomer: Maybe<AssignCompareListToCustomerOutput>;
  /** Assign a logged-in customer to the specified guest shopping cart. */
  assignCustomerToGuestCart: Cart;
  /** Add a card with Peach Payments and redirect to 3D secure, see section 2 @ https://peachpayments.docs.oppwa.com/tutorials/threeDSecure */
  authenticate3DSecure: Maybe<PeachPayments3DSecure>;
  /** Change the password for the logged-in customer. */
  changeCustomerPassword: Maybe<Customer>;
  clearAllCache: Scalars['Boolean']['output'];
  clearClientCache: Scalars['Boolean']['output'];
  clearImageCache: Scalars['Boolean']['output'];
  clearRouteCache: Scalars['Boolean']['output'];
  clearServiceCache: Scalars['Boolean']['output'];
  /** Send a 'Contact Us' email to the merchant. */
  contactUs: Maybe<ContactUsOutput>;
  /** This is the description for the contactUsFormSubmit mutation */
  contactUsFormSubmit: Maybe<ContactUsFormSubmitPayload>;
  /** Create a new compare list. The compare list is saved for logged in customers. */
  createCompareList: Maybe<CompareList>;
  /** Use `createCustomerV2` instead. */
  createCustomer: Maybe<CustomerOutput>;
  /** Create a billing or shipping address for a customer or guest. */
  createCustomerAddress: Maybe<CustomerAddress>;
  /** Create a customer account. */
  createCustomerV2: Maybe<CustomerOutput>;
  /** Create an empty shopping cart for a guest or logged in user */
  createEmptyCart: Maybe<Scalars['String']['output']>;
  createNewPageVersion: Page;
  /** Initiate a transaction and receive a token. Use this mutation for Payflow Pro and Payments Pro payment methods */
  createPayflowProToken: Maybe<CreatePayflowProTokenOutput>;
  /** Creates a payment order for further payment processing */
  createPaymentOrder: Maybe<CreatePaymentOrderOutput>;
  /** Initiate an Express Checkout transaction and receive a token. Use this mutation for Express Checkout and Payments Standard payment methods. */
  createPaypalExpressToken: Maybe<PaypalExpressTokenOutput>;
  /** Create a product review for the specified product. */
  createProductReview: CreateProductReviewOutput;
  /** Delete the specified compare list. */
  deleteCompareList: Maybe<DeleteCompareListOutput>;
  /** Delete customer account */
  deleteCustomer: Maybe<Scalars['Boolean']['output']>;
  /** Delete the billing or shipping address of a customer. */
  deleteCustomerAddress: Maybe<Scalars['Boolean']['output']>;
  /** Delete a customer's payment token. */
  deletePaymentToken: Maybe<DeletePaymentTokenOutput>;
  deleteReviewVote: Maybe<Scalars['Boolean']['output']>;
  /** Generate a token for specified customer. */
  generateCustomerToken: Maybe<CustomerToken>;
  /** Request a customer token so that an administrator can perform remote shopping assistance. */
  generateCustomerTokenAsAdmin: Maybe<GenerateCustomerTokenAsAdminOutput>;
  /** Authenticate the Single Sign-on (SSO) access token and retrieve the customer token */
  generateCustomerTokenForSso: Maybe<CustomerToken>;
  /** Handle a payment response and save the payment in Quote. Use this mutation for Payflow Pro and Payments Pro payment methods. */
  handlePayflowProResponse: Maybe<PayflowProResponseOutput>;
  /** Transfer the contents of a guest cart into the cart of a logged-in customer. */
  mergeCarts: Cart;
  /** Moves a single wishlist item to cart. */
  moveWishlistItemToCart: Maybe<Scalars['Boolean']['output']>;
  /** Moves all wishlist items to cart. */
  moveWishlistToCart: Maybe<Scalars['Boolean']['output']>;
  /** Convert the quote into an order. */
  placeOrder: Maybe<PlaceOrderOutput>;
  /** Customer subsribe for product alert */
  productAlertSubscribe: Maybe<Scalars['Boolean']['output']>;
  reloadClients: Scalars['Boolean']['output'];
  /** Removes all wishlist items for the specified wishlist. */
  removeAllWishlistItems: Maybe<Scalars['Boolean']['output']>;
  /** Remove gift card account from customer's account */
  removeAmGiftCardCodeToAccount: Maybe<AmGiftCardMutationCommonOutput>;
  /** Remove a gift card code from the specified cart */
  removeAmGiftCardFromCart: Maybe<RemoveAmGiftCardFromCartOutput>;
  removeContent: Scalars['ID']['output'];
  /** Remove a previously-applied coupon from the cart. The cart must contain at least one item in order to remove the coupon. */
  removeCouponFromCart: Maybe<RemoveCouponFromCartOutput>;
  removeImage: Scalars['ID']['output'];
  /** Delete the entire quantity of a specified item from the cart. If you remove all items from the cart, the cart continues to exist. */
  removeItemFromCart: Maybe<RemoveItemFromCartOutput>;
  removePage: Scalars['ID']['output'];
  removePageAccordionSection: Scalars['ID']['output'];
  removePageBanner: Scalars['ID']['output'];
  removePageBannerCategory: Scalars['ID']['output'];
  removePageBannerSection: Scalars['ID']['output'];
  removePageBlockSection: Scalars['ID']['output'];
  removePageCarouselSection: Scalars['ID']['output'];
  removePageChipSection: Scalars['ID']['output'];
  removePageExcerptSection: Scalars['ID']['output'];
  removePageHeadingSection: Scalars['ID']['output'];
  removePageHeroSection: Scalars['ID']['output'];
  removePageIconSection: Scalars['ID']['output'];
  removePageLinkSection: Scalars['ID']['output'];
  removePageMarkupSection: Scalars['ID']['output'];
  removePageMediaSection: Scalars['ID']['output'];
  removePageMyDiscountsSection: Scalars['ID']['output'];
  removePageMyShopSection: Scalars['ID']['output'];
  removePageNotificationSection: Scalars['ID']['output'];
  removePagePlainTextSection: Scalars['ID']['output'];
  removePageProductSliderSection: Scalars['ID']['output'];
  removePageQuestionGroup: Scalars['ID']['output'];
  removePageQuestionSection: Scalars['ID']['output'];
  removePageSectionGroup: Scalars['ID']['output'];
  removePageSiteNoticeSection: Scalars['ID']['output'];
  removePageSplitTextSection: Scalars['ID']['output'];
  removePageTimetableSection: Scalars['ID']['output'];
  /** Remove card from system and Peach Payments */
  removePeachPaymentsCard: Maybe<PeachPaymentsRemoveCard>;
  /** Remove products from the specified compare list. */
  removeProductsFromCompareList: Maybe<CompareList>;
  /** Remove one or more products from the specified wish list. */
  removeProductsFromWishlist: Maybe<RemoveProductsFromWishlistOutput>;
  removeWidgetContactUs: Scalars['ID']['output'];
  removeWidgetDropDownCart: Scalars['ID']['output'];
  removeWidgetProductDetailsAddToCart: Scalars['ID']['output'];
  removeWidgetSiteWideBanner: Scalars['ID']['output'];
  /** Add all products from a customer's previous order to the cart. */
  reorderItems: Maybe<ReorderItemsOutput>;
  replaceSectionWithClone: PageSection;
  /** Request an email with a reset password token for the registered customer identified by the specified email. */
  requestPasswordResetEmail: Maybe<Scalars['Boolean']['output']>;
  /** Reset a customer's password using the reset password token that the customer received in an email after requesting it using `requestPasswordResetEmail`. */
  resetPassword: Maybe<Scalars['Boolean']['output']>;
  /** Revoke the customer token. */
  revokeCustomerToken: Maybe<RevokeCustomerTokenOutput>;
  rollBackToContent: Page;
  saveNotificationOptions: Maybe<SaveNotificationOptionsOutput>;
  /** Send a message on behalf of a customer to the specified email addresses. */
  sendEmailToFriend: Maybe<SendEmailToFriendOutput>;
  /** Send mobile verification code to the customer */
  sendMobileVerification: Maybe<SendMobileVerificationOutput>;
  /** Set the billing address on a specific cart. */
  setBillingAddressOnCart: Maybe<SetBillingAddressOnCartOutput>;
  /** Assign the email address of a guest to the cart. */
  setGuestEmailOnCart: Maybe<SetGuestEmailOnCartOutput>;
  /**
   * Set the cart payment method and convert the cart into an order.
   * @deprecated Should use setPaymentMethodOnCart and placeOrder mutations in single request.
   */
  setPaymentMethodAndPlaceOrder: Maybe<PlaceOrderOutput>;
  /** Apply a payment method to the cart. */
  setPaymentMethodOnCart: Maybe<SetPaymentMethodOnCartOutput>;
  /** Set one or more shipping addresses on a specific cart. */
  setShippingAddressesOnCart: Maybe<SetShippingAddressesOnCartOutput>;
  /** Set one or more delivery methods on a cart. */
  setShippingMethodsOnCart: Maybe<SetShippingMethodsOnCartOutput>;
  /** Subscribe the specified email to the store's newsletter. */
  subscribeEmailToNewsletter: Maybe<SubscribeEmailToNewsletterOutput>;
  /** Synchronizes the payment order details for further payment processing */
  syncPaymentOrder: Maybe<Scalars['Boolean']['output']>;
  testCategorySocket: Scalars['Boolean']['output'];
  testCategoryTreeSocket: Scalars['Boolean']['output'];
  testProductSocket: Scalars['Boolean']['output'];
  /** Modify items in the cart. */
  updateCartItems: Maybe<UpdateCartItemsOutput>;
  updateContent: Content;
  /** Use `updateCustomerV2` instead. */
  updateCustomer: Maybe<CustomerOutput>;
  /** Update the billing or shipping address of a customer or guest. */
  updateCustomerAddress: Maybe<CustomerAddress>;
  /** Change the email address for the logged-in customer. */
  updateCustomerEmail: Maybe<CustomerOutput>;
  /** Update the customer's personal information. */
  updateCustomerV2: Maybe<CustomerOutput>;
  updateImage: Image;
  updatePage: Page;
  updatePageAccordionSection: PageAccordionSection;
  updatePageBanner: PageBanner;
  updatePageBannerCategory: PageBannerCategory;
  updatePageBannerSection: PageBannerSection;
  updatePageBlockSection: PageBlockSection;
  updatePageCarouselSection: PageCarouselSection;
  updatePageChipSection: PageChipSection;
  updatePageExcerptSection: PageExcerptSection;
  updatePageHeadingSection: PageHeadingSection;
  updatePageHeroSection: PageHeroSection;
  updatePageIconSection: PageIconSection;
  updatePageLinkSection: PageLinkSection;
  updatePageMarkupSection: PageMarkupSection;
  updatePageMediaSection: PageMediaSection;
  updatePageMyDiscountsSection: PageMyDiscountsSection;
  updatePageMyShopSection: PageMyShopSection;
  updatePageNotificationSection: PageNotificationSection;
  updatePagePlainTextSection: PagePlainTextSection;
  updatePageProductSliderSection: PageProductSliderSection;
  updatePageQuestionGroup: PageQuestionGroup;
  updatePageQuestionSection: PageQuestionSection;
  updatePageSectionGroup: PageSectionGroup;
  updatePageSiteNoticeSection: PageSiteNoticeSection;
  updatePageSplitTextSection: PageSplitTextSection;
  updatePageTimetableSection: PageTimetableSection;
  /** Update card nickname */
  updatePeachPaymentsCardNick: Maybe<PeachPaymentsUpdateCardNickName>;
  /** Update one or more products in the specified wish list. */
  updateProductsInWishlist: Maybe<UpdateProductsInWishlistOutput>;
  updateWidgetContactUs: WidgetContactUs;
  updateWidgetDropDownCart: WidgetDropDownCart;
  updateWidgetProductDetailsAddToCart: WidgetProductDetailsAddToCart;
  updateWidgetSiteWideBanner: WidgetSiteWideBanner;
  /** Apply or cancel (set points to 0) rewards points to current cart. */
  useRewardPoints: Maybe<UseRewardPointsOutput>;
  /** Validate secret for Login As Customer */
  validateLoginAsCustomer: Maybe<ValidateLoginAsCustomerOutput>;
  /** Verify the mobile verification code */
  verifyMobile: Maybe<VerifyMobileOutput>;
};


export type MutationaddAmGiftCardCodeToAccountArgs = {
  input?: InputMaybe<AmGiftCardCodeInAccountInput>;
};


export type MutationaddAmGiftCardProductsToCartArgs = {
  input?: InputMaybe<AddAmGiftCardProductsToCartInput>;
};


export type MutationaddBundleProductsToCartArgs = {
  input?: InputMaybe<AddBundleProductsToCartInput>;
};


export type MutationaddConfigurableProductsToCartArgs = {
  input?: InputMaybe<AddConfigurableProductsToCartInput>;
};


export type MutationaddContentArgs = {
  input?: InputMaybe<ContentAddInput>;
};


export type MutationaddDownloadableProductsToCartArgs = {
  input?: InputMaybe<AddDownloadableProductsToCartInput>;
};


export type MutationaddImageArgs = {
  input?: InputMaybe<ImageAddInput>;
};


export type MutationaddPageArgs = {
  input?: InputMaybe<PageAddInput>;
};


export type MutationaddPageAccordionSectionArgs = {
  input?: InputMaybe<PageAccordionSectionAddInput>;
};


export type MutationaddPageBannerArgs = {
  input?: InputMaybe<PageBannerAddInput>;
};


export type MutationaddPageBannerCategoryArgs = {
  input?: InputMaybe<PageBannerCategoryAddInput>;
};


export type MutationaddPageBannerSectionArgs = {
  input?: InputMaybe<PageBannerSectionAddInput>;
};


export type MutationaddPageBlockSectionArgs = {
  input?: InputMaybe<PageBlockSectionAddInput>;
};


export type MutationaddPageCarouselSectionArgs = {
  input?: InputMaybe<PageCarouselSectionAddInput>;
};


export type MutationaddPageChipSectionArgs = {
  input?: InputMaybe<PageChipSectionAddInput>;
};


export type MutationaddPageExcerptSectionArgs = {
  input?: InputMaybe<PageExcerptSectionAddInput>;
};


export type MutationaddPageHeadingSectionArgs = {
  input?: InputMaybe<PageHeadingSectionAddInput>;
};


export type MutationaddPageHeroSectionArgs = {
  input?: InputMaybe<PageHeroSectionAddInput>;
};


export type MutationaddPageIconSectionArgs = {
  input?: InputMaybe<PageIconSectionAddInput>;
};


export type MutationaddPageLinkSectionArgs = {
  input?: InputMaybe<PageLinkSectionAddInput>;
};


export type MutationaddPageMarkupSectionArgs = {
  input?: InputMaybe<PageMarkupSectionAddInput>;
};


export type MutationaddPageMediaSectionArgs = {
  input?: InputMaybe<PageMediaSectionAddInput>;
};


export type MutationaddPageMyDiscountsSectionArgs = {
  input?: InputMaybe<PageMyDiscountsSectionAddInput>;
};


export type MutationaddPageMyShopSectionArgs = {
  input?: InputMaybe<PageMyShopSectionAddInput>;
};


export type MutationaddPageNotificationSectionArgs = {
  input?: InputMaybe<PageNotificationSectionAddInput>;
};


export type MutationaddPagePlainTextSectionArgs = {
  input?: InputMaybe<PagePlainTextSectionAddInput>;
};


export type MutationaddPageProductSliderSectionArgs = {
  input?: InputMaybe<PageProductSliderSectionAddInput>;
};


export type MutationaddPageQuestionGroupArgs = {
  input?: InputMaybe<PageQuestionGroupAddInput>;
};


export type MutationaddPageQuestionSectionArgs = {
  input?: InputMaybe<PageQuestionSectionAddInput>;
};


export type MutationaddPageSectionGroupArgs = {
  input?: InputMaybe<PageSectionGroupAddInput>;
};


export type MutationaddPageSiteNoticeSectionArgs = {
  input?: InputMaybe<PageSiteNoticeSectionAddInput>;
};


export type MutationaddPageSplitTextSectionArgs = {
  input?: InputMaybe<PageSplitTextSectionAddInput>;
};


export type MutationaddPageTimetableSectionArgs = {
  input?: InputMaybe<PageTimetableSectionAddInput>;
};


export type MutationaddProductsToCartArgs = {
  cartId: Scalars['String']['input'];
  cartItems: Array<CartItemInput>;
};


export type MutationaddProductsToCompareListArgs = {
  input?: InputMaybe<AddProductsToCompareListInput>;
};


export type MutationaddProductsToWishlistArgs = {
  wishlistId: Scalars['ID']['input'];
  wishlistItems: Array<WishlistItemInput>;
};


export type MutationaddReviewVoteArgs = {
  reviewVote: ReviewVoteInput;
};


export type MutationaddSimpleProductsToCartArgs = {
  input?: InputMaybe<AddSimpleProductsToCartInput>;
};


export type MutationaddVirtualProductsToCartArgs = {
  input?: InputMaybe<AddVirtualProductsToCartInput>;
};


export type MutationaddWidgetContactUsArgs = {
  input?: InputMaybe<WidgetContactUsAddInput>;
};


export type MutationaddWidgetDropDownCartArgs = {
  input?: InputMaybe<WidgetDropDownCartAddInput>;
};


export type MutationaddWidgetProductDetailsAddToCartArgs = {
  input?: InputMaybe<WidgetProductDetailsAddToCartAddInput>;
};


export type MutationaddWidgetSiteWideBannerArgs = {
  input?: InputMaybe<WidgetSiteWideBannerAddInput>;
};


export type MutationaddWishlistItemsToCartArgs = {
  wishlistId: Scalars['ID']['input'];
  wishlistItemIds?: InputMaybe<Array<Scalars['ID']['input']>>;
};


export type MutationapplyAmGiftCardToCartArgs = {
  input?: InputMaybe<ApplyAmGiftCardToCartInput>;
};


export type MutationapplyCouponToCartArgs = {
  input?: InputMaybe<ApplyCouponToCartInput>;
};


export type MutationassignCompareListToCustomerArgs = {
  uid: Scalars['ID']['input'];
};


export type MutationassignCustomerToGuestCartArgs = {
  cart_id: Scalars['String']['input'];
};


export type Mutationauthenticate3DSecureArgs = {
  input: PeachPayments3DsInput;
};


export type MutationchangeCustomerPasswordArgs = {
  currentPassword: Scalars['String']['input'];
  newPassword: Scalars['String']['input'];
};


export type MutationcontactUsArgs = {
  input: ContactUsInput;
};


export type MutationcontactUsFormSubmitArgs = {
  input: ContactUsFormSubmitInput;
};


export type MutationcreateCompareListArgs = {
  input?: InputMaybe<CreateCompareListInput>;
};


export type MutationcreateCustomerArgs = {
  input: CustomerInput;
};


export type MutationcreateCustomerAddressArgs = {
  input: CustomerAddressInput;
};


export type MutationcreateCustomerV2Args = {
  input: CustomerCreateInput;
};


export type MutationcreateEmptyCartArgs = {
  input?: InputMaybe<CreateEmptyCartInput>;
};


export type MutationcreateNewPageVersionArgs = {
  deepClone?: InputMaybe<Scalars['Boolean']['input']>;
  fromVersion: Scalars['Int']['input'];
  name?: InputMaybe<Scalars['String']['input']>;
  pageId: Scalars['ID']['input'];
  targetPage?: InputMaybe<Scalars['ID']['input']>;
};


export type MutationcreatePayflowProTokenArgs = {
  input: PayflowProTokenInput;
};


export type MutationcreatePaymentOrderArgs = {
  input: CreatePaymentOrderInput;
};


export type MutationcreatePaypalExpressTokenArgs = {
  input: PaypalExpressTokenInput;
};


export type MutationcreateProductReviewArgs = {
  input: CreateProductReviewInput;
};


export type MutationdeleteCompareListArgs = {
  uid: Scalars['ID']['input'];
};


export type MutationdeleteCustomerAddressArgs = {
  id: Scalars['Int']['input'];
};


export type MutationdeletePaymentTokenArgs = {
  public_hash: Scalars['String']['input'];
};


export type MutationdeleteReviewVoteArgs = {
  reviewId: Scalars['Int']['input'];
};


export type MutationgenerateCustomerTokenArgs = {
  email: Scalars['String']['input'];
  password: Scalars['String']['input'];
};


export type MutationgenerateCustomerTokenAsAdminArgs = {
  input: GenerateCustomerTokenAsAdminInput;
};


export type MutationgenerateCustomerTokenForSsoArgs = {
  accessToken: Scalars['String']['input'];
  accessType: Scalars['String']['input'];
  provider: SsoProviderEnum;
};


export type MutationhandlePayflowProResponseArgs = {
  input: PayflowProResponseInput;
};


export type MutationmergeCartsArgs = {
  destination_cart_id?: InputMaybe<Scalars['String']['input']>;
  source_cart_id: Scalars['String']['input'];
};


export type MutationmoveWishlistItemToCartArgs = {
  itemId: Scalars['ID']['input'];
  wishlistId: Scalars['ID']['input'];
};


export type MutationmoveWishlistToCartArgs = {
  wishlistId: Scalars['ID']['input'];
};


export type MutationplaceOrderArgs = {
  input?: InputMaybe<PlaceOrderInput>;
};


export type MutationproductAlertSubscribeArgs = {
  productId: Scalars['ID']['input'];
  type: Scalars['String']['input'];
};


export type MutationreloadClientsArgs = {
  reloadType?: InputMaybe<ClientReloadTypeEnum>;
};


export type MutationremoveAllWishlistItemsArgs = {
  wishlistId: Scalars['ID']['input'];
};


export type MutationremoveAmGiftCardCodeToAccountArgs = {
  input?: InputMaybe<AmGiftCardCodeInAccountInput>;
};


export type MutationremoveAmGiftCardFromCartArgs = {
  input?: InputMaybe<RemoveAmGiftCardFromCartInput>;
};


export type MutationremoveContentArgs = {
  id?: InputMaybe<Scalars['ID']['input']>;
};


export type MutationremoveCouponFromCartArgs = {
  input?: InputMaybe<RemoveCouponFromCartInput>;
};


export type MutationremoveImageArgs = {
  id?: InputMaybe<Scalars['ID']['input']>;
};


export type MutationremoveItemFromCartArgs = {
  input?: InputMaybe<RemoveItemFromCartInput>;
};


export type MutationremovePageArgs = {
  id?: InputMaybe<Scalars['ID']['input']>;
};


export type MutationremovePageAccordionSectionArgs = {
  id?: InputMaybe<Scalars['ID']['input']>;
};


export type MutationremovePageBannerArgs = {
  id?: InputMaybe<Scalars['ID']['input']>;
};


export type MutationremovePageBannerCategoryArgs = {
  id?: InputMaybe<Scalars['ID']['input']>;
};


export type MutationremovePageBannerSectionArgs = {
  id?: InputMaybe<Scalars['ID']['input']>;
};


export type MutationremovePageBlockSectionArgs = {
  id?: InputMaybe<Scalars['ID']['input']>;
};


export type MutationremovePageCarouselSectionArgs = {
  id?: InputMaybe<Scalars['ID']['input']>;
};


export type MutationremovePageChipSectionArgs = {
  id?: InputMaybe<Scalars['ID']['input']>;
};


export type MutationremovePageExcerptSectionArgs = {
  id?: InputMaybe<Scalars['ID']['input']>;
};


export type MutationremovePageHeadingSectionArgs = {
  id?: InputMaybe<Scalars['ID']['input']>;
};


export type MutationremovePageHeroSectionArgs = {
  id?: InputMaybe<Scalars['ID']['input']>;
};


export type MutationremovePageIconSectionArgs = {
  id?: InputMaybe<Scalars['ID']['input']>;
};


export type MutationremovePageLinkSectionArgs = {
  id?: InputMaybe<Scalars['ID']['input']>;
};


export type MutationremovePageMarkupSectionArgs = {
  id?: InputMaybe<Scalars['ID']['input']>;
};


export type MutationremovePageMediaSectionArgs = {
  id?: InputMaybe<Scalars['ID']['input']>;
};


export type MutationremovePageMyDiscountsSectionArgs = {
  id?: InputMaybe<Scalars['ID']['input']>;
};


export type MutationremovePageMyShopSectionArgs = {
  id?: InputMaybe<Scalars['ID']['input']>;
};


export type MutationremovePageNotificationSectionArgs = {
  id?: InputMaybe<Scalars['ID']['input']>;
};


export type MutationremovePagePlainTextSectionArgs = {
  id?: InputMaybe<Scalars['ID']['input']>;
};


export type MutationremovePageProductSliderSectionArgs = {
  id?: InputMaybe<Scalars['ID']['input']>;
};


export type MutationremovePageQuestionGroupArgs = {
  id?: InputMaybe<Scalars['ID']['input']>;
};


export type MutationremovePageQuestionSectionArgs = {
  id?: InputMaybe<Scalars['ID']['input']>;
};


export type MutationremovePageSectionGroupArgs = {
  id?: InputMaybe<Scalars['ID']['input']>;
};


export type MutationremovePageSiteNoticeSectionArgs = {
  id?: InputMaybe<Scalars['ID']['input']>;
};


export type MutationremovePageSplitTextSectionArgs = {
  id?: InputMaybe<Scalars['ID']['input']>;
};


export type MutationremovePageTimetableSectionArgs = {
  id?: InputMaybe<Scalars['ID']['input']>;
};


export type MutationremovePeachPaymentsCardArgs = {
  input: Scalars['ID']['input'];
};


export type MutationremoveProductsFromCompareListArgs = {
  input?: InputMaybe<RemoveProductsFromCompareListInput>;
};


export type MutationremoveProductsFromWishlistArgs = {
  wishlistId: Scalars['ID']['input'];
  wishlistItemsIds: Array<Scalars['ID']['input']>;
};


export type MutationremoveWidgetContactUsArgs = {
  id?: InputMaybe<Scalars['ID']['input']>;
};


export type MutationremoveWidgetDropDownCartArgs = {
  id?: InputMaybe<Scalars['ID']['input']>;
};


export type MutationremoveWidgetProductDetailsAddToCartArgs = {
  id?: InputMaybe<Scalars['ID']['input']>;
};


export type MutationremoveWidgetSiteWideBannerArgs = {
  id?: InputMaybe<Scalars['ID']['input']>;
};


export type MutationreorderItemsArgs = {
  orderNumber: Scalars['String']['input'];
};


export type MutationreplaceSectionWithCloneArgs = {
  pageSectionGroupId: Scalars['ID']['input'];
  pageSectionId: Scalars['ID']['input'];
};


export type MutationrequestPasswordResetEmailArgs = {
  email: Scalars['String']['input'];
};


export type MutationresetPasswordArgs = {
  email: Scalars['String']['input'];
  newPassword: Scalars['String']['input'];
  resetPasswordToken: Scalars['String']['input'];
};


export type MutationrollBackToContentArgs = {
  contentId: Scalars['ID']['input'];
};


export type MutationsaveNotificationOptionsArgs = {
  input?: InputMaybe<SaveNotificationOptionsInput>;
};


export type MutationsendEmailToFriendArgs = {
  input?: InputMaybe<SendEmailToFriendInput>;
};


export type MutationsendMobileVerificationArgs = {
  input: SendMobileVerificationInput;
};


export type MutationsetBillingAddressOnCartArgs = {
  input?: InputMaybe<SetBillingAddressOnCartInput>;
};


export type MutationsetGuestEmailOnCartArgs = {
  input?: InputMaybe<SetGuestEmailOnCartInput>;
};


export type MutationsetPaymentMethodAndPlaceOrderArgs = {
  input?: InputMaybe<SetPaymentMethodAndPlaceOrderInput>;
};


export type MutationsetPaymentMethodOnCartArgs = {
  input?: InputMaybe<SetPaymentMethodOnCartInput>;
};


export type MutationsetShippingAddressesOnCartArgs = {
  input?: InputMaybe<SetShippingAddressesOnCartInput>;
};


export type MutationsetShippingMethodsOnCartArgs = {
  input?: InputMaybe<SetShippingMethodsOnCartInput>;
};


export type MutationsubscribeEmailToNewsletterArgs = {
  email: Scalars['String']['input'];
};


export type MutationsyncPaymentOrderArgs = {
  input?: InputMaybe<SyncPaymentOrderInput>;
};


export type MutationtestCategorySocketArgs = {
  id: Scalars['String']['input'];
};


export type MutationtestCategoryTreeSocketArgs = {
  id: Scalars['String']['input'];
};


export type MutationtestProductSocketArgs = {
  id: Scalars['String']['input'];
};


export type MutationupdateCartItemsArgs = {
  input?: InputMaybe<UpdateCartItemsInput>;
};


export type MutationupdateContentArgs = {
  id: Scalars['ID']['input'];
  input?: InputMaybe<ContentUpdateInput>;
};


export type MutationupdateCustomerArgs = {
  input: CustomerInput;
};


export type MutationupdateCustomerAddressArgs = {
  id: Scalars['Int']['input'];
  input?: InputMaybe<CustomerAddressInput>;
};


export type MutationupdateCustomerEmailArgs = {
  email: Scalars['String']['input'];
  password: Scalars['String']['input'];
};


export type MutationupdateCustomerV2Args = {
  input: CustomerUpdateInput;
};


export type MutationupdateImageArgs = {
  id: Scalars['ID']['input'];
  input?: InputMaybe<ImageUpdateInput>;
};


export type MutationupdatePageArgs = {
  id: Scalars['ID']['input'];
  input?: InputMaybe<PageUpdateInput>;
};


export type MutationupdatePageAccordionSectionArgs = {
  id: Scalars['ID']['input'];
  input?: InputMaybe<PageAccordionSectionUpdateInput>;
};


export type MutationupdatePageBannerArgs = {
  id: Scalars['ID']['input'];
  input?: InputMaybe<PageBannerUpdateInput>;
};


export type MutationupdatePageBannerCategoryArgs = {
  id: Scalars['ID']['input'];
  input?: InputMaybe<PageBannerCategoryUpdateInput>;
};


export type MutationupdatePageBannerSectionArgs = {
  id: Scalars['ID']['input'];
  input?: InputMaybe<PageBannerSectionUpdateInput>;
};


export type MutationupdatePageBlockSectionArgs = {
  id: Scalars['ID']['input'];
  input?: InputMaybe<PageBlockSectionUpdateInput>;
};


export type MutationupdatePageCarouselSectionArgs = {
  id: Scalars['ID']['input'];
  input?: InputMaybe<PageCarouselSectionUpdateInput>;
};


export type MutationupdatePageChipSectionArgs = {
  id: Scalars['ID']['input'];
  input?: InputMaybe<PageChipSectionUpdateInput>;
};


export type MutationupdatePageExcerptSectionArgs = {
  id: Scalars['ID']['input'];
  input?: InputMaybe<PageExcerptSectionUpdateInput>;
};


export type MutationupdatePageHeadingSectionArgs = {
  id: Scalars['ID']['input'];
  input?: InputMaybe<PageHeadingSectionUpdateInput>;
};


export type MutationupdatePageHeroSectionArgs = {
  id: Scalars['ID']['input'];
  input?: InputMaybe<PageHeroSectionUpdateInput>;
};


export type MutationupdatePageIconSectionArgs = {
  id: Scalars['ID']['input'];
  input?: InputMaybe<PageIconSectionUpdateInput>;
};


export type MutationupdatePageLinkSectionArgs = {
  id: Scalars['ID']['input'];
  input?: InputMaybe<PageLinkSectionUpdateInput>;
};


export type MutationupdatePageMarkupSectionArgs = {
  id: Scalars['ID']['input'];
  input?: InputMaybe<PageMarkupSectionUpdateInput>;
};


export type MutationupdatePageMediaSectionArgs = {
  id: Scalars['ID']['input'];
  input?: InputMaybe<PageMediaSectionUpdateInput>;
};


export type MutationupdatePageMyDiscountsSectionArgs = {
  id: Scalars['ID']['input'];
  input?: InputMaybe<PageMyDiscountsSectionUpdateInput>;
};


export type MutationupdatePageMyShopSectionArgs = {
  id: Scalars['ID']['input'];
  input?: InputMaybe<PageMyShopSectionUpdateInput>;
};


export type MutationupdatePageNotificationSectionArgs = {
  id: Scalars['ID']['input'];
  input?: InputMaybe<PageNotificationSectionUpdateInput>;
};


export type MutationupdatePagePlainTextSectionArgs = {
  id: Scalars['ID']['input'];
  input?: InputMaybe<PagePlainTextSectionUpdateInput>;
};


export type MutationupdatePageProductSliderSectionArgs = {
  id: Scalars['ID']['input'];
  input?: InputMaybe<PageProductSliderSectionUpdateInput>;
};


export type MutationupdatePageQuestionGroupArgs = {
  id: Scalars['ID']['input'];
  input?: InputMaybe<PageQuestionGroupUpdateInput>;
};


export type MutationupdatePageQuestionSectionArgs = {
  id: Scalars['ID']['input'];
  input?: InputMaybe<PageQuestionSectionUpdateInput>;
};


export type MutationupdatePageSectionGroupArgs = {
  id: Scalars['ID']['input'];
  input?: InputMaybe<PageSectionGroupUpdateInput>;
};


export type MutationupdatePageSiteNoticeSectionArgs = {
  id: Scalars['ID']['input'];
  input?: InputMaybe<PageSiteNoticeSectionUpdateInput>;
};


export type MutationupdatePageSplitTextSectionArgs = {
  id: Scalars['ID']['input'];
  input?: InputMaybe<PageSplitTextSectionUpdateInput>;
};


export type MutationupdatePageTimetableSectionArgs = {
  id: Scalars['ID']['input'];
  input?: InputMaybe<PageTimetableSectionUpdateInput>;
};


export type MutationupdatePeachPaymentsCardNickArgs = {
  input: PeachPaymentsCartNickChangeInput;
};


export type MutationupdateProductsInWishlistArgs = {
  wishlistId: Scalars['ID']['input'];
  wishlistItems: Array<WishlistItemUpdateInput>;
};


export type MutationupdateWidgetContactUsArgs = {
  id: Scalars['ID']['input'];
  input?: InputMaybe<WidgetContactUsUpdateInput>;
};


export type MutationupdateWidgetDropDownCartArgs = {
  id: Scalars['ID']['input'];
  input?: InputMaybe<WidgetDropDownCartUpdateInput>;
};


export type MutationupdateWidgetProductDetailsAddToCartArgs = {
  id: Scalars['ID']['input'];
  input?: InputMaybe<WidgetProductDetailsAddToCartUpdateInput>;
};


export type MutationupdateWidgetSiteWideBannerArgs = {
  id: Scalars['ID']['input'];
  input?: InputMaybe<WidgetSiteWideBannerUpdateInput>;
};


export type MutationuseRewardPointsArgs = {
  input?: InputMaybe<UseRewardPointsInput>;
};


export type MutationvalidateLoginAsCustomerArgs = {
  input: ValidateLoginAsCustomerInput;
};


export type MutationverifyMobileArgs = {
  input: VerifyMobileInput;
};

export type Navigation = {
  __typename: 'Navigation';
  dropDownCartDisplayType: DropDownCartDisplayType;
  id: Scalars['ID']['output'];
  isTest: Scalars['Boolean']['output'];
  mobileMenuDisplayState: MobileMenuDisplayEnum;
  navigationOpen: Scalars['Boolean']['output'];
  path: Scalars['String']['output'];
  preventScroll: Scalars['Boolean']['output'];
  searchOpen: Scalars['Boolean']['output'];
  showUserMenu: Scalars['Boolean']['output'];
};

/** Contains an error message when an invalid UID was specified. */
export type NoSuchEntityUidError = ErrorInterface & {
  __typename: 'NoSuchEntityUidError';
  /** The returned error message. */
  message: Scalars['String']['output'];
  /** The specified invalid unique ID of an object. */
  uid: Scalars['ID']['output'];
};

/** Contains the order ID. */
export type Order = {
  __typename: 'Order';
  /** @deprecated Use `order_number` instead. */
  orderId: Maybe<Scalars['String']['output']>;
  /** The unique ID for an `Order` object. */
  orderNumber: Scalars['String']['output'];
};

/** Contains detailed information about an order's billing and shipping addresses. */
export type OrderAddress = {
  __typename: 'OrderAddress';
  /** The customer's prefered address group */
  addressGroup: Maybe<AddressGroupEnum>;
  /** The city or town. */
  city: Scalars['String']['output'];
  /** The customer's company. */
  company: Maybe<Scalars['String']['output']>;
  /** The customer's country. */
  countryCode: Maybe<CountryCodeEnum>;
  /** The customer's delivery note for the address */
  deliveryNote: Maybe<Scalars['String']['output']>;
  /** The fax number. */
  fax: Maybe<Scalars['String']['output']>;
  /** The first name of the person associated with the shipping/billing address. */
  firstname: Scalars['String']['output'];
  /** The family name of the person associated with the shipping/billing address. */
  lastname: Scalars['String']['output'];
  /** The middle name of the person associated with the shipping/billing address. */
  middlename: Maybe<Scalars['String']['output']>;
  /** The customer's ZIP or postal code. */
  postcode: Maybe<Scalars['String']['output']>;
  /** An honorific, such as Dr., Mr., or Mrs. */
  prefix: Maybe<Scalars['String']['output']>;
  /** The state or province name. */
  region: Maybe<Scalars['String']['output']>;
  /** The unique ID for a `Region` object of a pre-defined region. */
  regionId: Maybe<Scalars['ID']['output']>;
  /** An array of strings that define the street number and name. */
  street: Array<Maybe<Scalars['String']['output']>>;
  /** The customer's suburb */
  suburb: Maybe<Scalars['String']['output']>;
  /** A value such as Sr., Jr., or III. */
  suffix: Maybe<Scalars['String']['output']>;
  /** The telephone number. */
  telephone: Maybe<Scalars['String']['output']>;
  /** The alternative telephone number */
  telephoneAlt: Maybe<Scalars['String']['output']>;
  /** The customer's Value-added tax (VAT) number (for corporate customers). */
  vatId: Maybe<Scalars['String']['output']>;
};

export enum OrderDirectionEnum {
  /** Low */
  ASC = 'ASC',
  /** High */
  DESC = 'DESC'
}

export type OrderItem = OrderItemInterface & {
  __typename: 'OrderItem';
  coverImage: Image;
  /** The final discount information for the product. */
  discounts: Maybe<Array<Maybe<Discount>>>;
  /** The entered option for the base product, such as a logo or image. */
  enteredOptions: Maybe<Array<Maybe<OrderItemOption>>>;
  /** The selected gift message for the order item */
  giftMessage: Maybe<GiftMessage>;
  /** The unique ID for an `OrderItemInterface` object. */
  id: Scalars['ID']['output'];
  /** The product image */
  productImage: Maybe<ProductImageV2>;
  /** The name of the base product. */
  productName: Maybe<Scalars['String']['output']>;
  /** The sale price of the base product, including selected options. */
  productSalePrice: Money;
  /** The SKU of the base product. */
  productSku: Scalars['String']['output'];
  /** The type of product, such as simple, configurable, etc. */
  productType: Maybe<Scalars['String']['output']>;
  /** URL key of the base product. */
  productUrlKey: Maybe<Scalars['String']['output']>;
  /** The number of canceled items. */
  quantityCanceled: Maybe<Scalars['Float']['output']>;
  /** The number of invoiced items. */
  quantityInvoiced: Maybe<Scalars['Float']['output']>;
  /** The number of units ordered for this item. */
  quantityOrdered: Maybe<Scalars['Float']['output']>;
  /** The number of refunded items. */
  quantityRefunded: Maybe<Scalars['Float']['output']>;
  /** The number of returned items. */
  quantityReturned: Maybe<Scalars['Float']['output']>;
  /** The number of shipped items. */
  quantityShipped: Maybe<Scalars['Float']['output']>;
  /** The selected options for the base product, such as color or size. */
  selectedOptions: Maybe<Array<Maybe<OrderItemOption>>>;
  /** The status of the order item. */
  status: Maybe<Scalars['String']['output']>;
};

/** Order item details. */
export type OrderItemInterface = {
  coverImage: Image;
  /** The final discount information for the product. */
  discounts: Maybe<Array<Maybe<Discount>>>;
  /** The entered option for the base product, such as a logo or image. */
  enteredOptions: Maybe<Array<Maybe<OrderItemOption>>>;
  /** The selected gift message for the order item */
  giftMessage: Maybe<GiftMessage>;
  /** The unique ID for an `OrderItemInterface` object. */
  id: Scalars['ID']['output'];
  /** The product image */
  productImage: Maybe<ProductImageV2>;
  /** The name of the base product. */
  productName: Maybe<Scalars['String']['output']>;
  /** The sale price of the base product, including selected options. */
  productSalePrice: Money;
  /** The SKU of the base product. */
  productSku: Scalars['String']['output'];
  /** The type of product, such as simple, configurable, etc. */
  productType: Maybe<Scalars['String']['output']>;
  /** URL key of the base product. */
  productUrlKey: Maybe<Scalars['String']['output']>;
  /** The number of canceled items. */
  quantityCanceled: Maybe<Scalars['Float']['output']>;
  /** The number of invoiced items. */
  quantityInvoiced: Maybe<Scalars['Float']['output']>;
  /** The number of units ordered for this item. */
  quantityOrdered: Maybe<Scalars['Float']['output']>;
  /** The number of refunded items. */
  quantityRefunded: Maybe<Scalars['Float']['output']>;
  /** The number of returned items. */
  quantityReturned: Maybe<Scalars['Float']['output']>;
  /** The number of shipped items. */
  quantityShipped: Maybe<Scalars['Float']['output']>;
  /** The selected options for the base product, such as color or size. */
  selectedOptions: Maybe<Array<Maybe<OrderItemOption>>>;
  /** The status of the order item. */
  status: Maybe<Scalars['String']['output']>;
};

/** Represents order item options like selected or entered. */
export type OrderItemOption = {
  __typename: 'OrderItemOption';
  /** The name of the option. */
  label: Scalars['String']['output'];
  /** The value of the option. */
  value: Scalars['String']['output'];
};

/** Contains details about the payment method used to pay for the order. */
export type OrderPaymentMethod = {
  __typename: 'OrderPaymentMethod';
  /** Additional data per payment method type. */
  additionalData: Maybe<Array<Maybe<KeyValue>>>;
  /** The label that describes the payment method. */
  name: Scalars['String']['output'];
  /** The payment method code that indicates how the order was paid for. */
  type: Scalars['String']['output'];
};

/** Contains order shipment details. */
export type OrderShipment = {
  __typename: 'OrderShipment';
  /** Comments added to the shipment. */
  comments: Maybe<Array<Maybe<SalesCommentItem>>>;
  /** The unique ID for a `OrderShipment` object. */
  id: Scalars['ID']['output'];
  /** An array of items included in the shipment. */
  items: Maybe<Array<Maybe<ShipmentItemInterface>>>;
  /** The sequential credit shipment number. */
  number: Scalars['String']['output'];
  /** An array of shipment tracking details. */
  tracking: Maybe<Array<Maybe<ShipmentTracking>>>;
};

/** Contains details about the sales total amounts used to calculate the final price. */
export type OrderTotal = {
  __typename: 'OrderTotal';
  /** The final base grand total amount in the base currency. */
  baseGrandTotal: Money;
  /** The applied discounts to the order. */
  discounts: Maybe<Array<Maybe<Discount>>>;
  /** The final total amount, including shipping, discounts, and taxes. */
  grandTotal: Money;
  /** Details about the shipping and handling costs for the order. */
  shippingHandling: Maybe<ShippingHandling>;
  /** The subtotal of the order, excluding shipping, discounts, and taxes. */
  subtotal: Money;
  /** The order tax details. */
  taxes: Maybe<Array<Maybe<TaxItem>>>;
  /** The shipping amount for the order. */
  totalShipping: Money;
  /** The amount of tax applied to the order. */
  totalTax: Money;
};

/** A set of relative URLs that Ozow will use in response to various actions during the authorization process. */
export type OzowInput = {
  /** The relative URL of the page that Ozow will redirect to when the buyer cancels the transaction in order to choose a different payment method. */
  cancelUrl: Scalars['String']['input'];
  /** The relative URL of the final confirmation page that Ozow will redirect to upon payment success. */
  successUrl: Scalars['String']['input'];
};

export type OzowRedirectDetailsV2 = {
  __typename: 'OzowRedirectDetailsV2';
  errorMessage: Maybe<Scalars['String']['output']>;
  paymentRequestId: Maybe<Scalars['String']['output']>;
  url: Maybe<Scalars['String']['output']>;
};

export type OzowRedirectInfoInput = {
  orderNumber: Scalars['String']['input'];
};

/** Represents a Page in the system */
export type Page = {
  __typename: 'Page';
  content: Maybe<Content>;
  createdAt: Scalars['DateTime']['output'];
  currentVersion: Maybe<Scalars['Int']['output']>;
  currentVersionChange: Maybe<Scalars['DateTime']['output']>;
  id: Scalars['ID']['output'];
  /** The name of the Page to identify it in here */
  name: Scalars['String']['output'];
  nextVersion: Maybe<Scalars['Int']['output']>;
  nextVersionChange: Maybe<Scalars['DateTime']['output']>;
  /** The type of page (Content Page, Category, Brand or Ingredient) */
  pageType: PageTypeEnum;
  scheduledVersions: Scalars['Int']['output'];
  updatedAt: Scalars['DateTime']['output'];
  versions: Maybe<Array<Content>>;
};

/** A Section which groups multiple question / answer style accordion elements into a composed layout */
export type PageAccordionSection = PageSection & {
  __typename: 'PageAccordionSection';
  createdAt: Scalars['DateTime']['output'];
  groups: Maybe<Array<PageSectionGroup>>;
  id: Scalars['ID']['output'];
  identifier: Maybe<Scalars['String']['output']>;
  /** Set weather the page section should hide or show on diferent device types */
  isLive: Scalars['Boolean']['output'];
  /** The name to identify this page section in here */
  name: Scalars['String']['output'];
  questions: Maybe<Array<PageQuestionGroup>>;
  quickLinks: Maybe<Array<PageLink>>;
  shouldShow: PageDeviceBooleanValue;
  title: Maybe<Scalars['String']['output']>;
  updatedAt: Scalars['DateTime']['output'];
  usedIn: Array<SectionUsedIn>;
  userStatus: PageUserStatus;
};

export type PageAccordionSectionAddInput = {
  groups?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** The name to identify this page section in here */
  name: Scalars['String']['input'];
  questions?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  shouldShow: PageDeviceBooleanValueInput;
  title?: InputMaybe<Scalars['String']['input']>;
  userStatus: PageUserStatus;
};

export type PageAccordionSectionFilterInput = {
  createdAt?: InputMaybe<DateTimeEqualsInRangeFilterInput>;
  id?: InputMaybe<IDEqualsInFilterInput>;
  identifier?: InputMaybe<StringEqualsInRangeFilterInput>;
  search?: InputMaybe<Scalars['String']['input']>;
  updatedAt?: InputMaybe<DateTimeEqualsInRangeFilterInput>;
};

export type PageAccordionSectionList = {
  __typename: 'PageAccordionSectionList';
  limit: Scalars['Int']['output'];
  list: Array<PageAccordionSection>;
  skip: Scalars['Int']['output'];
  total: Scalars['Int']['output'];
};

export type PageAccordionSectionOrderInput = {
  createdAt?: InputMaybe<OrderDirectionEnum>;
  id?: InputMaybe<OrderDirectionEnum>;
  name?: InputMaybe<OrderDirectionEnum>;
  updatedAt?: InputMaybe<OrderDirectionEnum>;
};

export type PageAccordionSectionUpdateInput = {
  groups?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** The name to identify this page section in here */
  name?: InputMaybe<Scalars['String']['input']>;
  questions?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  shouldShow?: InputMaybe<PageDeviceBooleanValueInput>;
  title?: InputMaybe<Scalars['String']['input']>;
  userStatus?: InputMaybe<PageUserStatus>;
};

export type PageAccordionSectionUpdates = {
  __typename: 'PageAccordionSectionUpdates';
  id: Scalars['ID']['output'];
  item: Maybe<PageAccordionSection>;
  type: SimpleUpdateTypeEnum;
};

export type PageAddInput = {
  /** The name of the Page to identify it in here */
  name: Scalars['String']['input'];
  /** The type of page (Content Page, Category, Brand or Ingredient) */
  pageType: PageTypeEnum;
  versions?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
};

export type PageBanner = {
  __typename: 'PageBanner';
  backgroundColor: Maybe<Scalars['Color']['output']>;
  bannerType: PageBannerTypeEnum;
  buttonStyle: Maybe<PageButtonStyle>;
  categories: Maybe<Array<PageBannerCategory>>;
  createdAt: Scalars['DateTime']['output'];
  description: Maybe<Scalars['HTML']['output']>;
  foregroundColor: Maybe<Scalars['Color']['output']>;
  id: Scalars['ID']['output'];
  image: Image;
  layout: Maybe<PageBannerLayoutEnum>;
  links: Maybe<Array<PageLink>>;
  mobileImage: Maybe<Image>;
  secondaryBackgroundColor: Maybe<Scalars['Color']['output']>;
  sections: Maybe<Array<PageBannerSection>>;
  subTitle: Maybe<Scalars['String']['output']>;
  subTitleAlignment: Maybe<PageHorizontalAlignment>;
  title: Maybe<Scalars['String']['output']>;
  titleAlignment: Maybe<PageHorizontalAlignment>;
  updatedAt: Scalars['DateTime']['output'];
};

export type PageBannerAddInput = {
  backgroundColor?: InputMaybe<Scalars['Color']['input']>;
  bannerType: PageBannerTypeEnum;
  buttonStyle?: InputMaybe<PageButtonStyle>;
  categories?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  description?: InputMaybe<Scalars['HTML']['input']>;
  foregroundColor?: InputMaybe<Scalars['Color']['input']>;
  image: Scalars['ID']['input'];
  layout?: InputMaybe<PageBannerLayoutEnum>;
  links?: InputMaybe<Array<InputMaybe<PageLinkInput>>>;
  mobileImage?: InputMaybe<Scalars['ID']['input']>;
  secondaryBackgroundColor?: InputMaybe<Scalars['Color']['input']>;
  sections?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  subTitle?: InputMaybe<Scalars['String']['input']>;
  subTitleAlignment?: InputMaybe<PageHorizontalAlignment>;
  title?: InputMaybe<Scalars['String']['input']>;
  titleAlignment?: InputMaybe<PageHorizontalAlignment>;
};

export type PageBannerCategory = {
  __typename: 'PageBannerCategory';
  banners: Maybe<Array<PageBanner>>;
  createdAt: Scalars['DateTime']['output'];
  description: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  updatedAt: Scalars['DateTime']['output'];
};

export type PageBannerCategoryAddInput = {
  banners?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  description?: InputMaybe<Scalars['String']['input']>;
  name: Scalars['String']['input'];
};

export type PageBannerCategoryFilterInput = {
  createdAt?: InputMaybe<DateTimeEqualsInRangeFilterInput>;
  id?: InputMaybe<IDEqualsInFilterInput>;
  search?: InputMaybe<Scalars['String']['input']>;
  updatedAt?: InputMaybe<DateTimeEqualsInRangeFilterInput>;
};

export type PageBannerCategoryList = {
  __typename: 'PageBannerCategoryList';
  limit: Scalars['Int']['output'];
  list: Array<PageBannerCategory>;
  skip: Scalars['Int']['output'];
  total: Scalars['Int']['output'];
};

export type PageBannerCategoryOrderInput = {
  createdAt?: InputMaybe<OrderDirectionEnum>;
  id?: InputMaybe<OrderDirectionEnum>;
  updatedAt?: InputMaybe<OrderDirectionEnum>;
};

export type PageBannerCategoryUpdateInput = {
  banners?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  description?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
};

export type PageBannerCategoryUpdates = {
  __typename: 'PageBannerCategoryUpdates';
  id: Scalars['ID']['output'];
  item: Maybe<PageBannerCategory>;
  type: SimpleUpdateTypeEnum;
};

export type PageBannerFilterInput = {
  categories?: InputMaybe<IDContainsFilterInput>;
  createdAt?: InputMaybe<DateTimeEqualsInRangeFilterInput>;
  id?: InputMaybe<IDEqualsInFilterInput>;
  search?: InputMaybe<Scalars['String']['input']>;
  updatedAt?: InputMaybe<DateTimeEqualsInRangeFilterInput>;
};

export enum PageBannerLayoutEnum {
  /** Image Left */
  IMAGE_LEFT = 'IMAGE_LEFT',
  /** Image Right */
  IMAGE_RIGHT = 'IMAGE_RIGHT'
}

export type PageBannerList = {
  __typename: 'PageBannerList';
  limit: Scalars['Int']['output'];
  list: Array<PageBanner>;
  skip: Scalars['Int']['output'];
  total: Scalars['Int']['output'];
};

export type PageBannerOrderInput = {
  createdAt?: InputMaybe<OrderDirectionEnum>;
  id?: InputMaybe<OrderDirectionEnum>;
  title?: InputMaybe<OrderDirectionEnum>;
  updatedAt?: InputMaybe<OrderDirectionEnum>;
};

/** A Section which displays banners, either a single banner or mutiple banners in a carousel */
export type PageBannerSection = PageSection & {
  __typename: 'PageBannerSection';
  banners: Maybe<Array<PageBanner>>;
  createdAt: Scalars['DateTime']['output'];
  groups: Maybe<Array<PageSectionGroup>>;
  id: Scalars['ID']['output'];
  identifier: Maybe<Scalars['String']['output']>;
  /** Set weather the page section should hide or show on diferent device types */
  isLive: Scalars['Boolean']['output'];
  /** The name to identify this page section in here */
  name: Scalars['String']['output'];
  shouldShow: PageDeviceBooleanValue;
  updatedAt: Scalars['DateTime']['output'];
  usedIn: Array<SectionUsedIn>;
  userStatus: PageUserStatus;
};

export type PageBannerSectionAddInput = {
  banners?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  groups?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** The name to identify this page section in here */
  name: Scalars['String']['input'];
  shouldShow: PageDeviceBooleanValueInput;
  userStatus: PageUserStatus;
};

export type PageBannerSectionFilterInput = {
  createdAt?: InputMaybe<DateTimeEqualsInRangeFilterInput>;
  id?: InputMaybe<IDEqualsInFilterInput>;
  identifier?: InputMaybe<StringEqualsInRangeFilterInput>;
  search?: InputMaybe<Scalars['String']['input']>;
  updatedAt?: InputMaybe<DateTimeEqualsInRangeFilterInput>;
};

export type PageBannerSectionList = {
  __typename: 'PageBannerSectionList';
  limit: Scalars['Int']['output'];
  list: Array<PageBannerSection>;
  skip: Scalars['Int']['output'];
  total: Scalars['Int']['output'];
};

export type PageBannerSectionOrderInput = {
  createdAt?: InputMaybe<OrderDirectionEnum>;
  id?: InputMaybe<OrderDirectionEnum>;
  name?: InputMaybe<OrderDirectionEnum>;
  updatedAt?: InputMaybe<OrderDirectionEnum>;
};

export type PageBannerSectionUpdateInput = {
  banners?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  groups?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** The name to identify this page section in here */
  name?: InputMaybe<Scalars['String']['input']>;
  shouldShow?: InputMaybe<PageDeviceBooleanValueInput>;
  userStatus?: InputMaybe<PageUserStatus>;
};

export type PageBannerSectionUpdates = {
  __typename: 'PageBannerSectionUpdates';
  id: Scalars['ID']['output'];
  item: Maybe<PageBannerSection>;
  type: SimpleUpdateTypeEnum;
};

export enum PageBannerTypeEnum {
  /** Full Image */
  FULL_IMAGE = 'FULL_IMAGE',
  /** Image Only */
  IMAGE_ONLY = 'IMAGE_ONLY',
  /** Split Large Image */
  SPLIT_LARGE_IMAGE = 'SPLIT_LARGE_IMAGE',
  /** Split Small Image */
  SPLIT_SMALL_IMAGE = 'SPLIT_SMALL_IMAGE'
}

export type PageBannerUpdateInput = {
  backgroundColor?: InputMaybe<Scalars['Color']['input']>;
  bannerType?: InputMaybe<PageBannerTypeEnum>;
  buttonStyle?: InputMaybe<PageButtonStyle>;
  categories?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  description?: InputMaybe<Scalars['HTML']['input']>;
  foregroundColor?: InputMaybe<Scalars['Color']['input']>;
  image?: InputMaybe<Scalars['ID']['input']>;
  layout?: InputMaybe<PageBannerLayoutEnum>;
  links?: InputMaybe<Array<InputMaybe<PageLinkInput>>>;
  mobileImage?: InputMaybe<Scalars['ID']['input']>;
  secondaryBackgroundColor?: InputMaybe<Scalars['Color']['input']>;
  sections?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  subTitle?: InputMaybe<Scalars['String']['input']>;
  subTitleAlignment?: InputMaybe<PageHorizontalAlignment>;
  title?: InputMaybe<Scalars['String']['input']>;
  titleAlignment?: InputMaybe<PageHorizontalAlignment>;
};

export type PageBannerUpdates = {
  __typename: 'PageBannerUpdates';
  id: Scalars['ID']['output'];
  item: Maybe<PageBanner>;
  type: SimpleUpdateTypeEnum;
};

export type PageBlock = {
  __typename: 'PageBlock';
  /** The name of the Author */
  author: Maybe<Scalars['String']['output']>;
  /** The background color for the block */
  backgroundColor: Maybe<Scalars['Color']['output']>;
  /** The description to appear in the blocks content */
  description: Maybe<Scalars['HTML']['output']>;
  /** The foreground color of the block */
  foregroundColor: Maybe<Scalars['Color']['output']>;
  /** The horizontal alignement of the blocks content */
  horizontalAlignment: Maybe<PageHorizontalAlignment>;
  /** The link to follow if the block is clicked */
  href: Maybe<Scalars['URI']['output']>;
  id: Scalars['ID']['output'];
  /** The blocks image */
  image: Maybe<Image>;
  /** The size of the image in the block */
  imageSize: Maybe<PageSize>;
  /** The button / link to appear on the block */
  link: Maybe<PageLink>;
  /** The rating value to show */
  rating: Maybe<Scalars['Float']['output']>;
  /** The blocks second image */
  secondImage: Maybe<Image>;
  /** The size of the second image in the block */
  secondImageSize: Maybe<PageSize>;
  /** The Blocks second title (displayed below title) */
  secondTitle: Maybe<Scalars['String']['output']>;
  /** The Blocs Title */
  title: Maybe<Scalars['String']['output']>;
};

export type PageBlockInput = {
  author?: InputMaybe<Scalars['String']['input']>;
  backgroundColor?: InputMaybe<Scalars['Color']['input']>;
  description?: InputMaybe<Scalars['HTML']['input']>;
  foregroundColor?: InputMaybe<Scalars['Color']['input']>;
  horizontalAlignment?: InputMaybe<PageHorizontalAlignment>;
  href?: InputMaybe<Scalars['URI']['input']>;
  image?: InputMaybe<Scalars['ID']['input']>;
  imageSize?: InputMaybe<PageSize>;
  link?: InputMaybe<PageLinkInput>;
  rating?: InputMaybe<Scalars['Float']['input']>;
  secondImage?: InputMaybe<Scalars['ID']['input']>;
  secondImageSize?: InputMaybe<PageSize>;
  secondTitle?: InputMaybe<Scalars['String']['input']>;
  title?: InputMaybe<Scalars['String']['input']>;
};

export enum PageBlockLayoutTypeEnum {
  /** Grid */
  GRID = 'GRID',
  /** Slider */
  SLIDER = 'SLIDER'
}

/** A Section which displays blocks of content arranged in either a grid or carousel format */
export type PageBlockSection = PageSection & {
  __typename: 'PageBlockSection';
  blockType: PageBlockType;
  blocks: Maybe<Array<PageBlock>>;
  columns: PageDeviceIntValue;
  createdAt: Scalars['DateTime']['output'];
  description: Maybe<Scalars['HTML']['output']>;
  gap: PageDeviceIntValue;
  groups: Maybe<Array<PageSectionGroup>>;
  id: Scalars['ID']['output'];
  identifier: Maybe<Scalars['String']['output']>;
  /** Set weather the page section should hide or show on diferent device types */
  isLive: Scalars['Boolean']['output'];
  /** The name to identify this page section in here */
  name: Scalars['String']['output'];
  rows: PageDeviceIntValue;
  shouldShow: PageDeviceBooleanValue;
  title: Maybe<Scalars['String']['output']>;
  updatedAt: Scalars['DateTime']['output'];
  usedIn: Array<SectionUsedIn>;
  userStatus: PageUserStatus;
};

export type PageBlockSectionAddInput = {
  blockType: PageBlockType;
  blocks?: InputMaybe<Array<InputMaybe<PageBlockInput>>>;
  columns: PageDeviceIntValueInput;
  description?: InputMaybe<Scalars['HTML']['input']>;
  gap: PageDeviceIntValueInput;
  groups?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** The name to identify this page section in here */
  name: Scalars['String']['input'];
  rows: PageDeviceIntValueInput;
  shouldShow: PageDeviceBooleanValueInput;
  title?: InputMaybe<Scalars['String']['input']>;
  userStatus: PageUserStatus;
};

export type PageBlockSectionFilterInput = {
  createdAt?: InputMaybe<DateTimeEqualsInRangeFilterInput>;
  id?: InputMaybe<IDEqualsInFilterInput>;
  identifier?: InputMaybe<StringEqualsInRangeFilterInput>;
  search?: InputMaybe<Scalars['String']['input']>;
  updatedAt?: InputMaybe<DateTimeEqualsInRangeFilterInput>;
};

export type PageBlockSectionList = {
  __typename: 'PageBlockSectionList';
  limit: Scalars['Int']['output'];
  list: Array<PageBlockSection>;
  skip: Scalars['Int']['output'];
  total: Scalars['Int']['output'];
};

export type PageBlockSectionOrderInput = {
  createdAt?: InputMaybe<OrderDirectionEnum>;
  id?: InputMaybe<OrderDirectionEnum>;
  name?: InputMaybe<OrderDirectionEnum>;
  updatedAt?: InputMaybe<OrderDirectionEnum>;
};

export type PageBlockSectionUpdateInput = {
  blockType?: InputMaybe<PageBlockType>;
  blocks?: InputMaybe<Array<InputMaybe<PageBlockInput>>>;
  columns?: InputMaybe<PageDeviceIntValueInput>;
  description?: InputMaybe<Scalars['HTML']['input']>;
  gap?: InputMaybe<PageDeviceIntValueInput>;
  groups?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** The name to identify this page section in here */
  name?: InputMaybe<Scalars['String']['input']>;
  rows?: InputMaybe<PageDeviceIntValueInput>;
  shouldShow?: InputMaybe<PageDeviceBooleanValueInput>;
  title?: InputMaybe<Scalars['String']['input']>;
  userStatus?: InputMaybe<PageUserStatus>;
};

export type PageBlockSectionUpdates = {
  __typename: 'PageBlockSectionUpdates';
  id: Scalars['ID']['output'];
  item: Maybe<PageBlockSection>;
  type: SimpleUpdateTypeEnum;
};

export enum PageBlockType {
  /** Award */
  AWARD = 'AWARD',
  /** Banner */
  BANNER = 'BANNER',
  /** BlogCard */
  BLOGCARD = 'BLOGCARD',
  /** Brand */
  BRAND = 'BRAND',
  /** Card */
  CARD = 'CARD',
  /** Details */
  DETAILS = 'DETAILS',
  /** Horizontal */
  HORIZONTAL = 'HORIZONTAL',
  /** Icon */
  ICON = 'ICON',
  /** IconText */
  ICONTEXT = 'ICONTEXT',
  /** Image */
  IMAGE = 'IMAGE',
  /** ImageTitle */
  IMAGETITLE = 'IMAGETITLE',
  /** MobileAppMessage */
  MOBILEAPPMESSAGE = 'MOBILEAPPMESSAGE',
  /** Rating */
  RATING = 'RATING',
  /** Text */
  TEXT = 'TEXT',
  /** Title */
  TITLE = 'TITLE'
}

export type PageBuilder = {
  __typename: 'PageBuilder';
  colapsedSectionGroups: Array<Scalars['String']['output']>;
  colapsedSections: Array<Scalars['String']['output']>;
  contentFetchId: Scalars['String']['output'];
  contentId: Maybe<Scalars['String']['output']>;
  editingSection: Maybe<Scalars['String']['output']>;
  editingSectionGroup: Maybe<Scalars['String']['output']>;
  editingSectionType: Maybe<Scalars['String']['output']>;
  groupEditingSections: Maybe<Scalars['String']['output']>;
  hiddenSectionCount: Scalars['Int']['output'];
  id: Scalars['ID']['output'];
  isEditing: Scalars['Boolean']['output'];
  isMaster: Scalars['Boolean']['output'];
  loading: Scalars['Boolean']['output'];
  newSection: Scalars['Boolean']['output'];
  newSectionGroup: Scalars['Boolean']['output'];
  sessionId: Scalars['String']['output'];
  showHidden: Scalars['Boolean']['output'];
  sizeClass: DeviceTypeEnum;
  slaveReady: Scalars['Boolean']['output'];
};

export enum PageBuilderFormType {
  /** Details */
  DETAILS = 'DETAILS',
  /** Layout */
  LAYOUT = 'LAYOUT'
}

export enum PageButtonStyle {
  /** Inverted */
  INVERTED = 'INVERTED',
  /** Primary */
  PRIMARY = 'PRIMARY',
  /** Solid */
  SOLID = 'SOLID'
}

/** A Section which displays one static and one carousel block of content */
export type PageCarouselSection = PageSection & {
  __typename: 'PageCarouselSection';
  createdAt: Scalars['DateTime']['output'];
  dynamicBlock: Maybe<PageDynamicCarousel>;
  groups: Maybe<Array<PageSectionGroup>>;
  id: Scalars['ID']['output'];
  identifier: Maybe<Scalars['String']['output']>;
  /** Set weather the page section should hide or show on diferent device types */
  isLive: Scalars['Boolean']['output'];
  name: Scalars['String']['output'];
  shouldShow: PageDeviceBooleanValue;
  staticBlock: Maybe<PageStaticCarousel>;
  updatedAt: Scalars['DateTime']['output'];
  usedIn: Array<SectionUsedIn>;
  userStatus: PageUserStatus;
};

export type PageCarouselSectionAddInput = {
  dynamicBlock?: InputMaybe<PageDynamicCarouselInput>;
  groups?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  name: Scalars['String']['input'];
  shouldShow: PageDeviceBooleanValueInput;
  staticBlock?: InputMaybe<PageStaticCarouselInput>;
  userStatus: PageUserStatus;
};

export type PageCarouselSectionFilterInput = {
  createdAt?: InputMaybe<DateTimeEqualsInRangeFilterInput>;
  id?: InputMaybe<IDEqualsInFilterInput>;
  identifier?: InputMaybe<StringEqualsInRangeFilterInput>;
  search?: InputMaybe<Scalars['String']['input']>;
  updatedAt?: InputMaybe<DateTimeEqualsInRangeFilterInput>;
};

export type PageCarouselSectionList = {
  __typename: 'PageCarouselSectionList';
  limit: Scalars['Int']['output'];
  list: Array<PageCarouselSection>;
  skip: Scalars['Int']['output'];
  total: Scalars['Int']['output'];
};

export type PageCarouselSectionOrderInput = {
  createdAt?: InputMaybe<OrderDirectionEnum>;
  id?: InputMaybe<OrderDirectionEnum>;
  name?: InputMaybe<OrderDirectionEnum>;
  updatedAt?: InputMaybe<OrderDirectionEnum>;
};

export type PageCarouselSectionUpdateInput = {
  dynamicBlock?: InputMaybe<PageDynamicCarouselInput>;
  groups?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  name?: InputMaybe<Scalars['String']['input']>;
  shouldShow?: InputMaybe<PageDeviceBooleanValueInput>;
  staticBlock?: InputMaybe<PageStaticCarouselInput>;
  userStatus?: InputMaybe<PageUserStatus>;
};

export type PageCarouselSectionUpdates = {
  __typename: 'PageCarouselSectionUpdates';
  id: Scalars['ID']['output'];
  item: Maybe<PageCarouselSection>;
  type: SimpleUpdateTypeEnum;
};

export type PageChip = {
  __typename: 'PageChip';
  backgroundColor: Maybe<Scalars['Color']['output']>;
  foregroundColor: Maybe<Scalars['Color']['output']>;
  id: Scalars['ID']['output'];
  title: Scalars['String']['output'];
  url: Maybe<Scalars['URI']['output']>;
};

export type PageChipInput = {
  backgroundColor?: InputMaybe<Scalars['Color']['input']>;
  foregroundColor?: InputMaybe<Scalars['Color']['input']>;
  title: Scalars['String']['input'];
  url?: InputMaybe<Scalars['URI']['input']>;
};

/** A Section which displays chips or small bubles of text which can be linkable */
export type PageChipSection = PageSection & {
  __typename: 'PageChipSection';
  backgroundColor: Maybe<Scalars['Color']['output']>;
  chipStyle: Maybe<PageChipStyle>;
  chips: Maybe<Array<PageChip>>;
  createdAt: Scalars['DateTime']['output'];
  description: Maybe<Scalars['HTML']['output']>;
  foregroundColor: Maybe<Scalars['Color']['output']>;
  groups: Maybe<Array<PageSectionGroup>>;
  id: Scalars['ID']['output'];
  identifier: Maybe<Scalars['String']['output']>;
  /** Set weather the page section should hide or show on diferent device types */
  isLive: Scalars['Boolean']['output'];
  link: Maybe<PageLink>;
  /** The name to identify this page section in here */
  name: Scalars['String']['output'];
  shouldShow: PageDeviceBooleanValue;
  title: Maybe<Scalars['String']['output']>;
  updatedAt: Scalars['DateTime']['output'];
  usedIn: Array<SectionUsedIn>;
  userStatus: PageUserStatus;
};

export type PageChipSectionAddInput = {
  backgroundColor?: InputMaybe<Scalars['Color']['input']>;
  chipStyle?: InputMaybe<PageChipStyle>;
  chips?: InputMaybe<Array<InputMaybe<PageChipInput>>>;
  description?: InputMaybe<Scalars['HTML']['input']>;
  foregroundColor?: InputMaybe<Scalars['Color']['input']>;
  groups?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  link?: InputMaybe<PageLinkInput>;
  /** The name to identify this page section in here */
  name: Scalars['String']['input'];
  shouldShow: PageDeviceBooleanValueInput;
  title?: InputMaybe<Scalars['String']['input']>;
  userStatus: PageUserStatus;
};

export type PageChipSectionFilterInput = {
  createdAt?: InputMaybe<DateTimeEqualsInRangeFilterInput>;
  id?: InputMaybe<IDEqualsInFilterInput>;
  identifier?: InputMaybe<StringEqualsInRangeFilterInput>;
  search?: InputMaybe<Scalars['String']['input']>;
  updatedAt?: InputMaybe<DateTimeEqualsInRangeFilterInput>;
};

export type PageChipSectionList = {
  __typename: 'PageChipSectionList';
  limit: Scalars['Int']['output'];
  list: Array<PageChipSection>;
  skip: Scalars['Int']['output'];
  total: Scalars['Int']['output'];
};

export type PageChipSectionOrderInput = {
  createdAt?: InputMaybe<OrderDirectionEnum>;
  id?: InputMaybe<OrderDirectionEnum>;
  name?: InputMaybe<OrderDirectionEnum>;
  updatedAt?: InputMaybe<OrderDirectionEnum>;
};

export type PageChipSectionUpdateInput = {
  backgroundColor?: InputMaybe<Scalars['Color']['input']>;
  chipStyle?: InputMaybe<PageChipStyle>;
  chips?: InputMaybe<Array<InputMaybe<PageChipInput>>>;
  description?: InputMaybe<Scalars['HTML']['input']>;
  foregroundColor?: InputMaybe<Scalars['Color']['input']>;
  groups?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  link?: InputMaybe<PageLinkInput>;
  /** The name to identify this page section in here */
  name?: InputMaybe<Scalars['String']['input']>;
  shouldShow?: InputMaybe<PageDeviceBooleanValueInput>;
  title?: InputMaybe<Scalars['String']['input']>;
  userStatus?: InputMaybe<PageUserStatus>;
};

export type PageChipSectionUpdates = {
  __typename: 'PageChipSectionUpdates';
  id: Scalars['ID']['output'];
  item: Maybe<PageChipSection>;
  type: SimpleUpdateTypeEnum;
};

export enum PageChipStyle {
  /** Bordered */
  BORDERED = 'BORDERED',
  /** Standard */
  STANDARD = 'STANDARD'
}

export type PageColumnLayout = {
  __typename: 'PageColumnLayout';
  desktop: Scalars['Int']['output'];
  mobile: Scalars['Int']['output'];
  tablet: Scalars['Int']['output'];
};

export type PageDeviceBooleanValue = {
  __typename: 'PageDeviceBooleanValue';
  desktop: Maybe<Scalars['Boolean']['output']>;
  id: Scalars['ID']['output'];
  mobile: Maybe<Scalars['Boolean']['output']>;
  tablet: Maybe<Scalars['Boolean']['output']>;
};

export type PageDeviceBooleanValueInput = {
  desktop?: InputMaybe<Scalars['Boolean']['input']>;
  mobile?: InputMaybe<Scalars['Boolean']['input']>;
  tablet?: InputMaybe<Scalars['Boolean']['input']>;
};

export type PageDeviceFloatValue = {
  __typename: 'PageDeviceFloatValue';
  desktop: Maybe<Scalars['Float']['output']>;
  id: Scalars['ID']['output'];
  mobile: Maybe<Scalars['Float']['output']>;
  tablet: Maybe<Scalars['Float']['output']>;
};

export type PageDeviceFloatValueInput = {
  desktop?: InputMaybe<Scalars['Float']['input']>;
  mobile?: InputMaybe<Scalars['Float']['input']>;
  tablet?: InputMaybe<Scalars['Float']['input']>;
};

export type PageDeviceIntValue = {
  __typename: 'PageDeviceIntValue';
  desktop: Maybe<Scalars['Int']['output']>;
  id: Scalars['ID']['output'];
  mobile: Maybe<Scalars['Int']['output']>;
  tablet: Maybe<Scalars['Int']['output']>;
};

export type PageDeviceIntValueInput = {
  desktop?: InputMaybe<Scalars['Int']['input']>;
  mobile?: InputMaybe<Scalars['Int']['input']>;
  tablet?: InputMaybe<Scalars['Int']['input']>;
};

export type PageDynamicCarousel = {
  __typename: 'PageDynamicCarousel';
  backgroundColor: Maybe<Scalars['Color']['output']>;
  foregroundColor: Maybe<Scalars['Color']['output']>;
  id: Scalars['ID']['output'];
  links: Maybe<Array<Maybe<PageLink>>>;
  name: Scalars['String']['output'];
  plainText: Maybe<Array<Maybe<Scalars['String']['output']>>>;
};

export type PageDynamicCarouselInput = {
  backgroundColor?: InputMaybe<Scalars['Color']['input']>;
  foregroundColor?: InputMaybe<Scalars['Color']['input']>;
  links?: InputMaybe<Array<InputMaybe<PageLinkInput>>>;
  name: Scalars['String']['input'];
  plainText?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

/** A Section which displays a excerpt or testimonial */
export type PageExcerptSection = PageSection & {
  __typename: 'PageExcerptSection';
  attribution: Maybe<Scalars['String']['output']>;
  backgroundColor: Maybe<Scalars['Color']['output']>;
  createdAt: Scalars['DateTime']['output'];
  excerpt: Scalars['String']['output'];
  foregroundColor: Maybe<Scalars['Color']['output']>;
  groups: Maybe<Array<PageSectionGroup>>;
  id: Scalars['ID']['output'];
  identifier: Maybe<Scalars['String']['output']>;
  image: Maybe<Image>;
  /** Set weather the page section should hide or show on diferent device types */
  isLive: Scalars['Boolean']['output'];
  /** The name to identify this page section in here */
  name: Scalars['String']['output'];
  shouldShow: PageDeviceBooleanValue;
  updatedAt: Scalars['DateTime']['output'];
  usedIn: Array<SectionUsedIn>;
  userStatus: PageUserStatus;
};

export type PageExcerptSectionAddInput = {
  attribution?: InputMaybe<Scalars['String']['input']>;
  backgroundColor?: InputMaybe<Scalars['Color']['input']>;
  excerpt: Scalars['String']['input'];
  foregroundColor?: InputMaybe<Scalars['Color']['input']>;
  groups?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  image?: InputMaybe<Scalars['ID']['input']>;
  /** The name to identify this page section in here */
  name: Scalars['String']['input'];
  shouldShow: PageDeviceBooleanValueInput;
  userStatus: PageUserStatus;
};

export type PageExcerptSectionFilterInput = {
  createdAt?: InputMaybe<DateTimeEqualsInRangeFilterInput>;
  id?: InputMaybe<IDEqualsInFilterInput>;
  identifier?: InputMaybe<StringEqualsInRangeFilterInput>;
  search?: InputMaybe<Scalars['String']['input']>;
  updatedAt?: InputMaybe<DateTimeEqualsInRangeFilterInput>;
};

export type PageExcerptSectionList = {
  __typename: 'PageExcerptSectionList';
  limit: Scalars['Int']['output'];
  list: Array<PageExcerptSection>;
  skip: Scalars['Int']['output'];
  total: Scalars['Int']['output'];
};

export type PageExcerptSectionOrderInput = {
  createdAt?: InputMaybe<OrderDirectionEnum>;
  id?: InputMaybe<OrderDirectionEnum>;
  name?: InputMaybe<OrderDirectionEnum>;
  updatedAt?: InputMaybe<OrderDirectionEnum>;
};

export type PageExcerptSectionUpdateInput = {
  attribution?: InputMaybe<Scalars['String']['input']>;
  backgroundColor?: InputMaybe<Scalars['Color']['input']>;
  excerpt?: InputMaybe<Scalars['String']['input']>;
  foregroundColor?: InputMaybe<Scalars['Color']['input']>;
  groups?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  image?: InputMaybe<Scalars['ID']['input']>;
  /** The name to identify this page section in here */
  name?: InputMaybe<Scalars['String']['input']>;
  shouldShow?: InputMaybe<PageDeviceBooleanValueInput>;
  userStatus?: InputMaybe<PageUserStatus>;
};

export type PageExcerptSectionUpdates = {
  __typename: 'PageExcerptSectionUpdates';
  id: Scalars['ID']['output'];
  item: Maybe<PageExcerptSection>;
  type: SimpleUpdateTypeEnum;
};

export type PageFilterInput = {
  createdAt?: InputMaybe<DateTimeEqualsInRangeFilterInput>;
  id?: InputMaybe<IDEqualsInFilterInput>;
  name?: InputMaybe<StringEqualsInRangeFilterInput>;
  pageType?: InputMaybe<PageTypeEnumEqualsInRangeFilterInput>;
  search?: InputMaybe<Scalars['String']['input']>;
  updatedAt?: InputMaybe<DateTimeEqualsInRangeFilterInput>;
};

/** A Section which displays a heading from H1 to H9 */
export type PageHeadingSection = PageSection & {
  __typename: 'PageHeadingSection';
  backgroundColor: Maybe<Scalars['Color']['output']>;
  createdAt: Scalars['DateTime']['output'];
  description: Maybe<Scalars['HTML']['output']>;
  forUserMobileWelcomeMessage: Scalars['Boolean']['output'];
  foregroundColor: Maybe<Scalars['Color']['output']>;
  groups: Maybe<Array<PageSectionGroup>>;
  heading: Scalars['String']['output'];
  horizontalAlignment: Maybe<PageHorizontalAlignment>;
  id: Scalars['ID']['output'];
  identifier: Maybe<Scalars['String']['output']>;
  /** Set weather the page section should hide or show on diferent device types */
  isLive: Scalars['Boolean']['output'];
  /** The name to identify this page section in here */
  name: Scalars['String']['output'];
  shouldShow: PageDeviceBooleanValue;
  updatedAt: Scalars['DateTime']['output'];
  usedIn: Array<SectionUsedIn>;
  userStatus: PageUserStatus;
  variant: PageHeadingVariant;
};

export type PageHeadingSectionAddInput = {
  backgroundColor?: InputMaybe<Scalars['Color']['input']>;
  description?: InputMaybe<Scalars['HTML']['input']>;
  forUserMobileWelcomeMessage: Scalars['Boolean']['input'];
  foregroundColor?: InputMaybe<Scalars['Color']['input']>;
  groups?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  heading: Scalars['String']['input'];
  horizontalAlignment?: InputMaybe<PageHorizontalAlignment>;
  /** The name to identify this page section in here */
  name: Scalars['String']['input'];
  shouldShow: PageDeviceBooleanValueInput;
  userStatus: PageUserStatus;
  variant: PageHeadingVariant;
};

export type PageHeadingSectionFilterInput = {
  createdAt?: InputMaybe<DateTimeEqualsInRangeFilterInput>;
  id?: InputMaybe<IDEqualsInFilterInput>;
  identifier?: InputMaybe<StringEqualsInRangeFilterInput>;
  search?: InputMaybe<Scalars['String']['input']>;
  updatedAt?: InputMaybe<DateTimeEqualsInRangeFilterInput>;
};

export type PageHeadingSectionList = {
  __typename: 'PageHeadingSectionList';
  limit: Scalars['Int']['output'];
  list: Array<PageHeadingSection>;
  skip: Scalars['Int']['output'];
  total: Scalars['Int']['output'];
};

export type PageHeadingSectionOrderInput = {
  createdAt?: InputMaybe<OrderDirectionEnum>;
  id?: InputMaybe<OrderDirectionEnum>;
  name?: InputMaybe<OrderDirectionEnum>;
  updatedAt?: InputMaybe<OrderDirectionEnum>;
};

export type PageHeadingSectionUpdateInput = {
  backgroundColor?: InputMaybe<Scalars['Color']['input']>;
  description?: InputMaybe<Scalars['HTML']['input']>;
  forUserMobileWelcomeMessage?: InputMaybe<Scalars['Boolean']['input']>;
  foregroundColor?: InputMaybe<Scalars['Color']['input']>;
  groups?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  heading?: InputMaybe<Scalars['String']['input']>;
  horizontalAlignment?: InputMaybe<PageHorizontalAlignment>;
  /** The name to identify this page section in here */
  name?: InputMaybe<Scalars['String']['input']>;
  shouldShow?: InputMaybe<PageDeviceBooleanValueInput>;
  userStatus?: InputMaybe<PageUserStatus>;
  variant?: InputMaybe<PageHeadingVariant>;
};

export type PageHeadingSectionUpdates = {
  __typename: 'PageHeadingSectionUpdates';
  id: Scalars['ID']['output'];
  item: Maybe<PageHeadingSection>;
  type: SimpleUpdateTypeEnum;
};

export enum PageHeadingVariant {
  /** H1 */
  h1 = 'h1',
  /** H2 */
  h2 = 'h2',
  /** H3 */
  h3 = 'h3',
  /** H4 */
  h4 = 'h4',
  /** H5 */
  h5 = 'h5',
  /** H6 */
  h6 = 'h6',
  /** H7 */
  h7 = 'h7',
  /** H8 */
  h8 = 'h8',
  /** H9 */
  h9 = 'h9'
}

/** A Section which displays a hero or large image banner */
export type PageHeroSection = PageSection & {
  __typename: 'PageHeroSection';
  backgroundColor: Maybe<Scalars['Color']['output']>;
  createdAt: Scalars['DateTime']['output'];
  description: Maybe<Scalars['HTML']['output']>;
  foregroundColor: Maybe<Scalars['Color']['output']>;
  groups: Maybe<Array<PageSectionGroup>>;
  id: Scalars['ID']['output'];
  identifier: Maybe<Scalars['String']['output']>;
  image: Maybe<Image>;
  /** Set weather the page section should hide or show on diferent device types */
  isLive: Scalars['Boolean']['output'];
  mobileImage: Maybe<Image>;
  /** The name to identify this page section in here */
  name: Scalars['String']['output'];
  shouldShow: PageDeviceBooleanValue;
  subTitle: Maybe<Scalars['String']['output']>;
  title: Maybe<Scalars['String']['output']>;
  updatedAt: Scalars['DateTime']['output'];
  usedIn: Array<SectionUsedIn>;
  userStatus: PageUserStatus;
};

export type PageHeroSectionAddInput = {
  backgroundColor?: InputMaybe<Scalars['Color']['input']>;
  description?: InputMaybe<Scalars['HTML']['input']>;
  foregroundColor?: InputMaybe<Scalars['Color']['input']>;
  groups?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  image?: InputMaybe<Scalars['ID']['input']>;
  mobileImage?: InputMaybe<Scalars['ID']['input']>;
  /** The name to identify this page section in here */
  name: Scalars['String']['input'];
  shouldShow: PageDeviceBooleanValueInput;
  subTitle?: InputMaybe<Scalars['String']['input']>;
  title?: InputMaybe<Scalars['String']['input']>;
  userStatus: PageUserStatus;
};

export type PageHeroSectionFilterInput = {
  createdAt?: InputMaybe<DateTimeEqualsInRangeFilterInput>;
  id?: InputMaybe<IDEqualsInFilterInput>;
  identifier?: InputMaybe<StringEqualsInRangeFilterInput>;
  search?: InputMaybe<Scalars['String']['input']>;
  updatedAt?: InputMaybe<DateTimeEqualsInRangeFilterInput>;
};

export type PageHeroSectionList = {
  __typename: 'PageHeroSectionList';
  limit: Scalars['Int']['output'];
  list: Array<PageHeroSection>;
  skip: Scalars['Int']['output'];
  total: Scalars['Int']['output'];
};

export type PageHeroSectionOrderInput = {
  createdAt?: InputMaybe<OrderDirectionEnum>;
  id?: InputMaybe<OrderDirectionEnum>;
  name?: InputMaybe<OrderDirectionEnum>;
  updatedAt?: InputMaybe<OrderDirectionEnum>;
};

export type PageHeroSectionUpdateInput = {
  backgroundColor?: InputMaybe<Scalars['Color']['input']>;
  description?: InputMaybe<Scalars['HTML']['input']>;
  foregroundColor?: InputMaybe<Scalars['Color']['input']>;
  groups?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  image?: InputMaybe<Scalars['ID']['input']>;
  mobileImage?: InputMaybe<Scalars['ID']['input']>;
  /** The name to identify this page section in here */
  name?: InputMaybe<Scalars['String']['input']>;
  shouldShow?: InputMaybe<PageDeviceBooleanValueInput>;
  subTitle?: InputMaybe<Scalars['String']['input']>;
  title?: InputMaybe<Scalars['String']['input']>;
  userStatus?: InputMaybe<PageUserStatus>;
};

export type PageHeroSectionUpdates = {
  __typename: 'PageHeroSectionUpdates';
  id: Scalars['ID']['output'];
  item: Maybe<PageHeroSection>;
  type: SimpleUpdateTypeEnum;
};

export enum PageHorizontalAlignment {
  /** Center */
  CENTER = 'CENTER',
  /** Left */
  LEFT = 'LEFT',
  /** Right */
  RIGHT = 'RIGHT'
}

export type PageIcon = {
  __typename: 'PageIcon';
  href: Maybe<Scalars['URI']['output']>;
  icon: Image;
  id: Scalars['ID']['output'];
  title: Scalars['String']['output'];
};

export type PageIconInput = {
  href?: InputMaybe<Scalars['URI']['input']>;
  icon: Scalars['ID']['input'];
  title: Scalars['String']['input'];
};

/** A Section which displays a grid of icons and text */
export type PageIconSection = PageSection & {
  __typename: 'PageIconSection';
  backgroundColor: Maybe<Scalars['Color']['output']>;
  createdAt: Scalars['DateTime']['output'];
  foregroundColor: Maybe<Scalars['Color']['output']>;
  groups: Maybe<Array<PageSectionGroup>>;
  icons: Maybe<Array<PageIcon>>;
  id: Scalars['ID']['output'];
  identifier: Maybe<Scalars['String']['output']>;
  /** Set weather the page section should hide or show on diferent device types */
  isLive: Scalars['Boolean']['output'];
  /** The name to identify this page section in here */
  name: Scalars['String']['output'];
  shouldShow: PageDeviceBooleanValue;
  title: Maybe<Scalars['String']['output']>;
  updatedAt: Scalars['DateTime']['output'];
  usedIn: Array<SectionUsedIn>;
  userStatus: PageUserStatus;
};

export type PageIconSectionAddInput = {
  backgroundColor?: InputMaybe<Scalars['Color']['input']>;
  foregroundColor?: InputMaybe<Scalars['Color']['input']>;
  groups?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  icons?: InputMaybe<Array<InputMaybe<PageIconInput>>>;
  /** The name to identify this page section in here */
  name: Scalars['String']['input'];
  shouldShow: PageDeviceBooleanValueInput;
  title?: InputMaybe<Scalars['String']['input']>;
  userStatus: PageUserStatus;
};

export type PageIconSectionFilterInput = {
  createdAt?: InputMaybe<DateTimeEqualsInRangeFilterInput>;
  id?: InputMaybe<IDEqualsInFilterInput>;
  identifier?: InputMaybe<StringEqualsInRangeFilterInput>;
  search?: InputMaybe<Scalars['String']['input']>;
  updatedAt?: InputMaybe<DateTimeEqualsInRangeFilterInput>;
};

export type PageIconSectionList = {
  __typename: 'PageIconSectionList';
  limit: Scalars['Int']['output'];
  list: Array<PageIconSection>;
  skip: Scalars['Int']['output'];
  total: Scalars['Int']['output'];
};

export type PageIconSectionOrderInput = {
  createdAt?: InputMaybe<OrderDirectionEnum>;
  id?: InputMaybe<OrderDirectionEnum>;
  name?: InputMaybe<OrderDirectionEnum>;
  updatedAt?: InputMaybe<OrderDirectionEnum>;
};

export type PageIconSectionUpdateInput = {
  backgroundColor?: InputMaybe<Scalars['Color']['input']>;
  foregroundColor?: InputMaybe<Scalars['Color']['input']>;
  groups?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  icons?: InputMaybe<Array<InputMaybe<PageIconInput>>>;
  /** The name to identify this page section in here */
  name?: InputMaybe<Scalars['String']['input']>;
  shouldShow?: InputMaybe<PageDeviceBooleanValueInput>;
  title?: InputMaybe<Scalars['String']['input']>;
  userStatus?: InputMaybe<PageUserStatus>;
};

export type PageIconSectionUpdates = {
  __typename: 'PageIconSectionUpdates';
  id: Scalars['ID']['output'];
  item: Maybe<PageIconSection>;
  type: SimpleUpdateTypeEnum;
};

export type PageLink = {
  __typename: 'PageLink';
  id: Scalars['ID']['output'];
  title: Scalars['String']['output'];
  url: Maybe<Scalars['URI']['output']>;
};

export type PageLinkInput = {
  title: Scalars['String']['input'];
  url?: InputMaybe<Scalars['URI']['input']>;
};

/** A Section which displays a single link either as a link of a button */
export type PageLinkSection = PageSection & {
  __typename: 'PageLinkSection';
  LinkType: PageLinkType;
  backgroundColor: Maybe<Scalars['Color']['output']>;
  buttonStyle: Maybe<PageButtonStyle>;
  createdAt: Scalars['DateTime']['output'];
  foregroundColor: Maybe<Scalars['Color']['output']>;
  groups: Maybe<Array<PageSectionGroup>>;
  id: Scalars['ID']['output'];
  identifier: Maybe<Scalars['String']['output']>;
  /** Set weather the page section should hide or show on diferent device types */
  isLive: Scalars['Boolean']['output'];
  /** The name to identify this page section in here */
  name: Scalars['String']['output'];
  shouldShow: PageDeviceBooleanValue;
  title: Maybe<Scalars['String']['output']>;
  updatedAt: Scalars['DateTime']['output'];
  url: Maybe<Scalars['URI']['output']>;
  usedIn: Array<SectionUsedIn>;
  userStatus: PageUserStatus;
};

export type PageLinkSectionAddInput = {
  LinkType: PageLinkType;
  backgroundColor?: InputMaybe<Scalars['Color']['input']>;
  buttonStyle?: InputMaybe<PageButtonStyle>;
  foregroundColor?: InputMaybe<Scalars['Color']['input']>;
  groups?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** The name to identify this page section in here */
  name: Scalars['String']['input'];
  shouldShow: PageDeviceBooleanValueInput;
  title?: InputMaybe<Scalars['String']['input']>;
  url?: InputMaybe<Scalars['URI']['input']>;
  userStatus: PageUserStatus;
};

export type PageLinkSectionFilterInput = {
  createdAt?: InputMaybe<DateTimeEqualsInRangeFilterInput>;
  id?: InputMaybe<IDEqualsInFilterInput>;
  identifier?: InputMaybe<StringEqualsInRangeFilterInput>;
  search?: InputMaybe<Scalars['String']['input']>;
  updatedAt?: InputMaybe<DateTimeEqualsInRangeFilterInput>;
};

export type PageLinkSectionList = {
  __typename: 'PageLinkSectionList';
  limit: Scalars['Int']['output'];
  list: Array<PageLinkSection>;
  skip: Scalars['Int']['output'];
  total: Scalars['Int']['output'];
};

export type PageLinkSectionOrderInput = {
  createdAt?: InputMaybe<OrderDirectionEnum>;
  id?: InputMaybe<OrderDirectionEnum>;
  name?: InputMaybe<OrderDirectionEnum>;
  updatedAt?: InputMaybe<OrderDirectionEnum>;
};

export type PageLinkSectionUpdateInput = {
  LinkType?: InputMaybe<PageLinkType>;
  backgroundColor?: InputMaybe<Scalars['Color']['input']>;
  buttonStyle?: InputMaybe<PageButtonStyle>;
  foregroundColor?: InputMaybe<Scalars['Color']['input']>;
  groups?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** The name to identify this page section in here */
  name?: InputMaybe<Scalars['String']['input']>;
  shouldShow?: InputMaybe<PageDeviceBooleanValueInput>;
  title?: InputMaybe<Scalars['String']['input']>;
  url?: InputMaybe<Scalars['URI']['input']>;
  userStatus?: InputMaybe<PageUserStatus>;
};

export type PageLinkSectionUpdates = {
  __typename: 'PageLinkSectionUpdates';
  id: Scalars['ID']['output'];
  item: Maybe<PageLinkSection>;
  type: SimpleUpdateTypeEnum;
};

export enum PageLinkType {
  /** Button */
  BUTTON = 'BUTTON',
  /** Link */
  Link = 'Link'
}

export type PageList = {
  __typename: 'PageList';
  limit: Scalars['Int']['output'];
  list: Array<Page>;
  skip: Scalars['Int']['output'];
  total: Scalars['Int']['output'];
};

/** A Section which displays HTML based content */
export type PageMarkupSection = PageSection & {
  __typename: 'PageMarkupSection';
  createdAt: Scalars['DateTime']['output'];
  groups: Maybe<Array<PageSectionGroup>>;
  id: Scalars['ID']['output'];
  identifier: Maybe<Scalars['String']['output']>;
  /** Set weather the page section should hide or show on diferent device types */
  isLive: Scalars['Boolean']['output'];
  markup: Scalars['HTML']['output'];
  markupType: MarkupTypeEnum;
  /** The name to identify this page section in here */
  name: Scalars['String']['output'];
  shouldShow: PageDeviceBooleanValue;
  title: Maybe<Scalars['String']['output']>;
  updatedAt: Scalars['DateTime']['output'];
  usedIn: Array<SectionUsedIn>;
  userStatus: PageUserStatus;
};

export type PageMarkupSectionAddInput = {
  groups?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  markup: Scalars['HTML']['input'];
  /** The name to identify this page section in here */
  name: Scalars['String']['input'];
  shouldShow: PageDeviceBooleanValueInput;
  title?: InputMaybe<Scalars['String']['input']>;
  userStatus: PageUserStatus;
};

export type PageMarkupSectionFilterInput = {
  createdAt?: InputMaybe<DateTimeEqualsInRangeFilterInput>;
  id?: InputMaybe<IDEqualsInFilterInput>;
  identifier?: InputMaybe<StringEqualsInRangeFilterInput>;
  search?: InputMaybe<Scalars['String']['input']>;
  updatedAt?: InputMaybe<DateTimeEqualsInRangeFilterInput>;
};

export type PageMarkupSectionList = {
  __typename: 'PageMarkupSectionList';
  limit: Scalars['Int']['output'];
  list: Array<PageMarkupSection>;
  skip: Scalars['Int']['output'];
  total: Scalars['Int']['output'];
};

export type PageMarkupSectionOrderInput = {
  createdAt?: InputMaybe<OrderDirectionEnum>;
  id?: InputMaybe<OrderDirectionEnum>;
  name?: InputMaybe<OrderDirectionEnum>;
  updatedAt?: InputMaybe<OrderDirectionEnum>;
};

export type PageMarkupSectionUpdateInput = {
  groups?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  markup?: InputMaybe<Scalars['HTML']['input']>;
  /** The name to identify this page section in here */
  name?: InputMaybe<Scalars['String']['input']>;
  shouldShow?: InputMaybe<PageDeviceBooleanValueInput>;
  title?: InputMaybe<Scalars['String']['input']>;
  userStatus?: InputMaybe<PageUserStatus>;
};

export type PageMarkupSectionUpdates = {
  __typename: 'PageMarkupSectionUpdates';
  id: Scalars['ID']['output'];
  item: Maybe<PageMarkupSection>;
  type: SimpleUpdateTypeEnum;
};

/** A Section which displays medis either an image or a video */
export type PageMediaSection = PageSection & {
  __typename: 'PageMediaSection';
  backgroundColor: Maybe<Scalars['Color']['output']>;
  createdAt: Scalars['DateTime']['output'];
  description: Maybe<Scalars['HTML']['output']>;
  foregroundColor: Maybe<Scalars['Color']['output']>;
  groups: Maybe<Array<PageSectionGroup>>;
  heading: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  identifier: Maybe<Scalars['String']['output']>;
  image: Maybe<Image>;
  /** Set weather the page section should hide or show on diferent device types */
  isLive: Scalars['Boolean']['output'];
  /** The name to identify this page section in here */
  name: Scalars['String']['output'];
  shouldShow: PageDeviceBooleanValue;
  subTitle: Maybe<Scalars['String']['output']>;
  updatedAt: Scalars['DateTime']['output'];
  usedIn: Array<SectionUsedIn>;
  userStatus: PageUserStatus;
  video: Maybe<Scalars['URI']['output']>;
};

export type PageMediaSectionAddInput = {
  backgroundColor?: InputMaybe<Scalars['Color']['input']>;
  description?: InputMaybe<Scalars['HTML']['input']>;
  foregroundColor?: InputMaybe<Scalars['Color']['input']>;
  groups?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  heading: Scalars['String']['input'];
  image?: InputMaybe<Scalars['ID']['input']>;
  /** The name to identify this page section in here */
  name: Scalars['String']['input'];
  shouldShow: PageDeviceBooleanValueInput;
  subTitle?: InputMaybe<Scalars['String']['input']>;
  userStatus: PageUserStatus;
  video?: InputMaybe<Scalars['URI']['input']>;
};

export type PageMediaSectionFilterInput = {
  createdAt?: InputMaybe<DateTimeEqualsInRangeFilterInput>;
  id?: InputMaybe<IDEqualsInFilterInput>;
  identifier?: InputMaybe<StringEqualsInRangeFilterInput>;
  search?: InputMaybe<Scalars['String']['input']>;
  updatedAt?: InputMaybe<DateTimeEqualsInRangeFilterInput>;
};

export type PageMediaSectionList = {
  __typename: 'PageMediaSectionList';
  limit: Scalars['Int']['output'];
  list: Array<PageMediaSection>;
  skip: Scalars['Int']['output'];
  total: Scalars['Int']['output'];
};

export type PageMediaSectionOrderInput = {
  createdAt?: InputMaybe<OrderDirectionEnum>;
  id?: InputMaybe<OrderDirectionEnum>;
  name?: InputMaybe<OrderDirectionEnum>;
  updatedAt?: InputMaybe<OrderDirectionEnum>;
};

export type PageMediaSectionUpdateInput = {
  backgroundColor?: InputMaybe<Scalars['Color']['input']>;
  description?: InputMaybe<Scalars['HTML']['input']>;
  foregroundColor?: InputMaybe<Scalars['Color']['input']>;
  groups?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  heading?: InputMaybe<Scalars['String']['input']>;
  image?: InputMaybe<Scalars['ID']['input']>;
  /** The name to identify this page section in here */
  name?: InputMaybe<Scalars['String']['input']>;
  shouldShow?: InputMaybe<PageDeviceBooleanValueInput>;
  subTitle?: InputMaybe<Scalars['String']['input']>;
  userStatus?: InputMaybe<PageUserStatus>;
  video?: InputMaybe<Scalars['URI']['input']>;
};

export type PageMediaSectionUpdates = {
  __typename: 'PageMediaSectionUpdates';
  id: Scalars['ID']['output'];
  item: Maybe<PageMediaSection>;
  type: SimpleUpdateTypeEnum;
};

/** A section thats displays a list of discounts available to a customer when they are logged in */
export type PageMyDiscountsSection = PageSection & {
  __typename: 'PageMyDiscountsSection';
  backgroundColor: Maybe<Scalars['Color']['output']>;
  createdAt: Scalars['DateTime']['output'];
  foregroundColor: Maybe<Scalars['Color']['output']>;
  groups: Maybe<Array<PageSectionGroup>>;
  id: Scalars['ID']['output'];
  identifier: Maybe<Scalars['String']['output']>;
  /** Set weather the page section should hide or show on diferent device types */
  isLive: Scalars['Boolean']['output'];
  /** The name to identify this page section in here */
  name: Scalars['String']['output'];
  placeholderBanner: Maybe<PageBanner>;
  shouldShow: PageDeviceBooleanValue;
  updatedAt: Scalars['DateTime']['output'];
  usedIn: Array<SectionUsedIn>;
  userStatus: PageUserStatus;
};

export type PageMyDiscountsSectionAddInput = {
  backgroundColor?: InputMaybe<Scalars['Color']['input']>;
  foregroundColor?: InputMaybe<Scalars['Color']['input']>;
  groups?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** The name to identify this page section in here */
  name: Scalars['String']['input'];
  placeholderBanner?: InputMaybe<Scalars['ID']['input']>;
  shouldShow: PageDeviceBooleanValueInput;
  userStatus: PageUserStatus;
};

export type PageMyDiscountsSectionFilterInput = {
  createdAt?: InputMaybe<DateTimeEqualsInRangeFilterInput>;
  id?: InputMaybe<IDEqualsInFilterInput>;
  identifier?: InputMaybe<StringEqualsInRangeFilterInput>;
  search?: InputMaybe<Scalars['String']['input']>;
  updatedAt?: InputMaybe<DateTimeEqualsInRangeFilterInput>;
};

export type PageMyDiscountsSectionList = {
  __typename: 'PageMyDiscountsSectionList';
  limit: Scalars['Int']['output'];
  list: Array<PageMyDiscountsSection>;
  skip: Scalars['Int']['output'];
  total: Scalars['Int']['output'];
};

export type PageMyDiscountsSectionOrderInput = {
  createdAt?: InputMaybe<OrderDirectionEnum>;
  id?: InputMaybe<OrderDirectionEnum>;
  name?: InputMaybe<OrderDirectionEnum>;
  updatedAt?: InputMaybe<OrderDirectionEnum>;
};

export type PageMyDiscountsSectionUpdateInput = {
  backgroundColor?: InputMaybe<Scalars['Color']['input']>;
  foregroundColor?: InputMaybe<Scalars['Color']['input']>;
  groups?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** The name to identify this page section in here */
  name?: InputMaybe<Scalars['String']['input']>;
  placeholderBanner?: InputMaybe<Scalars['ID']['input']>;
  shouldShow?: InputMaybe<PageDeviceBooleanValueInput>;
  userStatus?: InputMaybe<PageUserStatus>;
};

export type PageMyDiscountsSectionUpdates = {
  __typename: 'PageMyDiscountsSectionUpdates';
  id: Scalars['ID']['output'];
  item: Maybe<PageMyDiscountsSection>;
  type: SimpleUpdateTypeEnum;
};

/** A section thats displays a list of frequently bought items of the customer */
export type PageMyShopSection = PageSection & {
  __typename: 'PageMyShopSection';
  backgroundColor: Maybe<Scalars['Color']['output']>;
  createdAt: Scalars['DateTime']['output'];
  description: Maybe<Scalars['HTML']['output']>;
  foregroundColor: Maybe<Scalars['Color']['output']>;
  groups: Maybe<Array<PageSectionGroup>>;
  id: Scalars['ID']['output'];
  identifier: Maybe<Scalars['String']['output']>;
  /** Set weather the page section should hide or show on diferent device types */
  isLive: Scalars['Boolean']['output'];
  /** The name to identify this page section in here */
  name: Scalars['String']['output'];
  placeholderBanner: Maybe<PageBanner>;
  productCount: Scalars['Int']['output'];
  shouldShow: PageDeviceBooleanValue;
  updatedAt: Scalars['DateTime']['output'];
  usedIn: Array<SectionUsedIn>;
  userStatus: PageUserStatus;
};

export type PageMyShopSectionAddInput = {
  backgroundColor?: InputMaybe<Scalars['Color']['input']>;
  description?: InputMaybe<Scalars['HTML']['input']>;
  foregroundColor?: InputMaybe<Scalars['Color']['input']>;
  groups?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** The name to identify this page section in here */
  name: Scalars['String']['input'];
  placeholderBanner?: InputMaybe<Scalars['ID']['input']>;
  productCount: Scalars['Int']['input'];
  shouldShow: PageDeviceBooleanValueInput;
  userStatus: PageUserStatus;
};

export type PageMyShopSectionFilterInput = {
  createdAt?: InputMaybe<DateTimeEqualsInRangeFilterInput>;
  id?: InputMaybe<IDEqualsInFilterInput>;
  identifier?: InputMaybe<StringEqualsInRangeFilterInput>;
  search?: InputMaybe<Scalars['String']['input']>;
  updatedAt?: InputMaybe<DateTimeEqualsInRangeFilterInput>;
};

export type PageMyShopSectionList = {
  __typename: 'PageMyShopSectionList';
  limit: Scalars['Int']['output'];
  list: Array<PageMyShopSection>;
  skip: Scalars['Int']['output'];
  total: Scalars['Int']['output'];
};

export type PageMyShopSectionOrderInput = {
  createdAt?: InputMaybe<OrderDirectionEnum>;
  id?: InputMaybe<OrderDirectionEnum>;
  name?: InputMaybe<OrderDirectionEnum>;
  updatedAt?: InputMaybe<OrderDirectionEnum>;
};

export type PageMyShopSectionUpdateInput = {
  backgroundColor?: InputMaybe<Scalars['Color']['input']>;
  description?: InputMaybe<Scalars['HTML']['input']>;
  foregroundColor?: InputMaybe<Scalars['Color']['input']>;
  groups?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** The name to identify this page section in here */
  name?: InputMaybe<Scalars['String']['input']>;
  placeholderBanner?: InputMaybe<Scalars['ID']['input']>;
  productCount?: InputMaybe<Scalars['Int']['input']>;
  shouldShow?: InputMaybe<PageDeviceBooleanValueInput>;
  userStatus?: InputMaybe<PageUserStatus>;
};

export type PageMyShopSectionUpdates = {
  __typename: 'PageMyShopSectionUpdates';
  id: Scalars['ID']['output'];
  item: Maybe<PageMyShopSection>;
  type: SimpleUpdateTypeEnum;
};

/** A Section which displays a notification */
export type PageNotificationSection = PageSection & {
  __typename: 'PageNotificationSection';
  backgroundColor: Maybe<Scalars['Color']['output']>;
  createdAt: Scalars['DateTime']['output'];
  foregroundColor: Maybe<Scalars['Color']['output']>;
  groups: Maybe<Array<PageSectionGroup>>;
  id: Scalars['ID']['output'];
  identifier: Maybe<Scalars['String']['output']>;
  /** Set weather the page section should hide or show on diferent device types */
  isLive: Scalars['Boolean']['output'];
  markup: Scalars['HTML']['output'];
  /** The name to identify this page section in here */
  name: Scalars['String']['output'];
  shouldShow: PageDeviceBooleanValue;
  updatedAt: Scalars['DateTime']['output'];
  usedIn: Array<SectionUsedIn>;
  userStatus: PageUserStatus;
};

export type PageNotificationSectionAddInput = {
  backgroundColor?: InputMaybe<Scalars['Color']['input']>;
  foregroundColor?: InputMaybe<Scalars['Color']['input']>;
  groups?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  markup: Scalars['HTML']['input'];
  /** The name to identify this page section in here */
  name: Scalars['String']['input'];
  shouldShow: PageDeviceBooleanValueInput;
  userStatus: PageUserStatus;
};

export type PageNotificationSectionFilterInput = {
  createdAt?: InputMaybe<DateTimeEqualsInRangeFilterInput>;
  id?: InputMaybe<IDEqualsInFilterInput>;
  identifier?: InputMaybe<StringEqualsInRangeFilterInput>;
  search?: InputMaybe<Scalars['String']['input']>;
  updatedAt?: InputMaybe<DateTimeEqualsInRangeFilterInput>;
};

export type PageNotificationSectionList = {
  __typename: 'PageNotificationSectionList';
  limit: Scalars['Int']['output'];
  list: Array<PageNotificationSection>;
  skip: Scalars['Int']['output'];
  total: Scalars['Int']['output'];
};

export type PageNotificationSectionOrderInput = {
  createdAt?: InputMaybe<OrderDirectionEnum>;
  id?: InputMaybe<OrderDirectionEnum>;
  name?: InputMaybe<OrderDirectionEnum>;
  updatedAt?: InputMaybe<OrderDirectionEnum>;
};

export type PageNotificationSectionUpdateInput = {
  backgroundColor?: InputMaybe<Scalars['Color']['input']>;
  foregroundColor?: InputMaybe<Scalars['Color']['input']>;
  groups?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  markup?: InputMaybe<Scalars['HTML']['input']>;
  /** The name to identify this page section in here */
  name?: InputMaybe<Scalars['String']['input']>;
  shouldShow?: InputMaybe<PageDeviceBooleanValueInput>;
  userStatus?: InputMaybe<PageUserStatus>;
};

export type PageNotificationSectionUpdates = {
  __typename: 'PageNotificationSectionUpdates';
  id: Scalars['ID']['output'];
  item: Maybe<PageNotificationSection>;
  type: SimpleUpdateTypeEnum;
};

export type PageOrderInput = {
  createdAt?: InputMaybe<OrderDirectionEnum>;
  id?: InputMaybe<OrderDirectionEnum>;
  name?: InputMaybe<OrderDirectionEnum>;
  pageType?: InputMaybe<OrderDirectionEnum>;
  updatedAt?: InputMaybe<OrderDirectionEnum>;
};

/** A Section which displays plain text */
export type PagePlainTextSection = PageSection & {
  __typename: 'PagePlainTextSection';
  backgroundColor: Maybe<Scalars['Color']['output']>;
  createdAt: Scalars['DateTime']['output'];
  description: Maybe<Scalars['HTML']['output']>;
  foregroundColor: Maybe<Scalars['Color']['output']>;
  groups: Maybe<Array<PageSectionGroup>>;
  horizontalAlignment: Maybe<PageHorizontalAlignment>;
  id: Scalars['ID']['output'];
  identifier: Maybe<Scalars['String']['output']>;
  /** Set weather the page section should hide or show on diferent device types */
  isLive: Scalars['Boolean']['output'];
  /** The name to identify this page section in here */
  name: Scalars['String']['output'];
  shouldShow: PageDeviceBooleanValue;
  title: Maybe<Scalars['String']['output']>;
  updatedAt: Scalars['DateTime']['output'];
  usedIn: Array<SectionUsedIn>;
  userStatus: PageUserStatus;
};

export type PagePlainTextSectionAddInput = {
  backgroundColor?: InputMaybe<Scalars['Color']['input']>;
  description?: InputMaybe<Scalars['HTML']['input']>;
  foregroundColor?: InputMaybe<Scalars['Color']['input']>;
  groups?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  horizontalAlignment?: InputMaybe<PageHorizontalAlignment>;
  /** The name to identify this page section in here */
  name: Scalars['String']['input'];
  shouldShow: PageDeviceBooleanValueInput;
  title?: InputMaybe<Scalars['String']['input']>;
  userStatus: PageUserStatus;
};

export type PagePlainTextSectionFilterInput = {
  createdAt?: InputMaybe<DateTimeEqualsInRangeFilterInput>;
  id?: InputMaybe<IDEqualsInFilterInput>;
  identifier?: InputMaybe<StringEqualsInRangeFilterInput>;
  search?: InputMaybe<Scalars['String']['input']>;
  updatedAt?: InputMaybe<DateTimeEqualsInRangeFilterInput>;
};

export type PagePlainTextSectionList = {
  __typename: 'PagePlainTextSectionList';
  limit: Scalars['Int']['output'];
  list: Array<PagePlainTextSection>;
  skip: Scalars['Int']['output'];
  total: Scalars['Int']['output'];
};

export type PagePlainTextSectionOrderInput = {
  createdAt?: InputMaybe<OrderDirectionEnum>;
  id?: InputMaybe<OrderDirectionEnum>;
  name?: InputMaybe<OrderDirectionEnum>;
  updatedAt?: InputMaybe<OrderDirectionEnum>;
};

export type PagePlainTextSectionUpdateInput = {
  backgroundColor?: InputMaybe<Scalars['Color']['input']>;
  description?: InputMaybe<Scalars['HTML']['input']>;
  foregroundColor?: InputMaybe<Scalars['Color']['input']>;
  groups?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  horizontalAlignment?: InputMaybe<PageHorizontalAlignment>;
  /** The name to identify this page section in here */
  name?: InputMaybe<Scalars['String']['input']>;
  shouldShow?: InputMaybe<PageDeviceBooleanValueInput>;
  title?: InputMaybe<Scalars['String']['input']>;
  userStatus?: InputMaybe<PageUserStatus>;
};

export type PagePlainTextSectionUpdates = {
  __typename: 'PagePlainTextSectionUpdates';
  id: Scalars['ID']['output'];
  item: Maybe<PagePlainTextSection>;
  type: SimpleUpdateTypeEnum;
};

/** A Section which displays products in aslider can be individual selected products or a category */
export type PageProductSliderSection = PageSection & {
  __typename: 'PageProductSliderSection';
  /** The Magento Category */
  category: Maybe<CategoryTree>;
  categoryId: Maybe<Scalars['String']['output']>;
  createdAt: Scalars['DateTime']['output'];
  groups: Maybe<Array<PageSectionGroup>>;
  id: Scalars['ID']['output'];
  identifier: Maybe<Scalars['String']['output']>;
  /** Set weather the page section should hide or show on diferent device types */
  isLive: Scalars['Boolean']['output'];
  /** The name to identify this page section in here */
  name: Scalars['String']['output'];
  productSkus: Maybe<Array<Scalars['String']['output']>>;
  products: Maybe<Array<ProductInterface>>;
  shouldShow: PageDeviceBooleanValue;
  /** The Section's Subtitle */
  subTitle: Maybe<Scalars['String']['output']>;
  /** The Section's Title */
  title: Maybe<Scalars['String']['output']>;
  updatedAt: Scalars['DateTime']['output'];
  usedIn: Array<SectionUsedIn>;
  userStatus: PageUserStatus;
};

export type PageProductSliderSectionAddInput = {
  categoryId?: InputMaybe<Scalars['String']['input']>;
  groups?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** The name to identify this page section in here */
  name: Scalars['String']['input'];
  productSkus?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  shouldShow: PageDeviceBooleanValueInput;
  /** The Section's Subtitle */
  subTitle?: InputMaybe<Scalars['String']['input']>;
  /** The Section's Title */
  title?: InputMaybe<Scalars['String']['input']>;
  userStatus: PageUserStatus;
};

export type PageProductSliderSectionFilterInput = {
  createdAt?: InputMaybe<DateTimeEqualsInRangeFilterInput>;
  id?: InputMaybe<IDEqualsInFilterInput>;
  identifier?: InputMaybe<StringEqualsInRangeFilterInput>;
  search?: InputMaybe<Scalars['String']['input']>;
  updatedAt?: InputMaybe<DateTimeEqualsInRangeFilterInput>;
};

export type PageProductSliderSectionList = {
  __typename: 'PageProductSliderSectionList';
  limit: Scalars['Int']['output'];
  list: Array<PageProductSliderSection>;
  skip: Scalars['Int']['output'];
  total: Scalars['Int']['output'];
};

export type PageProductSliderSectionOrderInput = {
  createdAt?: InputMaybe<OrderDirectionEnum>;
  id?: InputMaybe<OrderDirectionEnum>;
  name?: InputMaybe<OrderDirectionEnum>;
  updatedAt?: InputMaybe<OrderDirectionEnum>;
};

export type PageProductSliderSectionUpdateInput = {
  categoryId?: InputMaybe<Scalars['String']['input']>;
  groups?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** The name to identify this page section in here */
  name?: InputMaybe<Scalars['String']['input']>;
  productSkus?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  shouldShow?: InputMaybe<PageDeviceBooleanValueInput>;
  /** The Section's Subtitle */
  subTitle?: InputMaybe<Scalars['String']['input']>;
  /** The Section's Title */
  title?: InputMaybe<Scalars['String']['input']>;
  userStatus?: InputMaybe<PageUserStatus>;
};

export type PageProductSliderSectionUpdates = {
  __typename: 'PageProductSliderSectionUpdates';
  id: Scalars['ID']['output'];
  item: Maybe<PageProductSliderSection>;
  type: SimpleUpdateTypeEnum;
};

export type PageQuestion = {
  __typename: 'PageQuestion';
  answer: Scalars['HTML']['output'];
  id: Scalars['ID']['output'];
  question: Scalars['String']['output'];
};

export type PageQuestionGroup = {
  __typename: 'PageQuestionGroup';
  createdAt: Scalars['DateTime']['output'];
  id: Scalars['ID']['output'];
  questions: Maybe<Array<PageQuestion>>;
  title: Maybe<Scalars['String']['output']>;
  updatedAt: Scalars['DateTime']['output'];
};

export type PageQuestionGroupAddInput = {
  questions?: InputMaybe<Array<InputMaybe<PageQuestionInput>>>;
  title?: InputMaybe<Scalars['String']['input']>;
};

export type PageQuestionGroupFilterInput = {
  createdAt?: InputMaybe<DateTimeEqualsInRangeFilterInput>;
  id?: InputMaybe<IDEqualsInFilterInput>;
  search?: InputMaybe<Scalars['String']['input']>;
  updatedAt?: InputMaybe<DateTimeEqualsInRangeFilterInput>;
};

export type PageQuestionGroupList = {
  __typename: 'PageQuestionGroupList';
  limit: Scalars['Int']['output'];
  list: Array<PageQuestionGroup>;
  skip: Scalars['Int']['output'];
  total: Scalars['Int']['output'];
};

export type PageQuestionGroupOrderInput = {
  createdAt?: InputMaybe<OrderDirectionEnum>;
  id?: InputMaybe<OrderDirectionEnum>;
  updatedAt?: InputMaybe<OrderDirectionEnum>;
};

export type PageQuestionGroupUpdateInput = {
  questions?: InputMaybe<Array<InputMaybe<PageQuestionInput>>>;
  title?: InputMaybe<Scalars['String']['input']>;
};

export type PageQuestionGroupUpdates = {
  __typename: 'PageQuestionGroupUpdates';
  id: Scalars['ID']['output'];
  item: Maybe<PageQuestionGroup>;
  type: SimpleUpdateTypeEnum;
};

export type PageQuestionInput = {
  answer: Scalars['HTML']['input'];
  question: Scalars['String']['input'];
};

/** ASection which displays a single question and answer in an accordion */
export type PageQuestionSection = PageSection & {
  __typename: 'PageQuestionSection';
  createdAt: Scalars['DateTime']['output'];
  groups: Maybe<Array<PageSectionGroup>>;
  id: Scalars['ID']['output'];
  identifier: Maybe<Scalars['String']['output']>;
  /** Set weather the page section should hide or show on diferent device types */
  isLive: Scalars['Boolean']['output'];
  /** The name to identify this page section in here */
  name: Scalars['String']['output'];
  question: PageQuestionGroup;
  shouldShow: PageDeviceBooleanValue;
  title: Maybe<Scalars['String']['output']>;
  updatedAt: Scalars['DateTime']['output'];
  usedIn: Array<SectionUsedIn>;
  userStatus: PageUserStatus;
};

export type PageQuestionSectionAddInput = {
  groups?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** The name to identify this page section in here */
  name: Scalars['String']['input'];
  question: Scalars['ID']['input'];
  shouldShow: PageDeviceBooleanValueInput;
  title?: InputMaybe<Scalars['String']['input']>;
  userStatus: PageUserStatus;
};

export type PageQuestionSectionFilterInput = {
  createdAt?: InputMaybe<DateTimeEqualsInRangeFilterInput>;
  id?: InputMaybe<IDEqualsInFilterInput>;
  identifier?: InputMaybe<StringEqualsInRangeFilterInput>;
  search?: InputMaybe<Scalars['String']['input']>;
  updatedAt?: InputMaybe<DateTimeEqualsInRangeFilterInput>;
};

export type PageQuestionSectionList = {
  __typename: 'PageQuestionSectionList';
  limit: Scalars['Int']['output'];
  list: Array<PageQuestionSection>;
  skip: Scalars['Int']['output'];
  total: Scalars['Int']['output'];
};

export type PageQuestionSectionOrderInput = {
  createdAt?: InputMaybe<OrderDirectionEnum>;
  id?: InputMaybe<OrderDirectionEnum>;
  name?: InputMaybe<OrderDirectionEnum>;
  updatedAt?: InputMaybe<OrderDirectionEnum>;
};

export type PageQuestionSectionUpdateInput = {
  groups?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** The name to identify this page section in here */
  name?: InputMaybe<Scalars['String']['input']>;
  question?: InputMaybe<Scalars['ID']['input']>;
  shouldShow?: InputMaybe<PageDeviceBooleanValueInput>;
  title?: InputMaybe<Scalars['String']['input']>;
  userStatus?: InputMaybe<PageUserStatus>;
};

export type PageQuestionSectionUpdates = {
  __typename: 'PageQuestionSectionUpdates';
  id: Scalars['ID']['output'];
  item: Maybe<PageQuestionSection>;
  type: SimpleUpdateTypeEnum;
};

/** Represents a PageSection in the system */
export type PageSection = {
  createdAt: Scalars['DateTime']['output'];
  groups: Maybe<Array<PageSectionGroup>>;
  id: Scalars['ID']['output'];
  identifier: Maybe<Scalars['String']['output']>;
  /** Set weather the page section should hide or show on diferent device types */
  isLive: Scalars['Boolean']['output'];
  /** The name to identify this page section in here */
  name: Scalars['String']['output'];
  shouldShow: PageDeviceBooleanValue;
  updatedAt: Scalars['DateTime']['output'];
  usedIn: Array<SectionUsedIn>;
  userStatus: PageUserStatus;
};

export type PageSectionFilterInput = {
  createdAt?: InputMaybe<DateTimeEqualsInRangeFilterInput>;
  id?: InputMaybe<IDEqualsInFilterInput>;
  identifier?: InputMaybe<StringEqualsInRangeFilterInput>;
  objectType?: InputMaybe<PageSectionObjectTypesEnumEqualsInFilterInput>;
  search?: InputMaybe<Scalars['String']['input']>;
  updatedAt?: InputMaybe<DateTimeEqualsInRangeFilterInput>;
};

/** Represents a PageSectionGroup in the system */
export type PageSectionGroup = {
  __typename: 'PageSectionGroup';
  /** The background color for the group */
  backgroundColor: Scalars['Color']['output'];
  /** Bottom padding for the group per device type (in responive pixels) */
  bottomPadding: Maybe<PageDeviceIntValue>;
  content: Maybe<Content>;
  createdAt: Scalars['DateTime']['output'];
  /** The gap between sections in the group per device type (in responive pixels) */
  gap: Maybe<PageDeviceIntValue>;
  id: Scalars['ID']['output'];
  /** Left padding for the group per device type (in responive pixels) */
  leftPadding: Maybe<PageDeviceIntValue>;
  /** The name of the page section group to identify it in here */
  name: Scalars['String']['output'];
  /** Right padding for the group per device type (in responive pixels) */
  rightPadding: Maybe<PageDeviceIntValue>;
  sections: Maybe<Array<PageSection>>;
  /** Set weather the page section group should hide or show on diferent device types */
  shouldShow: PageDeviceBooleanValue;
  /** Top padding for the group per device type (in responive pixels) */
  topPadding: Maybe<PageDeviceIntValue>;
  updatedAt: Scalars['DateTime']['output'];
  userStatus: PageUserStatus;
};

export type PageSectionGroupAddInput = {
  /** The background color for the group */
  backgroundColor: Scalars['Color']['input'];
  /** Bottom padding for the group per device type (in responive pixels) */
  bottomPadding?: InputMaybe<PageDeviceIntValueInput>;
  content?: InputMaybe<Scalars['ID']['input']>;
  /** The gap between sections in the group per device type (in responive pixels) */
  gap?: InputMaybe<PageDeviceIntValueInput>;
  /** Left padding for the group per device type (in responive pixels) */
  leftPadding?: InputMaybe<PageDeviceIntValueInput>;
  /** The name of the page section group to identify it in here */
  name: Scalars['String']['input'];
  /** Right padding for the group per device type (in responive pixels) */
  rightPadding?: InputMaybe<PageDeviceIntValueInput>;
  sections?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** Set weather the page section group should hide or show on diferent device types */
  shouldShow: PageDeviceBooleanValueInput;
  /** Top padding for the group per device type (in responive pixels) */
  topPadding?: InputMaybe<PageDeviceIntValueInput>;
  userStatus: PageUserStatus;
};

export type PageSectionGroupFilterInput = {
  createdAt?: InputMaybe<DateTimeEqualsInRangeFilterInput>;
  id?: InputMaybe<IDEqualsInFilterInput>;
  name?: InputMaybe<StringEqualsInRangeFilterInput>;
  search?: InputMaybe<Scalars['String']['input']>;
  updatedAt?: InputMaybe<DateTimeEqualsInRangeFilterInput>;
};

export type PageSectionGroupList = {
  __typename: 'PageSectionGroupList';
  limit: Scalars['Int']['output'];
  list: Array<PageSectionGroup>;
  skip: Scalars['Int']['output'];
  total: Scalars['Int']['output'];
};

export type PageSectionGroupOrderInput = {
  createdAt?: InputMaybe<OrderDirectionEnum>;
  id?: InputMaybe<OrderDirectionEnum>;
  updatedAt?: InputMaybe<OrderDirectionEnum>;
};

export type PageSectionGroupUpdateInput = {
  /** The background color for the group */
  backgroundColor?: InputMaybe<Scalars['Color']['input']>;
  /** Bottom padding for the group per device type (in responive pixels) */
  bottomPadding?: InputMaybe<PageDeviceIntValueInput>;
  content?: InputMaybe<Scalars['ID']['input']>;
  /** The gap between sections in the group per device type (in responive pixels) */
  gap?: InputMaybe<PageDeviceIntValueInput>;
  /** Left padding for the group per device type (in responive pixels) */
  leftPadding?: InputMaybe<PageDeviceIntValueInput>;
  /** The name of the page section group to identify it in here */
  name?: InputMaybe<Scalars['String']['input']>;
  /** Right padding for the group per device type (in responive pixels) */
  rightPadding?: InputMaybe<PageDeviceIntValueInput>;
  sections?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** Set weather the page section group should hide or show on diferent device types */
  shouldShow?: InputMaybe<PageDeviceBooleanValueInput>;
  /** Top padding for the group per device type (in responive pixels) */
  topPadding?: InputMaybe<PageDeviceIntValueInput>;
  userStatus?: InputMaybe<PageUserStatus>;
};

export type PageSectionGroupUpdates = {
  __typename: 'PageSectionGroupUpdates';
  id: Scalars['ID']['output'];
  item: Maybe<PageSectionGroup>;
  type: SimpleUpdateTypeEnum;
};

export type PageSectionList = {
  __typename: 'PageSectionList';
  limit: Scalars['Int']['output'];
  list: Array<PageSection>;
  skip: Scalars['Int']['output'];
  total: Scalars['Int']['output'];
};

export enum PageSectionObjectTypesEnum {
  /** Page accordion section Type */
  PAGE_ACCORDION_SECTION = 'PAGE_ACCORDION_SECTION',
  /** Page banner section Type */
  PAGE_BANNER_SECTION = 'PAGE_BANNER_SECTION',
  /** Page block section Type */
  PAGE_BLOCK_SECTION = 'PAGE_BLOCK_SECTION',
  /** Page carousel section Type */
  PAGE_CAROUSEL_SECTION = 'PAGE_CAROUSEL_SECTION',
  /** Page chip section Type */
  PAGE_CHIP_SECTION = 'PAGE_CHIP_SECTION',
  /** Page excerpt section Type */
  PAGE_EXCERPT_SECTION = 'PAGE_EXCERPT_SECTION',
  /** Page heading section Type */
  PAGE_HEADING_SECTION = 'PAGE_HEADING_SECTION',
  /** Page hero section Type */
  PAGE_HERO_SECTION = 'PAGE_HERO_SECTION',
  /** Page icon section Type */
  PAGE_ICON_SECTION = 'PAGE_ICON_SECTION',
  /** Page link section Type */
  PAGE_LINK_SECTION = 'PAGE_LINK_SECTION',
  /** Page markup section Type */
  PAGE_MARKUP_SECTION = 'PAGE_MARKUP_SECTION',
  /** Page media section Type */
  PAGE_MEDIA_SECTION = 'PAGE_MEDIA_SECTION',
  /** Page my discounts section Type */
  PAGE_MY_DISCOUNTS_SECTION = 'PAGE_MY_DISCOUNTS_SECTION',
  /** Page my shop section Type */
  PAGE_MY_SHOP_SECTION = 'PAGE_MY_SHOP_SECTION',
  /** Page notification section Type */
  PAGE_NOTIFICATION_SECTION = 'PAGE_NOTIFICATION_SECTION',
  /** Page plain text section Type */
  PAGE_PLAIN_TEXT_SECTION = 'PAGE_PLAIN_TEXT_SECTION',
  /** Page product slider section Type */
  PAGE_PRODUCT_SLIDER_SECTION = 'PAGE_PRODUCT_SLIDER_SECTION',
  /** Page question section Type */
  PAGE_QUESTION_SECTION = 'PAGE_QUESTION_SECTION',
  /** Page site notice section Type */
  PAGE_SITE_NOTICE_SECTION = 'PAGE_SITE_NOTICE_SECTION',
  /** Page split text section Type */
  PAGE_SPLIT_TEXT_SECTION = 'PAGE_SPLIT_TEXT_SECTION',
  /** Page timetable section Type */
  PAGE_TIMETABLE_SECTION = 'PAGE_TIMETABLE_SECTION'
}

export type PageSectionObjectTypesEnumEqualsInFilterInput = {
  doesNotEqual?: InputMaybe<PageSectionObjectTypesEnum>;
  equals?: InputMaybe<PageSectionObjectTypesEnum>;
  in?: InputMaybe<Array<InputMaybe<PageSectionObjectTypesEnum>>>;
  notIn?: InputMaybe<Array<InputMaybe<PageSectionObjectTypesEnum>>>;
};

export type PageSectionOrderInput = {
  createdAt?: InputMaybe<OrderDirectionEnum>;
  id?: InputMaybe<OrderDirectionEnum>;
  name?: InputMaybe<OrderDirectionEnum>;
  objectType?: InputMaybe<OrderDirectionEnum>;
  updatedAt?: InputMaybe<OrderDirectionEnum>;
};

export type PageSectionUpdates = {
  __typename: 'PageSectionUpdates';
  id: Scalars['ID']['output'];
  item: Maybe<PageSection>;
  type: SimpleUpdateTypeEnum;
};

/** A Sectionwhich displays a full width notification banner */
export type PageSiteNoticeSection = PageSection & {
  __typename: 'PageSiteNoticeSection';
  backgroundColor: Maybe<Scalars['Color']['output']>;
  createdAt: Scalars['DateTime']['output'];
  foregroundColor: Maybe<Scalars['Color']['output']>;
  groups: Maybe<Array<PageSectionGroup>>;
  id: Scalars['ID']['output'];
  identifier: Maybe<Scalars['String']['output']>;
  image: Maybe<Image>;
  /** Set weather the page section should hide or show on diferent device types */
  isLive: Scalars['Boolean']['output'];
  link: Maybe<PageLink>;
  /** The name to identify this page section in here */
  name: Scalars['String']['output'];
  shouldShow: PageDeviceBooleanValue;
  updatedAt: Scalars['DateTime']['output'];
  usedIn: Array<SectionUsedIn>;
  userStatus: PageUserStatus;
};

export type PageSiteNoticeSectionAddInput = {
  backgroundColor?: InputMaybe<Scalars['Color']['input']>;
  foregroundColor?: InputMaybe<Scalars['Color']['input']>;
  groups?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  image?: InputMaybe<Scalars['ID']['input']>;
  link?: InputMaybe<PageLinkInput>;
  /** The name to identify this page section in here */
  name: Scalars['String']['input'];
  shouldShow: PageDeviceBooleanValueInput;
  userStatus: PageUserStatus;
};

export type PageSiteNoticeSectionFilterInput = {
  createdAt?: InputMaybe<DateTimeEqualsInRangeFilterInput>;
  id?: InputMaybe<IDEqualsInFilterInput>;
  identifier?: InputMaybe<StringEqualsInRangeFilterInput>;
  search?: InputMaybe<Scalars['String']['input']>;
  updatedAt?: InputMaybe<DateTimeEqualsInRangeFilterInput>;
};

export type PageSiteNoticeSectionList = {
  __typename: 'PageSiteNoticeSectionList';
  limit: Scalars['Int']['output'];
  list: Array<PageSiteNoticeSection>;
  skip: Scalars['Int']['output'];
  total: Scalars['Int']['output'];
};

export type PageSiteNoticeSectionOrderInput = {
  createdAt?: InputMaybe<OrderDirectionEnum>;
  id?: InputMaybe<OrderDirectionEnum>;
  name?: InputMaybe<OrderDirectionEnum>;
  updatedAt?: InputMaybe<OrderDirectionEnum>;
};

export type PageSiteNoticeSectionUpdateInput = {
  backgroundColor?: InputMaybe<Scalars['Color']['input']>;
  foregroundColor?: InputMaybe<Scalars['Color']['input']>;
  groups?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  image?: InputMaybe<Scalars['ID']['input']>;
  link?: InputMaybe<PageLinkInput>;
  /** The name to identify this page section in here */
  name?: InputMaybe<Scalars['String']['input']>;
  shouldShow?: InputMaybe<PageDeviceBooleanValueInput>;
  userStatus?: InputMaybe<PageUserStatus>;
};

export type PageSiteNoticeSectionUpdates = {
  __typename: 'PageSiteNoticeSectionUpdates';
  id: Scalars['ID']['output'];
  item: Maybe<PageSiteNoticeSection>;
  type: SimpleUpdateTypeEnum;
};

export enum PageSize {
  /** Large */
  LARGE = 'LARGE',
  /** Medium */
  MEDIUM = 'MEDIUM',
  /** Small */
  SMALL = 'SMALL'
}

/** A Section which displays a title and sub title on the left and HTML content on the right and colapses for mobile */
export type PageSplitTextSection = PageSection & {
  __typename: 'PageSplitTextSection';
  backgroundColor: Maybe<Scalars['Color']['output']>;
  createdAt: Scalars['DateTime']['output'];
  description: Maybe<Scalars['HTML']['output']>;
  foregroundColor: Maybe<Scalars['Color']['output']>;
  groups: Maybe<Array<PageSectionGroup>>;
  id: Scalars['ID']['output'];
  identifier: Maybe<Scalars['String']['output']>;
  /** Set weather the page section should hide or show on diferent device types */
  isLive: Scalars['Boolean']['output'];
  /** The name to identify this page section in here */
  name: Scalars['String']['output'];
  shouldShow: PageDeviceBooleanValue;
  subTitle: Maybe<Scalars['String']['output']>;
  title: Maybe<Scalars['String']['output']>;
  updatedAt: Scalars['DateTime']['output'];
  usedIn: Array<SectionUsedIn>;
  userStatus: PageUserStatus;
};

export type PageSplitTextSectionAddInput = {
  backgroundColor?: InputMaybe<Scalars['Color']['input']>;
  description?: InputMaybe<Scalars['HTML']['input']>;
  foregroundColor?: InputMaybe<Scalars['Color']['input']>;
  groups?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** The name to identify this page section in here */
  name: Scalars['String']['input'];
  shouldShow: PageDeviceBooleanValueInput;
  subTitle?: InputMaybe<Scalars['String']['input']>;
  title?: InputMaybe<Scalars['String']['input']>;
  userStatus: PageUserStatus;
};

export type PageSplitTextSectionFilterInput = {
  createdAt?: InputMaybe<DateTimeEqualsInRangeFilterInput>;
  id?: InputMaybe<IDEqualsInFilterInput>;
  identifier?: InputMaybe<StringEqualsInRangeFilterInput>;
  search?: InputMaybe<Scalars['String']['input']>;
  updatedAt?: InputMaybe<DateTimeEqualsInRangeFilterInput>;
};

export type PageSplitTextSectionList = {
  __typename: 'PageSplitTextSectionList';
  limit: Scalars['Int']['output'];
  list: Array<PageSplitTextSection>;
  skip: Scalars['Int']['output'];
  total: Scalars['Int']['output'];
};

export type PageSplitTextSectionOrderInput = {
  createdAt?: InputMaybe<OrderDirectionEnum>;
  id?: InputMaybe<OrderDirectionEnum>;
  name?: InputMaybe<OrderDirectionEnum>;
  updatedAt?: InputMaybe<OrderDirectionEnum>;
};

export type PageSplitTextSectionUpdateInput = {
  backgroundColor?: InputMaybe<Scalars['Color']['input']>;
  description?: InputMaybe<Scalars['HTML']['input']>;
  foregroundColor?: InputMaybe<Scalars['Color']['input']>;
  groups?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** The name to identify this page section in here */
  name?: InputMaybe<Scalars['String']['input']>;
  shouldShow?: InputMaybe<PageDeviceBooleanValueInput>;
  subTitle?: InputMaybe<Scalars['String']['input']>;
  title?: InputMaybe<Scalars['String']['input']>;
  userStatus?: InputMaybe<PageUserStatus>;
};

export type PageSplitTextSectionUpdates = {
  __typename: 'PageSplitTextSectionUpdates';
  id: Scalars['ID']['output'];
  item: Maybe<PageSplitTextSection>;
  type: SimpleUpdateTypeEnum;
};

export type PageStaticCarousel = {
  __typename: 'PageStaticCarousel';
  backgroundColor: Maybe<Scalars['Color']['output']>;
  foregroundColor: Maybe<Scalars['Color']['output']>;
  id: Scalars['ID']['output'];
  link: Maybe<PageLink>;
  name: Scalars['String']['output'];
  plainText: Maybe<Scalars['String']['output']>;
};

export type PageStaticCarouselInput = {
  backgroundColor?: InputMaybe<Scalars['Color']['input']>;
  foregroundColor?: InputMaybe<Scalars['Color']['input']>;
  link?: InputMaybe<PageLinkInput>;
  name: Scalars['String']['input'];
  plainText?: InputMaybe<Scalars['String']['input']>;
};

export enum PageStatusEnum {
  /** Draft */
  DRAFT = 'DRAFT',
  /** Published */
  PUBLISHED = 'PUBLISHED'
}

export type PageTimetable = {
  __typename: 'PageTimetable';
  content: Scalars['HTML']['output'];
  id: Scalars['ID']['output'];
  isClosing: Maybe<Scalars['Boolean']['output']>;
  title: Maybe<Scalars['String']['output']>;
};

export type PageTimetableGroup = {
  __typename: 'PageTimetableGroup';
  id: Scalars['ID']['output'];
  timetables: Maybe<Array<PageTimetable>>;
  title: Maybe<Scalars['String']['output']>;
};

export type PageTimetableGroupInput = {
  timetables?: InputMaybe<Array<InputMaybe<PageTimetableInput>>>;
  title?: InputMaybe<Scalars['String']['input']>;
};

export type PageTimetableInput = {
  content: Scalars['HTML']['input'];
  isClosing?: InputMaybe<Scalars['Boolean']['input']>;
  title?: InputMaybe<Scalars['String']['input']>;
};

/** A section which displays a group of timetable blocks */
export type PageTimetableSection = PageSection & {
  __typename: 'PageTimetableSection';
  createdAt: Scalars['DateTime']['output'];
  groups: Maybe<Array<PageSectionGroup>>;
  id: Scalars['ID']['output'];
  identifier: Maybe<Scalars['String']['output']>;
  /** Set weather the page section should hide or show on diferent device types */
  isLive: Scalars['Boolean']['output'];
  /** The name to identify this page section in here */
  name: Scalars['String']['output'];
  shouldShow: PageDeviceBooleanValue;
  timetableGroups: Maybe<Array<PageTimetableGroup>>;
  title: Maybe<Scalars['String']['output']>;
  updatedAt: Scalars['DateTime']['output'];
  usedIn: Array<SectionUsedIn>;
  userStatus: PageUserStatus;
};

export type PageTimetableSectionAddInput = {
  groups?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** The name to identify this page section in here */
  name: Scalars['String']['input'];
  shouldShow: PageDeviceBooleanValueInput;
  timetableGroups?: InputMaybe<Array<InputMaybe<PageTimetableGroupInput>>>;
  title?: InputMaybe<Scalars['String']['input']>;
  userStatus: PageUserStatus;
};

export type PageTimetableSectionFilterInput = {
  createdAt?: InputMaybe<DateTimeEqualsInRangeFilterInput>;
  id?: InputMaybe<IDEqualsInFilterInput>;
  identifier?: InputMaybe<StringEqualsInRangeFilterInput>;
  search?: InputMaybe<Scalars['String']['input']>;
  updatedAt?: InputMaybe<DateTimeEqualsInRangeFilterInput>;
};

export type PageTimetableSectionList = {
  __typename: 'PageTimetableSectionList';
  limit: Scalars['Int']['output'];
  list: Array<PageTimetableSection>;
  skip: Scalars['Int']['output'];
  total: Scalars['Int']['output'];
};

export type PageTimetableSectionOrderInput = {
  createdAt?: InputMaybe<OrderDirectionEnum>;
  id?: InputMaybe<OrderDirectionEnum>;
  name?: InputMaybe<OrderDirectionEnum>;
  updatedAt?: InputMaybe<OrderDirectionEnum>;
};

export type PageTimetableSectionUpdateInput = {
  groups?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** The name to identify this page section in here */
  name?: InputMaybe<Scalars['String']['input']>;
  shouldShow?: InputMaybe<PageDeviceBooleanValueInput>;
  timetableGroups?: InputMaybe<Array<InputMaybe<PageTimetableGroupInput>>>;
  title?: InputMaybe<Scalars['String']['input']>;
  userStatus?: InputMaybe<PageUserStatus>;
};

export type PageTimetableSectionUpdates = {
  __typename: 'PageTimetableSectionUpdates';
  id: Scalars['ID']['output'];
  item: Maybe<PageTimetableSection>;
  type: SimpleUpdateTypeEnum;
};

export enum PageTypeEnum {
  /** Brand Section */
  BRAND_SECTION = 'BRAND_SECTION',
  /** Category Section */
  CATEGORY_SECTION = 'CATEGORY_SECTION',
  /** Ingredient Section */
  INGREDIENT_SECTION = 'INGREDIENT_SECTION',
  /** Content Page */
  PAGE = 'PAGE'
}

export type PageTypeEnumEqualsInRangeFilterInput = {
  doesNotEqual?: InputMaybe<PageTypeEnum>;
  equals?: InputMaybe<PageTypeEnum>;
  greaterThan?: InputMaybe<PageTypeEnum>;
  in?: InputMaybe<Array<InputMaybe<PageTypeEnum>>>;
  lessThan?: InputMaybe<PageTypeEnum>;
  notIn?: InputMaybe<Array<InputMaybe<PageTypeEnum>>>;
};

export type PageUpdateInput = {
  /** The name of the Page to identify it in here */
  name?: InputMaybe<Scalars['String']['input']>;
  /** The type of page (Content Page, Category, Brand or Ingredient) */
  pageType?: InputMaybe<PageTypeEnum>;
  versions?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
};

export type PageUpdates = {
  __typename: 'PageUpdates';
  id: Scalars['ID']['output'];
  item: Maybe<Page>;
  type: SimpleUpdateTypeEnum;
};

export enum PageUserStatus {
  /** Both */
  BOTH = 'BOTH',
  /** Logged In */
  LOGGED_IN = 'LOGGED_IN',
  /** Logged Out */
  LOGGED_OUT = 'LOGGED_OUT'
}

export enum PageVerticalAlignment {
  /** Bottom */
  BOTTOM = 'BOTTOM',
  /** Middle */
  MIDDLE = 'MIDDLE',
  /** Top */
  TOP = 'TOP'
}

export type PargoLocation = {
  __typename: 'PargoLocation';
  address1: Maybe<Scalars['String']['output']>;
  address2: Maybe<Scalars['String']['output']>;
  addressSms: Maybe<Scalars['String']['output']>;
  businessHours: Maybe<Scalars['String']['output']>;
  city: Maybe<Scalars['String']['output']>;
  distance: Maybe<Scalars['Float']['output']>;
  latitude: Maybe<Scalars['Float']['output']>;
  longitude: Maybe<Scalars['Float']['output']>;
  pargoPointCode: Maybe<Scalars['String']['output']>;
  phoneNumber: Maybe<Scalars['String']['output']>;
  photo: Maybe<Scalars['String']['output']>;
  postalcode: Maybe<Scalars['String']['output']>;
  province: Maybe<Scalars['String']['output']>;
  storeName: Maybe<Scalars['String']['output']>;
  storeOwner: Maybe<Scalars['String']['output']>;
  suburb: Maybe<Scalars['String']['output']>;
};

/** Contains required input for Payflow Express Checkout payments. */
export type PayflowExpressInput = {
  /** The unique ID of the PayPal user. */
  payerId: Scalars['String']['input'];
  /** The token returned by the createPaypalExpressToken mutation. */
  token: Scalars['String']['input'];
};

/** A set of relative URLs that PayPal uses in response to various actions during the authorization process. Adobe Commerce prepends the base URL to this value to create a full URL. For example, if the full URL is https://www.example.com/path/to/page.html, the relative URL is path/to/page.html. Use this input for Payflow Link and Payments Advanced payment methods. */
export type PayflowLinkInput = {
  /** The relative URL of the page that PayPal redirects to when the buyer cancels the transaction in order to choose a different payment method. If the full URL to this page is https://www.example.com/paypal/action/cancel.html, the relative URL is paypal/action/cancel.html. */
  cancelUrl: Scalars['String']['input'];
  /** The relative URL of the transaction error page that PayPal redirects to upon payment error. If the full URL to this page is https://www.example.com/paypal/action/error.html, the relative URL is paypal/action/error.html. */
  errorUrl: Scalars['String']['input'];
  /** The relative URL of the order confirmation page that PayPal redirects to when the payment is successful and additional confirmation is not needed. If the full URL to this page is https://www.example.com/paypal/action/return.html, the relative URL is paypal/action/return.html. */
  returnUrl: Scalars['String']['input'];
};

/** Indicates the mode for payment. Applies to the Payflow Link and Payments Advanced payment methods. */
export enum PayflowLinkMode {
  /** @deprecated  */
  LIVE = 'LIVE',
  /** @deprecated  */
  TEST = 'TEST'
}

/** Contains information used to generate PayPal iframe for transaction. Applies to Payflow Link and Payments Advanced payment methods. */
export type PayflowLinkToken = {
  __typename: 'PayflowLinkToken';
  /** The mode for the Payflow transaction. */
  mode: Maybe<PayflowLinkMode>;
  /** The PayPal URL used for requesting a Payflow form. */
  paypalUrl: Maybe<Scalars['String']['output']>;
  /** The secure token generated by PayPal. */
  secureToken: Maybe<Scalars['String']['output']>;
  /** The secure token ID generated by PayPal. */
  secureTokenId: Maybe<Scalars['String']['output']>;
};

/** Contains information required to fetch payment token information for the Payflow Link and Payments Advanced payment methods. */
export type PayflowLinkTokenInput = {
  /** The unique ID that identifies the customer's cart. */
  cartId: Scalars['String']['input'];
};

/** Contains input for the Payflow Pro and Payments Pro payment methods. */
export type PayflowProInput = {
  /** Required input for credit card related information. */
  ccDetails: CreditCardDetailsInput;
  /** Indicates whether details about the shopper's credit/debit card should be tokenized for later usage. Required only if Vault is enabled for the PayPal Payflow Pro payment integration. */
  isActivePaymentTokenEnabler?: InputMaybe<Scalars['Boolean']['input']>;
};

/** Input required to complete payment. Applies to Payflow Pro and Payments Pro payment methods. */
export type PayflowProResponseInput = {
  /** The unique ID that identifies the shopper's cart. */
  cartId: Scalars['String']['input'];
  /** The payload returned from PayPal. */
  paypalPayload: Scalars['String']['input'];
};

export type PayflowProResponseOutput = {
  __typename: 'PayflowProResponseOutput';
  /** The cart with the updated selected payment method. */
  cart: Cart;
};

/** Contains the secure information used to authorize transaction. Applies to Payflow Pro and Payments Pro payment methods. */
export type PayflowProToken = {
  __typename: 'PayflowProToken';
  /** The RESPMSG returned by PayPal. If the `result` is `0`, then `response_message` is `Approved`. */
  responseMessage: Scalars['String']['output'];
  /** A non-zero value if any errors occurred. */
  result: Scalars['Int']['output'];
  /** The RESULT returned by PayPal. A value of `0` indicates the transaction was approved. */
  resultCode: Scalars['Int']['output'];
  /** A secure token generated by PayPal. */
  secureToken: Scalars['String']['output'];
  /** A secure token ID generated by PayPal. */
  secureTokenId: Scalars['String']['output'];
};

/** Contains input required to fetch payment token information for the Payflow Pro and Payments Pro payment methods. */
export type PayflowProTokenInput = {
  /** The unique ID that identifies the shopper's cart. */
  cartId: Scalars['String']['input'];
  /** A set of relative URLs that PayPal uses for callback. */
  urls: PayflowProUrlInput;
};

/** Contains a set of relative URLs that PayPal uses in response to various actions during the authorization process. Magento prepends the base URL to this value to create a full URL. For example, if the full URL is https://www.example.com/path/to/page.html, the relative URL is path/to/page.html. Use this input for the Payflow Pro and Payment Pro payment methods. */
export type PayflowProUrlInput = {
  /** The relative URL of the page that PayPal redirects to when the buyer cancels the transaction in order to choose a different payment method. If the full URL to this page is https://www.example.com/paypal/action/cancel.html, the relative URL is paypal/action/cancel.html. */
  cancelUrl: Scalars['String']['input'];
  /** The relative URL of the transaction error page that PayPal redirects to upon payment error. If the full URL to this page is https://www.example.com/paypal/action/error.html, the relative URL is paypal/action/error.html. */
  errorUrl: Scalars['String']['input'];
  /** The relative URL of the final confirmation page that PayPal redirects to upon payment success. If the full URL to this page is https://www.example.com/paypal/action/return.html, the relative URL is paypal/action/return.html. */
  returnUrl: Scalars['String']['input'];
};

export type PaymentCommonConfig = PaymentConfigItem & {
  __typename: 'PaymentCommonConfig';
  /** The payment method code as defined in the payment gateway */
  code: Maybe<Scalars['String']['output']>;
  /** Indicates whether the payment method is displayed */
  isVisible: Maybe<Scalars['Boolean']['output']>;
  /** Defines the payment intent (Authorize or Capture */
  paymentIntent: Maybe<Scalars['String']['output']>;
  /** The PayPal parameters required to load the JS SDK */
  sdkParams: Maybe<Array<Maybe<SdkParams>>>;
  /** The relative order the payment method is displayed on the checkout page */
  sortOrder: Maybe<Scalars['String']['output']>;
  /** The name displayed for the payment method */
  title: Maybe<Scalars['String']['output']>;
};

/** Contains payment fields that are common to all types of payment methods. */
export type PaymentConfigItem = {
  /** The payment method code as defined in the payment gateway */
  code: Maybe<Scalars['String']['output']>;
  /** Indicates whether the payment method is displayed */
  isVisible: Maybe<Scalars['Boolean']['output']>;
  /** Defines the payment intent (Authorize or Capture */
  paymentIntent: Maybe<Scalars['String']['output']>;
  /** The PayPal parameters required to load the JS SDK */
  sdkParams: Maybe<Array<Maybe<SdkParams>>>;
  /** The relative order the payment method is displayed on the checkout page */
  sortOrder: Maybe<Scalars['String']['output']>;
  /** The name displayed for the payment method */
  title: Maybe<Scalars['String']['output']>;
};

/** Retrieves the payment configuration for a given location */
export type PaymentConfigOutput = {
  __typename: 'PaymentConfigOutput';
  /** ApplePay payment method configuration */
  applePay: Maybe<ApplePayConfig>;
  /** GooglePay payment method configuration */
  googlePay: Maybe<GooglePayConfig>;
  /** Hosted fields payment method configuration */
  hostedFields: Maybe<HostedFieldsConfig>;
  /** Smart Buttons payment method configuration */
  smartButtons: Maybe<SmartButtonsConfig>;
};

export type PaymentInfo = {
  __typename: 'PaymentInfo';
  billingAddressId: Maybe<Scalars['Int']['output']>;
  canSkipThreeDSecure: Maybe<Scalars['Boolean']['output']>;
  creditCardId: Maybe<Scalars['ID']['output']>;
  hasSetInfo: Scalars['Boolean']['output'];
  id: Scalars['ID']['output'];
  isNewCard: Maybe<Scalars['Boolean']['output']>;
  newCardInfo: Maybe<CreditCardInfo>;
  paymentMethod: Maybe<PaymentMethodEnum>;
  step: PaymentStepEnum;
  threeDSecureId: Maybe<Scalars['String']['output']>;
};

/** Defines the origin location for that payment request */
export enum PaymentLocation {
  /** @deprecated  */
  ADMIN = 'ADMIN',
  /** @deprecated  */
  CART = 'CART',
  /** @deprecated  */
  CHECKOUT = 'CHECKOUT',
  /** @deprecated  */
  MINICART = 'MINICART',
  /** @deprecated  */
  PRODUCT_DETAIL = 'PRODUCT_DETAIL'
}

export enum PaymentMethodEnum {
  checkmo = 'checkmo',
  ozow = 'ozow',
  peachpayments_s2s = 'peachpayments_s2s'
}

/** Defines the payment method. */
export type PaymentMethodInput = {
  /** The internal name for the payment method. */
  code: Scalars['String']['input'];
  /** Required input for PayPal Hosted pro payments. */
  hostedPro?: InputMaybe<HostedProInput>;
  /** Required input for Ozow Hosted payments */
  ozow?: InputMaybe<OzowInput>;
  /** Required input for Payflow Express Checkout payments. */
  payflowExpress?: InputMaybe<PayflowExpressInput>;
  /** Required input for PayPal Payflow Link and Payments Advanced payments. */
  payflowLink?: InputMaybe<PayflowLinkInput>;
  /** Required input for PayPal Payflow Pro and Payment Pro payments. */
  payflowpro?: InputMaybe<PayflowProInput>;
  /** Required input for PayPal Payflow Pro vault payments. */
  payflowproCcVault?: InputMaybe<VaultTokenInput>;
  /** Required input for Apple Pay button */
  paymentServicesPaypalApplePay?: InputMaybe<ApplePayMethodInput>;
  /** Required input for Google Pay button */
  paymentServicesPaypalGooglePay?: InputMaybe<GooglePayMethodInput>;
  /** Required input for Hosted Fields */
  paymentServicesPaypalHostedFields?: InputMaybe<HostedFieldsInput>;
  /** Required input for Smart buttons */
  paymentServicesPaypalSmartButtons?: InputMaybe<SmartButtonMethodInput>;
  /** Required input for vault */
  paymentServicesPaypalVault?: InputMaybe<VaultMethodInput>;
  /** Required input for Express Checkout and Payments Standard payments. */
  paypalExpress?: InputMaybe<PaypalExpressInput>;
  /** Add a card with Peach Payments via set payment method */
  peachpaymentsS2s?: InputMaybe<PeachPaymentsCardInput>;
  /** The purchase order number. Optional for most payment methods. */
  purchaseOrderNumber?: InputMaybe<Scalars['String']['input']>;
};

/** Contains the payment order details */
export type PaymentOrderOutput = {
  __typename: 'PaymentOrderOutput';
  /** PayPal order ID */
  id: Maybe<Scalars['String']['output']>;
  /** The order ID generated by Payment Services */
  mpOrderId: Maybe<Scalars['String']['output']>;
  /** Details about the card used on the order */
  paymentSourceDetails: Maybe<PaymentSourceDetails>;
  /** The status of the payment order */
  status: Maybe<Scalars['String']['output']>;
};

export type PaymentSdkParamsItem = {
  __typename: 'PaymentSdkParamsItem';
  /** The payment method code used in the order */
  code: Maybe<Scalars['String']['output']>;
  /** The payment SDK parameters */
  params: Maybe<Array<Maybe<SdkParams>>>;
};

export type PaymentSourceDetails = {
  __typename: 'PaymentSourceDetails';
  /** Details about the card used on the order */
  card: Maybe<Card>;
};

export enum PaymentStepEnum {
  /** Payment Method */
  PAYMENT_METHOD = 'PAYMENT_METHOD',
  /** Three D Secure */
  THREE_D_SECURE = 'THREE_D_SECURE'
}

export enum PaymentTokeStatus {
  SAVED = 'SAVED',
  TEMP_LOCAL = 'TEMP_LOCAL'
}

/** The stored payment method available to the customer. */
export type PaymentToken = {
  __typename: 'PaymentToken';
  /** A description of the stored account details. */
  details: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  /** The payment method code associated with the token. */
  paymentMethodCode: Scalars['String']['output'];
  /** The public hash of the token. */
  publicHash: Scalars['String']['output'];
  /** Specifies the payment token type. */
  type: PaymentTokenTypeEnum;
};

/** The list of available payment token types. */
export enum PaymentTokenTypeEnum {
  /**
   * phpcs:ignore Magento2.GraphQL.ValidArgumentName
   * @deprecated
   */
  account = 'account',
  /**
   * phpcs:ignore Magento2.GraphQL.ValidArgumentName
   * @deprecated
   */
  card = 'card'
}

/** Contains required input for Express Checkout and Payments Standard payments. */
export type PaypalExpressInput = {
  /** The unique ID of the PayPal user. */
  payerId: Scalars['String']['input'];
  /** The token returned by the `createPaypalExpressToken` mutation. */
  token: Scalars['String']['input'];
};

/** Deprecated. Use `PaypalExpressTokenOutput` instead. */
export type PaypalExpressToken = {
  __typename: 'PaypalExpressToken';
  /**
   * A set of URLs that allow the buyer to authorize payment and adjust checkout details.
   * @deprecated Use `PaypalExpressTokenOutput.paypal_urls` instead.
   */
  paypalUrls: Maybe<PaypalExpressUrlList>;
  /**
   * The token returned by PayPal.
   * @deprecated Use `PaypalExpressTokenOutput.token` instead.
   */
  token: Maybe<Scalars['String']['output']>;
};

/** Defines the attributes required to receive a payment token for Express Checkout and Payments Standard payment methods. */
export type PaypalExpressTokenInput = {
  /** The unique ID that identifies the customer's cart. */
  cartId: Scalars['String']['input'];
  /** The payment method code. */
  code: Scalars['String']['input'];
  /** Indicates whether the buyer selected the quick checkout button. The default value is false. */
  expressButton?: InputMaybe<Scalars['Boolean']['input']>;
  /** A set of relative URLs that PayPal uses in response to various actions during the authorization process. */
  urls: PaypalExpressUrlsInput;
  /** Indicates whether the buyer clicked the PayPal credit button. The default value is false. */
  usePaypalCredit?: InputMaybe<Scalars['Boolean']['input']>;
};

/** Contains the token returned by PayPal and a set of URLs that allow the buyer to authorize payment and adjust checkout details. Applies to Express Checkout and Payments Standard payment methods. */
export type PaypalExpressTokenOutput = {
  __typename: 'PaypalExpressTokenOutput';
  /** A set of URLs that allow the buyer to authorize payment and adjust checkout details. */
  paypalUrls: Maybe<PaypalExpressUrlList>;
  /** The token returned by PayPal. */
  token: Maybe<Scalars['String']['output']>;
};

/** Contains a set of URLs that allow the buyer to authorize payment and adjust checkout details for Express Checkout and Payments Standard transactions. */
export type PaypalExpressUrlList = {
  __typename: 'PaypalExpressUrlList';
  /** The PayPal URL that allows the buyer to edit their checkout details. */
  edit: Maybe<Scalars['String']['output']>;
  /** The URL to the PayPal login page. */
  start: Maybe<Scalars['String']['output']>;
};

/** Contains a set of relative URLs that PayPal uses in response to various actions during the authorization process. Magento prepends the base URL to this value to create a full URL. For example, if the full URL is https://www.example.com/path/to/page.html, the relative URL is path/to/page.html. Use this input for Express Checkout and Payments Standard payment methods. */
export type PaypalExpressUrlsInput = {
  /** The relative URL of the page that PayPal redirects to when the buyer cancels the transaction in order to choose a different payment method. If the full URL to this page is https://www.example.com/paypal/action/cancel.html, the relative URL is paypal/action/cancel.html. */
  cancelUrl: Scalars['String']['input'];
  /** The relative URL of the page that PayPal redirects to when the payment has been put on hold for additional review. This condition mostly applies to ACH transactions, and is not applicable to most PayPal solutions. If the full URL to this page is https://www.example.com/paypal/action/success_pending.html, the relative URL is paypal/action/success_pending.html. */
  pendingUrl?: InputMaybe<Scalars['String']['input']>;
  /** The relative URL of the final confirmation page that PayPal redirects to upon payment success. If the full URL to this page is https://www.example.com/paypal/action/return.html, the relative URL is paypal/action/return.html. */
  returnUrl: Scalars['String']['input'];
  /** The relative URL of the order confirmation page that PayPal redirects to when the payment is successful and additional confirmation is not needed. Not applicable to most PayPal solutions. If the full URL to this page is https://www.example.com/paypal/action/success.html, the relative URL is paypal/action/success.html. */
  successUrl?: InputMaybe<Scalars['String']['input']>;
};

export type PeachPayments3DSecure = {
  __typename: 'PeachPayments3DSecure';
  /** Determines if we can skip 3D secure */
  canSkip_3ds: Maybe<Scalars['Boolean']['output']>;
  /** 3DS v2 Params inputs to redirect url - note case : [MD, connector, PaReq, TermUrl] please see section 2 @ https://peachpayments.docs.oppwa.com/tutorials/threeDSecure */
  parameters: Maybe<Array<Maybe<PeachPayments3DSecureParameters>>>;
  /** Payment ID */
  paymentId: Scalars['ID']['output'];
  /** 3DS v2 Precondition set  */
  preconditions: Maybe<Array<Maybe<PeachPayments3DSecurePrecondition>>>;
  /** Url to redirect the customer to 3DS */
  redirectUrl: Maybe<Scalars['String']['output']>;
};

export type PeachPayments3DSecureParameters = {
  __typename: 'PeachPayments3DSecureParameters';
  name: Scalars['String']['output'];
  value: Scalars['String']['output'];
};

export type PeachPayments3DSecurePrecondition = {
  __typename: 'PeachPayments3DSecurePrecondition';
  /** Description of the iframe */
  description: Maybe<Scalars['String']['output']>;
  /** Method of the iframe */
  method: Maybe<Scalars['String']['output']>;
  /** Origin of the iframe */
  origin: Maybe<Scalars['String']['output']>;
  /** Parameters of the iframe precondition */
  parameters: Maybe<Array<Maybe<PeachPayments3DSecurePreconditionParameters>>>;
  /** Url of the iframe */
  url: Maybe<Scalars['String']['output']>;
  /** Wait until the iframe is loaded signal */
  waitUntil: Maybe<Scalars['String']['output']>;
};

export type PeachPayments3DSecurePreconditionParameters = {
  __typename: 'PeachPayments3DSecurePreconditionParameters';
  name: Maybe<Scalars['String']['output']>;
  value: Maybe<Scalars['String']['output']>;
};

/** PeachPaymentsCardInput & Cart id */
export type PeachPayments3DsInput = {
  /** PeachPaymentsTokensFilterInput defines the list of card attribute. */
  card: PeachPaymentsCardInput;
  /** The unique ID that identifies the customer's cart */
  cartId: Scalars['String']['input'];
  /** Uri 3ds should redirect to after successfully authenticating */
  redirectTo?: InputMaybe<Scalars['String']['input']>;
};

export type PeachPaymentsAddedToken = {
  __typename: 'PeachPaymentsAddedToken';
  /** Secure Token generated by PeachPayments */
  token: Maybe<PeachPaymentsToken>;
};

/** PeachPaymentsCardInput is the input required to add a card into peach payments */
export type PeachPaymentsCardInput = {
  /** Card brands */
  brand?: InputMaybe<PeachPaymentsCreditCardTypeEnum>;
  /** Card CVV */
  cvv?: InputMaybe<Scalars['String']['input']>;
  /** Card expiry month */
  expiryMonth?: InputMaybe<Scalars['String']['input']>;
  /** Card expiry year */
  expiryYear?: InputMaybe<Scalars['String']['input']>;
  /** Name on the card */
  name?: InputMaybe<Scalars['String']['input']>;
  /** Card nick name */
  nickname?: InputMaybe<Scalars['String']['input']>;
  /** Number on card */
  number?: InputMaybe<Scalars['String']['input']>;
  /** Customer can opt in to save the card or not, will only be applicable to authenticated customers */
  saveCard?: InputMaybe<Scalars['Boolean']['input']>;
  /** ID returned from ThreeDSecure call, we will validate this first, please note this is not required when doing the 3DS call itself. */
  threeDSecure?: InputMaybe<Scalars['ID']['input']>;
  /** Customer saved token can be used instead of card details */
  token?: InputMaybe<Scalars['String']['input']>;
};

/** Input to be able to update the peach payments card nickname */
export type PeachPaymentsCartNickChangeInput = {
  /** Id of card that needs updating */
  cardId: Scalars['String']['input'];
  /** New card nick name */
  cardNickname: Scalars['String']['input'];
};

export enum PeachPaymentsCreditCardTypeEnum {
  /**
   * American express
   * @deprecated
   */
  AMERICAN_EXPRESS = 'AMERICAN_EXPRESS',
  /**
   * Diners club
   * @deprecated
   */
  DINERS_CLUB = 'DINERS_CLUB',
  /**
   * Discover
   * @deprecated
   */
  DISCOVER = 'DISCOVER',
  /**
   * Elo
   * @deprecated
   */
  ELO = 'ELO',
  /**
   * Hiper
   * @deprecated
   */
  HIPER = 'HIPER',
  /**
   * Hipercard
   * @deprecated
   */
  HIPERCARD = 'HIPERCARD',
  /**
   * Jcb
   * @deprecated
   */
  JCB = 'JCB',
  /**
   * Maestro
   * @deprecated
   */
  MAESTRO = 'MAESTRO',
  /**
   * Mastercard
   * @deprecated
   */
  MASTERCARD = 'MASTERCARD',
  /**
   * Mir
   * @deprecated
   */
  MIR = 'MIR',
  /**
   * Unionpay
   * @deprecated
   */
  UNIONPAY = 'UNIONPAY',
  /**
   * Visa
   * @deprecated
   */
  VISA = 'VISA'
}

export type PeachPaymentsPaymentStatus = {
  __typename: 'PeachPaymentsPaymentStatus';
  /** Amount */
  amount: Maybe<Scalars['String']['output']>;
  /** Card */
  card: Maybe<PeachPaymentsPaymentStatusCard>;
  /** Currency */
  currency: Maybe<Scalars['String']['output']>;
  /** Payment brand */
  paymentBrand: Maybe<Scalars['String']['output']>;
  /** Payment type */
  paymentType: Maybe<Scalars['String']['output']>;
  /** Simple status , experimental we check if its redirect or success anything else gets marked as failed */
  simpleStatus: Maybe<PeachPaymentsSimpleStatus>;
};

export type PeachPaymentsPaymentStatusCard = {
  __typename: 'PeachPaymentsPaymentStatusCard';
  /** Bin */
  bin: Maybe<Scalars['String']['output']>;
  /** Bin country */
  binCountry: Maybe<Scalars['String']['output']>;
  /** Expiry month */
  expiryMonth: Maybe<Scalars['String']['output']>;
  /** Expiry year */
  expiryYear: Maybe<Scalars['String']['output']>;
  /** Account Holder */
  holder: Maybe<Scalars['String']['output']>;
  /** Last 4 digits */
  last4digits: Maybe<Scalars['String']['output']>;
};

export type PeachPaymentsRemoveCard = {
  __typename: 'PeachPaymentsRemoveCard';
  /** Status message in case we want to do say something useful */
  status: Maybe<Scalars['String']['output']>;
  /** Bool indicating if the delete was successful */
  statusFlag: Maybe<Scalars['Boolean']['output']>;
};

export enum PeachPaymentsSimpleStatus {
  /** @deprecated  */
  FAILED = 'FAILED',
  /** @deprecated  */
  PENDING = 'PENDING',
  /** @deprecated  */
  REVIEW = 'REVIEW',
  /** @deprecated  */
  SUCCESS = 'SUCCESS'
}

export type PeachPaymentsToken = {
  __typename: 'PeachPaymentsToken';
  /** First 6 numbers, identify brand and bank */
  binNumber: Maybe<Scalars['String']['output']>;
  /** Card brand */
  cardBrand: PeachPaymentsCreditCardTypeEnum;
  /** Card name created by user */
  cardNickname: Maybe<Scalars['String']['output']>;
  /** Expiry month */
  expiryMonth: Scalars['String']['output'];
  /** Expiry year */
  expiryYear: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  /** Is the User's Default Card */
  isDefault: Maybe<Scalars['Boolean']['output']>;
  /** Is the User's Card validated */
  isValidated: Maybe<Scalars['Boolean']['output']>;
  /** Last 4 digits of a card */
  last4Digits: Maybe<Scalars['String']['output']>;
  /** Name on card */
  nameOnCard: Scalars['String']['output'];
  nexusNoCacheDummyPropert: Maybe<Scalars['String']['output']>;
  /** Saved token for reuse */
  paymentToken: Maybe<Scalars['String']['output']>;
};

export type PeachPaymentsTokens = {
  __typename: 'PeachPaymentsTokens';
  items: Maybe<Array<Maybe<PeachPaymentsToken>>>;
  /** An object that includes the page_info and currentPage values specified in the query. */
  pageInfo: Maybe<SearchResultPageInfo>;
  /** The number of tokens returned. */
  totalCount: Maybe<Scalars['Int']['output']>;
};

/** PeachPaymentsTokensFilterInput defines the list of attributes and filters for the search. */
export type PeachPaymentsTokensFilterInput = {
  /** Filter by brand. */
  cardBrand?: InputMaybe<FilterTypeInput>;
  /** Filter by nick name. */
  cardNickname?: InputMaybe<FilterTypeInput>;
  /** Filter by expiry year. */
  expiryYear?: InputMaybe<FilterTypeInput>;
  /** Filter by validated. */
  isValidated?: InputMaybe<FilterTypeInput>;
  /** Filter by name on card. */
  nameOnCard?: InputMaybe<FilterTypeInput>;
};

/** PeachPaymentsTokensSortInput specifies attribute to use for sorting search results and indicates whether the results are sorted in ascending or descending order. */
export type PeachPaymentsTokensSortInput = {
  /** Sort by brand. */
  cardBrand?: InputMaybe<SortEnum>;
  /** Sort by nick name. */
  cardNickname?: InputMaybe<SortEnum>;
  /** Sort by expiry year. */
  expiryYear?: InputMaybe<SortEnum>;
  /** Sort by validated. */
  isValidated?: InputMaybe<SortEnum>;
  /** Sort by name on card. */
  nameOnCard?: InputMaybe<SortEnum>;
};

export type PeachPaymentsUpdateCardNickName = {
  __typename: 'PeachPaymentsUpdateCardNickName';
  status: Maybe<Scalars['String']['output']>;
};

export type PersonalDiscount = {
  __typename: 'PersonalDiscount';
  /** The discount code */
  code: Maybe<Scalars['String']['output']>;
  discountProducts: Array<ProductInterface>;
  /** The discount end date */
  endDate: Maybe<Scalars['String']['output']>;
  /** The discount description */
  label: Maybe<Scalars['String']['output']>;
  nexusNoCacheDummyPropert: Maybe<Scalars['String']['output']>;
  /** The discount value */
  percentage: Maybe<Scalars['Float']['output']>;
  /** The discount products */
  products: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  /** The discount start date */
  startDate: Maybe<Scalars['String']['output']>;
  /** The discount status */
  status: Maybe<Scalars['Boolean']['output']>;
};

export type PersonalDiscountConfig = {
  __typename: 'PersonalDiscountConfig';
  discountActivationText: Scalars['String']['output'];
};

/** Contains attributes specific to tangible products. */
export type PhysicalProductInterface = {
  /** The weight of the item, in units defined by the store. */
  weight: Maybe<Scalars['Float']['output']>;
};

/** Specifies the quote to be converted to an order. */
export type PlaceOrderInput = {
  /** The unique ID of a `Cart` object. */
  cartId: Scalars['String']['input'];
};

/** Contains the results of the request to place an order. */
export type PlaceOrderOutput = {
  __typename: 'PlaceOrderOutput';
  /** The ID of the order. */
  order: Order;
};

export enum PlaceStepEnum {
  /** Confirm */
  CONFIRM = 'CONFIRM',
  /** Place Order */
  PLACE_ORDER = 'PLACE_ORDER'
}

export enum PreferredContactMethodEnum {
  /** @deprecated  */
  EMAIL = 'EMAIL',
  /** @deprecated  */
  PHONE = 'PHONE'
}

/** Deprecated. Use `ProductPrice` instead. Defines the price of a product as well as any tax-related adjustments. */
export type Price = {
  __typename: 'Price';
  /**
   * An array that provides information about tax, weee, or weee_tax adjustments.
   * @deprecated Use `ProductPrice` instead.
   */
  adjustments: Maybe<Array<Maybe<PriceAdjustment>>>;
  /**
   * The price of a product plus a three-letter currency code.
   * @deprecated Use `ProductPrice` instead.
   */
  amount: Maybe<Money>;
};

/** Deprecated. Taxes will be included or excluded in the price. Defines the amount of money to apply as an adjustment, the type of adjustment to apply, and whether the item is included or excluded from the adjustment. */
export type PriceAdjustment = {
  __typename: 'PriceAdjustment';
  /** The amount of the price adjustment and its currency code. */
  amount: Maybe<Money>;
  /**
   * Indicates whether the adjustment involves tax, weee, or weee_tax.
   * @deprecated `PriceAdjustment` is deprecated.
   */
  code: Maybe<PriceAdjustmentCodesEnum>;
  /**
   * Indicates whether the entity described by the code attribute is included or excluded from the adjustment.
   * @deprecated `PriceAdjustment` is deprecated.
   */
  description: Maybe<PriceAdjustmentDescriptionEnum>;
};

/** `PriceAdjustment.code` is deprecated. */
export enum PriceAdjustmentCodesEnum {
  /** @deprecated `PriceAdjustmentCodesEnum` is deprecated. Tax is included or excluded in the price. Tax is not shown separately in Catalog. */
  TAX = 'TAX'
}

/** `PriceAdjustmentDescriptionEnum` is deprecated. States whether a price adjustment is included or excluded. */
export enum PriceAdjustmentDescriptionEnum {
  /** @deprecated  */
  EXCLUDED = 'EXCLUDED',
  /** @deprecated  */
  INCLUDED = 'INCLUDED'
}

/** Contains the price range for a product. If the product has a single price, the minimum and maximum price will be the same. */
export type PriceRange = {
  __typename: 'PriceRange';
  /** The highest possible price for the product. */
  maximumPrice: Maybe<ProductPrice>;
  /** The lowest possible price for the product. */
  minimumPrice: ProductPrice;
};

/** Defines the price type. */
export enum PriceTypeEnum {
  /** @deprecated  */
  DYNAMIC = 'DYNAMIC',
  /** @deprecated  */
  FIXED = 'FIXED',
  /** @deprecated  */
  PERCENT = 'PERCENT'
}

/** Defines whether a bundle product's price is displayed as the lowest possible value or as a range. */
export enum PriceViewEnum {
  /** @deprecated  */
  AS_LOW_AS = 'AS_LOW_AS',
  /** @deprecated  */
  PRICE_RANGE = 'PRICE_RANGE'
}

/** Contains a product attribute code and value. */
export type ProductAttribute = {
  __typename: 'ProductAttribute';
  /** The unique identifier for a product attribute code. */
  code: Scalars['String']['output'];
  /** The display value of the attribute. */
  value: Scalars['String']['output'];
};

/** This enumeration defines the product attributes that are visible on the frontend */
export enum ProductAttributeEnum {
  /**
   * Age
   * @deprecated
   */
  AGE = 'AGE',
  /**
   * Colour
   * @deprecated
   */
  COLOUR = 'COLOUR',
  /**
   * Concentration
   * @deprecated
   */
  CONCENTRATION = 'CONCENTRATION',
  /**
   * Department
   * @deprecated
   */
  DEPARTMENT = 'DEPARTMENT',
  /**
   * Flavour
   * @deprecated
   */
  FLAVOUR = 'FLAVOUR',
  /**
   * Fragrance
   * @deprecated
   */
  FRAGRANCE = 'FRAGRANCE',
  /**
   * Gender
   * @deprecated
   */
  GENDER = 'GENDER',
  /**
   * Hair Type
   * @deprecated
   */
  HAIR_TYPE = 'HAIR_TYPE',
  /**
   * Height
   * @deprecated
   */
  HEIGHT = 'HEIGHT',
  /**
   * Ingredients
   * @deprecated
   */
  INGREDIENTS = 'INGREDIENTS',
  /**
   * Length
   * @deprecated
   */
  LENGTH = 'LENGTH',
  /**
   * Licensing Status
   * @deprecated
   */
  LICENSING_STATUS = 'LICENSING_STATUS',
  /**
   * Manufacturer
   * @deprecated
   */
  MANUFACTURER = 'MANUFACTURER',
  /**
   * Product Name
   * @deprecated
   */
  NAME = 'NAME',
  /**
   * Price
   * @deprecated
   */
  PRICE = 'PRICE',
  /**
   * Quantity
   * @deprecated
   */
  QUANTITY = 'QUANTITY',
  /**
   * Rating filter
   * @deprecated
   */
  RATING_FILTER = 'RATING_FILTER',
  /**
   * Size
   * @deprecated
   */
  SIZE = 'SIZE',
  /**
   * Skin Concern
   * @deprecated
   */
  SKIN_CONCERN = 'SKIN_CONCERN',
  /**
   * Skin Type
   * @deprecated
   */
  SKIN_TYPE = 'SKIN_TYPE',
  /**
   * Special Icons
   * @deprecated
   */
  SPECIAL_ICONS = 'SPECIAL_ICONS',
  /**
   * Diet Values
   * @deprecated
   */
  VALUES_DIET = 'VALUES_DIET',
  /**
   * Environmental Values
   * @deprecated
   */
  VALUES_ENVIRONMENTAL = 'VALUES_ENVIRONMENTAL',
  /**
   * Ingredient Values
   * @deprecated
   */
  VALUES_INGREDIENT = 'VALUES_INGREDIENT',
  /**
   * Social Values
   * @deprecated
   */
  VALUES_SOCIAL = 'VALUES_SOCIAL',
  /**
   * Value Traits
   * @deprecated
   */
  VALUE_TRAITS = 'VALUE_TRAITS',
  /**
   * Volume Weight
   * @deprecated
   */
  VOLUME_WEIGHT = 'VOLUME_WEIGHT',
  /**
   * Width
   * @deprecated
   */
  WIDTH = 'WIDTH'
}

/** Defines the filters to be used in the search. A filter contains at least one attribute, a comparison operator, and the value that is being searched for. */
export type ProductAttributeFilterInput = {
  /** Attribute label: Age */
  age?: InputMaybe<FilterEqualTypeInput>;
  /** Deprecated: use `category_uid` to filter product by category ID. */
  categoryId?: InputMaybe<FilterEqualTypeInput>;
  /** Filter product by the unique ID for a `CategoryInterface` object. */
  categoryUid?: InputMaybe<FilterEqualTypeInput>;
  /** Filter product by category URL path. */
  categoryUrlPath?: InputMaybe<FilterEqualTypeInput>;
  /** Attribute label: Colour */
  colour?: InputMaybe<FilterEqualTypeInput>;
  /** Attribute label: Concentration */
  concentration?: InputMaybe<FilterEqualTypeInput>;
  /** Attribute label: Department */
  department?: InputMaybe<FilterEqualTypeInput>;
  /** Attribute label: Description */
  description?: InputMaybe<FilterMatchTypeInput>;
  /** Filter by the identifier */
  entityId?: InputMaybe<FilterEqualTypeInput>;
  /** Attribute label: Flavour */
  flavour?: InputMaybe<FilterEqualTypeInput>;
  /** Attribute label: Fragrance */
  fragrance?: InputMaybe<FilterEqualTypeInput>;
  /** Attribute label: Gender */
  gender?: InputMaybe<FilterEqualTypeInput>;
  /** Attribute label: Hair Type */
  hairType?: InputMaybe<FilterEqualTypeInput>;
  /** Attribute label: Height */
  height?: InputMaybe<FilterMatchTypeInput>;
  /** Attribute label: Ingredients */
  ingredients?: InputMaybe<FilterEqualTypeInput>;
  /** Attribute label: Length */
  length?: InputMaybe<FilterMatchTypeInput>;
  /** Attribute label: Licensing Status */
  licensingStatus?: InputMaybe<FilterEqualTypeInput>;
  /** Attribute label: Manufacturer */
  manufacturer?: InputMaybe<FilterEqualTypeInput>;
  /** Attribute label: Product Name */
  name?: InputMaybe<FilterMatchTypeInput>;
  /** Attribute label: Price */
  price?: InputMaybe<FilterRangeTypeInput>;
  /** Attribute label: Quantity */
  quantity?: InputMaybe<FilterEqualTypeInput>;
  /** Attribute label: Rating filter */
  ratingFilter?: InputMaybe<FilterMatchTypeInput>;
  /** Attribute label: Short Description */
  shortDescription?: InputMaybe<FilterMatchTypeInput>;
  /** Attribute label: Size */
  size?: InputMaybe<FilterEqualTypeInput>;
  /** Attribute label: Skin Concern */
  skinConcern?: InputMaybe<FilterEqualTypeInput>;
  /** Attribute label: Skin Type */
  skinType?: InputMaybe<FilterEqualTypeInput>;
  /** Attribute label: SKU */
  sku?: InputMaybe<FilterEqualTypeInput>;
  /** Attribute label: Special Icons */
  specialIcons?: InputMaybe<FilterEqualTypeInput>;
  /** The part of the URL that identifies the product */
  urlKey?: InputMaybe<FilterEqualTypeInput>;
  /** Attribute label: Value Traits */
  valueTraits?: InputMaybe<FilterEqualTypeInput>;
  /** Attribute label: Diet Values */
  valuesDiet?: InputMaybe<FilterEqualTypeInput>;
  /** Attribute label: Environmental Values */
  valuesEnvironmental?: InputMaybe<FilterEqualTypeInput>;
  /** Attribute label: Ingredient Values */
  valuesIngredient?: InputMaybe<FilterEqualTypeInput>;
  /** Attribute label: Social Values */
  valuesSocial?: InputMaybe<FilterEqualTypeInput>;
  /** Attribute label: Volume Weight */
  volumeWeight?: InputMaybe<FilterMatchTypeInput>;
  /** Attribute label: Width */
  width?: InputMaybe<FilterMatchTypeInput>;
};

/** Specifies the attribute to use for sorting search results and indicates whether the results are sorted in ascending or descending order. It's possible to sort products using searchable attributes with enabled 'Use in Filter Options' option */
export type ProductAttributeSortInput = {
  /** Attribute label: Product Name */
  name?: InputMaybe<SortEnum>;
  /** Sort by the position assigned to each product. */
  position?: InputMaybe<SortEnum>;
  /** Attribute label: Price */
  price?: InputMaybe<SortEnum>;
  /** Sort by the search relevance score (default). */
  relevance?: InputMaybe<SortEnum>;
};

export type ProductAttributes = {
  __typename: 'ProductAttributes';
  /** The unique identifier for a product attribute code */
  code: ProductAttributeEnum;
  /** The label for a product attribute code */
  label: Scalars['String']['output'];
  /** The display value of the attribute */
  value: Scalars['String']['output'];
};

/** Contains the discount applied to a product price. */
export type ProductDiscount = {
  __typename: 'ProductDiscount';
  /** The actual value of the discount. */
  amountOff: Maybe<Scalars['Float']['output']>;
  /** The discount expressed a percentage. */
  percentOff: Maybe<Scalars['Float']['output']>;
};

export type ProductFacet = {
  __typename: 'ProductFacet';
  end: Maybe<Scalars['Float']['output']>;
  facetType: FacetTypeEnum;
  gap: Maybe<Scalars['Float']['output']>;
  id: Scalars['String']['output'];
  multipleAllowed: Scalars['Boolean']['output'];
  start: Maybe<Scalars['Float']['output']>;
  title: Scalars['String']['output'];
  values: Maybe<Array<ProductFacetValue>>;
};

export type ProductFacetInput = {
  id: Scalars['String']['input'];
  value?: InputMaybe<Scalars['String']['input']>;
  values?: InputMaybe<Array<Scalars['String']['input']>>;
};

export type ProductFacetValue = {
  __typename: 'ProductFacetValue';
  count: Scalars['Int']['output'];
  title: Scalars['String']['output'];
  value: Scalars['String']['output'];
};

/** ProductFilterInput is deprecated, use @ProductAttributeFilterInput instead. ProductFilterInput defines the filters to be used in the search. A filter contains at least one attribute, a comparison operator, and the value that is being searched for. */
export type ProductFilterInput = {
  /** The category ID the product belongs to. */
  categoryId?: InputMaybe<FilterTypeInput>;
  /** The product's country of origin. */
  countryOfManufacture?: InputMaybe<FilterTypeInput>;
  /** The timestamp indicating when the product was created. */
  createdAt?: InputMaybe<FilterTypeInput>;
  /** The name of a custom layout. */
  customLayout?: InputMaybe<FilterTypeInput>;
  /** XML code that is applied as a layout update to the product page. */
  customLayoutUpdate?: InputMaybe<FilterTypeInput>;
  /** Detailed information about the product. The value can include simple HTML tags. */
  description?: InputMaybe<FilterTypeInput>;
  /** Indicates whether a gift message is available. */
  giftMessageAvailable?: InputMaybe<FilterTypeInput>;
  /** Indicates whether additional attributes have been created for the product. */
  hasOptions?: InputMaybe<FilterTypeInput>;
  /** The relative path to the main image on the product page. */
  image?: InputMaybe<FilterTypeInput>;
  /** The label assigned to a product image. */
  imageLabel?: InputMaybe<FilterTypeInput>;
  /** A number representing the product's manufacturer. */
  manufacturer?: InputMaybe<FilterTypeInput>;
  /** The numeric maximal price of the product. Do not include the currency code. */
  maxPrice?: InputMaybe<FilterTypeInput>;
  /** A brief overview of the product for search results listings, maximum 255 characters. */
  metaDescription?: InputMaybe<FilterTypeInput>;
  /** A comma-separated list of keywords that are visible only to search engines. */
  metaKeyword?: InputMaybe<FilterTypeInput>;
  /** A string that is displayed in the title bar and tab of the browser and in search results lists. */
  metaTitle?: InputMaybe<FilterTypeInput>;
  /** The numeric minimal price of the product. Do not include the currency code. */
  minPrice?: InputMaybe<FilterTypeInput>;
  /** The product name. Customers use this name to identify the product. */
  name?: InputMaybe<FilterTypeInput>;
  /** The beginning date for new product listings, and determines if the product is featured as a new product. */
  newsFromDate?: InputMaybe<FilterTypeInput>;
  /** The end date for new product listings. */
  newsToDate?: InputMaybe<FilterTypeInput>;
  /** If the product has multiple options, determines where they appear on the product page. */
  optionsContainer?: InputMaybe<FilterTypeInput>;
  /** The keyword required to perform a logical OR comparison. */
  or?: InputMaybe<ProductFilterInput>;
  /** The price of an item. */
  price?: InputMaybe<FilterTypeInput>;
  /** Indicates whether the product has required options. */
  requiredOptions?: InputMaybe<FilterTypeInput>;
  /** A short description of the product. Its use depends on the theme. */
  shortDescription?: InputMaybe<FilterTypeInput>;
  /** A number or code assigned to a product to identify the product, options, price, and manufacturer. */
  sku?: InputMaybe<FilterTypeInput>;
  /** The relative path to the small image, which is used on catalog pages. */
  smallImage?: InputMaybe<FilterTypeInput>;
  /** The label assigned to a product's small image. */
  smallImageLabel?: InputMaybe<FilterTypeInput>;
  /** The beginning date that a product has a special price. */
  specialFromDate?: InputMaybe<FilterTypeInput>;
  /** The discounted price of the product. Do not include the currency code. */
  specialPrice?: InputMaybe<FilterTypeInput>;
  /** The end date that a product has a special price. */
  specialToDate?: InputMaybe<FilterTypeInput>;
  /** The file name of a swatch image. */
  swatchImage?: InputMaybe<FilterTypeInput>;
  /** The relative path to the product's thumbnail image. */
  thumbnail?: InputMaybe<FilterTypeInput>;
  /** The label assigned to a product's thumbnail image. */
  thumbnailLabel?: InputMaybe<FilterTypeInput>;
  /** The price when tier pricing is in effect and the items purchased threshold has been reached. */
  tierPrice?: InputMaybe<FilterTypeInput>;
  /** The timestamp indicating when the product was updated. */
  updatedAt?: InputMaybe<FilterTypeInput>;
  /** The part of the URL that identifies the product */
  urlKey?: InputMaybe<FilterTypeInput>;
  urlPath?: InputMaybe<FilterTypeInput>;
  /** The weight of the item, in units defined by the store. */
  weight?: InputMaybe<FilterTypeInput>;
};

/** Product filtering options */
export type ProductFilters = {
  categories?: InputMaybe<Array<Scalars['ID']['input']>>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  facets?: InputMaybe<Array<ProductFacetInput>>;
  id?: InputMaybe<Scalars['ID']['input']>;
  ids?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  maxCreatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  maxUpdatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  minCreatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  minUpdatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  search?: InputMaybe<Scalars['String']['input']>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
};

export type ProductFragment = {
  __typename: 'ProductFragment';
  createdAt: Scalars['DateTime']['output'];
  fragmentDoc: Scalars['String']['output'];
  hash: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  lastRequested: Scalars['DateTime']['output'];
  originalDoc: Scalars['String']['output'];
  path: Scalars['String']['output'];
  priority: Scalars['Int']['output'];
  updatedAt: Scalars['DateTime']['output'];
  warming: Scalars['Boolean']['output'];
};

/** Contains product image information, including the image URL and label. */
export type ProductImage = MediaGalleryInterface & {
  __typename: 'ProductImage';
  /** Indicates whether the image is hidden from view. */
  disabled: Maybe<Scalars['Boolean']['output']>;
  /** The label of the product image or video. */
  label: Maybe<Scalars['String']['output']>;
  /** The media item's position after it has been sorted. */
  position: Maybe<Scalars['Int']['output']>;
  /** The URL of the product image or video. */
  url: Maybe<Scalars['String']['output']>;
};

/** Contains product image information, including the image path and label. */
export type ProductImageV2 = MediaGalleryV2Interface & {
  __typename: 'ProductImageV2';
  /** The label of the product image. */
  label: Maybe<Scalars['String']['output']>;
  /** The path of the product image. */
  path: Maybe<Scalars['String']['output']>;
};

/**  TODO: When the ingredients gets sorted by Shaughn this will need to change  */
export type ProductIngredient = {
  __typename: 'ProductIngredient';
  name: Maybe<Scalars['String']['output']>;
  urlKey: Maybe<Scalars['String']['output']>;
};

/** Contains fields that are common to all types of products. */
export type ProductInterface = {
  /** @deprecated Use the `custom_attributes` field instead. */
  age: Maybe<Scalars['String']['output']>;
  /** Information about guest highlight points available by product. */
  amastyRewardsGuestHighlights: Maybe<HighlightOutput>;
  /** Information about highlight points available by product. */
  amastyRewardsHighlights: Maybe<HighlightOutput>;
  /**
   * The attribute set assigned to the product.
   * @deprecated The field should not be used on the storefront.
   */
  attributeSetId: Maybe<Scalars['Int']['output']>;
  /** Returns all assigned product attributes and their values */
  attributes: Array<Maybe<ProductAttributes>>;
  /** Returns the brand of the product */
  brand: Maybe<Brand>;
  cacheBuster: Maybe<Scalars['String']['output']>;
  /** The relative canonical URL. This value is returned only if the system setting 'Use Canonical Link Meta Tag For Products' is enabled. */
  canonicalUrl: Maybe<Scalars['String']['output']>;
  cartItemUid: Maybe<Scalars['String']['output']>;
  /** The categories assigned to a product. */
  categories: Maybe<Array<Maybe<CategoryInterface>>>;
  /** @deprecated Use the `custom_attributes` field instead. */
  colour: Maybe<Scalars['Int']['output']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  concentration: Maybe<Scalars['Int']['output']>;
  /** The product's country of origin. */
  countryOfManufacture: Maybe<Scalars['String']['output']>;
  coverImage: Image;
  /**
   * Timestamp indicating when the product was created.
   * @deprecated The field should not be used on the storefront.
   */
  createdAt: Maybe<Scalars['String']['output']>;
  /** Crosssell Products */
  crosssellProducts: Maybe<Array<Maybe<ProductInterface>>>;
  /** @deprecated Use the `custom_attributes` field instead. */
  department: Maybe<Scalars['String']['output']>;
  /** Detailed information about the product. The value can include simple HTML tags. */
  description: Maybe<ComplexTextValue>;
  /** @deprecated Use the `custom_attributes` field instead. */
  flavour: Maybe<Scalars['Int']['output']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  fragrance: Maybe<Scalars['Int']['output']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  gender: Maybe<Scalars['String']['output']>;
  /** Indicates whether a gift message is available. */
  giftMessageAvailable: Maybe<Scalars['String']['output']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  hairType: Maybe<Scalars['String']['output']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  height: Maybe<Scalars['Float']['output']>;
  /**
   * The ID number assigned to the product.
   * @deprecated Use the `uid` field instead.
   */
  id: Maybe<Scalars['Int']['output']>;
  /** The relative path to the main image on the product page. */
  image: Maybe<ProductImage>;
  imageGallery: Array<Image>;
  ingredientList: Maybe<Array<Maybe<ProductIngredient>>>;
  /** @deprecated Use the `custom_attributes` field instead. */
  ingredients: Maybe<Scalars['String']['output']>;
  isInWishlist: Scalars['Boolean']['output'];
  /** List of product labels associated to the product */
  labels: Array<Maybe<LabelItem>>;
  /** @deprecated Use the `custom_attributes` field instead. */
  length: Maybe<Scalars['Float']['output']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  licensingStatus: Maybe<Scalars['String']['output']>;
  /**
   * A number representing the product's manufacturer.
   * @deprecated Use the `custom_attributes` field instead.
   */
  manufacturer: Maybe<Scalars['Int']['output']>;
  /** An array of media gallery objects. */
  mediaGallery: Maybe<Array<Maybe<MediaGalleryInterface>>>;
  /**
   * An array of MediaGalleryEntry objects.
   * @deprecated Use `media_gallery` instead.
   */
  mediaGalleryEntries: Maybe<Array<Maybe<MediaGalleryEntry>>>;
  /** An array of media gallery objects. */
  mediaGalleryV2: Maybe<Array<Maybe<MediaGalleryV2Interface>>>;
  /** A brief overview of the product for search results listings, maximum 255 characters. */
  metaDescription: Maybe<Scalars['String']['output']>;
  /** A comma-separated list of keywords that are visible only to search engines. */
  metaKeyword: Maybe<Scalars['String']['output']>;
  /** A string that is displayed in the title bar and tab of the browser and in search results lists. */
  metaTitle: Maybe<Scalars['String']['output']>;
  /** The product name. Customers use this name to identify the product. */
  name: Maybe<Scalars['String']['output']>;
  /** The beginning date for new product listings, and determines if the product is featured as a new product. */
  newFromDate: Maybe<Scalars['String']['output']>;
  /** The end date for new product listings. */
  newToDate: Maybe<Scalars['String']['output']>;
  /** Product stock only x left count */
  onlyXLeftInStock: Maybe<Scalars['Float']['output']>;
  /** If the product has multiple options, determines where they appear on the product page. */
  optionsContainer: Maybe<Scalars['String']['output']>;
  /**
   * Indicates the price of an item.
   * @deprecated Use `price_range` for product price information.
   */
  price: Maybe<ProductPrices>;
  /** The range of prices for the product */
  priceRange: PriceRange;
  /** An array of `TierPrice` objects. */
  priceTiers: Maybe<Array<Maybe<TierPrice>>>;
  /** An array of `ProductLinks` objects. */
  productLinks: Maybe<Array<Maybe<ProductLinksInterface>>>;
  productType: ProductTypeEnum;
  /** @deprecated Use the `custom_attributes` field instead. */
  quantity: Maybe<Scalars['Int']['output']>;
  quantityInCart: Scalars['Int']['output'];
  /** The rating summary for the product */
  ratingSummary: RatingSummary;
  /** Related Products */
  relatedProducts: Maybe<Array<Maybe<ProductInterface>>>;
  /** The total count of all the reviews given to the product. */
  reviewCount: Scalars['Int']['output'];
  /** The list of products reviews */
  reviews: ProductReviews;
  /** A short description of the product. Its use depends on the theme. */
  shortDescription: Maybe<ComplexTextValue>;
  /** @deprecated Use the `custom_attributes` field instead. */
  size: Maybe<Scalars['Int']['output']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  skinConcern: Maybe<Scalars['String']['output']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  skinType: Maybe<Scalars['String']['output']>;
  /** A number or code assigned to a product to identify the product, options, price, and manufacturer. */
  sku: Maybe<Scalars['String']['output']>;
  /** The relative path to the small image, which is used on catalog pages. */
  smallImage: Maybe<ProductImage>;
  /**
   * The beginning date that a product has a special price.
   * @deprecated The field should not be used on the storefront.
   */
  specialFromDate: Maybe<Scalars['String']['output']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  specialIcons: Maybe<Scalars['String']['output']>;
  /** The discounted price of the product. */
  specialPrice: Maybe<Scalars['Float']['output']>;
  /** The end date for a product with a special price. */
  specialToDate: Maybe<Scalars['String']['output']>;
  stockInfo: Maybe<StockInfo>;
  /** Stock status of the product */
  stockStatus: Maybe<ProductStockStatus>;
  /** The file name of a swatch image. */
  swatchImage: Maybe<Scalars['String']['output']>;
  /** Returns all assigned product tags and their values */
  tags: Array<Maybe<ProductTags>>;
  /** The relative path to the product's thumbnail image. */
  thumbnail: Maybe<ProductImage>;
  /**
   * The price when tier pricing is in effect and the items purchased threshold has been reached.
   * @deprecated Use `price_tiers` for product tier price information.
   */
  tierPrice: Maybe<Scalars['Float']['output']>;
  /**
   * An array of ProductTierPrices objects.
   * @deprecated Use `price_tiers` for product tier price information.
   */
  tierPrices: Maybe<Array<Maybe<ProductTierPrices>>>;
  /**
   * One of simple, virtual, bundle, downloadable, grouped, or configurable.
   * @deprecated Use `__typename` instead.
   */
  typeId: Maybe<Scalars['String']['output']>;
  /** The unique ID for a `ProductInterface` object. */
  uid: Scalars['ID']['output'];
  /**
   * Timestamp indicating when the product was updated.
   * @deprecated The field should not be used on the storefront.
   */
  updatedAt: Maybe<Scalars['String']['output']>;
  /** Upsell Products */
  upsellProducts: Maybe<Array<Maybe<ProductInterface>>>;
  /** The part of the URL that identifies the product */
  urlKey: Maybe<Scalars['String']['output']>;
  /** @deprecated Use product's `canonical_url` or url rewrites instead */
  urlPath: Maybe<Scalars['String']['output']>;
  /** URL rewrites list */
  urlRewrites: Maybe<Array<Maybe<UrlRewrite>>>;
  /** The part of the product URL that is appended after the url key */
  urlSuffix: Maybe<Scalars['String']['output']>;
  valueAttributes: Maybe<Array<Maybe<ProductValue>>>;
  /** @deprecated Use the `custom_attributes` field instead. */
  valueTraits: Maybe<Scalars['String']['output']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  valuesDiet: Maybe<Scalars['String']['output']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  valuesEnvironmental: Maybe<Scalars['String']['output']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  valuesIngredient: Maybe<Scalars['String']['output']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  valuesSocial: Maybe<Scalars['String']['output']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  volumeWeight: Maybe<Scalars['Float']['output']>;
  /**
   * An array of websites in which the product is available.
   * @deprecated The field should not be used on the storefront.
   */
  websites: Maybe<Array<Maybe<Website>>>;
  /** @deprecated Use the `custom_attributes` field instead. */
  width: Maybe<Scalars['Float']['output']>;
  wishlistItemId: Maybe<Scalars['Int']['output']>;
};


/** Contains fields that are common to all types of products. */
export type ProductInterfaceamastyRewardsHighlightsArgs = {
  attributes?: InputMaybe<Scalars['String']['input']>;
};


/** Contains fields that are common to all types of products. */
export type ProductInterfacelabelsArgs = {
  order?: InputMaybe<LabelOrderInput>;
};


/** Contains fields that are common to all types of products. */
export type ProductInterfacereviewsArgs = {
  currentPage?: InputMaybe<Scalars['Int']['input']>;
  filters?: InputMaybe<ReviewFilterInput>;
  pageSize?: InputMaybe<Scalars['Int']['input']>;
  sort?: InputMaybe<ReviewSortInput>;
};

/** An implementation of `ProductLinksInterface`. */
export type ProductLinks = ProductLinksInterface & {
  __typename: 'ProductLinks';
  /** One of related, associated, upsell, or crosssell. */
  linkType: Maybe<Scalars['String']['output']>;
  /** The SKU of the linked product. */
  linkedProductSku: Maybe<Scalars['String']['output']>;
  /** The type of linked product (simple, virtual, bundle, downloadable, grouped, configurable). */
  linkedProductType: Maybe<Scalars['String']['output']>;
  /** The position within the list of product links. */
  position: Maybe<Scalars['Int']['output']>;
  /** The identifier of the linked product. */
  sku: Maybe<Scalars['String']['output']>;
};

/** Contains information about linked products, including the link type and product type of each item. */
export type ProductLinksInterface = {
  /** One of related, associated, upsell, or crosssell. */
  linkType: Maybe<Scalars['String']['output']>;
  /** The SKU of the linked product. */
  linkedProductSku: Maybe<Scalars['String']['output']>;
  /** The type of linked product (simple, virtual, bundle, downloadable, grouped, configurable). */
  linkedProductType: Maybe<Scalars['String']['output']>;
  /** The position within the list of product links. */
  position: Maybe<Scalars['Int']['output']>;
  /** The identifier of the linked product. */
  sku: Maybe<Scalars['String']['output']>;
};

/** Properties items object */
export type ProductList = {
  __typename: 'ProductList';
  /** A count of Properties */
  count: Scalars['Int']['output'];
  facets: Array<ProductFacet>;
  /** A items of Properties */
  items: Array<Maybe<ProductInterface>>;
  /** Number of Product records returned */
  limit: Scalars['Int']['output'];
  /** Number of Product records skipped */
  skip: Scalars['Int']['output'];
};

/** Contains an image in base64 format and basic information about the image. */
export type ProductMediaGalleryEntriesContent = {
  __typename: 'ProductMediaGalleryEntriesContent';
  /** The image in base64 format. */
  base64EncodedData: Maybe<Scalars['String']['output']>;
  /** The file name of the image. */
  name: Maybe<Scalars['String']['output']>;
  /** The MIME type of the file, such as image/png. */
  type: Maybe<Scalars['String']['output']>;
};

/** Contains a link to a video file and basic information about the video. */
export type ProductMediaGalleryEntriesVideoContent = {
  __typename: 'ProductMediaGalleryEntriesVideoContent';
  /** Must be external-video. */
  mediaType: Maybe<Scalars['String']['output']>;
  /** A description of the video. */
  videoDescription: Maybe<Scalars['String']['output']>;
  /** Optional data about the video. */
  videoMetadata: Maybe<Scalars['String']['output']>;
  /** Describes the video source. */
  videoProvider: Maybe<Scalars['String']['output']>;
  /** The title of the video. */
  videoTitle: Maybe<Scalars['String']['output']>;
  /** The URL to the video. */
  videoUrl: Maybe<Scalars['String']['output']>;
};

/** Product ordering options */
export type ProductOrder = {
  /** Order direction */
  direction?: InputMaybe<OrderDirectionEnum>;
  /** Order field */
  field?: InputMaybe<ProductOrderEnum>;
};

export enum ProductOrderEnum {
  AVAILABILITY = 'AVAILABILITY',
  BEST_SELLING = 'BEST_SELLING',
  CREATED_AT = 'CREATED_AT',
  FALVOUR = 'FALVOUR',
  FRAGRANCE = 'FRAGRANCE',
  MANUFACTURER = 'MANUFACTURER',
  PRICE = 'PRICE',
  QUANTITY = 'QUANTITY',
  SPECIAL_FROM_DATE = 'SPECIAL_FROM_DATE',
  SPECIAL_TO_DATE = 'SPECIAL_TO_DATE',
  STATUS = 'STATUS',
  TITLE = 'TITLE',
  UPDATED_AT = 'UPDATED_AT'
}

/** Represents a product price. */
export type ProductPrice = {
  __typename: 'ProductPrice';
  /** The price discount. Represents the difference between the regular and final price. */
  discount: Maybe<ProductDiscount>;
  /** The final price of the product after applying discounts. */
  finalPrice: Money;
  /** The regular price of the product. */
  regularPrice: Money;
};

/** Deprecated. Use `PriceRange` instead. Contains the regular price of an item, as well as its minimum and maximum prices. Only composite products, which include bundle, configurable, and grouped products, can contain a minimum and maximum price. */
export type ProductPrices = {
  __typename: 'ProductPrices';
  /**
   * The highest possible final price for all the options defined within a composite product. If you are specifying a price range, this would be the `to` value.
   * @deprecated Use `PriceRange.maximum_price` instead.
   */
  maximalPrice: Maybe<Price>;
  /**
   * The lowest possible final price for all the options defined within a composite product. If you are specifying a price range, this would be the `from` value.
   * @deprecated Use `PriceRange.minimum_price` instead.
   */
  minimalPrice: Maybe<Price>;
  /**
   * The base price of a product.
   * @deprecated Use `regular_price` from `PriceRange.minimum_price` or `PriceRange.maximum_price` instead.
   */
  regularPrice: Maybe<Price>;
};

/** Contains details of a product review. */
export type ProductReview = {
  __typename: 'ProductReview';
  /** The average of all ratings for this product. */
  averageRating: Scalars['Float']['output'];
  /** The date the review was created. */
  createdAt: Scalars['String']['output'];
  /** The customer's nickname. Defaults to the customer name, if logged in. */
  nickname: Scalars['String']['output'];
  /** The reviewed product. */
  product: ProductInterface;
  /** An array of ratings by rating category, such as quality, price, and value. */
  ratingsBreakdown: Array<Maybe<ProductReviewRating>>;
  /** The review ID */
  reviewId: Maybe<Scalars['Int']['output']>;
  /** The summary (title) of the review. */
  summary: Scalars['String']['output'];
  /** The review text. */
  text: Scalars['String']['output'];
  /** The vote totals for a review */
  votes: Maybe<ReviewVotes>;
};

/** Contains data about a single aspect of a product review. */
export type ProductReviewRating = {
  __typename: 'ProductReviewRating';
  /** The label assigned to an aspect of a product that is being rated, such as quality or price. */
  name: Scalars['String']['output'];
  /** The rating value given by customer. By default, possible values range from 1 to 5. */
  value: Scalars['String']['output'];
};

/** Contains the reviewer's rating for a single aspect of a review. */
export type ProductReviewRatingInput = {
  /** An encoded rating ID. */
  id: Scalars['String']['input'];
  /** An encoded rating value ID. */
  valueId: Scalars['String']['input'];
};

/** Contains details about a single aspect of a product review. */
export type ProductReviewRatingMetadata = {
  __typename: 'ProductReviewRatingMetadata';
  /** An encoded rating ID. */
  id: Scalars['String']['output'];
  /** The label assigned to an aspect of a product that is being rated, such as quality or price. */
  name: Scalars['String']['output'];
  /** List of product review ratings sorted by position. */
  values: Array<Maybe<ProductReviewRatingValueMetadata>>;
};

/** Contains details about a single value in a product review. */
export type ProductReviewRatingValueMetadata = {
  __typename: 'ProductReviewRatingValueMetadata';
  /** A ratings scale, such as the number of stars awarded. */
  value: Scalars['String']['output'];
  /** An encoded rating value ID. */
  valueId: Scalars['String']['output'];
};

/** Contains an array of metadata about each aspect of a product review. */
export type ProductReviewRatingsMetadata = {
  __typename: 'ProductReviewRatingsMetadata';
  /** An array of product reviews sorted by position. */
  items: Array<Maybe<ProductReviewRatingMetadata>>;
};

/** Contains an array of product reviews. */
export type ProductReviews = {
  __typename: 'ProductReviews';
  /** An array of product reviews. */
  items: Array<Maybe<ProductReview>>;
  /** Metadata for pagination rendering. */
  pageInfo: SearchResultPageInfo;
};

/** Deprecated. Use `ProductAttributeSortInput` instead. Specifies the attribute to use for sorting search results and indicates whether the results are sorted in ascending or descending order. */
export type ProductSortInput = {
  /** The product's country of origin. */
  countryOfManufacture?: InputMaybe<SortEnum>;
  /** The timestamp indicating when the product was created. */
  createdAt?: InputMaybe<SortEnum>;
  /** The name of a custom layout. */
  customLayout?: InputMaybe<SortEnum>;
  /** XML code that is applied as a layout update to the product page. */
  customLayoutUpdate?: InputMaybe<SortEnum>;
  /** Detailed information about the product. The value can include simple HTML tags. */
  description?: InputMaybe<SortEnum>;
  /** Indicates whether a gift message is available. */
  giftMessageAvailable?: InputMaybe<SortEnum>;
  /** Indicates whether additional attributes have been created for the product. */
  hasOptions?: InputMaybe<SortEnum>;
  /** The relative path to the main image on the product page. */
  image?: InputMaybe<SortEnum>;
  /** The label assigned to a product image. */
  imageLabel?: InputMaybe<SortEnum>;
  /** A number representing the product's manufacturer. */
  manufacturer?: InputMaybe<SortEnum>;
  /** A brief overview of the product for search results listings, maximum 255 characters. */
  metaDescription?: InputMaybe<SortEnum>;
  /** A comma-separated list of keywords that are visible only to search engines. */
  metaKeyword?: InputMaybe<SortEnum>;
  /** A string that is displayed in the title bar and tab of the browser and in search results lists. */
  metaTitle?: InputMaybe<SortEnum>;
  /** The product name. Customers use this name to identify the product. */
  name?: InputMaybe<SortEnum>;
  /** The beginning date for new product listings, and determines if the product is featured as a new product. */
  newsFromDate?: InputMaybe<SortEnum>;
  /** The end date for new product listings. */
  newsToDate?: InputMaybe<SortEnum>;
  /** If the product has multiple options, determines where they appear on the product page. */
  optionsContainer?: InputMaybe<SortEnum>;
  /** The price of the item. */
  price?: InputMaybe<SortEnum>;
  /** Indicates whether the product has required options. */
  requiredOptions?: InputMaybe<SortEnum>;
  /** A short description of the product. Its use depends on the theme. */
  shortDescription?: InputMaybe<SortEnum>;
  /** A number or code assigned to a product to identify the product, options, price, and manufacturer. */
  sku?: InputMaybe<SortEnum>;
  /** The relative path to the small image, which is used on catalog pages. */
  smallImage?: InputMaybe<SortEnum>;
  /** The label assigned to a product's small image. */
  smallImageLabel?: InputMaybe<SortEnum>;
  /** The beginning date that a product has a special price. */
  specialFromDate?: InputMaybe<SortEnum>;
  /** The discounted price of the product. */
  specialPrice?: InputMaybe<SortEnum>;
  /** The end date that a product has a special price. */
  specialToDate?: InputMaybe<SortEnum>;
  /** Indicates the criteria to sort swatches. */
  swatchImage?: InputMaybe<SortEnum>;
  /** The relative path to the product's thumbnail image. */
  thumbnail?: InputMaybe<SortEnum>;
  /** The label assigned to a product's thumbnail image. */
  thumbnailLabel?: InputMaybe<SortEnum>;
  /** The price when tier pricing is in effect and the items purchased threshold has been reached. */
  tierPrice?: InputMaybe<SortEnum>;
  /** The timestamp indicating when the product was updated. */
  updatedAt?: InputMaybe<SortEnum>;
  /** The part of the URL that identifies the product */
  urlKey?: InputMaybe<SortEnum>;
  urlPath?: InputMaybe<SortEnum>;
  /** The weight of the item, in units defined by the store. */
  weight?: InputMaybe<SortEnum>;
};

/** This enumeration states whether a product stock status is in stock or out of stock */
export enum ProductStockStatus {
  /** @deprecated  */
  IN_STOCK = 'IN_STOCK',
  /** @deprecated  */
  OUT_OF_STOCK = 'OUT_OF_STOCK'
}

export type ProductTags = {
  __typename: 'ProductTags';
  /** The tag colour */
  colourTag: Scalars['String']['output'];
  /** The text colour of the tag */
  colourText: Scalars['String']['output'];
  /** The label for the tag */
  label: Scalars['String']['output'];
};

/** Deprecated. Use `TierPrice` instead. Defines a tier price, which is a quantity discount offered to a specific customer group. */
export type ProductTierPrices = {
  __typename: 'ProductTierPrices';
  /**
   * The ID of the customer group.
   * @deprecated Not relevant for the storefront.
   */
  customerGroupId: Maybe<Scalars['String']['output']>;
  /**
   * The percentage discount of the item.
   * @deprecated Use `TierPrice.discount` instead.
   */
  percentageValue: Maybe<Scalars['Float']['output']>;
  /**
   * The number of items that must be purchased to qualify for tier pricing.
   * @deprecated Use `TierPrice.quantity` instead.
   */
  qty: Maybe<Scalars['Float']['output']>;
  /**
   * The price of the fixed price item.
   * @deprecated Use `TierPrice.final_price` instead.
   */
  value: Maybe<Scalars['Float']['output']>;
  /**
   * The ID assigned to the website.
   * @deprecated Not relevant for the storefront.
   */
  websiteId: Maybe<Scalars['Float']['output']>;
};

export enum ProductTypeEnum {
  /** Gift Card */
  AmGiftCardProduct = 'AmGiftCardProduct',
  /** Bundle Product */
  BundleProduct = 'BundleProduct',
  /** Configurable Product */
  ConfigurableProduct = 'ConfigurableProduct',
  /** Downloadable Product */
  DownloadableProduct = 'DownloadableProduct',
  /** Frequently Ordered */
  FrequentlyOrderedProductInterface = 'FrequentlyOrderedProductInterface',
  /** Grouped Product */
  GroupedProduct = 'GroupedProduct',
  /** M1 Gift Voucher */
  M1GiftVoucher = 'M1GiftVoucher',
  /** Product Interface */
  ProductInterface = 'ProductInterface',
  /** Simple Product */
  SimpleProduct = 'SimpleProduct',
  /** Virtual Product */
  VirtualProduct = 'VirtualProduct'
}

export type ProductValue = {
  __typename: 'ProductValue';
  code: Maybe<Scalars['String']['output']>;
  label: Maybe<Scalars['String']['output']>;
  value: Maybe<Array<Maybe<Scalars['String']['output']>>>;
};

/** Contains information about a product video. */
export type ProductVideo = MediaGalleryInterface & {
  __typename: 'ProductVideo';
  /** Indicates whether the image is hidden from view. */
  disabled: Maybe<Scalars['Boolean']['output']>;
  /** The label of the product image or video. */
  label: Maybe<Scalars['String']['output']>;
  /** The media item's position after it has been sorted. */
  position: Maybe<Scalars['Int']['output']>;
  /** The URL of the product image or video. */
  url: Maybe<Scalars['String']['output']>;
  /** Contains a `ProductMediaGalleryEntriesVideoContent` object. */
  videoContent: Maybe<ProductMediaGalleryEntriesVideoContent>;
};

/** Contains the results of a `products` query. */
export type Products = {
  __typename: 'Products';
  /** A bucket that contains the attribute code and label for each filterable option. */
  aggregations: Maybe<Array<Maybe<Aggregation>>>;
  /**
   * Layered navigation filters array.
   * @deprecated Use `aggregations` instead.
   */
  filters: Maybe<Array<Maybe<LayerFilter>>>;
  /** An array of products that match the specified search criteria. */
  items: Maybe<Array<Maybe<ProductInterface>>>;
  /** An object that includes the page_info and currentPage values specified in the query. */
  pageInfo: Maybe<SearchResultPageInfo>;
  /** An object that includes the default sort field and all available sort fields. */
  sortFields: Maybe<SortFields>;
  /** An array of search suggestions for case when search query have no results. */
  suggestions: Maybe<Array<Maybe<SearchSuggestion>>>;
  /** The number of products that are marked as visible. By default, in complex products, parent products are visible, but their child products are not. */
  totalCount: Maybe<Scalars['Int']['output']>;
};


/** Contains the results of a `products` query. */
export type ProductsaggregationsArgs = {
  filter?: InputMaybe<AggregationsFilterInput>;
};

export type Query = {
  __typename: 'Query';
  activeWidgets: ActiveWidgets;
  allContents: ContentList;
  allImages: ImageList;
  allPageAccordionSections: PageAccordionSectionList;
  allPageBannerCategorys: PageBannerCategoryList;
  allPageBannerSections: PageBannerSectionList;
  allPageBanners: PageBannerList;
  allPageBlockSections: PageBlockSectionList;
  allPageCarouselSections: PageCarouselSectionList;
  allPageChipSections: PageChipSectionList;
  allPageExcerptSections: PageExcerptSectionList;
  allPageHeadingSections: PageHeadingSectionList;
  allPageHeroSections: PageHeroSectionList;
  allPageIconSections: PageIconSectionList;
  allPageLinkSections: PageLinkSectionList;
  allPageMarkupSections: PageMarkupSectionList;
  allPageMediaSections: PageMediaSectionList;
  allPageMyDiscountsSections: PageMyDiscountsSectionList;
  allPageMyShopSections: PageMyShopSectionList;
  allPageNotificationSections: PageNotificationSectionList;
  allPagePlainTextSections: PagePlainTextSectionList;
  allPageProductSliderSections: PageProductSliderSectionList;
  allPageQuestionGroups: PageQuestionGroupList;
  allPageQuestionSections: PageQuestionSectionList;
  allPageSectionGroups: PageSectionGroupList;
  allPageSections: PageSectionList;
  allPageSiteNoticeSections: PageSiteNoticeSectionList;
  allPageSplitTextSections: PageSplitTextSectionList;
  allPageTimetableSections: PageTimetableSectionList;
  allPages: PageList;
  allProducts: Products;
  allSearchAttributes: CustomAttributeMetadata;
  allWidgetContactUss: WidgetContactUsList;
  allWidgetDropDownCarts: WidgetDropDownCartList;
  allWidgetProductDetailsAddToCarts: WidgetProductDetailsAddToCartList;
  allWidgetSiteWideBanners: WidgetSiteWideBannerList;
  allWidgets: WidgetList;
  /** Get gift card account information by code */
  amGiftCardAccount: Maybe<AmGiftCardAccount>;
  /** Get rendered email template for preview. */
  amGiftCardPreview: Maybe<AmGiftCardPreview>;
  /** Get gift card module settings */
  amGiftCardSetting: Maybe<AmGiftCardSettings>;
  /** Get current user's gift card accounts. */
  amUserGiftCardAccount: Maybe<Array<Maybe<AmGiftCardAccount>>>;
  app: App;
  /** Retrieve EAV attributes associated to a frontend form. */
  attributesForm: AttributesFormOutput;
  /** Returns a list of attributes metadata for a given entity type. */
  attributesList: Maybe<AttributesMetadataOutput>;
  auth: Auth;
  /** Get a list of available store views and their config information. */
  availableStores: Maybe<Array<Maybe<StoreConfig>>>;
  builder: PageBuilder;
  /** Return information about the specified shopping cart. */
  cart: Maybe<Cart>;
  /** Return a list of categories that match the specified filter. */
  categories: Maybe<CategoryResult>;
  /**
   * Search for categories that match the criteria specified in the `search` and `filter` attributes.
   * @deprecated Use `categories` instead.
   */
  category: Maybe<CategoryTree>;
  /**
   * Return an array of categories based on the specified filters.
   * @deprecated Use `categories` instead.
   */
  categoryList: Maybe<Array<Maybe<CategoryTree>>>;
  checkout: Checkout;
  /** Return Terms and Conditions configuration information. */
  checkoutAgreements: Maybe<Array<Maybe<CheckoutAgreement>>>;
  /** Return information about CMS blocks. */
  cmsBlocks: Maybe<CmsBlocks>;
  /** Return details about a CMS page. */
  cmsPage: Maybe<CmsPage>;
  /** Return products that have been added to the specified compare list. */
  compareList: Maybe<CompareList>;
  contentCount: Scalars['Int']['output'];
  /** The countries query provides information for all countries. */
  countries: Maybe<Array<Maybe<Country>>>;
  /** The countries query provides information for a single country. */
  country: Maybe<Country>;
  /** Return information about the store's currency. */
  currency: Maybe<Currency>;
  currentCustomer: Customer;
  /**
   * Return the attribute type, given an attribute code and entity type.
   * @deprecated Use `customAttributeMetadataV2` query instead.
   */
  customAttributeMetadata: Maybe<CustomAttributeMetadata>;
  /** Retrieve EAV attributes metadata. */
  customAttributeMetadataV2: AttributesMetadataOutput;
  /** Return detailed information about a customer account. */
  customer: Maybe<Customer>;
  /** Return information about the customer's shopping cart. */
  customerCart: Cart;
  /** Return a list of downloadable products the customer has purchased. */
  customerDownloadableProducts: Maybe<CustomerDownloadableProducts>;
  /** @deprecated Use the `customer` query instead. */
  customerOrders: Maybe<CustomerOrders>;
  /** Return a list of customer payment tokens stored in the vault. */
  customerPaymentTokens: Maybe<CustomerPaymentTokens>;
  /** AB campaigns applied or to apply for the current customer. */
  elasticsuiteAbCampaigns: Maybe<Array<Maybe<AbCampaign>>>;
  elasticsuiteTermRecommender: Maybe<TermRecommendations>;
  elasticsuiteVisitorProducts: Maybe<VisitorProducts>;
  elasticsuitefacetRecommender: Maybe<FilterRecommendations>;
  /** Retrieve the customer's recently ordered products */
  frequentlyBoughtProducts: Maybe<FrequentlyBoughtResult>;
  getAdminPaths: Array<AdminPathItem>;
  getAllEntityDefinitions: Array<EntityConfig>;
  getCustomerNotificationOptions: Maybe<GetCustomerNotificationOptionsOutput>;
  getDetailedInputObjectConfig: Maybe<Scalars['JSON']['output']>;
  getEntityFilterOptions: Array<EntityFilterOption>;
  getEnum: Maybe<EnumDescription>;
  getEnums: Maybe<Array<Maybe<EnumDescription>>>;
  getExpiringData: Maybe<GetExpiringDataArrayOutput>;
  /** Retrieve the secure PayPal URL for a Payments Pro Hosted Solution transaction. */
  getHostedProUrl: Maybe<HostedProUrl>;
  getInputObjectConfig: Maybe<Scalars['JSON']['output']>;
  getInterfaceTypes: Maybe<Array<Maybe<ClientInterface>>>;
  /** Get redirect data required to complete an ozow payment */
  getOzowRedirectInfoV2: Maybe<OzowRedirectDetailsV2>;
  /** Retrieve payment credentials for a transaction. Use this query for Payflow Link and Payments Advanced payment methods. */
  getPayflowLinkToken: Maybe<PayflowLinkToken>;
  /** Retrieves the payment configuration for a given location */
  getPaymentConfig: Maybe<PaymentConfigOutput>;
  /** Retrieves the payment details for the order */
  getPaymentOrder: Maybe<PaymentOrderOutput>;
  /** Gets the payment SDK urls and values */
  getPaymentSdk: Maybe<GetPaymentSdkOutput>;
  /** Get the status of a payment */
  getPeachPaymentsPaymentStatus: Maybe<PeachPaymentsPaymentStatus>;
  /** Get all PeachPayments tokens for an existing customer */
  getPeachPaymentsTokens: Maybe<PeachPaymentsTokens>;
  getRewardsProperty: Maybe<GetRewardsPropertyOutput>;
  getRewardsStatistic: Maybe<GetRewardsStatisticOutput>;
  getSimpleMediaObjects: Maybe<Array<SimpleMediaObject>>;
  /** Information about highlight points available for guest. */
  guestRewards: Maybe<HighlightOutput>;
  imageCount: Scalars['Int']['output'];
  /** Check whether the specified email has already been used to create a customer account. */
  isEmailAvailable: Maybe<IsEmailAvailableOutput>;
  menuCategories: MenuCategories;
  modals: Modals;
  navigation: Navigation;
  oneContent: Content;
  oneImage: Image;
  onePage: Page;
  onePageAccordionSection: PageAccordionSection;
  onePageBanner: PageBanner;
  onePageBannerCategory: PageBannerCategory;
  onePageBannerSection: PageBannerSection;
  onePageBlockSection: PageBlockSection;
  onePageCarouselSection: PageCarouselSection;
  onePageChipSection: PageChipSection;
  onePageExcerptSection: PageExcerptSection;
  onePageHeadingSection: PageHeadingSection;
  onePageHeroSection: PageHeroSection;
  onePageIconSection: PageIconSection;
  onePageLinkSection: PageLinkSection;
  onePageMarkupSection: PageMarkupSection;
  onePageMediaSection: PageMediaSection;
  onePageMyDiscountsSection: PageMyDiscountsSection;
  onePageMyShopSection: PageMyShopSection;
  onePageNotificationSection: PageNotificationSection;
  onePagePlainTextSection: PagePlainTextSection;
  onePageProductSliderSection: PageProductSliderSection;
  onePageQuestionGroup: PageQuestionGroup;
  onePageQuestionSection: PageQuestionSection;
  onePageSection: PageSection;
  onePageSectionGroup: PageSectionGroup;
  onePageSiteNoticeSection: PageSiteNoticeSection;
  onePageSplitTextSection: PageSplitTextSection;
  onePageTimetableSection: PageTimetableSection;
  oneProduct: ProductInterface;
  oneWidget: Widget;
  oneWidgetContactUs: WidgetContactUs;
  oneWidgetDropDownCart: WidgetDropDownCart;
  oneWidgetProductDetailsAddToCart: WidgetProductDetailsAddToCart;
  oneWidgetSiteWideBanner: WidgetSiteWideBanner;
  pageAccordionSectionCount: Scalars['Int']['output'];
  pageBannerCategoryCount: Scalars['Int']['output'];
  pageBannerCount: Scalars['Int']['output'];
  pageBannerSectionCount: Scalars['Int']['output'];
  pageBlockSectionCount: Scalars['Int']['output'];
  pageCarouselSectionCount: Scalars['Int']['output'];
  pageChipSectionCount: Scalars['Int']['output'];
  pageCount: Scalars['Int']['output'];
  pageExcerptSectionCount: Scalars['Int']['output'];
  pageHeadingSectionCount: Scalars['Int']['output'];
  pageHeroSectionCount: Scalars['Int']['output'];
  pageIconSectionCount: Scalars['Int']['output'];
  pageLinkSectionCount: Scalars['Int']['output'];
  pageMarkupSectionCount: Scalars['Int']['output'];
  pageMediaSectionCount: Scalars['Int']['output'];
  pageMyDiscountsSectionCount: Scalars['Int']['output'];
  pageMyShopSectionCount: Scalars['Int']['output'];
  pageNotificationSectionCount: Scalars['Int']['output'];
  pagePlainTextSectionCount: Scalars['Int']['output'];
  pageProductSliderSectionCount: Scalars['Int']['output'];
  pageQuestionGroupCount: Scalars['Int']['output'];
  pageQuestionSectionCount: Scalars['Int']['output'];
  pageSectionCount: Scalars['Int']['output'];
  pageSectionGroupCount: Scalars['Int']['output'];
  pageSiteNoticeSectionCount: Scalars['Int']['output'];
  pageSplitTextSectionCount: Scalars['Int']['output'];
  pageTimetableSectionCount: Scalars['Int']['output'];
  /** Retrieve the customer's personal discounts */
  personalDiscounts: Maybe<Array<Maybe<PersonalDiscount>>>;
  /** Return the active ratings attributes and the values each rating can have. */
  productReviewRatingsMetadata: ProductReviewRatingsMetadata;
  productReviews: ProductReviews;
  /** Search for products that match the criteria specified in the `search` and `filter` attributes. */
  products: Maybe<Products>;
  /** Customer rewards information query. */
  rewards: Maybe<Rewards>;
  /** Return the full details for a specified product, category, or CMS page. */
  route: Maybe<RoutableInterface>;
  routing: Maybe<Route>;
  search: Maybe<SearchResult>;
  /** Return details about the store's configuration. */
  storeConfig: Maybe<StoreConfig>;
  /**
   * Return the relative URL for a specified product, category or CMS page.
   * @deprecated Use the `route` query instead.
   */
  urlResolver: Maybe<EntityUrl>;
  /** The viewMoreFilter query searches for all values of a given filter name in a specific context. */
  viewMoreFilter: Maybe<ViewMoreResult>;
  widgetContactUsCount: Scalars['Int']['output'];
  widgetCount: Scalars['Int']['output'];
  widgetDropDownCartCount: Scalars['Int']['output'];
  widgetProductDetailsAddToCartCount: Scalars['Int']['output'];
  widgetSiteWideBannerCount: Scalars['Int']['output'];
  /**
   * Return the contents of a customer's wish list.
   * @deprecated Moved under `Customer.wishlist`.
   */
  wishlist: Maybe<WishlistOutput>;
};


export type QueryallContentsArgs = {
  filters?: InputMaybe<ContentFilterInput>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  order?: InputMaybe<ContentOrderInput>;
  skip?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryallImagesArgs = {
  filters?: InputMaybe<ImageFilterInput>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  order?: InputMaybe<ImageOrderInput>;
  skip?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryallPageAccordionSectionsArgs = {
  filters?: InputMaybe<PageAccordionSectionFilterInput>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  order?: InputMaybe<PageAccordionSectionOrderInput>;
  skip?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryallPageBannerCategorysArgs = {
  filters?: InputMaybe<PageBannerCategoryFilterInput>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  order?: InputMaybe<PageBannerCategoryOrderInput>;
  skip?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryallPageBannerSectionsArgs = {
  filters?: InputMaybe<PageBannerSectionFilterInput>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  order?: InputMaybe<PageBannerSectionOrderInput>;
  skip?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryallPageBannersArgs = {
  filters?: InputMaybe<PageBannerFilterInput>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  order?: InputMaybe<PageBannerOrderInput>;
  skip?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryallPageBlockSectionsArgs = {
  filters?: InputMaybe<PageBlockSectionFilterInput>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  order?: InputMaybe<PageBlockSectionOrderInput>;
  skip?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryallPageCarouselSectionsArgs = {
  filters?: InputMaybe<PageCarouselSectionFilterInput>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  order?: InputMaybe<PageCarouselSectionOrderInput>;
  skip?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryallPageChipSectionsArgs = {
  filters?: InputMaybe<PageChipSectionFilterInput>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  order?: InputMaybe<PageChipSectionOrderInput>;
  skip?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryallPageExcerptSectionsArgs = {
  filters?: InputMaybe<PageExcerptSectionFilterInput>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  order?: InputMaybe<PageExcerptSectionOrderInput>;
  skip?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryallPageHeadingSectionsArgs = {
  filters?: InputMaybe<PageHeadingSectionFilterInput>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  order?: InputMaybe<PageHeadingSectionOrderInput>;
  skip?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryallPageHeroSectionsArgs = {
  filters?: InputMaybe<PageHeroSectionFilterInput>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  order?: InputMaybe<PageHeroSectionOrderInput>;
  skip?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryallPageIconSectionsArgs = {
  filters?: InputMaybe<PageIconSectionFilterInput>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  order?: InputMaybe<PageIconSectionOrderInput>;
  skip?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryallPageLinkSectionsArgs = {
  filters?: InputMaybe<PageLinkSectionFilterInput>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  order?: InputMaybe<PageLinkSectionOrderInput>;
  skip?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryallPageMarkupSectionsArgs = {
  filters?: InputMaybe<PageMarkupSectionFilterInput>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  order?: InputMaybe<PageMarkupSectionOrderInput>;
  skip?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryallPageMediaSectionsArgs = {
  filters?: InputMaybe<PageMediaSectionFilterInput>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  order?: InputMaybe<PageMediaSectionOrderInput>;
  skip?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryallPageMyDiscountsSectionsArgs = {
  filters?: InputMaybe<PageMyDiscountsSectionFilterInput>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  order?: InputMaybe<PageMyDiscountsSectionOrderInput>;
  skip?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryallPageMyShopSectionsArgs = {
  filters?: InputMaybe<PageMyShopSectionFilterInput>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  order?: InputMaybe<PageMyShopSectionOrderInput>;
  skip?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryallPageNotificationSectionsArgs = {
  filters?: InputMaybe<PageNotificationSectionFilterInput>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  order?: InputMaybe<PageNotificationSectionOrderInput>;
  skip?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryallPagePlainTextSectionsArgs = {
  filters?: InputMaybe<PagePlainTextSectionFilterInput>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  order?: InputMaybe<PagePlainTextSectionOrderInput>;
  skip?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryallPageProductSliderSectionsArgs = {
  filters?: InputMaybe<PageProductSliderSectionFilterInput>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  order?: InputMaybe<PageProductSliderSectionOrderInput>;
  skip?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryallPageQuestionGroupsArgs = {
  filters?: InputMaybe<PageQuestionGroupFilterInput>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  order?: InputMaybe<PageQuestionGroupOrderInput>;
  skip?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryallPageQuestionSectionsArgs = {
  filters?: InputMaybe<PageQuestionSectionFilterInput>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  order?: InputMaybe<PageQuestionSectionOrderInput>;
  skip?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryallPageSectionGroupsArgs = {
  filters?: InputMaybe<PageSectionGroupFilterInput>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  order?: InputMaybe<PageSectionGroupOrderInput>;
  skip?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryallPageSectionsArgs = {
  filters?: InputMaybe<PageSectionFilterInput>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  order?: InputMaybe<PageSectionOrderInput>;
  skip?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryallPageSiteNoticeSectionsArgs = {
  filters?: InputMaybe<PageSiteNoticeSectionFilterInput>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  order?: InputMaybe<PageSiteNoticeSectionOrderInput>;
  skip?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryallPageSplitTextSectionsArgs = {
  filters?: InputMaybe<PageSplitTextSectionFilterInput>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  order?: InputMaybe<PageSplitTextSectionOrderInput>;
  skip?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryallPageTimetableSectionsArgs = {
  filters?: InputMaybe<PageTimetableSectionFilterInput>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  order?: InputMaybe<PageTimetableSectionOrderInput>;
  skip?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryallPagesArgs = {
  filters?: InputMaybe<PageFilterInput>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  order?: InputMaybe<PageOrderInput>;
  skip?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryallProductsArgs = {
  filters?: InputMaybe<ProductAttributeFilterInput>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  order?: InputMaybe<ProductAttributeSortInput>;
  search?: InputMaybe<Scalars['String']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryallWidgetContactUssArgs = {
  filters?: InputMaybe<WidgetContactUsFilterInput>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  order?: InputMaybe<WidgetContactUsOrderInput>;
  skip?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryallWidgetDropDownCartsArgs = {
  filters?: InputMaybe<WidgetDropDownCartFilterInput>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  order?: InputMaybe<WidgetDropDownCartOrderInput>;
  skip?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryallWidgetProductDetailsAddToCartsArgs = {
  filters?: InputMaybe<WidgetProductDetailsAddToCartFilterInput>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  order?: InputMaybe<WidgetProductDetailsAddToCartOrderInput>;
  skip?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryallWidgetSiteWideBannersArgs = {
  filters?: InputMaybe<WidgetSiteWideBannerFilterInput>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  order?: InputMaybe<WidgetSiteWideBannerOrderInput>;
  skip?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryallWidgetsArgs = {
  filters?: InputMaybe<WidgetFilterInput>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  order?: InputMaybe<WidgetOrderInput>;
  skip?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryamGiftCardAccountArgs = {
  input: AmGiftCardAccountInput;
};


export type QueryamGiftCardPreviewArgs = {
  input?: InputMaybe<AmGiftCardPreviewInput>;
};


export type QueryattributesFormArgs = {
  formCode: Scalars['String']['input'];
};


export type QueryattributesListArgs = {
  entityType: AttributeEntityTypeEnum;
};


export type QueryavailableStoresArgs = {
  useCurrentGroup?: InputMaybe<Scalars['Boolean']['input']>;
};


export type QuerycartArgs = {
  cart_id: Scalars['String']['input'];
};


export type QuerycategoriesArgs = {
  currentPage?: InputMaybe<Scalars['Int']['input']>;
  filters?: InputMaybe<CategoryFilterInput>;
  pageSize?: InputMaybe<Scalars['Int']['input']>;
  sort?: InputMaybe<CategoryAttributeSortInput>;
};


export type QuerycategoryArgs = {
  id?: InputMaybe<Scalars['Int']['input']>;
};


export type QuerycategoryListArgs = {
  currentPage?: InputMaybe<Scalars['Int']['input']>;
  filters?: InputMaybe<CategoryFilterInput>;
  pageSize?: InputMaybe<Scalars['Int']['input']>;
};


export type QuerycmsBlocksArgs = {
  identifiers?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};


export type QuerycmsPageArgs = {
  id?: InputMaybe<Scalars['Int']['input']>;
  identifier?: InputMaybe<Scalars['String']['input']>;
};


export type QuerycompareListArgs = {
  uid: Scalars['ID']['input'];
};


export type QuerycontentCountArgs = {
  filters?: InputMaybe<ContentFilterInput>;
};


export type QuerycountryArgs = {
  id?: InputMaybe<Scalars['String']['input']>;
};


export type QuerycustomAttributeMetadataArgs = {
  attributes: Array<AttributeInput>;
};


export type QuerycustomAttributeMetadataV2Args = {
  attributes?: InputMaybe<Array<AttributeInput>>;
};


export type QueryelasticsuiteTermRecommenderArgs = {
  queryText: Scalars['String']['input'];
};


export type QueryelasticsuiteVisitorProductsArgs = {
  categoryId: Scalars['Int']['input'];
  pageSize?: InputMaybe<Scalars['Int']['input']>;
  visitorId: Scalars['String']['input'];
};


export type QueryelasticsuitefacetRecommenderArgs = {
  categoryId: Scalars['Int']['input'];
  userId: Scalars['String']['input'];
  visitorId: Scalars['String']['input'];
};


export type QueryfrequentlyBoughtProductsArgs = {
  currentPage?: InputMaybe<Scalars['Int']['input']>;
  pageSize?: InputMaybe<Scalars['Int']['input']>;
  period?: InputMaybe<Scalars['Int']['input']>;
};


export type QuerygetDetailedInputObjectConfigArgs = {
  name: Scalars['String']['input'];
};


export type QuerygetEntityFilterOptionsArgs = {
  typeName: Scalars['String']['input'];
};


export type QuerygetEnumArgs = {
  enum: Scalars['String']['input'];
};


export type QuerygetEnumsArgs = {
  enums: Array<InputMaybe<Scalars['String']['input']>>;
};


export type QuerygetHostedProUrlArgs = {
  input: HostedProUrlInput;
};


export type QuerygetInputObjectConfigArgs = {
  name: Scalars['String']['input'];
};


export type QuerygetOzowRedirectInfoV2Args = {
  input: OzowRedirectInfoInput;
};


export type QuerygetPayflowLinkTokenArgs = {
  input: PayflowLinkTokenInput;
};


export type QuerygetPaymentConfigArgs = {
  location: PaymentLocation;
};


export type QuerygetPaymentOrderArgs = {
  cartId: Scalars['String']['input'];
  id: Scalars['String']['input'];
};


export type QuerygetPaymentSdkArgs = {
  location: PaymentLocation;
};


export type QuerygetPeachPaymentsPaymentStatusArgs = {
  payment_id: Scalars['String']['input'];
};


export type QuerygetPeachPaymentsTokensArgs = {
  currentPage?: InputMaybe<Scalars['Int']['input']>;
  filter?: InputMaybe<PeachPaymentsTokensFilterInput>;
  pageSize?: InputMaybe<Scalars['Int']['input']>;
  search?: InputMaybe<Scalars['String']['input']>;
  sort?: InputMaybe<PeachPaymentsTokensSortInput>;
};


export type QueryguestRewardsArgs = {
  page: Scalars['Int']['input'];
};


export type QueryimageCountArgs = {
  filters?: InputMaybe<ImageFilterInput>;
};


export type QueryisEmailAvailableArgs = {
  email: Scalars['String']['input'];
};


export type QueryoneContentArgs = {
  filters?: InputMaybe<ContentFilterInput>;
};


export type QueryoneImageArgs = {
  filters?: InputMaybe<ImageFilterInput>;
};


export type QueryonePageArgs = {
  filters?: InputMaybe<PageFilterInput>;
};


export type QueryonePageAccordionSectionArgs = {
  filters?: InputMaybe<PageAccordionSectionFilterInput>;
};


export type QueryonePageBannerArgs = {
  filters?: InputMaybe<PageBannerFilterInput>;
};


export type QueryonePageBannerCategoryArgs = {
  filters?: InputMaybe<PageBannerCategoryFilterInput>;
};


export type QueryonePageBannerSectionArgs = {
  filters?: InputMaybe<PageBannerSectionFilterInput>;
};


export type QueryonePageBlockSectionArgs = {
  filters?: InputMaybe<PageBlockSectionFilterInput>;
};


export type QueryonePageCarouselSectionArgs = {
  filters?: InputMaybe<PageCarouselSectionFilterInput>;
};


export type QueryonePageChipSectionArgs = {
  filters?: InputMaybe<PageChipSectionFilterInput>;
};


export type QueryonePageExcerptSectionArgs = {
  filters?: InputMaybe<PageExcerptSectionFilterInput>;
};


export type QueryonePageHeadingSectionArgs = {
  filters?: InputMaybe<PageHeadingSectionFilterInput>;
};


export type QueryonePageHeroSectionArgs = {
  filters?: InputMaybe<PageHeroSectionFilterInput>;
};


export type QueryonePageIconSectionArgs = {
  filters?: InputMaybe<PageIconSectionFilterInput>;
};


export type QueryonePageLinkSectionArgs = {
  filters?: InputMaybe<PageLinkSectionFilterInput>;
};


export type QueryonePageMarkupSectionArgs = {
  filters?: InputMaybe<PageMarkupSectionFilterInput>;
};


export type QueryonePageMediaSectionArgs = {
  filters?: InputMaybe<PageMediaSectionFilterInput>;
};


export type QueryonePageMyDiscountsSectionArgs = {
  filters?: InputMaybe<PageMyDiscountsSectionFilterInput>;
};


export type QueryonePageMyShopSectionArgs = {
  filters?: InputMaybe<PageMyShopSectionFilterInput>;
};


export type QueryonePageNotificationSectionArgs = {
  filters?: InputMaybe<PageNotificationSectionFilterInput>;
};


export type QueryonePagePlainTextSectionArgs = {
  filters?: InputMaybe<PagePlainTextSectionFilterInput>;
};


export type QueryonePageProductSliderSectionArgs = {
  filters?: InputMaybe<PageProductSliderSectionFilterInput>;
};


export type QueryonePageQuestionGroupArgs = {
  filters?: InputMaybe<PageQuestionGroupFilterInput>;
};


export type QueryonePageQuestionSectionArgs = {
  filters?: InputMaybe<PageQuestionSectionFilterInput>;
};


export type QueryonePageSectionArgs = {
  filters?: InputMaybe<PageSectionFilterInput>;
};


export type QueryonePageSectionGroupArgs = {
  filters?: InputMaybe<PageSectionGroupFilterInput>;
};


export type QueryonePageSiteNoticeSectionArgs = {
  filters?: InputMaybe<PageSiteNoticeSectionFilterInput>;
};


export type QueryonePageSplitTextSectionArgs = {
  filters?: InputMaybe<PageSplitTextSectionFilterInput>;
};


export type QueryonePageTimetableSectionArgs = {
  filters?: InputMaybe<PageTimetableSectionFilterInput>;
};


export type QueryoneProductArgs = {
  sku?: InputMaybe<Scalars['String']['input']>;
  slug?: InputMaybe<Scalars['String']['input']>;
};


export type QueryoneWidgetArgs = {
  filters?: InputMaybe<WidgetFilterInput>;
};


export type QueryoneWidgetContactUsArgs = {
  filters?: InputMaybe<WidgetContactUsFilterInput>;
};


export type QueryoneWidgetDropDownCartArgs = {
  filters?: InputMaybe<WidgetDropDownCartFilterInput>;
};


export type QueryoneWidgetProductDetailsAddToCartArgs = {
  filters?: InputMaybe<WidgetProductDetailsAddToCartFilterInput>;
};


export type QueryoneWidgetSiteWideBannerArgs = {
  filters?: InputMaybe<WidgetSiteWideBannerFilterInput>;
};


export type QuerypageAccordionSectionCountArgs = {
  filters?: InputMaybe<PageAccordionSectionFilterInput>;
};


export type QuerypageBannerCategoryCountArgs = {
  filters?: InputMaybe<PageBannerCategoryFilterInput>;
};


export type QuerypageBannerCountArgs = {
  filters?: InputMaybe<PageBannerFilterInput>;
};


export type QuerypageBannerSectionCountArgs = {
  filters?: InputMaybe<PageBannerSectionFilterInput>;
};


export type QuerypageBlockSectionCountArgs = {
  filters?: InputMaybe<PageBlockSectionFilterInput>;
};


export type QuerypageCarouselSectionCountArgs = {
  filters?: InputMaybe<PageCarouselSectionFilterInput>;
};


export type QuerypageChipSectionCountArgs = {
  filters?: InputMaybe<PageChipSectionFilterInput>;
};


export type QuerypageCountArgs = {
  filters?: InputMaybe<PageFilterInput>;
};


export type QuerypageExcerptSectionCountArgs = {
  filters?: InputMaybe<PageExcerptSectionFilterInput>;
};


export type QuerypageHeadingSectionCountArgs = {
  filters?: InputMaybe<PageHeadingSectionFilterInput>;
};


export type QuerypageHeroSectionCountArgs = {
  filters?: InputMaybe<PageHeroSectionFilterInput>;
};


export type QuerypageIconSectionCountArgs = {
  filters?: InputMaybe<PageIconSectionFilterInput>;
};


export type QuerypageLinkSectionCountArgs = {
  filters?: InputMaybe<PageLinkSectionFilterInput>;
};


export type QuerypageMarkupSectionCountArgs = {
  filters?: InputMaybe<PageMarkupSectionFilterInput>;
};


export type QuerypageMediaSectionCountArgs = {
  filters?: InputMaybe<PageMediaSectionFilterInput>;
};


export type QuerypageMyDiscountsSectionCountArgs = {
  filters?: InputMaybe<PageMyDiscountsSectionFilterInput>;
};


export type QuerypageMyShopSectionCountArgs = {
  filters?: InputMaybe<PageMyShopSectionFilterInput>;
};


export type QuerypageNotificationSectionCountArgs = {
  filters?: InputMaybe<PageNotificationSectionFilterInput>;
};


export type QuerypagePlainTextSectionCountArgs = {
  filters?: InputMaybe<PagePlainTextSectionFilterInput>;
};


export type QuerypageProductSliderSectionCountArgs = {
  filters?: InputMaybe<PageProductSliderSectionFilterInput>;
};


export type QuerypageQuestionGroupCountArgs = {
  filters?: InputMaybe<PageQuestionGroupFilterInput>;
};


export type QuerypageQuestionSectionCountArgs = {
  filters?: InputMaybe<PageQuestionSectionFilterInput>;
};


export type QuerypageSectionCountArgs = {
  filters?: InputMaybe<PageSectionFilterInput>;
};


export type QuerypageSectionGroupCountArgs = {
  filters?: InputMaybe<PageSectionGroupFilterInput>;
};


export type QuerypageSiteNoticeSectionCountArgs = {
  filters?: InputMaybe<PageSiteNoticeSectionFilterInput>;
};


export type QuerypageSplitTextSectionCountArgs = {
  filters?: InputMaybe<PageSplitTextSectionFilterInput>;
};


export type QuerypageTimetableSectionCountArgs = {
  filters?: InputMaybe<PageTimetableSectionFilterInput>;
};


export type QueryproductReviewsArgs = {
  filters?: InputMaybe<ReviewFilterInput>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  order?: InputMaybe<ReviewSortInput>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  sku: Scalars['String']['input'];
};


export type QueryproductsArgs = {
  currentPage?: InputMaybe<Scalars['Int']['input']>;
  filter?: InputMaybe<ProductAttributeFilterInput>;
  pageSize?: InputMaybe<Scalars['Int']['input']>;
  search?: InputMaybe<Scalars['String']['input']>;
  sort?: InputMaybe<ProductAttributeSortInput>;
};


export type QueryrouteArgs = {
  url: Scalars['String']['input'];
};


export type QueryroutingArgs = {
  path: Scalars['String']['input'];
};


export type QuerysearchArgs = {
  currentPage?: InputMaybe<Scalars['Int']['input']>;
  pageSize?: InputMaybe<Scalars['Int']['input']>;
  phrase: Scalars['String']['input'];
};


export type QueryurlResolverArgs = {
  url: Scalars['String']['input'];
};


export type QueryviewMoreFilterArgs = {
  filter?: InputMaybe<ProductAttributeFilterInput>;
  filterName: Scalars['String']['input'];
  search?: InputMaybe<Scalars['String']['input']>;
};


export type QuerywidgetContactUsCountArgs = {
  filters?: InputMaybe<WidgetContactUsFilterInput>;
};


export type QuerywidgetCountArgs = {
  filters?: InputMaybe<WidgetFilterInput>;
};


export type QuerywidgetDropDownCartCountArgs = {
  filters?: InputMaybe<WidgetDropDownCartFilterInput>;
};


export type QuerywidgetProductDetailsAddToCartCountArgs = {
  filters?: InputMaybe<WidgetProductDetailsAddToCartFilterInput>;
};


export type QuerywidgetSiteWideBannerCountArgs = {
  filters?: InputMaybe<WidgetSiteWideBannerFilterInput>;
};

export type RatingBreakdown = {
  __typename: 'RatingBreakdown';
  /** The value of the rating */
  rating: Scalars['String']['output'];
  /** The total count for the current rating */
  total: Scalars['Int']['output'];
};

export type RatingSummary = {
  __typename: 'RatingSummary';
  /** The average product rating */
  averageRating: Scalars['Float']['output'];
  /** The rating breakdown for the product */
  ratingBreakdown: Array<Maybe<RatingBreakdown>>;
};

export type Region = {
  __typename: 'Region';
  /** The two-letter code for the region, such as TX for Texas. */
  code: Maybe<Scalars['String']['output']>;
  /** The unique ID for a `Region` object. */
  id: Maybe<Scalars['Int']['output']>;
  /** The name of the region, such as Texas. */
  name: Maybe<Scalars['String']['output']>;
};

/** Defines the input required to run the removeAmGiftCardFromCart mutation */
export type RemoveAmGiftCardFromCartInput = {
  /** The gift card code to be removed to the cart */
  amGiftCardCode: Scalars['String']['input'];
  /** The unique ID that identifies the customer's cart */
  cartId: Scalars['String']['input'];
};

/** Defines the possible output for the removeGiftCardFromCart mutation */
export type RemoveAmGiftCardFromCartOutput = {
  __typename: 'RemoveAmGiftCardFromCartOutput';
  /** Describes the contents of the specified shopping cart */
  cart: Cart;
};

/** Specifies the cart from which to remove a coupon. */
export type RemoveCouponFromCartInput = {
  /** The unique ID of a `Cart` object. */
  cartId: Scalars['String']['input'];
};

/** Contains details about the cart after removing a coupon. */
export type RemoveCouponFromCartOutput = {
  __typename: 'RemoveCouponFromCartOutput';
  /** The cart after removing a coupon. */
  cart: Maybe<Cart>;
};

/** Specifies which items to remove from the cart. */
export type RemoveItemFromCartInput = {
  /** The unique ID of a `Cart` object. */
  cartId: Scalars['String']['input'];
  /** Deprecated. Use `cart_item_uid` instead. */
  cartItemId?: InputMaybe<Scalars['Int']['input']>;
  /** Required field. The unique ID for a `CartItemInterface` object. */
  cartItemUid?: InputMaybe<Scalars['ID']['input']>;
};

/** Contains details about the cart after removing an item. */
export type RemoveItemFromCartOutput = {
  __typename: 'RemoveItemFromCartOutput';
  /** The cart after removing an item. */
  cart: Cart;
};

/** Defines which products to remove from a compare list. */
export type RemoveProductsFromCompareListInput = {
  /** An array of product IDs to remove from the compare list. */
  products: Array<InputMaybe<Scalars['ID']['input']>>;
  /** The unique identifier of the compare list to modify. */
  uid: Scalars['ID']['input'];
};

/** Contains the customer's wish list and any errors encountered. */
export type RemoveProductsFromWishlistOutput = {
  __typename: 'RemoveProductsFromWishlistOutput';
  /** An array of errors encountered while deleting products from a wish list. */
  userErrors: Array<Maybe<WishListUserInputError>>;
  /** Contains the wish list with after items were successfully deleted. */
  wishlist: Wishlist;
};

/** Contains the cart and any errors after adding products. */
export type ReorderItemsOutput = {
  __typename: 'ReorderItemsOutput';
  /** Detailed information about the customer's cart. */
  cart: Cart;
  /** An array of reordering errors. */
  userInputErrors: Array<Maybe<CheckoutUserInputError>>;
};

export type ReviewFilterInput = {
  /** The rating value */
  rating?: InputMaybe<FilterEqualTypeInput>;
};

/** Review sorting input */
export type ReviewSortInput = {
  /** Sorting direction */
  direction: SortEnum;
  /** Sorting order */
  order: ReviewSortOrderEnum;
};

/** This enumeration defines the review sorting order */
export enum ReviewSortOrderEnum {
  /**
   * Most recent reviews
   * @deprecated
   */
  RECENT = 'RECENT',
  /**
   * Top reviews
   * @deprecated
   */
  TOP = 'TOP'
}

/** This enumeration defines the review vote types */
export enum ReviewVoteEnum {
  /**
   * Down vote
   * @deprecated
   */
  DOWN = 'DOWN',
  /**
   * Up vote
   * @deprecated
   */
  UP = 'UP'
}

/** Review vote input */
export type ReviewVoteInput = {
  /** Review ID */
  reviewId: Scalars['Int']['input'];
  /** Vote */
  vote: ReviewVoteEnum;
};

/** The vote for a specific product review */
export type ReviewVoteItem = {
  __typename: 'ReviewVoteItem';
  /** Review ID */
  reviewId: Maybe<Scalars['Int']['output']>;
  /** Vote */
  vote: Maybe<ReviewVoteEnum>;
  /** Vote ID */
  voteId: Maybe<Scalars['Int']['output']>;
};

/** The vote totals for a specific product review */
export type ReviewVotes = {
  __typename: 'ReviewVotes';
  /** Current vote for customer */
  currentVote: Maybe<ReviewVoteEnum>;
  /** Total down votes */
  downVotes: Maybe<Scalars['Int']['output']>;
  /** Total up votes */
  upVotes: Maybe<Scalars['Int']['output']>;
};

/** Contains the result of a request to revoke a customer token. */
export type RevokeCustomerTokenOutput = {
  __typename: 'RevokeCustomerTokenOutput';
  /** The result of a request to revoke a customer token. */
  result: Scalars['Boolean']['output'];
};

export type Rewards = {
  __typename: 'Rewards';
  /** Current customer rewards balance. */
  balance: Maybe<Scalars['Float']['output']>;
  /** Customer rewards information query. */
  highlight: Maybe<HighlightQuery>;
  /** Information about the movement of reward points. */
  history: Maybe<HistoryOutput>;
};

/** Routable entities serve as the model for a rendered page. */
export type RoutableInterface = {
  /** Contains 0 when there is no redirect error. A value of 301 indicates the URL of the requested resource has been changed permanently, while a value of 302 indicates a temporary redirect. */
  redirectCode: Scalars['Int']['output'];
  /** The internal relative URL. If the specified URL is a redirect, the query returns the redirected URL, not the original. */
  relativeUrl: Maybe<Scalars['String']['output']>;
  /** One of PRODUCT, CATEGORY, or CMS_PAGE. */
  type: Maybe<UrlRewriteEntityTypeEnum>;
};

/** Default implementation of RoutableInterface. This type is returned when the URL is not linked to an entity. */
export type RoutableUrl = RoutableInterface & {
  __typename: 'RoutableUrl';
  /** Contains 0 when there is no redirect error. A value of 301 indicates the URL of the requested resource has been changed permanently, while a value of 302 indicates a temporary redirect. */
  redirectCode: Scalars['Int']['output'];
  /** The internal relative URL. If the specified URL is a redirect, the query returns the redirected URL, not the original. */
  relativeUrl: Maybe<Scalars['String']['output']>;
  /** One of PRODUCT, CATEGORY, or CMS_PAGE. */
  type: Maybe<UrlRewriteEntityTypeEnum>;
};

export type Route = {
  __typename: 'Route';
  baseCategory: Maybe<CategoryTree>;
  brand: Maybe<CategoryTree>;
  category: Maybe<CategoryTree>;
  createdAt: Scalars['DateTime']['output'];
  deals: Maybe<CategoryTree>;
  entityId: Maybe<Scalars['String']['output']>;
  entityType: EntityTypeEnum;
  id: Scalars['ID']['output'];
  ingredient: Maybe<CategoryTree>;
  level: Maybe<Scalars['Int']['output']>;
  page: Maybe<CmsPage>;
  path: Scalars['String']['output'];
  product: Maybe<ProductInterface>;
  sku: Maybe<Scalars['String']['output']>;
  uid: Maybe<Scalars['String']['output']>;
  updatedAt: Scalars['DateTime']['output'];
};

export type RouteItem = Page;

/** Contains details about a comment. */
export type SalesCommentItem = {
  __typename: 'SalesCommentItem';
  /** The text of the message. */
  message: Scalars['String']['output'];
  /** The timestamp of the comment. */
  timestamp: Scalars['String']['output'];
};

export type SalesItemInterface = {
  __typename: 'SalesItemInterface';
  /** The entered gift message for the order item */
  giftMessage: Maybe<GiftMessage>;
};

/** Defines the input required to run the addGiftMessageForOrderItems mutation */
export type SaveNotificationOptionsInput = {
  /** Receive emails when reward points are added to the balance */
  earnOption?: InputMaybe<Scalars['Boolean']['input']>;
  /** Receive emails when reward points are about to expire */
  expireOption?: InputMaybe<Scalars['Boolean']['input']>;
};

export type SaveNotificationOptionsOutput = {
  __typename: 'SaveNotificationOptionsOutput';
  /** Mutation response */
  response: Maybe<Scalars['String']['output']>;
};

/** This enumeration defines the scope type for customer orders. */
export enum ScopeTypeEnum {
  /** @deprecated  */
  GLOBAL = 'GLOBAL',
  /** @deprecated  */
  STORE = 'STORE',
  /** @deprecated  */
  WEBSITE = 'WEBSITE'
}

/** Defines the name and value of a SDK parameter */
export type SdkParams = {
  __typename: 'SdkParams';
  /** The name of the SDK parameter */
  name: Maybe<Scalars['String']['output']>;
  /** The value of the SDK parameter */
  value: Maybe<Scalars['String']['output']>;
};

/** The SearchResultItem object is the top-level object returned in a search. */
export type SearchResult = {
  __typename: 'SearchResult';
  /** An array of result items that match the specified search criteria. */
  items: Maybe<Array<Maybe<SearchResultItem>>>;
  /** An object that includes the page_info and currentPage values specified in the query. */
  pageInfo: Maybe<SearchResultPageInfo>;
  /** The number of result items that are visible. By default, in complex products, parent products are visible, but their child products are not. */
  totalCount: Maybe<Scalars['Int']['output']>;
};

export type SearchResultItem = BundleProduct | CategorySuggestion | ConfigurableProduct | DownloadableProduct | GroupedProduct | SearchTerm | SimpleProduct | VirtualProduct;

/** Provides navigation for the query response. */
export type SearchResultPageInfo = {
  __typename: 'SearchResultPageInfo';
  /** The specific page to return. */
  currentPage: Maybe<Scalars['Int']['output']>;
  isSpellchecked: Maybe<Scalars['Boolean']['output']>;
  /** The maximum number of items to return per page of results. */
  pageSize: Maybe<Scalars['Int']['output']>;
  queryId: Maybe<Scalars['Int']['output']>;
  /** The total number of pages in the response. */
  totalPages: Maybe<Scalars['Int']['output']>;
};

/** A string that contains search suggestion */
export type SearchSuggestion = {
  __typename: 'SearchSuggestion';
  /** The search suggestion of existing product. */
  search: Scalars['String']['output'];
};

export type SearchTerm = {
  __typename: 'SearchTerm';
  displayInTerms: Maybe<Scalars['Boolean']['output']>;
  numResults: Maybe<Scalars['Int']['output']>;
  popularity: Maybe<Scalars['Int']['output']>;
  queryId: Maybe<Scalars['Int']['output']>;
  queryText: Maybe<Scalars['String']['output']>;
  /** The unique ID for a `SearchTerm` object. Please note this does not get used in Magento only to cache on the frontend. */
  uid: Scalars['ID']['output'];
};

export type SectionUsedIn = {
  __typename: 'SectionUsedIn';
  contentId: Scalars['ID']['output'];
  id: Scalars['ID']['output'];
  isActive: Scalars['Boolean']['output'];
  pageId: Scalars['ID']['output'];
  pageName: Scalars['String']['output'];
  version: Scalars['Int']['output'];
};

export type SectionUsedInInput = {
  contentId: Scalars['ID']['input'];
  isActive: Scalars['Boolean']['input'];
  pageId: Scalars['ID']['input'];
  pageName: Scalars['String']['input'];
  version: Scalars['Int']['input'];
};

/** Contains details about a selected bundle option. */
export type SelectedBundleOption = {
  __typename: 'SelectedBundleOption';
  /** @deprecated Use `uid` instead */
  id: Scalars['Int']['output'];
  /** The display name of the selected bundle product option. */
  label: Scalars['String']['output'];
  /** The type of selected bundle product option. */
  type: Scalars['String']['output'];
  /** The unique ID for a `SelectedBundleOption` object */
  uid: Scalars['ID']['output'];
  /** An array of selected bundle option values. */
  values: Array<Maybe<SelectedBundleOptionValue>>;
};

/** Contains details about a value for a selected bundle option. */
export type SelectedBundleOptionValue = {
  __typename: 'SelectedBundleOptionValue';
  /** Use `uid` instead */
  id: Scalars['Int']['output'];
  /** The display name of the value for the selected bundle product option. */
  label: Scalars['String']['output'];
  /** The price of the value for the selected bundle product option. */
  price: Scalars['Float']['output'];
  /** The quantity of the value for the selected bundle product option. */
  quantity: Scalars['Float']['output'];
  /** The unique ID for a `SelectedBundleOptionValue` object */
  uid: Scalars['ID']['output'];
};

/** Contains details about a selected configurable option. */
export type SelectedConfigurableOption = {
  __typename: 'SelectedConfigurableOption';
  /** The unique ID for a `ConfigurableProductOptions` object. */
  configurableProductOptionUid: Scalars['ID']['output'];
  /** The unique ID for a `ConfigurableProductOptionsValues` object. */
  configurableProductOptionValueUid: Scalars['ID']['output'];
  /** @deprecated Use `SelectedConfigurableOption.configurable_product_option_uid` instead. */
  id: Scalars['Int']['output'];
  /** The display text for the option. */
  optionLabel: Scalars['String']['output'];
  /** @deprecated Use `SelectedConfigurableOption.configurable_product_option_value_uid` instead. */
  valueId: Scalars['Int']['output'];
  /** The display name of the selected configurable option. */
  valueLabel: Scalars['String']['output'];
};

/** Identifies a customized product that has been placed in a cart. */
export type SelectedCustomizableOption = {
  __typename: 'SelectedCustomizableOption';
  /** The unique ID for a specific `CustomizableOptionInterface` object, such as a `CustomizableFieldOption`, `CustomizableFileOption`, or `CustomizableAreaOption` object. */
  customizableOptionUid: Scalars['ID']['output'];
  /** @deprecated Use `SelectedCustomizableOption.customizable_option_uid` instead. */
  id: Scalars['Int']['output'];
  /** Indicates whether the customizable option is required. */
  isRequired: Scalars['Boolean']['output'];
  /** The display name of the selected customizable option. */
  label: Scalars['String']['output'];
  /** A value indicating the order to display this option. */
  sortOrder: Scalars['Int']['output'];
  /** The type of `CustomizableOptionInterface` object. */
  type: Scalars['String']['output'];
  /** An array of selectable values. */
  values: Array<Maybe<SelectedCustomizableOptionValue>>;
};

/** Identifies the value of the selected customized option. */
export type SelectedCustomizableOptionValue = {
  __typename: 'SelectedCustomizableOptionValue';
  /** The unique ID for a value object that corresponds to the object represented by the `customizable_option_uid` attribute. */
  customizableOptionValueUid: Scalars['ID']['output'];
  /** @deprecated Use `SelectedCustomizableOptionValue.customizable_option_value_uid` instead. */
  id: Scalars['Int']['output'];
  /** The display name of the selected value. */
  label: Scalars['String']['output'];
  /** The price of the selected customizable value. */
  price: CartItemSelectedOptionValuePrice;
  /** The text identifying the selected value. */
  value: Scalars['String']['output'];
};

/** Describes the payment method the shopper selected. */
export type SelectedPaymentMethod = {
  __typename: 'SelectedPaymentMethod';
  /** The payment method code. */
  code: Scalars['String']['output'];
  /** The purchase order number. */
  purchaseOrderNumber: Maybe<Scalars['String']['output']>;
  /** The payment method title. */
  title: Scalars['String']['output'];
};

/** Contains details about the selected shipping method and carrier. */
export type SelectedShippingMethod = {
  __typename: 'SelectedShippingMethod';
  /** The cost of shipping using this shipping method. */
  amount: Money;
  /** @deprecated The field should not be used on the storefront. */
  baseAmount: Maybe<Money>;
  /** A string that identifies a commercial carrier or an offline shipping method. */
  carrierCode: Scalars['String']['output'];
  /** The label for the carrier code. */
  carrierTitle: Scalars['String']['output'];
  /** The carrier type */
  carrierType: Maybe<CarrierTypeEnum>;
  /** A shipping method code associated with a carrier. */
  methodCode: Scalars['String']['output'];
  /** The label for the method code. */
  methodTitle: Scalars['String']['output'];
  /** The cost of shipping using this shipping method, excluding tax. */
  priceExclTax: Money;
  /** The cost of shipping using this shipping method, including tax. */
  priceInclTax: Money;
};

/** Defines the referenced product and the email sender and recipients. */
export type SendEmailToFriendInput = {
  /** The ID of the product that the sender is referencing. */
  productId: Scalars['Int']['input'];
  /** An array containing information about each recipient. */
  recipients: Array<InputMaybe<SendEmailToFriendRecipientInput>>;
  /** Information about the customer and the content of the message. */
  sender: SendEmailToFriendSenderInput;
};

/** Contains information about the sender and recipients. */
export type SendEmailToFriendOutput = {
  __typename: 'SendEmailToFriendOutput';
  /** An array containing information about each recipient. */
  recipients: Maybe<Array<Maybe<SendEmailToFriendRecipient>>>;
  /** Information about the customer and the content of the message. */
  sender: Maybe<SendEmailToFriendSender>;
};

/** An output object that contains information about the recipient. */
export type SendEmailToFriendRecipient = {
  __typename: 'SendEmailToFriendRecipient';
  /** The email address of the recipient. */
  email: Scalars['String']['output'];
  /** The name of the recipient. */
  name: Scalars['String']['output'];
};

/** Contains details about a recipient. */
export type SendEmailToFriendRecipientInput = {
  /** The email address of the recipient. */
  email: Scalars['String']['input'];
  /** The name of the recipient. */
  name: Scalars['String']['input'];
};

/** An output object that contains information about the sender. */
export type SendEmailToFriendSender = {
  __typename: 'SendEmailToFriendSender';
  /** The email address of the sender. */
  email: Scalars['String']['output'];
  /** The text of the message to be sent. */
  message: Scalars['String']['output'];
  /** The name of the sender. */
  name: Scalars['String']['output'];
};

/** Contains details about the sender. */
export type SendEmailToFriendSenderInput = {
  /** The email address of the sender. */
  email: Scalars['String']['input'];
  /** The text of the message to be sent. */
  message: Scalars['String']['input'];
  /** The name of the sender. */
  name: Scalars['String']['input'];
};

/** Contains details about the configuration of the Email to a Friend feature. */
export type SendFriendConfiguration = {
  __typename: 'SendFriendConfiguration';
  /** Indicates whether the Email to a Friend feature is enabled. */
  enabledForCustomers: Scalars['Boolean']['output'];
  /** Indicates whether the Email to a Friend feature is enabled for guests. */
  enabledForGuests: Scalars['Boolean']['output'];
};

export type SendMobileVerificationInput = {
  /** The customer's mobile number */
  mobile: Scalars['String']['input'];
};

/** Contains the result of the mobile verification code sent. */
export type SendMobileVerificationOutput = {
  __typename: 'SendMobileVerificationOutput';
  /** The date and time the verification code will expire */
  expiryDate: Scalars['String']['output'];
  /** The number of send attempts remaining */
  sendAttemptsRemaining: Scalars['Int']['output'];
  /** The date and time the verification code was sent */
  sentDate: Scalars['String']['output'];
};

/** Sets the billing address. */
export type SetBillingAddressOnCartInput = {
  /** The billing address. */
  billingAddress: BillingAddressInput;
  /** The unique ID of a `Cart` object. */
  cartId: Scalars['String']['input'];
};

/** Contains details about the cart after setting the billing address. */
export type SetBillingAddressOnCartOutput = {
  __typename: 'SetBillingAddressOnCartOutput';
  /** The cart after setting the billing address. */
  cart: Cart;
};

/** Defines the guest email and cart. */
export type SetGuestEmailOnCartInput = {
  /** The unique ID of a `Cart` object. */
  cartId: Scalars['String']['input'];
  /** The email address of the guest. */
  email: Scalars['String']['input'];
};

/** Contains details about the cart after setting the email of a guest. */
export type SetGuestEmailOnCartOutput = {
  __typename: 'SetGuestEmailOnCartOutput';
  /** The cart after setting the guest email. */
  cart: Cart;
};

/** Applies a payment method to the quote. */
export type SetPaymentMethodAndPlaceOrderInput = {
  /** The unique ID of a `Cart` object. */
  cartId: Scalars['String']['input'];
  /** The payment method data to apply to the cart. */
  paymentMethod: PaymentMethodInput;
};

/** Applies a payment method to the cart. */
export type SetPaymentMethodOnCartInput = {
  /** The unique ID of a `Cart` object. */
  cartId: Scalars['String']['input'];
  /** The payment method data to apply to the cart. */
  paymentMethod: PaymentMethodInput;
};

/** Contains details about the cart after setting the payment method. */
export type SetPaymentMethodOnCartOutput = {
  __typename: 'SetPaymentMethodOnCartOutput';
  /** The cart after setting the payment method. */
  cart: Cart;
};

/** Specifies an array of addresses to use for shipping. */
export type SetShippingAddressesOnCartInput = {
  /** The unique ID of a `Cart` object. */
  cartId: Scalars['String']['input'];
  /** An array of shipping addresses. */
  shippingAddresses: Array<InputMaybe<ShippingAddressInput>>;
};

/** Contains details about the cart after setting the shipping addresses. */
export type SetShippingAddressesOnCartOutput = {
  __typename: 'SetShippingAddressesOnCartOutput';
  /** The cart after setting the shipping addresses. */
  cart: Cart;
};

/** Applies one or shipping methods to the cart. */
export type SetShippingMethodsOnCartInput = {
  /** The unique ID of a `Cart` object. */
  cartId: Scalars['String']['input'];
  /** An array of shipping methods. */
  shippingMethods: Array<InputMaybe<ShippingMethodInput>>;
};

/** Contains details about the cart after setting the shipping methods. */
export type SetShippingMethodsOnCartOutput = {
  __typename: 'SetShippingMethodsOnCartOutput';
  /** The cart after setting the shipping methods. */
  cart: Cart;
};

/** Defines whether bundle items must be shipped together. */
export enum ShipBundleItemsEnum {
  /** @deprecated  */
  SEPARATELY = 'SEPARATELY',
  /** @deprecated  */
  TOGETHER = 'TOGETHER'
}

export type ShipmentItem = ShipmentItemInterface & {
  __typename: 'ShipmentItem';
  /** The unique ID for a `ShipmentItemInterface` object. */
  id: Scalars['ID']['output'];
  /** The order item associated with the shipment item. */
  orderItem: Maybe<OrderItemInterface>;
  /** The name of the base product. */
  productName: Maybe<Scalars['String']['output']>;
  /** The sale price for the base product. */
  productSalePrice: Money;
  /** The SKU of the base product. */
  productSku: Scalars['String']['output'];
  /** The number of shipped items. */
  quantityShipped: Scalars['Float']['output'];
};

/** Order shipment item details. */
export type ShipmentItemInterface = {
  /** The unique ID for a `ShipmentItemInterface` object. */
  id: Scalars['ID']['output'];
  /** The order item associated with the shipment item. */
  orderItem: Maybe<OrderItemInterface>;
  /** The name of the base product. */
  productName: Maybe<Scalars['String']['output']>;
  /** The sale price for the base product. */
  productSalePrice: Money;
  /** The SKU of the base product. */
  productSku: Scalars['String']['output'];
  /** The number of shipped items. */
  quantityShipped: Scalars['Float']['output'];
};

export type ShipmentSource = {
  __typename: 'ShipmentSource';
  /** The promise ETA */
  eta: Maybe<Scalars['String']['output']>;
  /** An array of items in the shipment */
  items: Maybe<Array<Maybe<ShipmentSourceItem>>>;
  /** The source details */
  source: Maybe<Source>;
  /** The status of the shipment */
  status: Maybe<Scalars['String']['output']>;
  /** Contains all the waybills with tracking information */
  waybills: Maybe<Array<Maybe<Waybill>>>;
};

export type ShipmentSourceItem = {
  __typename: 'ShipmentSourceItem';
  /** The quantity */
  qty: Maybe<Scalars['Int']['output']>;
  /** The SKU */
  sku: Maybe<Scalars['String']['output']>;
};

/** Contains order shipment tracking details. */
export type ShipmentTracking = {
  __typename: 'ShipmentTracking';
  /** The shipping carrier for the order delivery. */
  carrier: Scalars['String']['output'];
  /** The tracking number of the order shipment. */
  number: Maybe<Scalars['String']['output']>;
  /** The shipment tracking title. */
  title: Scalars['String']['output'];
  /** The tracking URL of the order shipment */
  url: Maybe<Scalars['String']['output']>;
};

export type ShippingAction = {
  __typename: 'ShippingAction';
  /** The action to be applied to the shipping method */
  action: Maybe<ShippingActionEnum>;
  /** The additional information for the action */
  actionItems: Maybe<Array<Maybe<ActionItems>>>;
  /** The description of the action */
  description: Maybe<Scalars['String']['output']>;
  /** The title of the action */
  title: Maybe<Scalars['String']['output']>;
};

/** This enumeration defines the shipping actions */
export enum ShippingActionEnum {
  /**
   * Normal
   * @deprecated
   */
  NORMAL = 'NORMAL',
  /**
   * Remove
   * @deprecated
   */
  REMOVE = 'REMOVE',
  /**
   * Reroute
   * @deprecated
   */
  REROUTE = 'REROUTE',
  /**
   * Split
   * @deprecated
   */
  SPLIT = 'SPLIT'
}

export type ShippingActions = {
  __typename: 'ShippingActions';
  /** An array of cart items that are delayed */
  actions: Maybe<Array<Maybe<ShippingAction>>>;
  /** The primary source for the order */
  primarySource: Maybe<Source>;
  /** An array of items under shortage, applicable for the 'remove' action only and display */
  shortageItems: Maybe<Array<Maybe<ShortageItem>>>;
};

/** Defines a single shipping address. */
export type ShippingAddressInput = {
  /** Defines a shipping address. */
  address?: InputMaybe<CartAddressInput>;
  /** An ID from the customer's address book that uniquely identifies the address to be used for shipping. */
  customerAddressId?: InputMaybe<Scalars['Int']['input']>;
  /** Text provided by the shopper. */
  customerNotes?: InputMaybe<Scalars['String']['input']>;
};

/** Contains shipping addresses and methods. */
export type ShippingCartAddress = CartAddressInterface & {
  __typename: 'ShippingCartAddress';
  /** The customer's prefered address group */
  addressGroup: Maybe<AddressGroupEnum>;
  /** An array that lists the shipping methods that can be applied to the cart. */
  availableShippingMethods: Maybe<Array<Maybe<AvailableShippingMethod>>>;
  /** @deprecated Use `cart_items_v2` instead. */
  cartItems: Maybe<Array<Maybe<CartItemQuantity>>>;
  /** An array that lists the items in the cart. */
  cartItemsV2: Maybe<Array<Maybe<CartItemInterface>>>;
  /** The city specified for the billing or shipping address. */
  city: Scalars['String']['output'];
  /** The company specified for the billing or shipping address. */
  company: Maybe<Scalars['String']['output']>;
  /** An object containing the country label and code. */
  country: CartAddressCountry;
  /** Text provided by the shopper. */
  customerNotes: Maybe<Scalars['String']['output']>;
  /** The customer's delivery note for the address */
  deliveryNote: Maybe<Scalars['String']['output']>;
  /** The first name of the customer or guest. */
  firstname: Scalars['String']['output'];
  /** @deprecated This information should not be exposed on the frontend. */
  itemsWeight: Maybe<Scalars['Float']['output']>;
  /** The last name of the customer or guest. */
  lastname: Scalars['String']['output'];
  /** The ZIP or postal code of the billing or shipping address. */
  postcode: Maybe<Scalars['String']['output']>;
  /** An object containing the region label and code. */
  region: Maybe<CartAddressRegion>;
  /** An object that describes the selected shipping method. */
  selectedShippingMethod: Maybe<SelectedShippingMethod>;
  /** An array of shipping actions that the customer can apply to the cart */
  shippingActions: Maybe<ShippingActions>;
  /** An array containing the street for the billing or shipping address. */
  street: Array<Maybe<Scalars['String']['output']>>;
  /** The customer's suburb */
  suburb: Maybe<Scalars['String']['output']>;
  /** The telephone number for the billing or shipping address. */
  telephone: Maybe<Scalars['String']['output']>;
  /** The alternative telephone number */
  telephoneAlt: Maybe<Scalars['String']['output']>;
  /** The unique id of the customer address. */
  uid: Scalars['String']['output'];
  /** The VAT company number for billing or shipping address. */
  vatId: Maybe<Scalars['String']['output']>;
};

/** Defines an individual shipping discount. This discount can be applied to shipping. */
export type ShippingDiscount = {
  __typename: 'ShippingDiscount';
  /** The amount of the discount. */
  amount: Money;
};

/** Contains details about shipping and handling costs. */
export type ShippingHandling = {
  __typename: 'ShippingHandling';
  /** The shipping amount, excluding tax. */
  amountExcludingTax: Maybe<Money>;
  /** The shipping amount, including tax. */
  amountIncludingTax: Maybe<Money>;
  /** The applied discounts to the shipping. */
  discounts: Maybe<Array<Maybe<ShippingDiscount>>>;
  /** Details about taxes applied for shipping. */
  taxes: Maybe<Array<Maybe<TaxItem>>>;
  /** The total amount for shipping. */
  totalAmount: Money;
};

/** Defines the shipping carrier and method. */
export type ShippingMethodInput = {
  /** A string that identifies a commercial carrier or an offline delivery method. */
  carrierCode: Scalars['String']['input'];
  /** A string that indicates which service a commercial carrier will use to ship items. For offline delivery methods, this value is similar to the label displayed on the checkout page. */
  methodCode: Scalars['String']['input'];
  /** The Pargo point code */
  pargoPointCode?: InputMaybe<Scalars['String']['input']>;
  /** The shipping rate UID */
  rateUid?: InputMaybe<Scalars['String']['input']>;
  /** The shipping action to be applied to the shipping method */
  shippingAction?: InputMaybe<ShippingActionEnum>;
};

export type ShortageItem = {
  __typename: 'ShortageItem';
  /** The cart item UID */
  cartItemUid: Maybe<Scalars['String']['output']>;
  /** The cart parent item UID */
  cartParentItemUid: Maybe<Scalars['String']['output']>;
  /** The shortage quantity */
  qty: Maybe<Scalars['Int']['output']>;
  /** The SKU */
  sku: Maybe<Scalars['String']['output']>;
};

export type SiWarehouse = {
  __typename: 'SiWarehouse';
  display: Maybe<Scalars['String']['output']>;
  location: Maybe<Scalars['String']['output']>;
  qty: Maybe<Scalars['Float']['output']>;
  status: Maybe<Scalars['Float']['output']>;
};

/** An implementation for simple product cart items. */
export type SimpleCartItem = CartItemInterface & {
  __typename: 'SimpleCartItem';
  /** An array containing the customizable options the shopper selected. */
  customizableOptions: Array<Maybe<SelectedCustomizableOption>>;
  /** An array of errors encountered while loading the cart item */
  errors: Maybe<Array<Maybe<CartItemError>>>;
  /** The entered gift message for the cart item */
  giftMessage: Maybe<GiftMessage>;
  /** @deprecated Use `uid` instead. */
  id: Scalars['String']['output'];
  /** Contains details about the price of the item, including taxes and discounts. */
  prices: Maybe<CartItemPrices>;
  /** Details about an item in the cart. */
  product: ProductInterface;
  /** The quantity of this item in the cart. */
  quantity: Scalars['Float']['output'];
  /** The unique ID for a `CartItemInterface` object. */
  uid: Scalars['ID']['output'];
};

export type SimpleEnitytRelatedObject = {
  __typename: 'SimpleEnitytRelatedObject';
  createdAt: Scalars['DateTime']['output'];
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  updatedAt: Scalars['DateTime']['output'];
};

export type SimpleEnitytRelatedObjectFilters = {
  createdAt?: InputMaybe<DateTimeEqualsInRangeFilterInput>;
  id?: InputMaybe<IDEqualsInFilterInput>;
  search?: InputMaybe<Scalars['String']['input']>;
  updatedAt?: InputMaybe<DateTimeEqualsInRangeFilterInput>;
};

export type SimpleEnitytRelatedObjectList = {
  __typename: 'SimpleEnitytRelatedObjectList';
  limit: Scalars['Int']['output'];
  list: Array<SimpleEnitytRelatedObject>;
  skip: Scalars['Int']['output'];
  total: Scalars['Int']['output'];
};

export type SimpleEnitytRelatedObjectOrder = {
  direction?: InputMaybe<OrderDirectionEnum>;
  field?: InputMaybe<Scalars['String']['input']>;
};

export type SimpleEntityConfig = EntityConfig & {
  __typename: 'SimpleEntityConfig';
  addFields: Maybe<Array<EntityInputFieldConfig>>;
  canAdd: Scalars['Boolean']['output'];
  canUpdate: Scalars['Boolean']['output'];
  description: Scalars['String']['output'];
  doesImplement: Array<Scalars['String']['output']>;
  enums: Array<AdminEnum>;
  externals: Maybe<Array<ExternalEntity>>;
  fields: Array<EntityFieldConfig>;
  filters: Array<EntityFilterConfig>;
  id: Scalars['ID']['output'];
  isImplementedBy: Array<Scalars['String']['output']>;
  isInterface: Maybe<Scalars['Boolean']['output']>;
  keys: EntityKeys;
  names: EntityNames;
  order: Array<EntityOrderConfig>;
  relationships: Maybe<Array<EntityRelationshipConfig>>;
  rowLayout: AdminLayout;
  type: Scalars['String']['output'];
  updateFields: Maybe<Array<EntityInputFieldConfig>>;
};

export enum SimpleFilterTypeEnum {
  /** Contains */
  CONTAINS = 'CONTAINS',
  /** Contains greater than */
  CONTAINS_GREATER_THAN = 'CONTAINS_GREATER_THAN',
  /** Contains less than */
  CONTAINS_LESS_THAN = 'CONTAINS_LESS_THAN',
  /** Does not contain */
  DOES_NOT_CONTAIN = 'DOES_NOT_CONTAIN',
  /** Does not equal */
  DOES_NOT_EQUAL = 'DOES_NOT_EQUAL',
  /** Equals */
  EQUALS = 'EQUALS',
  /** Greater than */
  GREATER_THAN = 'GREATER_THAN',
  /** In */
  IN = 'IN',
  /** Less than */
  LESS_THAN = 'LESS_THAN',
  /** Not in */
  NOT_IN = 'NOT_IN'
}

export type SimpleMediaObject = {
  __typename: 'SimpleMediaObject';
  mediaType: SimpleMediaType;
  type: Scalars['String']['output'];
};

export enum SimpleMediaType {
  /** File */
  FILE = 'FILE',
  /** Image */
  IMAGE = 'IMAGE',
  /** PDF */
  PDF = 'PDF',
  /** Video */
  VIDEO = 'VIDEO'
}

/** Defines a simple product, which is tangible and is usually sold in single units or in fixed quantities. */
export type SimpleProduct = CustomizableProductInterface & PhysicalProductInterface & ProductInterface & RoutableInterface & {
  __typename: 'SimpleProduct';
  /** @deprecated Use the `custom_attributes` field instead. */
  age: Maybe<Scalars['String']['output']>;
  /** Information about guest highlight points available by product. */
  amastyRewardsGuestHighlights: Maybe<HighlightOutput>;
  /** Information about highlight points available by product. */
  amastyRewardsHighlights: Maybe<HighlightOutput>;
  /**
   * The attribute set assigned to the product.
   * @deprecated The field should not be used on the storefront.
   */
  attributeSetId: Maybe<Scalars['Int']['output']>;
  /** Returns all assigned product attributes and their values */
  attributes: Array<Maybe<ProductAttributes>>;
  /** Returns the brand of the product */
  brand: Maybe<Brand>;
  cacheBuster: Maybe<Scalars['String']['output']>;
  /** The relative canonical URL. This value is returned only if the system setting 'Use Canonical Link Meta Tag For Products' is enabled. */
  canonicalUrl: Maybe<Scalars['String']['output']>;
  cartItemUid: Maybe<Scalars['String']['output']>;
  /** The categories assigned to a product. */
  categories: Maybe<Array<Maybe<CategoryInterface>>>;
  /** @deprecated Use the `custom_attributes` field instead. */
  colour: Maybe<Scalars['Int']['output']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  concentration: Maybe<Scalars['Int']['output']>;
  /** The product's country of origin. */
  countryOfManufacture: Maybe<Scalars['String']['output']>;
  coverImage: Image;
  /**
   * Timestamp indicating when the product was created.
   * @deprecated The field should not be used on the storefront.
   */
  createdAt: Maybe<Scalars['String']['output']>;
  /** Crosssell Products */
  crosssellProducts: Maybe<Array<Maybe<ProductInterface>>>;
  /** @deprecated Use the `custom_attributes` field instead. */
  department: Maybe<Scalars['String']['output']>;
  /** Detailed information about the product. The value can include simple HTML tags. */
  description: Maybe<ComplexTextValue>;
  /** @deprecated Use the `custom_attributes` field instead. */
  flavour: Maybe<Scalars['Int']['output']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  fragrance: Maybe<Scalars['Int']['output']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  gender: Maybe<Scalars['String']['output']>;
  /** Indicates whether a gift message is available. */
  giftMessageAvailable: Maybe<Scalars['String']['output']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  hairType: Maybe<Scalars['String']['output']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  height: Maybe<Scalars['Float']['output']>;
  /**
   * The ID number assigned to the product.
   * @deprecated Use the `uid` field instead.
   */
  id: Maybe<Scalars['Int']['output']>;
  /** The relative path to the main image on the product page. */
  image: Maybe<ProductImage>;
  imageGallery: Array<Image>;
  ingredientList: Maybe<Array<Maybe<ProductIngredient>>>;
  /** @deprecated Use the `custom_attributes` field instead. */
  ingredients: Maybe<Scalars['String']['output']>;
  isInWishlist: Scalars['Boolean']['output'];
  /** List of product labels associated to the product */
  labels: Array<Maybe<LabelItem>>;
  /** @deprecated Use the `custom_attributes` field instead. */
  length: Maybe<Scalars['Float']['output']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  licensingStatus: Maybe<Scalars['String']['output']>;
  /**
   * A number representing the product's manufacturer.
   * @deprecated Use the `custom_attributes` field instead.
   */
  manufacturer: Maybe<Scalars['Int']['output']>;
  /** An array of media gallery objects. */
  mediaGallery: Maybe<Array<Maybe<MediaGalleryInterface>>>;
  /**
   * An array of MediaGalleryEntry objects.
   * @deprecated Use `media_gallery` instead.
   */
  mediaGalleryEntries: Maybe<Array<Maybe<MediaGalleryEntry>>>;
  /** An array of media gallery objects. */
  mediaGalleryV2: Maybe<Array<Maybe<MediaGalleryV2Interface>>>;
  /** A brief overview of the product for search results listings, maximum 255 characters. */
  metaDescription: Maybe<Scalars['String']['output']>;
  /** A comma-separated list of keywords that are visible only to search engines. */
  metaKeyword: Maybe<Scalars['String']['output']>;
  /** A string that is displayed in the title bar and tab of the browser and in search results lists. */
  metaTitle: Maybe<Scalars['String']['output']>;
  /** The product name. Customers use this name to identify the product. */
  name: Maybe<Scalars['String']['output']>;
  /** The beginning date for new product listings, and determines if the product is featured as a new product. */
  newFromDate: Maybe<Scalars['String']['output']>;
  /** The end date for new product listings. */
  newToDate: Maybe<Scalars['String']['output']>;
  /** Product stock only x left count */
  onlyXLeftInStock: Maybe<Scalars['Float']['output']>;
  /** An array of options for a customizable product. */
  options: Maybe<Array<Maybe<CustomizableOptionInterface>>>;
  /** If the product has multiple options, determines where they appear on the product page. */
  optionsContainer: Maybe<Scalars['String']['output']>;
  /**
   * Indicates the price of an item.
   * @deprecated Use `price_range` for product price information.
   */
  price: Maybe<ProductPrices>;
  /** The range of prices for the product */
  priceRange: PriceRange;
  /** An array of `TierPrice` objects. */
  priceTiers: Maybe<Array<Maybe<TierPrice>>>;
  /** An array of `ProductLinks` objects. */
  productLinks: Maybe<Array<Maybe<ProductLinksInterface>>>;
  productType: ProductTypeEnum;
  /** @deprecated Use the `custom_attributes` field instead. */
  quantity: Maybe<Scalars['Int']['output']>;
  quantityInCart: Scalars['Int']['output'];
  /** The rating summary for the product */
  ratingSummary: RatingSummary;
  /** Contains 0 when there is no redirect error. A value of 301 indicates the URL of the requested resource has been changed permanently, while a value of 302 indicates a temporary redirect. */
  redirectCode: Scalars['Int']['output'];
  /** Related Products */
  relatedProducts: Maybe<Array<Maybe<ProductInterface>>>;
  /** The internal relative URL. If the specified URL is a redirect, the query returns the redirected URL, not the original. */
  relativeUrl: Maybe<Scalars['String']['output']>;
  /** The total count of all the reviews given to the product. */
  reviewCount: Scalars['Int']['output'];
  /** The list of products reviews */
  reviews: ProductReviews;
  /** A short description of the product. Its use depends on the theme. */
  shortDescription: Maybe<ComplexTextValue>;
  /** @deprecated Use the `custom_attributes` field instead. */
  size: Maybe<Scalars['Int']['output']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  skinConcern: Maybe<Scalars['String']['output']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  skinType: Maybe<Scalars['String']['output']>;
  /** A number or code assigned to a product to identify the product, options, price, and manufacturer. */
  sku: Maybe<Scalars['String']['output']>;
  /** The relative path to the small image, which is used on catalog pages. */
  smallImage: Maybe<ProductImage>;
  /**
   * The beginning date that a product has a special price.
   * @deprecated The field should not be used on the storefront.
   */
  specialFromDate: Maybe<Scalars['String']['output']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  specialIcons: Maybe<Scalars['String']['output']>;
  /** The discounted price of the product. */
  specialPrice: Maybe<Scalars['Float']['output']>;
  /** The end date for a product with a special price. */
  specialToDate: Maybe<Scalars['String']['output']>;
  stockInfo: Maybe<StockInfo>;
  /** Stock status of the product */
  stockStatus: Maybe<ProductStockStatus>;
  /** The file name of a swatch image. */
  swatchImage: Maybe<Scalars['String']['output']>;
  /** Returns all assigned product tags and their values */
  tags: Array<Maybe<ProductTags>>;
  /** The relative path to the product's thumbnail image. */
  thumbnail: Maybe<ProductImage>;
  /**
   * The price when tier pricing is in effect and the items purchased threshold has been reached.
   * @deprecated Use `price_tiers` for product tier price information.
   */
  tierPrice: Maybe<Scalars['Float']['output']>;
  /**
   * An array of ProductTierPrices objects.
   * @deprecated Use `price_tiers` for product tier price information.
   */
  tierPrices: Maybe<Array<Maybe<ProductTierPrices>>>;
  /** One of PRODUCT, CATEGORY, or CMS_PAGE. */
  type: Maybe<UrlRewriteEntityTypeEnum>;
  /**
   * One of simple, virtual, bundle, downloadable, grouped, or configurable.
   * @deprecated Use `__typename` instead.
   */
  typeId: Maybe<Scalars['String']['output']>;
  /** The unique ID for a `ProductInterface` object. */
  uid: Scalars['ID']['output'];
  /**
   * Timestamp indicating when the product was updated.
   * @deprecated The field should not be used on the storefront.
   */
  updatedAt: Maybe<Scalars['String']['output']>;
  /** Upsell Products */
  upsellProducts: Maybe<Array<Maybe<ProductInterface>>>;
  /** The part of the URL that identifies the product */
  urlKey: Maybe<Scalars['String']['output']>;
  /** @deprecated Use product's `canonical_url` or url rewrites instead */
  urlPath: Maybe<Scalars['String']['output']>;
  /** URL rewrites list */
  urlRewrites: Maybe<Array<Maybe<UrlRewrite>>>;
  /** The part of the product URL that is appended after the url key */
  urlSuffix: Maybe<Scalars['String']['output']>;
  valueAttributes: Maybe<Array<Maybe<ProductValue>>>;
  /** @deprecated Use the `custom_attributes` field instead. */
  valueTraits: Maybe<Scalars['String']['output']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  valuesDiet: Maybe<Scalars['String']['output']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  valuesEnvironmental: Maybe<Scalars['String']['output']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  valuesIngredient: Maybe<Scalars['String']['output']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  valuesSocial: Maybe<Scalars['String']['output']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  volumeWeight: Maybe<Scalars['Float']['output']>;
  /**
   * An array of websites in which the product is available.
   * @deprecated The field should not be used on the storefront.
   */
  websites: Maybe<Array<Maybe<Website>>>;
  /** The weight of the item, in units defined by the store. */
  weight: Maybe<Scalars['Float']['output']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  width: Maybe<Scalars['Float']['output']>;
  wishlistItemId: Maybe<Scalars['Int']['output']>;
};


/** Defines a simple product, which is tangible and is usually sold in single units or in fixed quantities. */
export type SimpleProductamastyRewardsHighlightsArgs = {
  attributes?: InputMaybe<Scalars['String']['input']>;
};


/** Defines a simple product, which is tangible and is usually sold in single units or in fixed quantities. */
export type SimpleProductlabelsArgs = {
  order?: InputMaybe<LabelOrderInput>;
};


/** Defines a simple product, which is tangible and is usually sold in single units or in fixed quantities. */
export type SimpleProductreviewsArgs = {
  currentPage?: InputMaybe<Scalars['Int']['input']>;
  filters?: InputMaybe<ReviewFilterInput>;
  pageSize?: InputMaybe<Scalars['Int']['input']>;
  sort?: InputMaybe<ReviewSortInput>;
};

/** Defines a single product to add to the cart. */
export type SimpleProductCartItemInput = {
  /** An array that defines customizable options for the product. */
  customizableOptions?: InputMaybe<Array<InputMaybe<CustomizableOptionInput>>>;
  /** An object containing the `sku`, `quantity`, and other relevant information about the product. */
  data: CartItemInput;
};

export type SimpleRowItem = {
  __typename: 'SimpleRowItem';
  gridArea: Scalars['String']['output'];
  key: Scalars['String']['output'];
  showTitle: Scalars['Boolean']['output'];
  style: Maybe<Scalars['String']['output']>;
  titleStyle: Maybe<Scalars['String']['output']>;
};

export enum SimpleUpdateTypeEnum {
  /** Added */
  ADDED = 'ADDED',
  /** Removed */
  REMOVED = 'REMOVED',
  /** Updated */
  UPDATED = 'UPDATED'
}

/** Contains a simple product wish list item. */
export type SimpleWishlistItem = WishlistItemInterface & {
  __typename: 'SimpleWishlistItem';
  /** The date and time the item was added to the wish list. */
  addedAt: Scalars['String']['output'];
  /** Custom options selected for the wish list item. */
  customizableOptions: Array<Maybe<SelectedCustomizableOption>>;
  /** The description of the item. */
  description: Maybe<Scalars['String']['output']>;
  /** The unique ID for a `WishlistItemInterface` object. */
  id: Scalars['ID']['output'];
  /** Product details of the wish list item. */
  product: Maybe<ProductInterface>;
  /** The quantity of this wish list item. */
  quantity: Scalars['Float']['output'];
};

/** Smart button payment inputs */
export type SmartButtonMethodInput = {
  /** The payment source for the payment method */
  paymentSource?: InputMaybe<Scalars['String']['input']>;
  /** The payment services order ID */
  paymentsOrderId?: InputMaybe<Scalars['String']['input']>;
  /** PayPal order ID */
  paypalOrderId?: InputMaybe<Scalars['String']['input']>;
};

export type SmartButtonsConfig = PaymentConfigItem & {
  __typename: 'SmartButtonsConfig';
  /** The styles for the PayPal Smart Button configuration */
  buttonStyles: Maybe<ButtonStyles>;
  /** The payment method code as defined in the payment gateway */
  code: Maybe<Scalars['String']['output']>;
  /** Indicates whether to display the PayPal Pay Later message */
  displayMessage: Maybe<Scalars['Boolean']['output']>;
  /** Indicates whether to display Venmo */
  displayVenmo: Maybe<Scalars['Boolean']['output']>;
  /** Indicates whether the payment method is displayed */
  isVisible: Maybe<Scalars['Boolean']['output']>;
  /** Contains details about the styles for the PayPal Pay Later message */
  messageStyles: Maybe<MessageStyles>;
  /** Defines the payment intent (Authorize or Capture */
  paymentIntent: Maybe<Scalars['String']['output']>;
  /** The PayPal parameters required to load the JS SDK */
  sdkParams: Maybe<Array<Maybe<SdkParams>>>;
  /** The relative order the payment method is displayed on the checkout page */
  sortOrder: Maybe<Scalars['String']['output']>;
  /** The name displayed for the payment method */
  title: Maybe<Scalars['String']['output']>;
};

/** Indicates whether to return results in ascending or descending order. */
export enum SortEnum {
  /** @deprecated  */
  ASC = 'ASC',
  /** @deprecated  */
  DESC = 'DESC'
}

/** Defines a possible sort field. */
export type SortField = {
  __typename: 'SortField';
  /** The label of the sort field. */
  label: Maybe<Scalars['String']['output']>;
  /** The attribute code of the sort field. */
  value: Maybe<Scalars['String']['output']>;
};

/** Contains a default value for sort fields and all available sort fields. */
export type SortFields = {
  __typename: 'SortFields';
  /** The default sort field value. */
  default: Maybe<Scalars['String']['output']>;
  /** An array of possible sort fields. */
  options: Maybe<Array<Maybe<SortField>>>;
};

export type Source = {
  __typename: 'Source';
  /** The source code */
  code: Maybe<Scalars['String']['output']>;
  /** The source name */
  name: Maybe<Scalars['String']['output']>;
};

export type SsoProvider = {
  __typename: 'SsoProvider';
  /** Indicates if the provider is active */
  active: Maybe<Scalars['Boolean']['output']>;
  /** The key ID */
  keyId: Maybe<Scalars['String']['output']>;
  /** The key secret */
  keySecret: Maybe<Scalars['String']['output']>;
  /** The provider name */
  name: Maybe<Scalars['String']['output']>;
  /** The provider */
  provider: Maybe<Scalars['String']['output']>;
  /** The provider scope */
  scope: Maybe<Scalars['String']['output']>;
  /** Indicates if the provider login button should be displayed */
  showLogin: Maybe<Scalars['Boolean']['output']>;
};

/** This enumeration defines the Single Sign-on (SSO) providers */
export enum SsoProviderEnum {
  /**
   * Apple
   * @deprecated
   */
  APPLE = 'APPLE',
  /**
   * Facebook
   * @deprecated
   */
  FACEBOOK = 'FACEBOOK',
  /**
   * Google
   * @deprecated
   */
  GOOGLE = 'GOOGLE',
  /**
   * Google One Tap
   * @deprecated
   */
  GOOGLE_ONE_TAP = 'GOOGLE_ONE_TAP',
  /**
   * Instagram
   * @deprecated
   */
  INSTAGRAM = 'INSTAGRAM',
  /**
   * LinkedIn
   * @deprecated
   */
  LINKEDIN = 'LINKEDIN',
  /**
   * Twitter
   * @deprecated
   */
  TWITTER = 'TWITTER'
}

export type StockInfo = {
  __typename: 'StockInfo';
  display: Maybe<Scalars['String']['output']>;
  qty: Maybe<Scalars['Float']['output']>;
  sources: Maybe<Array<Maybe<SiWarehouse>>>;
};

/** The type contains information about a store config */
export type StoreConfig = {
  __typename: 'StoreConfig';
  /** Contains scripts that must be included in the HTML before the closing `<body>` tag. */
  absoluteFooter: Maybe<Scalars['String']['output']>;
  /** The aggregation type overrides. */
  aggregationTypeOverrides: Maybe<Array<Maybe<AggregationTypeOverride>>>;
  /** Indicates whether guest users can write product reviews. Possible values: 1 (Yes) and 0 (No). */
  allowGuestsToWriteProductReviews: Maybe<Scalars['String']['output']>;
  /** The value of the Allow Gift Messages for Order Items option */
  allowItems: Maybe<Scalars['String']['output']>;
  /** The value of the Allow Gift Messages on Order Level option */
  allowOrder: Maybe<Scalars['String']['output']>;
  /** Customer account balance label */
  amrewardsCustomerBalanceLabel: Maybe<Scalars['String']['output']>;
  /** Show reward points description in customer account */
  amrewardsCustomerDescriptionEnable: Maybe<Scalars['Boolean']['output']>;
  /** Reward points description */
  amrewardsCustomerDescriptionMessage: Maybe<Scalars['String']['output']>;
  /** Show actual reward points balance on storefront */
  amrewardsCustomerShowBalance: Maybe<Scalars['Boolean']['output']>;
  /** Is enabled reward points */
  amrewardsGeneralEnabled: Maybe<Scalars['Boolean']['output']>;
  /** Show in cart */
  amrewardsHighlightCart: Maybe<Scalars['Boolean']['output']>;
  /** Show on categories pages */
  amrewardsHighlightCategory: Maybe<Scalars['Boolean']['output']>;
  /** Show on checkout page */
  amrewardsHighlightCheckout: Maybe<Scalars['Boolean']['output']>;
  /** Text color */
  amrewardsHighlightColor: Maybe<Scalars['String']['output']>;
  /** Show highlight for guests */
  amrewardsHighlightGuest: Maybe<Scalars['Boolean']['output']>;
  /** Show on product pages */
  amrewardsHighlightProduct: Maybe<Scalars['Boolean']['output']>;
  /** Send email when customer earns reward points */
  amrewardsNotificationSendEarn: Maybe<Scalars['Boolean']['output']>;
  /** Send email when customer's reward points are about to expire */
  amrewardsNotificationSendExpire: Maybe<Scalars['Boolean']['output']>;
  /** Subscribe customers by default to earn notifications */
  amrewardsNotificationSubscribeByDefaultToEarnNotifications: Maybe<Scalars['Boolean']['output']>;
  /** Subscribe customers by default to expire notifications */
  amrewardsNotificationSubscribeByDefaultToExpireNotifications: Maybe<Scalars['Boolean']['output']>;
  /** Minimum Points Requirement */
  amrewardsPointsMinimumReward: Maybe<Scalars['String']['output']>;
  /** Points spending rate */
  amrewardsPointsRate: Maybe<Scalars['Float']['output']>;
  /** Can show restriction message */
  amrewardsRestrictionMessageEnabled: Maybe<Scalars['Boolean']['output']>;
  /** Restriction message text */
  amrewardsRestrictionMessageText: Maybe<Scalars['String']['output']>;
  /** Add tooltip in reward point block */
  amrewardsSpendingBlockTooltipEnabled: Maybe<Scalars['Boolean']['output']>;
  /** Tooltip text */
  amrewardsSpendingBlockTooltipText: Maybe<Scalars['String']['output']>;
  /** Indicates whether to enable autocomplete on login and forgot password forms. */
  autocompleteOnStorefront: Maybe<Scalars['Boolean']['output']>;
  /** The base currency code. */
  baseCurrencyCode: Maybe<Scalars['String']['output']>;
  /** A fully-qualified URL that is used to create relative links to the `base_url`. */
  baseLinkUrl: Maybe<Scalars['String']['output']>;
  /** The fully-qualified URL that specifies the location of media files. */
  baseMediaUrl: Maybe<Scalars['String']['output']>;
  /** The fully-qualified URL that specifies the location of static view files. */
  baseStaticUrl: Maybe<Scalars['String']['output']>;
  /** The store’s fully-qualified base URL. */
  baseUrl: Maybe<Scalars['String']['output']>;
  /** Pargo Map URL */
  carriersPargoMapUrl: Maybe<Scalars['String']['output']>;
  /** The default sort order of the search results list. */
  catalogDefaultSortBy: Maybe<Scalars['String']['output']>;
  /** The title colour for a category. */
  categoryDefaultTitleColour: Maybe<Scalars['String']['output']>;
  /** The maximum number of filter options to display per filter. */
  categoryFilterMaxOptions: Maybe<Scalars['Int']['output']>;
  /** The suffix applied to category pages, such as `.htm` or `.html`. */
  categoryUrlSuffix: Maybe<Scalars['String']['output']>;
  /** Indicates whether only specific countries can use this payment method. */
  checkMoneyOrderEnableForSpecificCountries: Maybe<Scalars['Boolean']['output']>;
  /** Indicates whether the Check/Money Order payment method is enabled. */
  checkMoneyOrderEnabled: Maybe<Scalars['Boolean']['output']>;
  /** The name of the party to whom the check must be payable. */
  checkMoneyOrderMakeCheckPayableTo: Maybe<Scalars['String']['output']>;
  /** The maximum order amount required to qualify for the Check/Money Order payment method. */
  checkMoneyOrderMaxOrderTotal: Maybe<Scalars['String']['output']>;
  /** The minimum order amount required to qualify for the Check/Money Order payment method. */
  checkMoneyOrderMinOrderTotal: Maybe<Scalars['String']['output']>;
  /** The status of new orders placed using the Check/Money Order payment method. */
  checkMoneyOrderNewOrderStatus: Maybe<Scalars['String']['output']>;
  /** A comma-separated list of specific countries allowed to use the Check/Money Order payment method. */
  checkMoneyOrderPaymentFromSpecificCountries: Maybe<Scalars['String']['output']>;
  /** The full street address or PO Box where the checks are mailed. */
  checkMoneyOrderSendCheckTo: Maybe<Scalars['String']['output']>;
  /** A number indicating the position of the Check/Money Order payment method in the list of available payment methods during checkout. */
  checkMoneyOrderSortOrder: Maybe<Scalars['Int']['output']>;
  /** The title of the Check/Money Order payment method displayed on the storefront. */
  checkMoneyOrderTitle: Maybe<Scalars['String']['output']>;
  /** The name of the CMS page that identifies the home page for the store. */
  cmsHomePage: Maybe<Scalars['String']['output']>;
  /** A specific CMS page that displays when cookies are not enabled for the browser. */
  cmsNoCookies: Maybe<Scalars['String']['output']>;
  /** A specific CMS page that displays when a 404 'Page Not Found' error occurs. */
  cmsNoRoute: Maybe<Scalars['String']['output']>;
  /**
   * A code assigned to the store to identify it.
   * @deprecated Use `store_code` instead.
   */
  code: Maybe<Scalars['String']['output']>;
  /** Indicates whether the `parent` or child (`itself`) thumbnail should be used in the cart for configurable products. */
  configurableThumbnailSource: Maybe<Scalars['String']['output']>;
  /** Indicates whether the Contact Us form in enabled. */
  contactEnabled: Scalars['Boolean']['output'];
  /** The copyright statement that appears at the bottom of each page. */
  copyright: Maybe<Scalars['String']['output']>;
  /** The unique ID for a `CategoryInterface` object. */
  dealsRootCategoryUid: Maybe<Scalars['ID']['output']>;
  /** The description that provides a summary of your site for search engine listings. It should not be more than 160 characters in length. */
  defaultDescription: Maybe<Scalars['String']['output']>;
  /** The default display currency code. */
  defaultDisplayCurrencyCode: Maybe<Scalars['String']['output']>;
  /** A series of keywords that describe your store, each separated by a comma. */
  defaultKeywords: Maybe<Scalars['String']['output']>;
  /** The title that appears at the title bar of each page when viewed in a browser. */
  defaultTitle: Maybe<Scalars['String']['output']>;
  /** Controls the display of the demo store notice at the top of the page. Options: 0 (No) or 1 (Yes). */
  demonotice: Maybe<Scalars['Int']['output']>;
  /** The landing page that is associated with the base URL. */
  front: Maybe<Scalars['String']['output']>;
  /** Store City */
  generalStoreInformationCity: Maybe<Scalars['String']['output']>;
  /** Store Country */
  generalStoreInformationCountryId: Maybe<Scalars['String']['output']>;
  /** Store Hours of Operation */
  generalStoreInformationHours: Maybe<Scalars['String']['output']>;
  /** Store VAT Number */
  generalStoreInformationMerchantVatNumber: Maybe<Scalars['String']['output']>;
  /** Store Name */
  generalStoreInformationName: Maybe<Scalars['String']['output']>;
  /** Store Phone Number */
  generalStoreInformationPhone: Maybe<Scalars['String']['output']>;
  /** Store Postal Code */
  generalStoreInformationPostcode: Maybe<Scalars['String']['output']>;
  /** Store Region */
  generalStoreInformationRegionId: Maybe<Scalars['String']['output']>;
  /** Store Street Address Line 1 */
  generalStoreInformationStreetLine1: Maybe<Scalars['String']['output']>;
  /** Store Street Address Line 2 */
  generalStoreInformationStreetLine2: Maybe<Scalars['String']['output']>;
  /** The default number of products per page in Grid View. */
  gridPerPage: Maybe<Scalars['Int']['output']>;
  /** A list of numbers that define how many products can be displayed in Grid View. */
  gridPerPageValues: Maybe<Scalars['String']['output']>;
  /** Scripts that must be included in the HTML before the closing `<head>` tag. */
  headIncludes: Maybe<Scalars['String']['output']>;
  /** The small graphic image (favicon) that appears in the address bar and tab of the browser. */
  headShortcutIcon: Maybe<Scalars['String']['output']>;
  /** The path to the logo that appears in the header. */
  headerLogoSrc: Maybe<Scalars['String']['output']>;
  /**
   * The ID number assigned to the store.
   * @deprecated Use `store_code` instead.
   */
  id: Maybe<Scalars['Int']['output']>;
  /** Indicates whether the store view has been designated as the default within the store group. */
  isDefaultStore: Maybe<Scalars['Boolean']['output']>;
  /** Indicates whether the store group has been designated as the default within the website. */
  isDefaultStoreGroup: Maybe<Scalars['Boolean']['output']>;
  /** The format of the search results list. */
  listMode: Maybe<Scalars['String']['output']>;
  /** The default number of products per page in List View. */
  listPerPage: Maybe<Scalars['Int']['output']>;
  /** A list of numbers that define how many products can be displayed in List View. */
  listPerPageValues: Maybe<Scalars['String']['output']>;
  /** The store locale. */
  locale: Maybe<Scalars['String']['output']>;
  /** The Alt text that is associated with the logo. */
  logoAlt: Maybe<Scalars['String']['output']>;
  /** The height of the logo image, in pixels. */
  logoHeight: Maybe<Scalars['Int']['output']>;
  /** The width of the logo image, in pixels. */
  logoWidth: Maybe<Scalars['Int']['output']>;
  /** Indicates whether wishlists are enabled (1) or disabled (0). */
  magentoWishlistGeneralIsEnabled: Maybe<Scalars['String']['output']>;
  /** The minimum number of characters required for a valid password. */
  minimumPasswordLength: Maybe<Scalars['String']['output']>;
  /** Indicates whether newsletters are enabled. */
  newsletterEnabled: Scalars['Boolean']['output'];
  /** The default page that displays when a 404 'Page not Found' error occurs. */
  noRoute: Maybe<Scalars['String']['output']>;
  /** Indicates if the customer mobile OTP confirmation is required. If true you may use sendMobileVerification and verifyMobile mutations to verify the customer mobile number. */
  otpConfirmationRequired: Maybe<Scalars['Boolean']['output']>;
  /** Payflow Pro vault status. */
  paymentPayflowproCcVaultActive: Maybe<Scalars['String']['output']>;
  personalDiscoutsConfig: PersonalDiscountConfig;
  /** Is product review sorting active */
  productReviewSortingActive: Maybe<Scalars['Boolean']['output']>;
  /** Is product review voting active */
  productReviewVotingActive: Maybe<Scalars['Boolean']['output']>;
  /** Indicates whether product reviews are enabled. Possible values: 1 (Yes) and 0 (No). */
  productReviewsEnabled: Maybe<Scalars['String']['output']>;
  /** The suffix applied to product pages, such as `.htm` or `.html`. */
  productUrlSuffix: Maybe<Scalars['String']['output']>;
  /** The number of different character classes (lowercase, uppercase, digits, special characters) required in a password. */
  requiredCharacterClassesNumber: Maybe<Scalars['String']['output']>;
  /**
   * The ID of the root category.
   * @deprecated Use `root_category_uid` instead.
   */
  rootCategoryId: Maybe<Scalars['Int']['output']>;
  /** The unique ID for a `CategoryInterface` object. */
  rootCategoryUid: Maybe<Scalars['ID']['output']>;
  /** A secure fully-qualified URL that is used to create relative links to the `base_url`. */
  secureBaseLinkUrl: Maybe<Scalars['String']['output']>;
  /** The secure fully-qualified URL that specifies the location of media files. */
  secureBaseMediaUrl: Maybe<Scalars['String']['output']>;
  /** The secure fully-qualified URL that specifies the location of static view files. */
  secureBaseStaticUrl: Maybe<Scalars['String']['output']>;
  /** The store’s fully-qualified secure base URL. */
  secureBaseUrl: Maybe<Scalars['String']['output']>;
  /** Email to a Friend configuration. */
  sendFriend: Maybe<SendFriendConfiguration>;
  /** Indicates whether a breadcrumb trail appears on all CMS pages in the catalog. 0 (No) or 1 (Yes). */
  showCmsBreadcrumbs: Maybe<Scalars['Int']['output']>;
  /** Single Sign-on (SSO) provider settings */
  ssoProviders: Maybe<Array<Maybe<SsoProvider>>>;
  /** The unique ID of the store view. In the Admin, this is called the Store View Code. When making a GraphQL call, assign this value to the `Store` header to provide the scope. */
  storeCode: Maybe<Scalars['ID']['output']>;
  /** The unique ID assigned to the store group. In the Admin, this is called the Store Name. */
  storeGroupCode: Maybe<Scalars['ID']['output']>;
  /** The label assigned to the store group. */
  storeGroupName: Maybe<Scalars['String']['output']>;
  /** The label assigned to the store view. */
  storeName: Maybe<Scalars['String']['output']>;
  /** The store view sort order. */
  storeSortOrder: Maybe<Scalars['Int']['output']>;
  /** The time zone of the store. */
  timezone: Maybe<Scalars['String']['output']>;
  /** A prefix that appears before the title to create a two- or three-part title. */
  titlePrefix: Maybe<Scalars['String']['output']>;
  /** The character that separates the category name and subcategory in the browser title bar. */
  titleSeparator: Maybe<Scalars['String']['output']>;
  /** A suffix that appears after the title to create a two- or three-part title. */
  titleSuffix: Maybe<Scalars['String']['output']>;
  /** General Contact Email */
  transEmailIdentGeneralEmail: Maybe<Scalars['String']['output']>;
  /** General Contact Name */
  transEmailIdentGeneralName: Maybe<Scalars['String']['output']>;
  /** Sales Representative Email */
  transEmailIdentSalesEmail: Maybe<Scalars['String']['output']>;
  /** Sales Representative Name */
  transEmailIdentSalesName: Maybe<Scalars['String']['output']>;
  /** Customer Support Email */
  transEmailIdentSupportEmail: Maybe<Scalars['String']['output']>;
  /** Customer Support Name */
  transEmailIdentSupportName: Maybe<Scalars['String']['output']>;
  /** Indicates whether the store code should be used in the URL. */
  useStoreInUrl: Maybe<Scalars['Boolean']['output']>;
  /** The unique ID for the website. */
  websiteCode: Maybe<Scalars['ID']['output']>;
  /**
   * The ID number assigned to the website store.
   * @deprecated The field should not be used on the storefront.
   */
  websiteId: Maybe<Scalars['Int']['output']>;
  /** The label assigned to the website. */
  websiteName: Maybe<Scalars['String']['output']>;
  /** The unit of weight. */
  weightUnit: Maybe<Scalars['String']['output']>;
  /** Text that appears in the header of the page and includes the name of the logged in customer. */
  welcome: Maybe<Scalars['String']['output']>;
  /** Indicates whether only specific countries can use this payment method. */
  zeroSubtotalEnableForSpecificCountries: Maybe<Scalars['Boolean']['output']>;
  /** Indicates whether the Zero Subtotal payment method is enabled. */
  zeroSubtotalEnabled: Maybe<Scalars['Boolean']['output']>;
  /** The status of new orders placed using the Zero Subtotal payment method. */
  zeroSubtotalNewOrderStatus: Maybe<Scalars['String']['output']>;
  /** When the new order status is 'Processing', this can be set to `authorize_capture` to automatically invoice all items that have a zero balance. */
  zeroSubtotalPaymentAction: Maybe<Scalars['String']['output']>;
  /** A comma-separated list of specific countries allowed to use the Zero Subtotal payment method. */
  zeroSubtotalPaymentFromSpecificCountries: Maybe<Scalars['String']['output']>;
  /** A number indicating the position of the Zero Subtotal payment method in the list of available payment methods during checkout. */
  zeroSubtotalSortOrder: Maybe<Scalars['Int']['output']>;
  /** The title of the Zero Subtotal payment method displayed on the storefront. */
  zeroSubtotalTitle: Maybe<Scalars['String']['output']>;
};

/** Indicates where an attribute can be displayed. */
export type StorefrontProperties = {
  __typename: 'StorefrontProperties';
  /** The relative position of the attribute in the layered navigation block. */
  position: Maybe<Scalars['Int']['output']>;
  /** Indicates whether the attribute is filterable with results, without results, or not at all. */
  useInLayeredNavigation: Maybe<UseInLayeredNavigationOptions>;
  /** Indicates whether the attribute is displayed in product listings. */
  useInProductListing: Maybe<Scalars['Boolean']['output']>;
  /** Indicates whether the attribute can be used in layered navigation on search results pages. */
  useInSearchResultsLayeredNavigation: Maybe<Scalars['Boolean']['output']>;
  /** Indicates whether the attribute is displayed on product pages. */
  visibleOnCatalogPages: Maybe<Scalars['Boolean']['output']>;
};

export type StringEqualsInRangeFilterInput = {
  doesNotEqual?: InputMaybe<Scalars['String']['input']>;
  equals?: InputMaybe<Scalars['String']['input']>;
  greaterThan?: InputMaybe<Scalars['String']['input']>;
  in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  lessThan?: InputMaybe<Scalars['String']['input']>;
  notIn?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export enum StringTransformTypeEnum {
  /** Camel Case */
  CAMEL_CASE = 'CAMEL_CASE',
  /** Constant Case */
  CONSTANT_CASE = 'CONSTANT_CASE',
  /** Dot Case */
  DOT_CASE = 'DOT_CASE',
  /** Header Case */
  HEADER_CASE = 'HEADER_CASE',
  /** Lower Case */
  LOWER_CASE = 'LOWER_CASE',
  /** Param Case */
  PARAM_CASE = 'PARAM_CASE',
  /** Pascal Case */
  PASCAL_CASE = 'PASCAL_CASE',
  /** Path Case */
  PATH_CASE = 'PATH_CASE',
  /** Sentence Case */
  SENTENCE_CASE = 'SENTENCE_CASE',
  /** Snake Case */
  SNAKE_CASE = 'SNAKE_CASE',
  /** Upper Case */
  UPPER_CASE = 'UPPER_CASE'
}

/** Contains the result of the `subscribeEmailToNewsletter` operation. */
export type SubscribeEmailToNewsletterOutput = {
  __typename: 'SubscribeEmailToNewsletterOutput';
  /** The status of the subscription request. */
  status: Maybe<SubscriptionStatusesEnum>;
};

export type Subscription = {
  __typename: 'Subscription';
  activeWidgetsChanged: Maybe<Widget>;
  categoryChange: Maybe<CategoryTree>;
  categoryTreeChange: Maybe<CategoryTree>;
  clientRefresh: ClientReloadTypeEnum;
  pageChange: Maybe<Page>;
  productChange: Maybe<ProductInterface>;
  watchAllContents: ContentUpdates;
  watchAllImages: ImageUpdates;
  watchAllPageAccordionSections: PageAccordionSectionUpdates;
  watchAllPageBannerCategorys: PageBannerCategoryUpdates;
  watchAllPageBannerSections: PageBannerSectionUpdates;
  watchAllPageBanners: PageBannerUpdates;
  watchAllPageBlockSections: PageBlockSectionUpdates;
  watchAllPageCarouselSections: PageCarouselSectionUpdates;
  watchAllPageChipSections: PageChipSectionUpdates;
  watchAllPageExcerptSections: PageExcerptSectionUpdates;
  watchAllPageHeadingSections: PageHeadingSectionUpdates;
  watchAllPageHeroSections: PageHeroSectionUpdates;
  watchAllPageIconSections: PageIconSectionUpdates;
  watchAllPageLinkSections: PageLinkSectionUpdates;
  watchAllPageMarkupSections: PageMarkupSectionUpdates;
  watchAllPageMediaSections: PageMediaSectionUpdates;
  watchAllPageMyDiscountsSections: PageMyDiscountsSectionUpdates;
  watchAllPageMyShopSections: PageMyShopSectionUpdates;
  watchAllPageNotificationSections: PageNotificationSectionUpdates;
  watchAllPagePlainTextSections: PagePlainTextSectionUpdates;
  watchAllPageProductSliderSections: PageProductSliderSectionUpdates;
  watchAllPageQuestionGroups: PageQuestionGroupUpdates;
  watchAllPageQuestionSections: PageQuestionSectionUpdates;
  watchAllPageSectionGroups: PageSectionGroupUpdates;
  watchAllPageSections: PageSectionUpdates;
  watchAllPageSiteNoticeSections: PageSiteNoticeSectionUpdates;
  watchAllPageSplitTextSections: PageSplitTextSectionUpdates;
  watchAllPageTimetableSections: PageTimetableSectionUpdates;
  watchAllPages: PageUpdates;
  watchAllWidgetContactUss: WidgetContactUsUpdates;
  watchAllWidgetDropDownCarts: WidgetDropDownCartUpdates;
  watchAllWidgetProductDetailsAddToCarts: WidgetProductDetailsAddToCartUpdates;
  watchAllWidgetSiteWideBanners: WidgetSiteWideBannerUpdates;
  watchAllWidgets: WidgetUpdates;
  watchOneContent: ContentUpdates;
  watchOneImage: ImageUpdates;
  watchOnePage: PageUpdates;
  watchOnePageAccordionSection: PageAccordionSectionUpdates;
  watchOnePageBanner: PageBannerUpdates;
  watchOnePageBannerCategory: PageBannerCategoryUpdates;
  watchOnePageBannerSection: PageBannerSectionUpdates;
  watchOnePageBlockSection: PageBlockSectionUpdates;
  watchOnePageCarouselSection: PageCarouselSectionUpdates;
  watchOnePageChipSection: PageChipSectionUpdates;
  watchOnePageExcerptSection: PageExcerptSectionUpdates;
  watchOnePageHeadingSection: PageHeadingSectionUpdates;
  watchOnePageHeroSection: PageHeroSectionUpdates;
  watchOnePageIconSection: PageIconSectionUpdates;
  watchOnePageLinkSection: PageLinkSectionUpdates;
  watchOnePageMarkupSection: PageMarkupSectionUpdates;
  watchOnePageMediaSection: PageMediaSectionUpdates;
  watchOnePageMyDiscountsSection: PageMyDiscountsSectionUpdates;
  watchOnePageMyShopSection: PageMyShopSectionUpdates;
  watchOnePageNotificationSection: PageNotificationSectionUpdates;
  watchOnePagePlainTextSection: PagePlainTextSectionUpdates;
  watchOnePageProductSliderSection: PageProductSliderSectionUpdates;
  watchOnePageQuestionGroup: PageQuestionGroupUpdates;
  watchOnePageQuestionSection: PageQuestionSectionUpdates;
  watchOnePageSection: PageSectionUpdates;
  watchOnePageSectionGroup: PageSectionGroupUpdates;
  watchOnePageSiteNoticeSection: PageSiteNoticeSectionUpdates;
  watchOnePageSplitTextSection: PageSplitTextSectionUpdates;
  watchOnePageTimetableSection: PageTimetableSectionUpdates;
  watchOneWidget: WidgetUpdates;
  watchOneWidgetContactUs: WidgetContactUsUpdates;
  watchOneWidgetDropDownCart: WidgetDropDownCartUpdates;
  watchOneWidgetProductDetailsAddToCart: WidgetProductDetailsAddToCartUpdates;
  watchOneWidgetSiteWideBanner: WidgetSiteWideBannerUpdates;
};


export type SubscriptioncategoryChangeArgs = {
  id?: InputMaybe<Scalars['String']['input']>;
};


export type SubscriptionproductChangeArgs = {
  id?: InputMaybe<Scalars['String']['input']>;
};


export type SubscriptionwatchOneContentArgs = {
  id: Scalars['ID']['input'];
};


export type SubscriptionwatchOneImageArgs = {
  id: Scalars['ID']['input'];
};


export type SubscriptionwatchOnePageArgs = {
  id: Scalars['ID']['input'];
};


export type SubscriptionwatchOnePageAccordionSectionArgs = {
  id: Scalars['ID']['input'];
};


export type SubscriptionwatchOnePageBannerArgs = {
  id: Scalars['ID']['input'];
};


export type SubscriptionwatchOnePageBannerCategoryArgs = {
  id: Scalars['ID']['input'];
};


export type SubscriptionwatchOnePageBannerSectionArgs = {
  id: Scalars['ID']['input'];
};


export type SubscriptionwatchOnePageBlockSectionArgs = {
  id: Scalars['ID']['input'];
};


export type SubscriptionwatchOnePageCarouselSectionArgs = {
  id: Scalars['ID']['input'];
};


export type SubscriptionwatchOnePageChipSectionArgs = {
  id: Scalars['ID']['input'];
};


export type SubscriptionwatchOnePageExcerptSectionArgs = {
  id: Scalars['ID']['input'];
};


export type SubscriptionwatchOnePageHeadingSectionArgs = {
  id: Scalars['ID']['input'];
};


export type SubscriptionwatchOnePageHeroSectionArgs = {
  id: Scalars['ID']['input'];
};


export type SubscriptionwatchOnePageIconSectionArgs = {
  id: Scalars['ID']['input'];
};


export type SubscriptionwatchOnePageLinkSectionArgs = {
  id: Scalars['ID']['input'];
};


export type SubscriptionwatchOnePageMarkupSectionArgs = {
  id: Scalars['ID']['input'];
};


export type SubscriptionwatchOnePageMediaSectionArgs = {
  id: Scalars['ID']['input'];
};


export type SubscriptionwatchOnePageMyDiscountsSectionArgs = {
  id: Scalars['ID']['input'];
};


export type SubscriptionwatchOnePageMyShopSectionArgs = {
  id: Scalars['ID']['input'];
};


export type SubscriptionwatchOnePageNotificationSectionArgs = {
  id: Scalars['ID']['input'];
};


export type SubscriptionwatchOnePagePlainTextSectionArgs = {
  id: Scalars['ID']['input'];
};


export type SubscriptionwatchOnePageProductSliderSectionArgs = {
  id: Scalars['ID']['input'];
};


export type SubscriptionwatchOnePageQuestionGroupArgs = {
  id: Scalars['ID']['input'];
};


export type SubscriptionwatchOnePageQuestionSectionArgs = {
  id: Scalars['ID']['input'];
};


export type SubscriptionwatchOnePageSectionArgs = {
  id: Scalars['ID']['input'];
};


export type SubscriptionwatchOnePageSectionGroupArgs = {
  id: Scalars['ID']['input'];
};


export type SubscriptionwatchOnePageSiteNoticeSectionArgs = {
  id: Scalars['ID']['input'];
};


export type SubscriptionwatchOnePageSplitTextSectionArgs = {
  id: Scalars['ID']['input'];
};


export type SubscriptionwatchOnePageTimetableSectionArgs = {
  id: Scalars['ID']['input'];
};


export type SubscriptionwatchOneWidgetArgs = {
  id: Scalars['ID']['input'];
};


export type SubscriptionwatchOneWidgetContactUsArgs = {
  id: Scalars['ID']['input'];
};


export type SubscriptionwatchOneWidgetDropDownCartArgs = {
  id: Scalars['ID']['input'];
};


export type SubscriptionwatchOneWidgetProductDetailsAddToCartArgs = {
  id: Scalars['ID']['input'];
};


export type SubscriptionwatchOneWidgetSiteWideBannerArgs = {
  id: Scalars['ID']['input'];
};

/** Indicates the status of the request. */
export enum SubscriptionStatusesEnum {
  /** @deprecated  */
  NOT_ACTIVE = 'NOT_ACTIVE',
  /** @deprecated  */
  SUBSCRIBED = 'SUBSCRIBED',
  /** @deprecated  */
  UNCONFIRMED = 'UNCONFIRMED',
  /** @deprecated  */
  UNSUBSCRIBED = 'UNSUBSCRIBED'
}

/** Describes the swatch type and a value. */
export type SwatchData = {
  __typename: 'SwatchData';
  /** The type of swatch filter item: 1 - text; 2 - image. */
  type: Maybe<Scalars['String']['output']>;
  /** The value for the swatch item. It could be text or an image link. */
  value: Maybe<Scalars['String']['output']>;
};

export type SwatchDataInterface = {
  /** The value can be represented as color (HEX code), image link, or text. */
  value: Maybe<Scalars['String']['output']>;
};

export type SwatchLayerFilterItem = LayerFilterItemInterface & SwatchLayerFilterItemInterface & {
  __typename: 'SwatchLayerFilterItem';
  /**
   * The count of items per filter.
   * @deprecated Use `AggregationOption.count` instead.
   */
  itemsCount: Maybe<Scalars['Int']['output']>;
  /**
   * The label for a filter.
   * @deprecated Use `AggregationOption.label` instead.
   */
  label: Maybe<Scalars['String']['output']>;
  /** Data required to render a swatch filter item. */
  swatchData: Maybe<SwatchData>;
  /**
   * The value of a filter request variable to be used in query.
   * @deprecated Use `AggregationOption.value` instead.
   */
  valueString: Maybe<Scalars['String']['output']>;
};

export type SwatchLayerFilterItemInterface = {
  /** Data required to render a swatch filter item. */
  swatchData: Maybe<SwatchData>;
};

/** Synchronizes the payment order details */
export type SyncPaymentOrderInput = {
  /** The customer cart ID */
  cartId: Scalars['String']['input'];
  /** PayPal order ID */
  id: Scalars['String']['input'];
};

/** Contains tax item details. */
export type TaxItem = {
  __typename: 'TaxItem';
  /** The amount of tax applied to the item. */
  amount: Money;
  /** The rate used to calculate the tax. */
  rate: Scalars['Float']['output'];
  /** A title that describes the tax. */
  title: Scalars['String']['output'];
};

export type TermRecommendationInterface = {
  /** Filter value. */
  count: Scalars['String']['output'];
  /** Recommended search term. */
  name: Maybe<Scalars['String']['output']>;
};

/** Recommendations of terms for a given query text. */
export type TermRecommendations = {
  __typename: 'TermRecommendations';
  /** An array of terms recommended for this query text. */
  items: Maybe<Array<Maybe<TermRecommendationInterface>>>;
};

export type TextSwatchData = SwatchDataInterface & {
  __typename: 'TextSwatchData';
  /** The value can be represented as color (HEX code), image link, or text. */
  value: Maybe<Scalars['String']['output']>;
};

export type ThreeDSecureInfo = {
  __typename: 'ThreeDSecureInfo';
  canSkip_3ds: Maybe<Scalars['Boolean']['output']>;
  params: Maybe<ThreeDSecureParams>;
  redirectUrl: Maybe<Scalars['String']['output']>;
};

/** ThreeDSecure details from the 3ds iframe returned data */
export type ThreeDSecureInput = {
  acsTransactionId?: InputMaybe<Scalars['String']['input']>;
  dsTransactionId?: InputMaybe<Scalars['String']['input']>;
  eci?: InputMaybe<Scalars['String']['input']>;
  paRes?: InputMaybe<Scalars['String']['input']>;
  verificationId?: InputMaybe<Scalars['String']['input']>;
  xid?: InputMaybe<Scalars['String']['input']>;
};

export type ThreeDSecureParams = {
  __typename: 'ThreeDSecureParams';
  connector: Maybe<Scalars['String']['output']>;
  md: Maybe<Scalars['ID']['output']>;
  paReq: Maybe<Scalars['String']['output']>;
  termUrl: Maybe<Scalars['String']['output']>;
};

/** Defines a price based on the quantity purchased. */
export type TierPrice = {
  __typename: 'TierPrice';
  /** The price discount that this tier represents. */
  discount: Maybe<ProductDiscount>;
  /** The price of the product at this tier. */
  finalPrice: Maybe<Money>;
  /** The minimum number of items that must be purchased to qualify for this price tier. */
  quantity: Maybe<Scalars['Float']['output']>;
};

export enum UnitTypeEnum {
  /** Gram */
  G = 'G',
  /** Kilogram */
  KG = 'KG',
  /** Litre */
  L = 'L',
  /** Milligram */
  MG = 'MG',
  /** Milliliter */
  ML = 'ML',
  /** Pinch */
  PINCH = 'PINCH',
  /** Portion */
  PORTION = 'PORTION',
  /** Table Spoon */
  TBSP = 'TBSP',
  /** Tea Spoon */
  TSP = 'TSP',
  /** Unit */
  UNIT = 'UNIT'
}

/** Modifies the specified items in the cart. */
export type UpdateCartItemsInput = {
  /** The unique ID of a `Cart` object. */
  cartId: Scalars['String']['input'];
  /** An array of items to be updated. */
  cartItems: Array<InputMaybe<CartItemUpdateInput>>;
};

/** Contains details about the cart after updating items. */
export type UpdateCartItemsOutput = {
  __typename: 'UpdateCartItemsOutput';
  /** The cart after updating products. */
  cart: Cart;
};

/** Contains the customer's wish list and any errors encountered. */
export type UpdateProductsInWishlistOutput = {
  __typename: 'UpdateProductsInWishlistOutput';
  /** An array of errors encountered while updating products in a wish list. */
  userErrors: Array<Maybe<WishListUserInputError>>;
  /** Contains the wish list with all items that were successfully updated. */
  wishlist: Wishlist;
};

/** Contains URL rewrite details. */
export type UrlRewrite = {
  __typename: 'UrlRewrite';
  /** An array of request parameters. */
  parameters: Maybe<Array<Maybe<HttpQueryParameter>>>;
  /** The request URL. */
  url: Maybe<Scalars['String']['output']>;
};

/** This enumeration defines the entity type. */
export enum UrlRewriteEntityTypeEnum {
  /** @deprecated  */
  CATEGORY = 'CATEGORY',
  /** @deprecated  */
  CMS_PAGE = 'CMS_PAGE',
  /** @deprecated  */
  PRODUCT = 'PRODUCT'
}

/** Defines whether the attribute is filterable in layered navigation. */
export enum UseInLayeredNavigationOptions {
  /** @deprecated  */
  FILTERABLE_NO_RESULT = 'FILTERABLE_NO_RESULT',
  /** @deprecated  */
  FILTERABLE_WITH_RESULTS = 'FILTERABLE_WITH_RESULTS',
  /** @deprecated  */
  NO = 'NO'
}

/** Defines the input required to run the useRewardPoints mutation */
export type UseRewardPointsInput = {
  /** The unique ID that identifies the customer's cart */
  cartId: Scalars['String']['input'];
  /** Reward points to spent */
  points: Scalars['Float']['input'];
};

export type UseRewardPointsOutput = {
  __typename: 'UseRewardPointsOutput';
  /** Describes the contents of the specified shopping cart */
  cart: Cart;
  /** Mutation response */
  response: Maybe<Scalars['String']['output']>;
};

export type UsedPointsOutput = {
  __typename: 'UsedPointsOutput';
  /** Used points. */
  usedPoints: Maybe<Scalars['Float']['output']>;
};

export type ValidateLoginAsCustomerInput = {
  /** Its a secret */
  secret: Scalars['String']['input'];
};

/** Contains the customer token. */
export type ValidateLoginAsCustomerOutput = {
  __typename: 'ValidateLoginAsCustomerOutput';
  /** The customer token. */
  customerToken: Scalars['String']['output'];
};

/** Defines a customer attribute validation rule. */
export type ValidationRule = {
  __typename: 'ValidationRule';
  /** Validation rule name applied to a customer attribute. */
  name: Maybe<ValidationRuleEnum>;
  /** Validation rule value. */
  value: Maybe<Scalars['String']['output']>;
};

/** List of validation rule names applied to a customer attribute. */
export enum ValidationRuleEnum {
  /** @deprecated  */
  DATE_RANGE_MAX = 'DATE_RANGE_MAX',
  /** @deprecated  */
  DATE_RANGE_MIN = 'DATE_RANGE_MIN',
  /** @deprecated  */
  FILE_EXTENSIONS = 'FILE_EXTENSIONS',
  /** @deprecated  */
  INPUT_VALIDATION = 'INPUT_VALIDATION',
  /** @deprecated  */
  MAX_FILE_SIZE = 'MAX_FILE_SIZE',
  /** @deprecated  */
  MAX_IMAGE_HEIGHT = 'MAX_IMAGE_HEIGHT',
  /** @deprecated  */
  MAX_IMAGE_WIDTH = 'MAX_IMAGE_WIDTH',
  /** @deprecated  */
  MAX_TEXT_LENGTH = 'MAX_TEXT_LENGTH',
  /** @deprecated  */
  MIN_TEXT_LENGTH = 'MIN_TEXT_LENGTH'
}

/** Vault payment inputs */
export type VaultMethodInput = {
  /** The payment source for the payment method */
  paymentSource?: InputMaybe<Scalars['String']['input']>;
  /** The payment services order ID */
  paymentsOrderId?: InputMaybe<Scalars['String']['input']>;
  /** PayPal order ID */
  paypalOrderId?: InputMaybe<Scalars['String']['input']>;
  /** The public hash of the token. */
  publicHash?: InputMaybe<Scalars['String']['input']>;
};

/** Contains required input for payment methods with Vault support. */
export type VaultTokenInput = {
  /** The public hash of the payment token. */
  publicHash: Scalars['String']['input'];
};

export type VerifyMobileInput = {
  /** The customer's mobile number */
  mobile: Scalars['String']['input'];
  /** The OTP verification code */
  otp: Scalars['String']['input'];
};

/** Contains the mobile verification status. */
export type VerifyMobileOutput = {
  __typename: 'VerifyMobileOutput';
  /** Indicates if the mobile verification code is verified */
  verified: Scalars['Boolean']['output'];
  /** The number of verification attempts remaining */
  verifyAttemptsRemaining: Scalars['Int']['output'];
};

/** The Products object is the top-level object returned in a product search. */
export type ViewMoreResult = {
  __typename: 'ViewMoreResult';
  /** Layered navigation aggregations. */
  aggregations: Maybe<Array<Maybe<Aggregation>>>;
};

/** An implementation for virtual product cart items. */
export type VirtualCartItem = CartItemInterface & {
  __typename: 'VirtualCartItem';
  /** An array containing customizable options the shopper selected. */
  customizableOptions: Array<Maybe<SelectedCustomizableOption>>;
  /** An array of errors encountered while loading the cart item */
  errors: Maybe<Array<Maybe<CartItemError>>>;
  /** @deprecated Use `uid` instead. */
  id: Scalars['String']['output'];
  /** Contains details about the price of the item, including taxes and discounts. */
  prices: Maybe<CartItemPrices>;
  /** Details about an item in the cart. */
  product: ProductInterface;
  /** The quantity of this item in the cart. */
  quantity: Scalars['Float']['output'];
  /** The unique ID for a `CartItemInterface` object. */
  uid: Scalars['ID']['output'];
};

/** Defines a virtual product, which is a non-tangible product that does not require shipping and is not kept in inventory. */
export type VirtualProduct = CustomizableProductInterface & ProductInterface & RoutableInterface & {
  __typename: 'VirtualProduct';
  /** @deprecated Use the `custom_attributes` field instead. */
  age: Maybe<Scalars['String']['output']>;
  /** Information about guest highlight points available by product. */
  amastyRewardsGuestHighlights: Maybe<HighlightOutput>;
  /** Information about highlight points available by product. */
  amastyRewardsHighlights: Maybe<HighlightOutput>;
  /**
   * The attribute set assigned to the product.
   * @deprecated The field should not be used on the storefront.
   */
  attributeSetId: Maybe<Scalars['Int']['output']>;
  /** Returns all assigned product attributes and their values */
  attributes: Array<Maybe<ProductAttributes>>;
  /** Returns the brand of the product */
  brand: Maybe<Brand>;
  cacheBuster: Maybe<Scalars['String']['output']>;
  /** The relative canonical URL. This value is returned only if the system setting 'Use Canonical Link Meta Tag For Products' is enabled. */
  canonicalUrl: Maybe<Scalars['String']['output']>;
  cartItemUid: Maybe<Scalars['String']['output']>;
  /** The categories assigned to a product. */
  categories: Maybe<Array<Maybe<CategoryInterface>>>;
  /** @deprecated Use the `custom_attributes` field instead. */
  colour: Maybe<Scalars['Int']['output']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  concentration: Maybe<Scalars['Int']['output']>;
  /** The product's country of origin. */
  countryOfManufacture: Maybe<Scalars['String']['output']>;
  coverImage: Image;
  /**
   * Timestamp indicating when the product was created.
   * @deprecated The field should not be used on the storefront.
   */
  createdAt: Maybe<Scalars['String']['output']>;
  /** Crosssell Products */
  crosssellProducts: Maybe<Array<Maybe<ProductInterface>>>;
  /** @deprecated Use the `custom_attributes` field instead. */
  department: Maybe<Scalars['String']['output']>;
  /** Detailed information about the product. The value can include simple HTML tags. */
  description: Maybe<ComplexTextValue>;
  /** @deprecated Use the `custom_attributes` field instead. */
  flavour: Maybe<Scalars['Int']['output']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  fragrance: Maybe<Scalars['Int']['output']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  gender: Maybe<Scalars['String']['output']>;
  /** Indicates whether a gift message is available. */
  giftMessageAvailable: Maybe<Scalars['String']['output']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  hairType: Maybe<Scalars['String']['output']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  height: Maybe<Scalars['Float']['output']>;
  /**
   * The ID number assigned to the product.
   * @deprecated Use the `uid` field instead.
   */
  id: Maybe<Scalars['Int']['output']>;
  /** The relative path to the main image on the product page. */
  image: Maybe<ProductImage>;
  imageGallery: Array<Image>;
  ingredientList: Maybe<Array<Maybe<ProductIngredient>>>;
  /** @deprecated Use the `custom_attributes` field instead. */
  ingredients: Maybe<Scalars['String']['output']>;
  isInWishlist: Scalars['Boolean']['output'];
  /** List of product labels associated to the product */
  labels: Array<Maybe<LabelItem>>;
  /** @deprecated Use the `custom_attributes` field instead. */
  length: Maybe<Scalars['Float']['output']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  licensingStatus: Maybe<Scalars['String']['output']>;
  /**
   * A number representing the product's manufacturer.
   * @deprecated Use the `custom_attributes` field instead.
   */
  manufacturer: Maybe<Scalars['Int']['output']>;
  /** An array of media gallery objects. */
  mediaGallery: Maybe<Array<Maybe<MediaGalleryInterface>>>;
  /**
   * An array of MediaGalleryEntry objects.
   * @deprecated Use `media_gallery` instead.
   */
  mediaGalleryEntries: Maybe<Array<Maybe<MediaGalleryEntry>>>;
  /** An array of media gallery objects. */
  mediaGalleryV2: Maybe<Array<Maybe<MediaGalleryV2Interface>>>;
  /** A brief overview of the product for search results listings, maximum 255 characters. */
  metaDescription: Maybe<Scalars['String']['output']>;
  /** A comma-separated list of keywords that are visible only to search engines. */
  metaKeyword: Maybe<Scalars['String']['output']>;
  /** A string that is displayed in the title bar and tab of the browser and in search results lists. */
  metaTitle: Maybe<Scalars['String']['output']>;
  /** The product name. Customers use this name to identify the product. */
  name: Maybe<Scalars['String']['output']>;
  /** The beginning date for new product listings, and determines if the product is featured as a new product. */
  newFromDate: Maybe<Scalars['String']['output']>;
  /** The end date for new product listings. */
  newToDate: Maybe<Scalars['String']['output']>;
  /** Product stock only x left count */
  onlyXLeftInStock: Maybe<Scalars['Float']['output']>;
  /** An array of options for a customizable product. */
  options: Maybe<Array<Maybe<CustomizableOptionInterface>>>;
  /** If the product has multiple options, determines where they appear on the product page. */
  optionsContainer: Maybe<Scalars['String']['output']>;
  /**
   * Indicates the price of an item.
   * @deprecated Use `price_range` for product price information.
   */
  price: Maybe<ProductPrices>;
  /** The range of prices for the product */
  priceRange: PriceRange;
  /** An array of `TierPrice` objects. */
  priceTiers: Maybe<Array<Maybe<TierPrice>>>;
  /** An array of `ProductLinks` objects. */
  productLinks: Maybe<Array<Maybe<ProductLinksInterface>>>;
  productType: ProductTypeEnum;
  /** @deprecated Use the `custom_attributes` field instead. */
  quantity: Maybe<Scalars['Int']['output']>;
  quantityInCart: Scalars['Int']['output'];
  /** The rating summary for the product */
  ratingSummary: RatingSummary;
  /** Contains 0 when there is no redirect error. A value of 301 indicates the URL of the requested resource has been changed permanently, while a value of 302 indicates a temporary redirect. */
  redirectCode: Scalars['Int']['output'];
  /** Related Products */
  relatedProducts: Maybe<Array<Maybe<ProductInterface>>>;
  /** The internal relative URL. If the specified URL is a redirect, the query returns the redirected URL, not the original. */
  relativeUrl: Maybe<Scalars['String']['output']>;
  /** The total count of all the reviews given to the product. */
  reviewCount: Scalars['Int']['output'];
  /** The list of products reviews */
  reviews: ProductReviews;
  /** A short description of the product. Its use depends on the theme. */
  shortDescription: Maybe<ComplexTextValue>;
  /** @deprecated Use the `custom_attributes` field instead. */
  size: Maybe<Scalars['Int']['output']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  skinConcern: Maybe<Scalars['String']['output']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  skinType: Maybe<Scalars['String']['output']>;
  /** A number or code assigned to a product to identify the product, options, price, and manufacturer. */
  sku: Maybe<Scalars['String']['output']>;
  /** The relative path to the small image, which is used on catalog pages. */
  smallImage: Maybe<ProductImage>;
  /**
   * The beginning date that a product has a special price.
   * @deprecated The field should not be used on the storefront.
   */
  specialFromDate: Maybe<Scalars['String']['output']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  specialIcons: Maybe<Scalars['String']['output']>;
  /** The discounted price of the product. */
  specialPrice: Maybe<Scalars['Float']['output']>;
  /** The end date for a product with a special price. */
  specialToDate: Maybe<Scalars['String']['output']>;
  stockInfo: Maybe<StockInfo>;
  /** Stock status of the product */
  stockStatus: Maybe<ProductStockStatus>;
  /** The file name of a swatch image. */
  swatchImage: Maybe<Scalars['String']['output']>;
  /** Returns all assigned product tags and their values */
  tags: Array<Maybe<ProductTags>>;
  /** The relative path to the product's thumbnail image. */
  thumbnail: Maybe<ProductImage>;
  /**
   * The price when tier pricing is in effect and the items purchased threshold has been reached.
   * @deprecated Use `price_tiers` for product tier price information.
   */
  tierPrice: Maybe<Scalars['Float']['output']>;
  /**
   * An array of ProductTierPrices objects.
   * @deprecated Use `price_tiers` for product tier price information.
   */
  tierPrices: Maybe<Array<Maybe<ProductTierPrices>>>;
  /** One of PRODUCT, CATEGORY, or CMS_PAGE. */
  type: Maybe<UrlRewriteEntityTypeEnum>;
  /**
   * One of simple, virtual, bundle, downloadable, grouped, or configurable.
   * @deprecated Use `__typename` instead.
   */
  typeId: Maybe<Scalars['String']['output']>;
  /** The unique ID for a `ProductInterface` object. */
  uid: Scalars['ID']['output'];
  /**
   * Timestamp indicating when the product was updated.
   * @deprecated The field should not be used on the storefront.
   */
  updatedAt: Maybe<Scalars['String']['output']>;
  /** Upsell Products */
  upsellProducts: Maybe<Array<Maybe<ProductInterface>>>;
  /** The part of the URL that identifies the product */
  urlKey: Maybe<Scalars['String']['output']>;
  /** @deprecated Use product's `canonical_url` or url rewrites instead */
  urlPath: Maybe<Scalars['String']['output']>;
  /** URL rewrites list */
  urlRewrites: Maybe<Array<Maybe<UrlRewrite>>>;
  /** The part of the product URL that is appended after the url key */
  urlSuffix: Maybe<Scalars['String']['output']>;
  valueAttributes: Maybe<Array<Maybe<ProductValue>>>;
  /** @deprecated Use the `custom_attributes` field instead. */
  valueTraits: Maybe<Scalars['String']['output']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  valuesDiet: Maybe<Scalars['String']['output']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  valuesEnvironmental: Maybe<Scalars['String']['output']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  valuesIngredient: Maybe<Scalars['String']['output']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  valuesSocial: Maybe<Scalars['String']['output']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  volumeWeight: Maybe<Scalars['Float']['output']>;
  /**
   * An array of websites in which the product is available.
   * @deprecated The field should not be used on the storefront.
   */
  websites: Maybe<Array<Maybe<Website>>>;
  /** @deprecated Use the `custom_attributes` field instead. */
  width: Maybe<Scalars['Float']['output']>;
  wishlistItemId: Maybe<Scalars['Int']['output']>;
};


/** Defines a virtual product, which is a non-tangible product that does not require shipping and is not kept in inventory. */
export type VirtualProductamastyRewardsHighlightsArgs = {
  attributes?: InputMaybe<Scalars['String']['input']>;
};


/** Defines a virtual product, which is a non-tangible product that does not require shipping and is not kept in inventory. */
export type VirtualProductlabelsArgs = {
  order?: InputMaybe<LabelOrderInput>;
};


/** Defines a virtual product, which is a non-tangible product that does not require shipping and is not kept in inventory. */
export type VirtualProductreviewsArgs = {
  currentPage?: InputMaybe<Scalars['Int']['input']>;
  filters?: InputMaybe<ReviewFilterInput>;
  pageSize?: InputMaybe<Scalars['Int']['input']>;
  sort?: InputMaybe<ReviewSortInput>;
};

/** Defines a single product to add to the cart. */
export type VirtualProductCartItemInput = {
  /** An array that defines customizable options for the product. */
  customizableOptions?: InputMaybe<Array<InputMaybe<CustomizableOptionInput>>>;
  /** An object containing the `sku`, `quantity`, and other relevant information about the product. */
  data: CartItemInput;
};

/** Contains a virtual product wish list item. */
export type VirtualWishlistItem = WishlistItemInterface & {
  __typename: 'VirtualWishlistItem';
  /** The date and time the item was added to the wish list. */
  addedAt: Scalars['String']['output'];
  /** Custom options selected for the wish list item. */
  customizableOptions: Array<Maybe<SelectedCustomizableOption>>;
  /** The description of the item. */
  description: Maybe<Scalars['String']['output']>;
  /** The unique ID for a `WishlistItemInterface` object. */
  id: Scalars['ID']['output'];
  /** Product details of the wish list item. */
  product: Maybe<ProductInterface>;
  /** The quantity of this wish list item. */
  quantity: Scalars['Float']['output'];
};

export type VisitorProducts = {
  __typename: 'VisitorProducts';
  items: Maybe<Array<Maybe<ProductInterface>>>;
};

export type Waybill = {
  __typename: 'Waybill';
  /** The carrier name */
  carrier: Maybe<Scalars['String']['output']>;
  /** The tracking URL */
  trackingUrl: Maybe<Scalars['String']['output']>;
  /** The waybill number */
  waybillNumber: Maybe<Scalars['String']['output']>;
};

/** Deprecated. It should not be used on the storefront. Contains information about a website. */
export type Website = {
  __typename: 'Website';
  /**
   * A code assigned to the website to identify it.
   * @deprecated The field should not be used on the storefront.
   */
  code: Maybe<Scalars['String']['output']>;
  /**
   * The default group ID of the website.
   * @deprecated The field should not be used on the storefront.
   */
  defaultGroupId: Maybe<Scalars['String']['output']>;
  /**
   * The ID number assigned to the website.
   * @deprecated The field should not be used on the storefront.
   */
  id: Maybe<Scalars['Int']['output']>;
  /**
   * Indicates whether this is the default website.
   * @deprecated The field should not be used on the storefront.
   */
  isDefault: Maybe<Scalars['Boolean']['output']>;
  /**
   * The website name. Websites use this name to identify it easier.
   * @deprecated The field should not be used on the storefront.
   */
  name: Maybe<Scalars['String']['output']>;
  /**
   * The attribute to use for sorting websites.
   * @deprecated The field should not be used on the storefront.
   */
  sortOrder: Maybe<Scalars['Int']['output']>;
};

/** Week Days */
export enum WeekDayEnum {
  /** Friday */
  FRIDAY = 'FRIDAY',
  /** Monday */
  MONDAY = 'MONDAY',
  /** Saturday */
  SATURDAY = 'SATURDAY',
  /** Sunday */
  SUNDAY = 'SUNDAY',
  /** Thursday */
  THURSDAY = 'THURSDAY',
  /** Tuesday */
  TUESDAY = 'TUESDAY',
  /** Wednesday */
  WEDNESDAY = 'WEDNESDAY'
}

export type Widget = {
  createdAt: Scalars['DateTime']['output'];
  id: Scalars['ID']['output'];
  isActive: Maybe<Scalars['Boolean']['output']>;
  name: Scalars['String']['output'];
  scheduledEnd: Maybe<Scalars['DateTime']['output']>;
  scheduledStart: Scalars['DateTime']['output'];
  updatedAt: Scalars['DateTime']['output'];
};

export type WidgetContactUs = Widget & {
  __typename: 'WidgetContactUs';
  content: Scalars['HTML']['output'];
  createdAt: Scalars['DateTime']['output'];
  emailAddress: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  isActive: Maybe<Scalars['Boolean']['output']>;
  name: Scalars['String']['output'];
  phoneNumber: Scalars['String']['output'];
  scheduledEnd: Maybe<Scalars['DateTime']['output']>;
  scheduledStart: Scalars['DateTime']['output'];
  stores: Array<ContactUsStore>;
  updatedAt: Scalars['DateTime']['output'];
};

export type WidgetContactUsAddInput = {
  content: Scalars['HTML']['input'];
  emailAddress: Scalars['String']['input'];
  name: Scalars['String']['input'];
  phoneNumber: Scalars['String']['input'];
  scheduledEnd?: InputMaybe<Scalars['DateTime']['input']>;
  scheduledStart: Scalars['DateTime']['input'];
  stores: Array<InputMaybe<ContactUsStoreInput>>;
};

export type WidgetContactUsFilterInput = {
  createdAt?: InputMaybe<DateTimeEqualsInRangeFilterInput>;
  id?: InputMaybe<IDEqualsInFilterInput>;
  isActive?: InputMaybe<BooleanEqualsInRangeFilterInput>;
  scheduledEnd?: InputMaybe<DateTimeEqualsInRangeFilterInput>;
  scheduledStart?: InputMaybe<DateTimeEqualsInRangeFilterInput>;
  search?: InputMaybe<Scalars['String']['input']>;
  updatedAt?: InputMaybe<DateTimeEqualsInRangeFilterInput>;
};

export type WidgetContactUsList = {
  __typename: 'WidgetContactUsList';
  limit: Scalars['Int']['output'];
  list: Array<WidgetContactUs>;
  skip: Scalars['Int']['output'];
  total: Scalars['Int']['output'];
};

export type WidgetContactUsOrderInput = {
  createdAt?: InputMaybe<OrderDirectionEnum>;
  id?: InputMaybe<OrderDirectionEnum>;
  name?: InputMaybe<OrderDirectionEnum>;
  scheduledEnd?: InputMaybe<OrderDirectionEnum>;
  scheduledStart?: InputMaybe<OrderDirectionEnum>;
  updatedAt?: InputMaybe<OrderDirectionEnum>;
};

export type WidgetContactUsUpdateInput = {
  content?: InputMaybe<Scalars['HTML']['input']>;
  emailAddress?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  phoneNumber?: InputMaybe<Scalars['String']['input']>;
  scheduledEnd?: InputMaybe<Scalars['DateTime']['input']>;
  scheduledStart?: InputMaybe<Scalars['DateTime']['input']>;
  stores?: InputMaybe<Array<InputMaybe<ContactUsStoreInput>>>;
};

export type WidgetContactUsUpdates = {
  __typename: 'WidgetContactUsUpdates';
  id: Scalars['ID']['output'];
  item: Maybe<WidgetContactUs>;
  type: SimpleUpdateTypeEnum;
};

export type WidgetDropDownCart = Widget & {
  __typename: 'WidgetDropDownCart';
  content: Scalars['HTML']['output'];
  contentBackgroundColor: Scalars['Color']['output'];
  contentForegroundColor: Scalars['Color']['output'];
  createdAt: Scalars['DateTime']['output'];
  icon: Maybe<Image>;
  id: Scalars['ID']['output'];
  isActive: Maybe<Scalars['Boolean']['output']>;
  name: Scalars['String']['output'];
  scheduledEnd: Maybe<Scalars['DateTime']['output']>;
  scheduledStart: Scalars['DateTime']['output'];
  title: Scalars['String']['output'];
  titleBackgroundColor: Scalars['Color']['output'];
  titleForegroundColor: Scalars['Color']['output'];
  updatedAt: Scalars['DateTime']['output'];
};

export type WidgetDropDownCartAddInput = {
  content: Scalars['HTML']['input'];
  contentBackgroundColor: Scalars['Color']['input'];
  contentForegroundColor: Scalars['Color']['input'];
  icon?: InputMaybe<Scalars['ID']['input']>;
  name: Scalars['String']['input'];
  scheduledEnd?: InputMaybe<Scalars['DateTime']['input']>;
  scheduledStart: Scalars['DateTime']['input'];
  title: Scalars['String']['input'];
  titleBackgroundColor: Scalars['Color']['input'];
  titleForegroundColor: Scalars['Color']['input'];
};

export type WidgetDropDownCartFilterInput = {
  createdAt?: InputMaybe<DateTimeEqualsInRangeFilterInput>;
  id?: InputMaybe<IDEqualsInFilterInput>;
  isActive?: InputMaybe<BooleanEqualsInRangeFilterInput>;
  scheduledEnd?: InputMaybe<DateTimeEqualsInRangeFilterInput>;
  scheduledStart?: InputMaybe<DateTimeEqualsInRangeFilterInput>;
  search?: InputMaybe<Scalars['String']['input']>;
  updatedAt?: InputMaybe<DateTimeEqualsInRangeFilterInput>;
};

export type WidgetDropDownCartList = {
  __typename: 'WidgetDropDownCartList';
  limit: Scalars['Int']['output'];
  list: Array<WidgetDropDownCart>;
  skip: Scalars['Int']['output'];
  total: Scalars['Int']['output'];
};

export type WidgetDropDownCartOrderInput = {
  createdAt?: InputMaybe<OrderDirectionEnum>;
  id?: InputMaybe<OrderDirectionEnum>;
  name?: InputMaybe<OrderDirectionEnum>;
  scheduledEnd?: InputMaybe<OrderDirectionEnum>;
  scheduledStart?: InputMaybe<OrderDirectionEnum>;
  updatedAt?: InputMaybe<OrderDirectionEnum>;
};

export type WidgetDropDownCartUpdateInput = {
  content?: InputMaybe<Scalars['HTML']['input']>;
  contentBackgroundColor?: InputMaybe<Scalars['Color']['input']>;
  contentForegroundColor?: InputMaybe<Scalars['Color']['input']>;
  icon?: InputMaybe<Scalars['ID']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  scheduledEnd?: InputMaybe<Scalars['DateTime']['input']>;
  scheduledStart?: InputMaybe<Scalars['DateTime']['input']>;
  title?: InputMaybe<Scalars['String']['input']>;
  titleBackgroundColor?: InputMaybe<Scalars['Color']['input']>;
  titleForegroundColor?: InputMaybe<Scalars['Color']['input']>;
};

export type WidgetDropDownCartUpdates = {
  __typename: 'WidgetDropDownCartUpdates';
  id: Scalars['ID']['output'];
  item: Maybe<WidgetDropDownCart>;
  type: SimpleUpdateTypeEnum;
};

export type WidgetFilterInput = {
  createdAt?: InputMaybe<DateTimeEqualsInRangeFilterInput>;
  id?: InputMaybe<IDEqualsInFilterInput>;
  isActive?: InputMaybe<BooleanEqualsInRangeFilterInput>;
  objectType?: InputMaybe<WidgetObjectTypesEnumEqualsInFilterInput>;
  scheduledEnd?: InputMaybe<DateTimeEqualsInRangeFilterInput>;
  scheduledStart?: InputMaybe<DateTimeEqualsInRangeFilterInput>;
  search?: InputMaybe<Scalars['String']['input']>;
  updatedAt?: InputMaybe<DateTimeEqualsInRangeFilterInput>;
};

export type WidgetList = {
  __typename: 'WidgetList';
  limit: Scalars['Int']['output'];
  list: Array<Widget>;
  skip: Scalars['Int']['output'];
  total: Scalars['Int']['output'];
};

export enum WidgetObjectTypesEnum {
  /** Widget contact us Type */
  WIDGET_CONTACT_US = 'WIDGET_CONTACT_US',
  /** Widget drop down cart Type */
  WIDGET_DROP_DOWN_CART = 'WIDGET_DROP_DOWN_CART',
  /** Widget product details add to cart Type */
  WIDGET_PRODUCT_DETAILS_ADD_TO_CART = 'WIDGET_PRODUCT_DETAILS_ADD_TO_CART',
  /** Widget site wide banner Type */
  WIDGET_SITE_WIDE_BANNER = 'WIDGET_SITE_WIDE_BANNER'
}

export type WidgetObjectTypesEnumEqualsInFilterInput = {
  doesNotEqual?: InputMaybe<WidgetObjectTypesEnum>;
  equals?: InputMaybe<WidgetObjectTypesEnum>;
  in?: InputMaybe<Array<InputMaybe<WidgetObjectTypesEnum>>>;
  notIn?: InputMaybe<Array<InputMaybe<WidgetObjectTypesEnum>>>;
};

export type WidgetOrderInput = {
  createdAt?: InputMaybe<OrderDirectionEnum>;
  id?: InputMaybe<OrderDirectionEnum>;
  name?: InputMaybe<OrderDirectionEnum>;
  objectType?: InputMaybe<OrderDirectionEnum>;
  scheduledEnd?: InputMaybe<OrderDirectionEnum>;
  scheduledStart?: InputMaybe<OrderDirectionEnum>;
  updatedAt?: InputMaybe<OrderDirectionEnum>;
};

export type WidgetProductDetailsAddToCart = Widget & {
  __typename: 'WidgetProductDetailsAddToCart';
  content: Scalars['HTML']['output'];
  contentBackgroundColor: Scalars['Color']['output'];
  contentForegroundColor: Scalars['Color']['output'];
  createdAt: Scalars['DateTime']['output'];
  icon: Maybe<Image>;
  id: Scalars['ID']['output'];
  isActive: Maybe<Scalars['Boolean']['output']>;
  name: Scalars['String']['output'];
  scheduledEnd: Maybe<Scalars['DateTime']['output']>;
  scheduledStart: Scalars['DateTime']['output'];
  updatedAt: Scalars['DateTime']['output'];
};

export type WidgetProductDetailsAddToCartAddInput = {
  content: Scalars['HTML']['input'];
  contentBackgroundColor: Scalars['Color']['input'];
  contentForegroundColor: Scalars['Color']['input'];
  icon?: InputMaybe<Scalars['ID']['input']>;
  name: Scalars['String']['input'];
  scheduledEnd?: InputMaybe<Scalars['DateTime']['input']>;
  scheduledStart: Scalars['DateTime']['input'];
};

export type WidgetProductDetailsAddToCartFilterInput = {
  createdAt?: InputMaybe<DateTimeEqualsInRangeFilterInput>;
  id?: InputMaybe<IDEqualsInFilterInput>;
  isActive?: InputMaybe<BooleanEqualsInRangeFilterInput>;
  scheduledEnd?: InputMaybe<DateTimeEqualsInRangeFilterInput>;
  scheduledStart?: InputMaybe<DateTimeEqualsInRangeFilterInput>;
  search?: InputMaybe<Scalars['String']['input']>;
  updatedAt?: InputMaybe<DateTimeEqualsInRangeFilterInput>;
};

export type WidgetProductDetailsAddToCartList = {
  __typename: 'WidgetProductDetailsAddToCartList';
  limit: Scalars['Int']['output'];
  list: Array<WidgetProductDetailsAddToCart>;
  skip: Scalars['Int']['output'];
  total: Scalars['Int']['output'];
};

export type WidgetProductDetailsAddToCartOrderInput = {
  createdAt?: InputMaybe<OrderDirectionEnum>;
  id?: InputMaybe<OrderDirectionEnum>;
  name?: InputMaybe<OrderDirectionEnum>;
  scheduledEnd?: InputMaybe<OrderDirectionEnum>;
  scheduledStart?: InputMaybe<OrderDirectionEnum>;
  updatedAt?: InputMaybe<OrderDirectionEnum>;
};

export type WidgetProductDetailsAddToCartUpdateInput = {
  content?: InputMaybe<Scalars['HTML']['input']>;
  contentBackgroundColor?: InputMaybe<Scalars['Color']['input']>;
  contentForegroundColor?: InputMaybe<Scalars['Color']['input']>;
  icon?: InputMaybe<Scalars['ID']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  scheduledEnd?: InputMaybe<Scalars['DateTime']['input']>;
  scheduledStart?: InputMaybe<Scalars['DateTime']['input']>;
};

export type WidgetProductDetailsAddToCartUpdates = {
  __typename: 'WidgetProductDetailsAddToCartUpdates';
  id: Scalars['ID']['output'];
  item: Maybe<WidgetProductDetailsAddToCart>;
  type: SimpleUpdateTypeEnum;
};

export type WidgetSiteWideBanner = Widget & {
  __typename: 'WidgetSiteWideBanner';
  backgroundColor: Scalars['Color']['output'];
  content: Scalars['HTML']['output'];
  createdAt: Scalars['DateTime']['output'];
  foregroundColor: Scalars['Color']['output'];
  icon: Maybe<Image>;
  id: Scalars['ID']['output'];
  isActive: Maybe<Scalars['Boolean']['output']>;
  name: Scalars['String']['output'];
  scheduledEnd: Maybe<Scalars['DateTime']['output']>;
  scheduledStart: Scalars['DateTime']['output'];
  updatedAt: Scalars['DateTime']['output'];
};

export type WidgetSiteWideBannerAddInput = {
  backgroundColor: Scalars['Color']['input'];
  content: Scalars['HTML']['input'];
  foregroundColor: Scalars['Color']['input'];
  icon?: InputMaybe<Scalars['ID']['input']>;
  name: Scalars['String']['input'];
  scheduledEnd?: InputMaybe<Scalars['DateTime']['input']>;
  scheduledStart: Scalars['DateTime']['input'];
};

export type WidgetSiteWideBannerFilterInput = {
  createdAt?: InputMaybe<DateTimeEqualsInRangeFilterInput>;
  id?: InputMaybe<IDEqualsInFilterInput>;
  isActive?: InputMaybe<BooleanEqualsInRangeFilterInput>;
  scheduledEnd?: InputMaybe<DateTimeEqualsInRangeFilterInput>;
  scheduledStart?: InputMaybe<DateTimeEqualsInRangeFilterInput>;
  search?: InputMaybe<Scalars['String']['input']>;
  updatedAt?: InputMaybe<DateTimeEqualsInRangeFilterInput>;
};

export type WidgetSiteWideBannerList = {
  __typename: 'WidgetSiteWideBannerList';
  limit: Scalars['Int']['output'];
  list: Array<WidgetSiteWideBanner>;
  skip: Scalars['Int']['output'];
  total: Scalars['Int']['output'];
};

export type WidgetSiteWideBannerOrderInput = {
  createdAt?: InputMaybe<OrderDirectionEnum>;
  id?: InputMaybe<OrderDirectionEnum>;
  name?: InputMaybe<OrderDirectionEnum>;
  scheduledEnd?: InputMaybe<OrderDirectionEnum>;
  scheduledStart?: InputMaybe<OrderDirectionEnum>;
  updatedAt?: InputMaybe<OrderDirectionEnum>;
};

export type WidgetSiteWideBannerUpdateInput = {
  backgroundColor?: InputMaybe<Scalars['Color']['input']>;
  content?: InputMaybe<Scalars['HTML']['input']>;
  foregroundColor?: InputMaybe<Scalars['Color']['input']>;
  icon?: InputMaybe<Scalars['ID']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  scheduledEnd?: InputMaybe<Scalars['DateTime']['input']>;
  scheduledStart?: InputMaybe<Scalars['DateTime']['input']>;
};

export type WidgetSiteWideBannerUpdates = {
  __typename: 'WidgetSiteWideBannerUpdates';
  id: Scalars['ID']['output'];
  item: Maybe<WidgetSiteWideBanner>;
  type: SimpleUpdateTypeEnum;
};

export type WidgetUpdates = {
  __typename: 'WidgetUpdates';
  id: Scalars['ID']['output'];
  item: Maybe<Widget>;
  type: SimpleUpdateTypeEnum;
};

/** An error encountered while performing operations with WishList. */
export type WishListUserInputError = {
  __typename: 'WishListUserInputError';
  /** A wish list-specific error code. */
  code: WishListUserInputErrorType;
  /** A localized error message. */
  message: Scalars['String']['output'];
};

/** A list of possible error types. */
export enum WishListUserInputErrorType {
  /** @deprecated  */
  PRODUCT_NOT_FOUND = 'PRODUCT_NOT_FOUND',
  /** @deprecated  */
  UNDEFINED = 'UNDEFINED'
}

/** Contains a customer wish list. */
export type Wishlist = {
  __typename: 'Wishlist';
  /** The unique ID for a `Wishlist` object. */
  id: Maybe<Scalars['ID']['output']>;
  /** @deprecated Use the `items_v2` field instead. */
  items: Maybe<Array<Maybe<WishlistItem>>>;
  /** The number of items in the wish list. */
  itemsCount: Maybe<Scalars['Int']['output']>;
  /** An array of items in the customer's wish list. */
  itemsV2: Maybe<WishlistItems>;
  open: Scalars['Boolean']['output'];
  /** An encrypted code that Magento uses to link to the wish list. */
  sharingCode: Maybe<Scalars['String']['output']>;
  /** The time of the last modification to the wish list. */
  updatedAt: Maybe<Scalars['String']['output']>;
};


/** Contains a customer wish list. */
export type WishlistitemsV2Args = {
  currentPage?: InputMaybe<Scalars['Int']['input']>;
  pageSize?: InputMaybe<Scalars['Int']['input']>;
};

/** Contains details about errors encountered when a customer added wish list items to the cart. */
export type WishlistCartUserInputError = {
  __typename: 'WishlistCartUserInputError';
  /** An error code that describes the error encountered. */
  code: WishlistCartUserInputErrorType;
  /** A localized error message. */
  message: Scalars['String']['output'];
  /** The unique ID of the `Wishlist` object containing an error. */
  wishlistId: Scalars['ID']['output'];
  /** The unique ID of the wish list item containing an error. */
  wishlistItemId: Scalars['ID']['output'];
};

/** A list of possible error types. */
export enum WishlistCartUserInputErrorType {
  /** @deprecated  */
  INSUFFICIENT_STOCK = 'INSUFFICIENT_STOCK',
  /** @deprecated  */
  NOT_SALABLE = 'NOT_SALABLE',
  /** @deprecated  */
  PRODUCT_NOT_FOUND = 'PRODUCT_NOT_FOUND',
  /** @deprecated  */
  UNDEFINED = 'UNDEFINED'
}

/** Contains details about a wish list item. */
export type WishlistItem = {
  __typename: 'WishlistItem';
  /** The time when the customer added the item to the wish list. */
  addedAt: Maybe<Scalars['String']['output']>;
  /** The customer's comment about this item. */
  description: Maybe<Scalars['String']['output']>;
  /** The unique ID for a `WishlistItem` object. */
  id: Maybe<Scalars['Int']['output']>;
  /** Details about the wish list item. */
  product: Maybe<ProductInterface>;
  /** The quantity of this wish list item */
  qty: Maybe<Scalars['Float']['output']>;
};

/** Defines the items to add to a wish list. */
export type WishlistItemInput = {
  /** An array of options that the customer entered. */
  enteredOptions?: InputMaybe<Array<InputMaybe<EnteredOptionInput>>>;
  /** For complex product types, the SKU of the parent product. */
  parentSku?: InputMaybe<Scalars['String']['input']>;
  /** The amount or number of items to add. */
  quantity: Scalars['Float']['input'];
  /** An array of strings corresponding to options the customer selected. */
  selectedOptions?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** The SKU of the product to add. For complex product types, specify the child product SKU. */
  sku: Scalars['String']['input'];
};

/** The interface for wish list items. */
export type WishlistItemInterface = {
  /** The date and time the item was added to the wish list. */
  addedAt: Scalars['String']['output'];
  /** Custom options selected for the wish list item. */
  customizableOptions: Array<Maybe<SelectedCustomizableOption>>;
  /** The description of the item. */
  description: Maybe<Scalars['String']['output']>;
  /** The unique ID for a `WishlistItemInterface` object. */
  id: Scalars['ID']['output'];
  /** Product details of the wish list item. */
  product: Maybe<ProductInterface>;
  /** The quantity of this wish list item. */
  quantity: Scalars['Float']['output'];
};

/** Defines updates to items in a wish list. */
export type WishlistItemUpdateInput = {
  /** Customer-entered comments about the item. */
  description?: InputMaybe<Scalars['String']['input']>;
  /** An array of options that the customer entered. */
  enteredOptions?: InputMaybe<Array<InputMaybe<EnteredOptionInput>>>;
  /** The new amount or number of this item. */
  quantity?: InputMaybe<Scalars['Float']['input']>;
  /** An array of strings corresponding to options the customer selected. */
  selectedOptions?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** The unique ID for a `WishlistItemInterface` object. */
  wishlistItemId: Scalars['ID']['input'];
};

/** Contains an array of items in a wish list. */
export type WishlistItems = {
  __typename: 'WishlistItems';
  /** A list of items in the wish list. */
  items: Array<Maybe<WishlistItemInterface>>;
  /** Contains pagination metadata. */
  pageInfo: Maybe<SearchResultPageInfo>;
};

/** Deprecated: Use the `Wishlist` type instead. */
export type WishlistOutput = {
  __typename: 'WishlistOutput';
  /**
   * An array of items in the customer's wish list
   * @deprecated Use the `Wishlist.items` field instead.
   */
  items: Maybe<Array<Maybe<WishlistItem>>>;
  /**
   * The number of items in the wish list.
   * @deprecated Use the `Wishlist.items_count` field instead.
   */
  itemsCount: Maybe<Scalars['Int']['output']>;
  /**
   * When multiple wish lists are enabled, the name the customer assigns to the wishlist.
   * @deprecated This field is related to Commerce functionality and is always `null` in Open Source.
   */
  name: Maybe<Scalars['String']['output']>;
  /**
   * An encrypted code that links to the wish list.
   * @deprecated Use the `Wishlist.sharing_code` field instead.
   */
  sharingCode: Maybe<Scalars['String']['output']>;
  /**
   * The time of the last modification to the wish list.
   * @deprecated Use the `Wishlist.updated_at` field instead.
   */
  updatedAt: Maybe<Scalars['String']['output']>;
};

/** YearMonths */
export enum YearMonthsEnum {
  /** April */
  APRIL = 'APRIL',
  /** August */
  AUGUST = 'AUGUST',
  /** December */
  DECEMBER = 'DECEMBER',
  /** February */
  FEBRUARY = 'FEBRUARY',
  /** January */
  JANUARY = 'JANUARY',
  /** July */
  JULY = 'JULY',
  /** June */
  JUNE = 'JUNE',
  /** March */
  MARCH = 'MARCH',
  /** May */
  MAY = 'MAY',
  /** November */
  NOVEMBER = 'NOVEMBER',
  /** October */
  OCTOBER = 'OCTOBER',
  /** September */
  SEPTEMBER = 'SEPTEMBER'
}
