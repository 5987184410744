import React, { useEffect, useMemo, useRef } from 'react'

import { decode } from 'html-entities'
import styled, { useTheme } from 'styled-components'

import { Rating } from '@atoms/controls'
import { Card, Seperator, Skeleton, SkeletonNode } from '@atoms/layout'
import { Heading, Link, Paragraph, Tag } from '@atoms/typography'
import { ResponsivePXValue } from '@components/Theme'
import { ProductAttributeFragment, ProductDetailsFragment, ProductIngredientFragment, WarehouseFragment } from '@hooks/api'
import AttributeHelper from '@lib/AttributeHelper'
import { SiteHelper } from '@lib/SiteHelper'
import { HTML } from '@molecules/content'
import { ProductReviews } from '@molecules/store'
import { ProductBrand, ProductImage, ProductStock, ProductValueAttributes } from '@organisms/product'
import { Ingredients } from '@pages/Ingredients'
import { useSearchParams } from 'react-router-dom'
import { Icon, LocalIconEnums } from '@atoms/index'

const Content = styled.div`
  ${ResponsivePXValue('padding-bottom', { mobile: '0' })}
  .product-info-card {
    ${ResponsivePXValue('margin-top', { mobile: '0', tablet: '16px', desktop: '16px' })}
    ${ResponsivePXValue('padding', { mobile: '16px', tablet: '24px 24px 16px 24px', desktop: '24px 24px 16px 24px' })}
  }
  .product-detail-card {
    ${ResponsivePXValue('padding', { mobile: '0 16px', tablet: '24px 24px 16px 24px', desktop: '24px 24px 16px 24px' })}
  }
  .description-title {
    ${ResponsivePXValue('margin-bottom', '8px')}
  }
  .short-description-title {
    ${ResponsivePXValue('margin-bottom', '8px')}
  }
`

const ProductReviewsContainer = styled.div``

const InfoContainer = styled.div`
  position: relative;
  display: flex;
  width: 100%;
  ${ResponsivePXValue('padding-top', { mobile: '16px', tablet: '0', desktop: '0' })}
  ${ResponsivePXValue('flex-direction', { mobile: 'column', tablet: 'row', desktop: 'row' })}
  .text-item {
      ${ResponsivePXValue('margin-right', { desktop: '5px' })}
  }
`

const ContentContainer = styled.div`
  ${ResponsivePXValue('display', { tablet: 'flex', desktop: 'flex' })}
  flex-direction: column;
  ${ResponsivePXValue('width', '100%')}
  
  .title {
    ${ResponsivePXValue('margin', '0 0 12px 0')}
  }
`

const RatingContainer = styled.div`
  display: flex;
  align-items: center;
  border-style: solid;
  ${ResponsivePXValue('height', '50px')}
  ${ResponsivePXValue('padding', '16px 0 16px 0')}
  ${ResponsivePXValue('gap', '6px')}
  ${ResponsivePXValue('border-bottom-width', '1px')}
  border-color: ${(props): string => props.theme.colors.grey.athens};
`

const PointsContainer = styled.div`
  display: flex;
  align-items: center;
  ${ResponsivePXValue('gap', '20px')}
  .logo {
    ${ResponsivePXValue('height', '22px')}
    ${ResponsivePXValue('width', '22px')}
  }
`

const Strong = styled.span`
  font-weight: 700;
  ${ResponsivePXValue('position', { mobile: 'relative' })}
  ${ResponsivePXValue('right', { mobile: '5px' })}
  ${ResponsivePXValue('font-size', { mobile: '11px', tablet: '12px', desktop: '12px' })}
  ${ResponsivePXValue('line-height', { mobile: '18px' })}
`

const SizeContainer = styled.div`
  border-style: solid;
  display: flex;
  align-items: center;
  ${ResponsivePXValue('height', { mobile: '40px', tablet: '54px', desktop: '54px' })}
  ${ResponsivePXValue('border-bottom-width', '1px')}
  border-color: ${(props): string => props.theme.colors.grey.athens};
`

const MarkupContainer = styled.div``

export interface ProductInformationProps {
  product: ProductDetailsFragment
  children?: React.ReactNode
}

export function ProductInformation({ product, children }: ProductInformationProps): JSX.Element {

  const theme = useTheme()
  const reviewsRef: React.RefObject<HTMLDivElement> = useRef(null)
  const [searchParams] = useSearchParams()
  const section = searchParams.get('section')

  const attributes = useMemo(() => AttributeHelper.shared().getProductAttributes(product?.attributes || []), [product?.attributes])
  const rewardAmount = product?.amastyRewardsHighlights?.captionText || product?.amastyRewardsGuestHighlights?.captionText

  const _handleScroll = (): void => reviewsRef?.current?.scrollIntoView({ behavior: 'smooth' })

  let ingredientIndex: number
  let productAttribute: ProductAttributeFragment
  let productAttributeIndex: number
  let productIngredient: ProductIngredientFragment
  let warehouse!: WarehouseFragment

  useEffect(() => {

    const reviews = searchParams.get('section')
    !!reviews && reviewsRef.current && _handleScroll()
    
  }, [reviewsRef, searchParams])

  return (
    <>
      <Content>
        <Card className='product-detail-card'>
          <InfoContainer>

            <ProductImage
              cover={product?.coverImage}
              gallery={product?.imageGallery || []}
              labels={product?.labels || []}
              product={product}
            />

            <ContentContainer>
              <Choose>
                <When condition={!product?.name}>
                  <Skeleton className='title' direction='column' justify='flex-start' align='flex-start' height='auto'>
                    <SkeletonNode shape='rounded' color='gallery' align='flex-start' justify='flex-start'
                      height={{ mobile: '24px', tablet: '24px', desktop: '24px' }}
                      variWidth={{ mobile: '30%-45%', tablet: '25%', desktop: '20%-50%' }}
                    />
                  </Skeleton>
                </When>
                <Otherwise>
                  <Heading className='title' variant='h9' >{decode(product?.name)}</Heading>
                </Otherwise>
              </Choose>

              <ProductBrand brand={product?.brand} />

              <RatingContainer>
                <Rating rating={product?.ratingSummary.averageRating || 0} size='large' />
                <Tag variant='t1' color={theme.colors.grey.stormDust}>({product?.reviewCount || 0})</Tag>
              </RatingContainer>

              <If condition={product?.amastyRewardsHighlights?.visible || product?.amastyRewardsGuestHighlights?.visible}>
                <PointsContainer>
                  <Icon icon={LocalIconEnums.LOGO} color={theme.colors.green.watercourse} className='logo'/>
                  <Tag variant='t2' color={theme.colors.green.watercourse}> {`You will earn ${rewardAmount} Faithful Point for purchasing this product.`}</Tag>
                </PointsContainer>
              </If>

              <For each='productAttribute' of={attributes} index='productAttributeIndex'>
                <SizeContainer key={productAttributeIndex}>
                  <Tag variant='t2' color={theme.colors.green.bottleGreen}><Strong>{productAttribute.label}:</Strong> {productAttribute.value}</Tag>
                </SizeContainer>
              </For>

              <If condition={product?.__typename === 'SimpleProduct'}>
                <ProductStock
                  sources={product?.stockInfo?.sources || []}
                  stockStatus={product?.stockStatus}
                  warehouse={warehouse}
                />
              </If>

              <If condition={!!product?.valueAttributes && !!product?.valueAttributes?.length}>
                <ProductValueAttributes
                  valueAttributes={product?.valueAttributes}
                />
              </If>

            </ContentContainer>
          </InfoContainer>
        </Card>

        <Seperator align='horizontal' />

        <If condition={!!children}>
          {children}
        </If>

        <If condition={!!product?.shortDescription}>
          <Card className='product-info-card'>
            <Heading variant='h5' className='short-description-title' color={theme.colors.black.pureBlack}>
              Why we love it
            </Heading>
            <Choose>
              <When condition={SiteHelper.isHTML(product?.shortDescription?.html)}>
                <HTML>
                  {product?.shortDescription.html}
                </HTML>
              </When>
              <Otherwise>
                <Paragraph variant='p2' className='markup-section-text' color={theme.colors.green.bottleGreen}>
                  <MarkupContainer style={{ whiteSpace: 'pre-line' }}>
                    {product?.shortDescription?.html}
                  </MarkupContainer>
                </Paragraph>
              </Otherwise>
            </Choose>
          </Card>
        </If>

        <Seperator align='horizontal' />

        <If condition={!!product?.description}>
          <Card className='product-info-card'>
            <Heading variant='h5' className='description-title' color={theme.colors.black.pureBlack}>
              Description
            </Heading>
            <Choose>
              <When condition={SiteHelper.isHTML(product.description.html)}>
                <HTML>
                  {product?.description.html}
                </HTML>
              </When>
              <Otherwise>
                <Paragraph variant='p2' className='markup-section-text' color={theme.colors.green.bottleGreen}>
                  <MarkupContainer style={{ whiteSpace: 'pre-line' }}>
                    {product?.description.html}
                  </MarkupContainer>
                </Paragraph>
              </Otherwise>
            </Choose>
          </Card>
        </If>

        <If condition={!!product?.ingredientList}>
          <Card className='product-info-card'>
            <Heading variant='h5' className='description-title' color={theme.colors.black.pureBlack}>
              Learn more about these ingredients ({product?.ingredientList.length})
            </Heading>
            <Paragraph variant='p2' className='markup-section-text' color={theme.colors.green.bottleGreen}>
              <For each='productIngredient' of={product?.ingredientList || []} index='ingredientIndex'>
                <Ingredients key={ingredientIndex}>
                  <Choose>
                    <When condition={productIngredient.urlKey !== null}>
                      <Link href={`/ingredient/${productIngredient.urlKey}`} bold={false} display='inline-block'>{productIngredient.name}</Link>
                    </When>
                    <Otherwise>
                      {productIngredient.name}
                    </Otherwise>
                  </Choose>
                  <If condition={ingredientIndex < product?.ingredientList.length - 1}>
                    {', '}
                  </If>
                </Ingredients>
              </For>
            </Paragraph>
          </Card>
        </If>
      </Content>

      <ProductReviewsContainer ref={reviewsRef}>
        <ProductReviews product={product} />
      </ProductReviewsContainer>
    </>
  )

}
