import React, { useState } from 'react'

import update from 'react-addons-update'
import styled, { useTheme } from 'styled-components'

import { APP_DEFAULT_STATE } from '@api/local/AppPlugin'
import { AuthPlugin } from '@api/local/AuthPlugin'
import { ModalPlugin, GlobalModalTypeEnum } from '@api/local/ModalPlugin'
import { Button, ButtonColors } from '@atoms/buttons'
import { Icon, LocalIconEnums } from '@atoms/images'
import { Seperator } from '@atoms/layout'
import { Link, Paragraph, Title } from '@atoms/typography'
import { ResponsivePXValue } from '@components/Theme'
import { useCustomerQuery, useGetAppQuery, useGetFrequentlyBoughtProductsQuery, usePersonalDiscountsQuery, usesubscribeEmailToNewsletterMutation } from '@hooks/api'
import { Form, TextInput, useForm } from '@molecules/inputs'
import { SocialAccounts } from '@molecules/misc'
import { NeedHelp } from '@molecules/navigation'
import { IndexAccordion, IndexSection } from '@organisms/content/IndexAccordion'
import { googleLogout } from '@react-oauth/google'
import { useSimpleToasts } from '@simple/toasts'
import { CustomerTypeEnum } from '@uctypes/api/globalTypes'
import { DeviceContainer } from '@utility/DeviceContainer'

import { FooterLinkGroup, FooterLinkItem, links } from './FooterLinks'

const Container = styled.div`
  width: 100%;
  background-color: ${(props): string => props.theme.colors.white.pureWhite};
  display: flex;
  flex-direction: column;
  align-items: center;
`

const TopBarContentWrapper = styled.div`
  width: 100%;
  background-color: ${(props): string => props.theme.colors.green.watercourse};
  display: flex;
  justify-content: center;
`

const TopBar = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  max-width: ${(props): number => props.theme.MAX_WIDTH}px;
  ${ResponsivePXValue('flex-direction', { mobile: 'column', tablet: 'row', desktop: 'row' })}
  ${ResponsivePXValue('padding', { mobile: '12px', tablet: '16px 48px', desktop: '16px 48px' })}
  form {
    flex-grow: 1;
    width: 100%;
  }
  .wrapper {
    margin: 0;
  }
  `

const TopBarTile = styled.div`
  flex-shrink: 0;
  ${ResponsivePXValue('width', { mobile: '100%', tablet: '334px', desktop: '334px' })}
  `

const TopBarSignup = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  ${ResponsivePXValue('gap', '12px')}
  ${ResponsivePXValue('margin-top', { mobile: '16px' })}
  .input {
    flex-grow: 1;
  }
  .button {
    flex-shrink: 0;
  }
  `

const ContentContentWrapper = styled.div`
  width: 100%;
  background-color: ${(props): string => props.theme.colors.green.swansDown};
  display: flex;
  justify-content: center;

`

const Content = styled.div`
  width: 100%;
  max-width: ${(props): number => props.theme.MAX_WIDTH}px;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  ${ResponsivePXValue('flex-direction', { mobile: 'column', tablet: 'row', desktop: 'row' })}
  ${ResponsivePXValue('padding', { mobile: '16px', tablet: '24px 48px', desktop: '24px 48px' })}
`

const NavigationGrid = styled.div`
  flex-grow: 1;
  ${ResponsivePXValue('display', { mobile: 'none', tablet: 'flex', desktop: 'flex' })}
`

const SwitchToWebsiteContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  ${ResponsivePXValue('gap', '12px')}
  background-color: ${({ theme }): string => theme.colors.green.snowFlurry};
  ${ResponsivePXValue('padding', '20px 12px 20px 12px')}
  ${ResponsivePXValue('border-radius', '2px')}
  ${ResponsivePXValue('margin-bottom', '16px')}
`

const NavigationAccordion = styled.div`
  ${ResponsivePXValue('display', { mobile: 'flex', tablet: 'none', desktop: 'none' })}
  ${ResponsivePXValue('margin-top', '16px')}
  width: 100%;
`

const NavColumn = styled.div`
  width: 100%;
  .title {
    ${ResponsivePXValue('margin-bottom', '16px')}
  }
  .link {
    padding: 0;
    margin: 0;
  }
`

const WidgetContainer = styled.div`
  flex-shrink: 0;
  ${ResponsivePXValue('width', { mobile: '100%', tablet: '320px', desktop: '320px' })}
`

const LogoutContainer = styled.div`
  display: flex;
  flex-direction: column;
  ${ResponsivePXValue('gap', '16px')}
`

const LogoContainer = styled.div`
  width: 100%;
  ${ResponsivePXValue('display', { mobile: 'none', tablet: 'block', desktop: 'block' })}
  ${ResponsivePXValue('margin-bottom', { mobile: '12px', tablet: '12px', desktop: '16px' })}
`

const BottomBarContentWrapper = styled.div`
  width: 100%;
  background-color: ${(props): string => props.theme.colors.green.greenVogue};
  display: flex;
  justify-content: center;

`

const BottomBar = styled.div`
  width: 100%;
  max-width: ${(props): number => props.theme.MAX_WIDTH}px;
  display: flex;
  align-items: center;
  ${ResponsivePXValue('flex-direction', { mobile: 'column', tablet: 'column', desktop: 'row' })}
  ${ResponsivePXValue('justify-content', { mobile: 'center', tablet: 'center', desktop: 'space-between' })}
  ${ResponsivePXValue('padding', { mobile: '12px', tablet: '16px 48px', desktop: '16px 48px' })}

  .mobile-push {
    ${ResponsivePXValue('margin-bottom', '60px')}
  }
`

const PaymentIcons = styled.div`
  display: flex;
  align-items: center;
  ${ResponsivePXValue('gap', { mobile: '16px', tablet: '32px', desktop: '32px' })}
  ${ResponsivePXValue('justify-content', { mobile: 'center', tablet: 'flex-start', desktop: 'flex-start' })}
`

const PaymentIcon = styled.div`
  ${ResponsivePXValue('height', { mobile: '20px', tablet: '30px', desktop: '30px' })}
`

const BottomLinks = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  ${ResponsivePXValue('height', { mobile: '15px', tablet: '20px', desktop: '20px' })}
  ${ResponsivePXValue('margin-top', { mobile: '12px' })}

  .footer-link-bottom-seperator {
    ${ResponsivePXValue('margin', { mobile: '0 8px', tablet: '0 16px', desktop: '0 16px' })}
  }
`

interface FooterNewState {
  tabs: string[]
}

interface ColorChangerObjectInterface {
  [k: string]: ButtonColors
}

const DEFAULT_STATE: FooterNewState = {
  tabs: [],
}

export function Footer(): JSX.Element {

  const [state, setState] = useState<FooterNewState>({ ...DEFAULT_STATE })
  const { data: customerData, refetch: refetchCustomer } = useCustomerQuery()
  const { refetch: refetchDiscounts } = usePersonalDiscountsQuery()
  const { refetch: refetchMyShop } = useGetFrequentlyBoughtProductsQuery()
  const [subscribeEmailToNewsletter, { loading: subscriptionLoading }] = usesubscribeEmailToNewsletterMutation()
  const { data: appData = { app: { ...APP_DEFAULT_STATE } } } = useGetAppQuery()
  const form = useForm()
  const theme = useTheme()
  const { addToast } = useSimpleToasts()

  const _handleLogOut = async (e: React.MouseEvent<HTMLAnchorElement>): Promise<void> => {
    if (!appData.app.isNativeApp) {
      googleLogout()
    }
    AuthPlugin.shared().clear()
    await refetchCustomer()
    refetchDiscounts()
    refetchMyShop()
    ModalPlugin.shared().toggleGlobalModal(true, GlobalModalTypeEnum.LOG_IN)
  }

  const _handleLogIn = (): void => {
    ModalPlugin.shared().toggleGlobalModal(true, GlobalModalTypeEnum.LOG_IN)
  }

  const _handleTabsChange = (tabs: string[]): void => {
    setState((prevState) => update(prevState, {
      tabs: { $set: tabs },
    }))
  }

  const _handleSubscribe = async (data: { email: string }): Promise<void> => {
    if (!data.email) {
      return
    }
    try {
      await subscribeEmailToNewsletter({
        variables: {
          email: data.email,
        },
      })
      addToast({
        message: 'Subscribed successfully',
        appearance: 'success',
      })
    } catch (e) {
      if (e instanceof Error) {
        addToast({
          message: e.message,
          appearance: 'error',
        })
      }
    }
  }

  let linkGroup!: FooterLinkGroup
  let linkGroupIndex!: number
  let link!: FooterLinkItem
  let linkIndex!: number

  const colorChangerObject: ColorChangerObjectInterface = {
    colorButtonObject: {
      color: theme.colors.white.pureWhite,
      hoverColor: theme.colors.white.pureWhite,
    },
    colorColumnObject: {
      color: theme.colors.black.codGrey,
      hoverColor: theme.colors.black.codGrey,
    },
  }

  return (
  <Container>
      <TopBarContentWrapper>
        <TopBar>
          <TopBarTile>
            <DeviceContainer desktop tablet>
              <Title variant='t5' color={theme.colors.white.pureWhite} align='center'>
                Join our conscious shopping community
              </Title>
            </DeviceContainer>
            <DeviceContainer mobile>
              <Title variant='t8' color={theme.colors.white.pureWhite} align='center'>
                Join our conscious shopping community
              </Title>
            </DeviceContainer>
          </TopBarTile>
          <Form form={form} onFinish={_handleSubscribe} loading={subscriptionLoading}>
            <TopBarSignup>
              <TextInput
                variant='email'
                name='email'
                placeholder='Enter your email address'
                className='input'
                wrapperClassName='wrapper'
                rules={[{ required: true, message: 'Please enter your email address' }]} />
              <Button
                title='SIGN UP'
                className='button'
                variant='cart'
                loading={subscriptionLoading}
                onClick={() => form.submit()} />
            </TopBarSignup>
          </Form>
        </TopBar>
      </TopBarContentWrapper>
      <ContentContentWrapper>
        <Content>
          <NavigationGrid>
            <For each='linkGroup' of={links} index='linkGroupIndex'>
              <NavColumn key={linkGroupIndex}>
                <Paragraph variant='p1' className='title' color={theme.colors.green.greenVogue} bold={true}>{linkGroup.title}</Paragraph>
                <For each='link' of={linkGroup.subs} index='linkIndex'>
                  <Button variant='action' title={link.title} href={link.link} key={`${linkGroupIndex}-${linkIndex}`} colors={colorChangerObject.colorColumnObject} className='link' target='_blank' />
                </For>
              </NavColumn>
            </For>
          </NavigationGrid>
          <If condition={appData.app.isNativeApp}>
            <SwitchToWebsiteContainer>
              <Paragraph
                variant='p1'
                color={theme.colors.green.greenVogue}
                align='center'>
                Oops! Our new app isn&apos;t fully functional yet. Switch to the website for a smoother experience.
              </Paragraph>
              <Link
                href='https://www.faithful-to-nature.co.za/'
                color={theme.colors.green.greenVogue}
                decoration='underline'
                bold={true}>Switch to Website</Link>
            </SwitchToWebsiteContainer>
          </If>
          <WidgetContainer>
            <LogoContainer>
              <Icon icon={LocalIconEnums.LOGO_DESKTOP} color={theme.colors.green.watercourse} />
            </LogoContainer>
            <DeviceContainer desktop tablet>
              <SocialAccounts align='left' />
            </DeviceContainer>
            <DeviceContainer mobile>
              <SocialAccounts align='center' />
            </DeviceContainer>
            <NeedHelp />
            <Choose>
              <When condition={customerData?.currentCustomer?.customerType === CustomerTypeEnum.REGISTERED}>
                <LogoutContainer>
                  <Button variant='ghost' title=' LOG OUT' onClick={_handleLogOut} fullWidth />
                  <Paragraph>
                    Hi, {customerData.currentCustomer.firstname} <Link href='' onClick={_handleLogOut}>Not you?</Link>
                  </Paragraph>
                </LogoutContainer>
              </When>
              <Otherwise>
                <Button variant='ghost' title='LOGIN NOW' onClick={_handleLogIn} fullWidth />
              </Otherwise>
            </Choose>
          </WidgetContainer>
          <NavigationAccordion>
            <IndexAccordion tabs={state.tabs} onTabsChange={_handleTabsChange}>
              <For each='linkGroup' of={links} index='linkGroupIndex'>
                <IndexSection key={linkGroupIndex} id={linkGroupIndex + ''} title={linkGroup.title}>
                  <For each='link' of={linkGroup.subs} index='linkIndex'>
                    {/* GIFT CARDS AND FAITHFUL POINTS LINKS NOT RENDERED FOR M2 APP LAUNCH */}
                    <If condition={link.title !== 'Gift Cards' && link.title !== 'Faithful Points'}>
                      <Button variant='action' title={link.title} href={link.link} key={`${linkGroupIndex}-${linkIndex}`} colors={colorChangerObject.colorColumnObject} className='link' />
                    </If>
                  </For>
                </IndexSection>
              </For>
            </IndexAccordion>
          </NavigationAccordion>
        </Content>
      </ContentContentWrapper>
      <BottomBarContentWrapper>
        <BottomBar>
          <PaymentIcons>
            <PaymentIcon>
              <Icon className='paymentMethods visa' color={theme.colors.white.romance} icon={LocalIconEnums.VISA} />
            </PaymentIcon>
            <PaymentIcon>
              <Icon className='paymentMethods mastercard' color={theme.colors.white.romance} icon={LocalIconEnums.MASTERCARD} />
            </PaymentIcon>
            <PaymentIcon>
              <Icon className='paymentMethods ozow' color={theme.colors.white.romance} icon={LocalIconEnums.OZOW} />
            </PaymentIcon>
            <PaymentIcon>
              <Icon className='paymentMethods peach' color={theme.colors.white.romance} icon={LocalIconEnums.PEACH_PAYMENTS} />
            </PaymentIcon>
            <PaymentIcon>
              <Icon className='paymentMethods ae' color={theme.colors.white.romance} icon={LocalIconEnums.AE} />
            </PaymentIcon>
          </PaymentIcons>
          <DeviceContainer desktop tablet>
            <BottomLinks>
              <Button variant='action' title='Terms & Conditions' href={'/terms-conditions'} colors={colorChangerObject.colorButtonObject} className='link' />
              <Seperator align='vertical' className='footer-link-bottom-seperator' />
              <Button variant='action' title='Privacy Policy' href={'/privacy-policy'} colors={colorChangerObject.colorButtonObject} className='link' />
              <Seperator align='vertical' className='footer-link-bottom-seperator' />
              <Button variant='action' title='PAIA Manual' href={'https://ftn-image.s3.eu-west-1.amazonaws.com/files/Company+PAIA+Manual_FTN_Webversion.pdf'} colors={colorChangerObject.colorButtonObject} className='link' />
            </BottomLinks>
          </DeviceContainer>
          <DeviceContainer mobile >
            <BottomLinks className='mobile-push'>
              <Button variant='action' title='Terms & Conditions' href={'/terms-conditions'} colors={colorChangerObject.colorButtonObject} className='link' />
              <Seperator align='vertical' className='footer-link-bottom-seperator' />
              <Button variant='action' title='Privacy Policy' href={'/privacy-policy'} colors={colorChangerObject.colorButtonObject} className='link' />
              <Seperator align='vertical' className='footer-link-bottom-seperator' />
              <Button variant='plain' title='PAIA Manual' href={'https://ftn-image.s3.eu-west-1.amazonaws.com/files/Company+PAIA+Manual_FTN_Webversion.pdf'} colors={colorChangerObject.colorButtonObject} className='link' />
            </BottomLinks>
          </DeviceContainer>
        </BottomBar>
      </BottomBarContentWrapper>
    </Container>
  )

}
