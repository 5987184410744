import React, { MutableRefObject, RefObject, useEffect, useRef, useState } from 'react'

import { camelCase } from 'change-case'
import deepmerge from 'deepmerge'
import qs from 'qs'
import update from 'react-addons-update'
import { useNavigate, useLocation } from 'react-router'
import { useIsomorphicLayoutEffect } from 'react-spring'
import styled from 'styled-components'

import { NavigationPlugin } from '@api/local/NavigationPlugin'
import { LayoutCol, LayoutGrid, LayoutRow } from '@atoms/layout/LayoutGrid'
import { ResponsivePXValue } from '@components/Theme'
import { CategoryFragment, BreadcrumbFragment, PageSectionGroupFragment, AggregationFragment, useGetAllAggregationsQuery } from '@hooks/api/index'
import { useLoadingData } from '@hooks/UseLoadingData'
import { BreadCrumb, BreadCrumbs } from '@molecules/navigation/BreadCrumbs'
import { PageSectionGroup } from '@organisms/content/PageSectionGroup'
import { CatalogueHeader } from '@organisms/navigation/CatalogueHeader'
import { CategoryHeader, ShopByValuesFilters } from '@organisms/shop'
import { ProductFilters, ProductFilterInputs } from '@organisms/shop/ProductFilters'
import { DisplayTypeEnum, ProductGrid, OrderValue } from '@organisms/shop/ProductGrid'
import { CategoryTypeEnum, OrderDirectionEnum } from '@uctypes/api/globalTypes'
import { Mutable } from '@uctypes/global'
import { SearchEngineOptimization, SearchEngineOptimizationParams } from '@utility/SearchEngineOptimization'

const PRODUCTS_PER_PAGE = 16

const Container = styled.div`
  .content-row {
    ${ResponsivePXValue('gap', { mobile: '0' })}
  }
`

const ProductGridContainer = styled.div`
  ${ResponsivePXValue('margin', '0 0 34px 0')}
`

const ProductsContainer = styled.div``

const ProductFilterContainer = styled.div`
  ${ResponsivePXValue('margin', { tablet: '0 16px 16px 0', desktop: '0 16px 16px 0' })}
`

const SectionContainer = styled.div`
  ${ResponsivePXValue('margin-bottom', { mobile: '16px', tablet: '32px', desktop: '32px' })}
`

const ValuesContainer = styled.div`
  ${ResponsivePXValue('margin-bottom', { mobile: '16px', tablet: '26px', desktop: '26px' })}
`

interface CategoryState {
  fetchingMore: boolean
  mobileFiltersOpen: boolean
  displayType: DisplayTypeEnum
  productsTop: number
}

const DEFAULT_STATE: CategoryState = {
  fetchingMore: false,
  mobileFiltersOpen: false,
  displayType: DisplayTypeEnum.GRID,
  productsTop: 0,
}

export interface CategoryProps {
  category?: CategoryFragment
  loading: boolean
}

export function Category({ category, loading: categoryLoading }: CategoryProps): JSX.Element {

  const navigate = useNavigate()
  const location = useLocation()

  const [state, setState] = useState<CategoryState>({ ...DEFAULT_STATE })
  const query = qs.parse(location.search.replace('?', ''))
  const queryFilters = query?.filters as { [k: string]: any } || {}
  const currentPage = parseInt(query?.page as string || '1')
  const filters = { ...queryFilters }
  const order = query?.order as unknown as OrderValue || { field: 'position', direction: OrderDirectionEnum.ASC }
  const where = filters?.categoryUid?.in ? { categoryUid: { in: [category?.uid] } } : { categoryUid: { eq: category?.uid } }
  const combinedFilters = deepmerge(filters || {}, where || {})
  const productGridRef: RefObject<HTMLDivElement> = useRef()
  const scrollRef: MutableRefObject<number> = useRef(0)
  const pathRef: MutableRefObject<string> = useRef('')
  const { data: aggregationData, loading: aggreationLoading } = useGetAllAggregationsQuery({
    skip: !category?.uid,
    variables: {
      filters: combinedFilters,
    },
  })

  const aggregations = useLoadingData<Readonly<AggregationFragment[]>>({
    data: aggregationData?.allProducts?.aggregations,
    loading: aggreationLoading,
    defaultData: [],
  })

  const valueTypesToFilter = ['values_ingredient', 'values_environmental', 'values_social', 'values_diet']

  const values = aggregations?.filter((aggregation) => valueTypesToFilter.includes(aggregation?.attributeCode))

  const updateSessionScroll = () => {
    if (pathRef.current && scrollRef.current) {
      sessionStorage.setItem(pathRef.current, scrollRef.current+'')
    }
  }

  const updateTop = (): void => {
    if (productGridRef.current) {
      const top = productGridRef.current.offsetTop
      setState((prevState) => update(prevState, {
        productsTop: {
          $set: top,
        },
      }))
    }
  }

  const _handleScroll = () => {
    const position = window.pageYOffset
    scrollRef.current = position
    updateSessionScroll()
  }

  const _handleDisplayTypeChange = (displayType: DisplayTypeEnum): void => {
    setState((prevState) => update(prevState, {
      displayType: { $set: displayType },
    }))
    _handleScroll()
  }

  const _handleOrderChange = (newOrder: OrderValue): void => {
    const newQueryString = qs.stringify({ filters, order: newOrder })
    navigate(`${location.pathname}?${newQueryString}`)
    _handleScroll()
  }

  const _handleFetchMore = (): void => {
    const newQueryString = qs.stringify({ filters, order, page: currentPage + 1 })
    navigate(`${location.pathname}?${newQueryString}`)
    _handleScroll()
  }

  const _handleFiltersChange = (filters: ProductFilterInputs): void => {

    const newFilters = { ...filters } as Mutable<ProductFilterInputs>
    delete newFilters.name
    let subCategoryId: string | null = null
    if (newFilters?.categoryUid?.in?.[0]) {
      subCategoryId = newFilters.categoryUid.in[0]
      delete newFilters.categoryUid
    }
    const newQueryString = qs.stringify({ filters: newFilters, order })
    if (subCategoryId) {
      const canonicalUrl = category?.children?.find((c) => c.uid === subCategoryId)?.canonicalUrl
      navigate(`/${canonicalUrl}?${newQueryString}`)
    } else {
      navigate(`${location.pathname}?${newQueryString}`)
    }
    _handleScroll()
  }

  const _handleToggleFilter = () => {
    NavigationPlugin.shared().closeSearch()
    setState((prevState) => update(prevState, {
      mobileFiltersOpen: { $set: !state.mobileFiltersOpen },
    }))
  }

  const _handleAllProductsLoaded = () => {
    const key = location.pathname+location.search
    const value = sessionStorage.getItem(key)
    if (value) {
      window.scroll(0, parseInt(value))
    }
  }

  const _handleValuesLayoutUpdated = () => {
    updateTop()
  }

  useEffect(() => {
    if (category?.defaultSortBy) {
      setState((prevState) => update(prevState, {
        order: {
          $set: {
            field: camelCase(category.defaultSortBy),
            direction: OrderDirectionEnum.ASC,
          },
        },
      }))
    }
  }, [category?.defaultSortBy])

  useIsomorphicLayoutEffect(() => {
    updateTop()
  }, [])

  useEffect(() => {
    window.addEventListener('scroll', _handleScroll, { passive: true })
    return () => {
      window.removeEventListener('scroll', _handleScroll)
    }
  }, [])

  useEffect(() => {
    pathRef.current = location.pathname+location.search
    updateSessionScroll()
  }, [location.pathname, location.search])

  let breadCrumb!: BreadcrumbFragment
  let section!: PageSectionGroupFragment
  const hasContent = !!category?.pageContent
  const actualBasePath = category?.categoryType === CategoryTypeEnum.CATEGORY && !category.isVirtualCategory ? `/${category.canonicalUrl}` : ''
  const overwriteBreadcrumbs: BreadcrumbFragment[] | undefined = category.breadcrumbs
    ? [...category.breadcrumbs, {
      categoryId: category.id,
      categoryLevel: 0,
      categoryName: category.name,
      categoryUid: category.uid,
      categoryUrlKey: category.urlKey,
      categoryUrlPath: category.canonicalUrl,
      __typename: 'Breadcrumb',
    }]
    : [{
      categoryId: category.id,
      categoryLevel: 0,
      categoryName: category.name,
      categoryUid: category.uid,
      categoryUrlKey: category.urlKey,
      categoryUrlPath: category.canonicalUrl,
      __typename: 'Breadcrumb',
    }]
  const seo: SearchEngineOptimizationParams = {
    name: category?.name,
    title: category?.name,
    meta: [], // <---- TODO
  }

  return (
    <Container>
      <SearchEngineOptimization seo={seo} />
      <LayoutGrid>
        <LayoutRow>
          <LayoutCol span={{ mobile: 10, tablet: 10, desktop: 12 }}>
            <BreadCrumbs>
              <BreadCrumb title='Home' href='/' />
              <For each='breadCrumb' of={category?.breadcrumbs || []}>
                <BreadCrumb title={breadCrumb.categoryName} key={breadCrumb.categoryUrlKey} href={`/${breadCrumb.categoryUrlPath}`} />
              </For>
              <BreadCrumb title={category?.name} href={`/${category?.canonicalUrl}`} />
            </BreadCrumbs>
          </LayoutCol>
        </LayoutRow>
        <If condition={hasContent}>
          <LayoutRow>
            <LayoutCol span={{ mobile: 10, tablet: 10, desktop: 12 }}>
              <SectionContainer>
                <For each='section' of={category.pageContent.content.sectionGroups}>
                  <PageSectionGroup pageSectionGroup={section} key={section.id} />
                </For>
              </SectionContainer>
            </LayoutCol>
          </LayoutRow>
        </If>
        <If condition={values.length > 0}>
          <LayoutRow>
            <LayoutCol span={{ mobile: 10, tablet: 10, desktop: 12 }}>
              <ValuesContainer>
                <ShopByValuesFilters
                  values={values}
                  onFilterChange={_handleFiltersChange}
                  onLayoutUpdate={_handleValuesLayoutUpdated}
                  filters={filters}
                  loading={aggreationLoading} />
              </ValuesContainer>
            </LayoutCol>
          </LayoutRow>
        </If>
        <LayoutRow className='content-row'>
          <LayoutCol span={{ mobile: 10, tablet: 3, desktop: 3 }}>
            <ProductFilterContainer>
              <ProductFilters
                open={state.mobileFiltersOpen}
                aggregations={aggregations}
                loading={aggreationLoading}
                filters={filters}
                onFilterChange={_handleFiltersChange}
                onToggleFilters={_handleToggleFilter} />
            </ProductFilterContainer>
          </LayoutCol>
          <LayoutCol span={{ mobile: 10, tablet: 7, desktop: 9 }}>
            <ProductGridContainer>
              <If condition={!hasContent}>
                <CategoryHeader category={category} />
              </If>
              <CatalogueHeader
                productsTop={state.productsTop}
                aggregations={aggregations}
                filters={filters}
                count={aggregationData?.allProducts?.totalCount || 0}
                loading={aggreationLoading}
                displayType={state.displayType}
                order={order}
                availableSortBy={category?.availableSortBy}
                onDisplayTypeChange={_handleDisplayTypeChange}
                onOrderChange={_handleOrderChange}
                onToggleFilters={_handleToggleFilter}
                onFilterChange={_handleFiltersChange} />
              <ProductsContainer ref={productGridRef}>
                <ProductGrid
                  filters={combinedFilters}
                  userFilters={filters}
                  order={order}
                  productsPerPage={PRODUCTS_PER_PAGE}
                  currentPage={currentPage}
                  overwriteBreadCrumbs={overwriteBreadcrumbs}
                  basePath={actualBasePath}
                  displayType={state.displayType}
                  category={category}
                  onFetchMore={_handleFetchMore}
                  onFilterChange={_handleFiltersChange}
                  onLoaded={_handleAllProductsLoaded} />
              </ProductsContainer>
            </ProductGridContainer>
          </LayoutCol>
        </LayoutRow>
      </LayoutGrid>
    </Container>
  )

}
