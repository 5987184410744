
import { Paragraph } from "@atoms/typography"
import { ResponsivePXValue } from "@components/Theme"
import React, { useCallback, useEffect, useRef, useState } from "react"
import styled from "styled-components"
import { FormContext, FormContextProperties } from '@molecules/inputs/index'
import { Field } from "rc-field-form"


const Track = styled.div`
    position: absolute;
    ${ResponsivePXValue('border-radius', '5px')}
    ${ResponsivePXValue('height', '5px')}
    background-color: ${({ theme }) => theme.colors.grey.gallery};
    width: 100%;
    top: 50%;
    transform: translateY(-50%);
`

const ActiveTrack = styled.div`
    position: absolute;
    ${ResponsivePXValue('border-radius', '5px')}
    ${ResponsivePXValue('height', '5px')}
    background-color: ${({ theme }) => theme.colors.green.greenVogue};
    top: 50%;
    transform: translateY(-50%);
`

const Thumb = styled.div`
    position: absolute;
    ${ResponsivePXValue('height', '18px')}
    ${ResponsivePXValue('width', '18px')}
    border-radius: 50%;
    background-color: ${({ theme }) => theme.colors.green.greenVogue};
    top: 50%;
    transform: translate(-50%, -50%);
    cursor: pointer;
    z-index: 2;
`


interface RangeInputInnerProps {
    value: [number, number]
    onChange: (value: [number, number]) => void
    min: number
    max: number
    step: number
    loading: boolean
    disabled: boolean
}

interface Values {
    value?: { min: number, max: number }
    onChange?: (value: [min: number, max: number]) => void
}

function RangeInputInner({ value, onChange, min, max, step, loading, disabled }: RangeInputInnerProps) {
    
    const trackRef = useRef<HTMLDivElement>(null)
    const minThumbRef = useRef<HTMLDivElement>(null)
    const maxThumbRef = useRef<HTMLDivElement>(null)
    const [minValue, maxValue] = value

  
  
    const updateThumbPositions = useCallback(() => {
        if (trackRef.current && minThumbRef.current && maxThumbRef.current) {
            const trackWidth = trackRef.current.clientWidth
            const minThumbLeft = ((minValue - min) / (max - min)) * trackWidth
            const maxThumbLeft = ((maxValue - min) / (max - min)) * trackWidth
            minThumbRef.current.style.left = `${minThumbLeft}px`
            maxThumbRef.current.style.left = `${maxThumbLeft}px`
        }
    }, [minValue, maxValue, min, max])
  
    useEffect(() => {
        updateThumbPositions()
        window.addEventListener('resize', updateThumbPositions)
        return () => window.removeEventListener('resize', updateThumbPositions)
    }, [updateThumbPositions])

    const clamp = (value: number, lower: number, upper: number) => 
        Math.max(lower, Math.min(value, upper))
    
  
    const updateMinValue = (newMin: number) => {
        const clampedValue = clamp(newMin, min, +maxValue - +step)
        onChange([clampedValue, maxValue])
    }
    const updateMaxValue = (newMax: number) => {
        const clampedValue = clamp(newMax, +minValue + +step, max)
        onChange([minValue, clampedValue])
    }
  
    const handleMouseDown = (thumbRef: React.RefObject<HTMLDivElement>, updateValue: (newValue: number) => void) => (e: React.MouseEvent) => {
        e.preventDefault()

        if (loading || disabled) return


        const startX = e.clientX
        const thumbRect = thumbRef.current!.getBoundingClientRect()
        const trackRect = trackRef.current!.getBoundingClientRect()
        const startPosition = thumbRect.left + thumbRect.width / 2 - trackRect.left
  
        const handleMouseMove = (moveEvent: MouseEvent) => {
            const deltaX = moveEvent.clientX - startX
            const newPosition = clamp(startPosition + deltaX, 0, trackRect.width)
            const percentage = newPosition / trackRect.width
            const newValue = Math.round(percentage * (max - min) / step) * step + min
            updateValue(newValue)
        }
  
        const handleMouseUp = () => {
            document.removeEventListener('mousemove', handleMouseMove)
            document.removeEventListener('mouseup', handleMouseUp)
        }
  
        document.addEventListener('mousemove', handleMouseMove)
        document.addEventListener('mouseup', handleMouseUp)
    }
  
    return (
        <>
            <Track ref={trackRef} />
            <ActiveTrack
                style={{
                left: `${((minValue - min) / (max - min)) * 100}%`,
                right: `${100 - ((maxValue - min) / (max - min)) * 100}%`,
            }}/>
            <Thumb
                ref={minThumbRef}
                onMouseDown={handleMouseDown(minThumbRef, updateMinValue)}
                style={{ cursor: loading || disabled ? 'not-allowed' : 'pointer' }}/>
            <Thumb
                ref={maxThumbRef}
                onMouseDown={handleMouseDown(maxThumbRef, updateMaxValue)}
                style={{ cursor: loading || disabled ? 'not-allowed' : 'pointer' }}/>
        </>
    )
  }

const SliderContainer = styled.div`
    position: relative;
    width: 100%;
    ${ResponsivePXValue('height', '65px')}
`

const RangeLabels = styled.div`
    display: flex;
    justify-content: space-between;
    ${ResponsivePXValue('margin-bottom', '14px')}
`
  

interface RangeInputState {
    minValue: number
    maxValue: number
}

const DEFAULT_STATE: RangeInputState = {
    minValue: 0,
    maxValue: 0,
} 

export interface RangeInputProps {
    name: string
    min: number 
    max: number
    step: number
    loading?: boolean
}

export function RangeInput({ name, min, max, step, loading = false }: RangeInputProps) {

    const trackRef = useRef(null)
    const minThumbRef = useRef(null)
    const maxThumbRef = useRef(null)

    return (
        <Field name={name}>
        {(control: { value?: [number, number]; onChange?: (value: [number, number]) => void }) => {
            const { value = [min, max], onChange = () => {} } = control
            return (
                <SliderContainer>
                    <RangeLabels>
                        <Paragraph>R{value[0]}</Paragraph>
                        <Paragraph>R{value[1]}</Paragraph>
                    </RangeLabels>
                    <FormContext.Consumer>
                    {({ loading: formLoading, disabled: formDisabled }: FormContextProperties) => (
                        <RangeInputInner
                            value={value}
                            onChange={onChange}
                            min={min}
                            max={max}
                            step={step}
                            loading={loading || formLoading}
                            disabled={formDisabled}/>    
                    )}
                    </FormContext.Consumer>
                </SliderContainer>
            ) 
        }}
        </Field>
    ) 
}



